import emptySplitApi from '..';

export const getExploreMembersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    updateArchive: build.mutation({
      query: (params) => ({
        url: `archive/${params.courseId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['isArchive', 'learning-communities'],
    }),
  }),
  overrideExisting: false,
});

export const { useUpdateArchiveMutation } = getExploreMembersApi;
