import ReactLoadingSkeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Skeleton = ({ skeletonTheme, skeleton }) => (
  <SkeletonTheme color={skeletonTheme?.color || '#E8EAF6'} highlightColor={skeletonTheme?.highlightColor}>
    <ReactLoadingSkeleton
      count={skeleton?.count}
      width={skeleton?.width}
      height={skeleton?.height}
      circle={skeleton?.circle}
      duration={skeleton?.duration}
      delay={skeleton?.delay}
      wrapper={skeleton?.wrapper}
      {...skeleton}
      containerTestId='skeleton-test-id'
      containerClassName='row skeletons-container'
    />
  </SkeletonTheme>
);

export default Skeleton;
