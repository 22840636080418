import classNames from 'classnames';
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';

import InitialThoughtCardWrapper from './style/InitialThoughtCardWrapper';

const InitialThoughtCard = forwardRef(({ title, children, footer = null, icon, $background, className }, ref) => {
  const { role } = useParams();
  const isMember = role === 'member';

  return (
    <InitialThoughtCardWrapper
      $background={$background}
      className={classNames('row', { 'no-hover-shadow': isMember }, { 'has-footer': footer }, className)}
      ref={ref}
    >
      <div className='col d-flex justify-content-between card-icons'>
        {title}
        {icon && <div className='d-flex hover-icons'>{icon}</div>}
      </div>
      <div className='col-12 content-section'>{children}</div>
      {footer}
    </InitialThoughtCardWrapper>
  );
});

export default InitialThoughtCard;
