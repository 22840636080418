import emptySplitApi from '..';

export const organizationReviewListApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationReviews: build.query({
      query: (params) => ({
        url: `/organizations/${params.urlName}/review-list`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
      providesTags: ['organization-reviews'],
    }),
    createOrganizationReview: build.mutation({
      query: (params) => ({
        url: `organizations/${params.urlName}/review-list`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['organization-reviews'],
    }),
    updateOrganizationReview: build.mutation({
      query: (params) => ({
        url: `organizations/${params.urlName}/review-list`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['organization-reviews'],
    }),
    deleteOrganizationReview: build.mutation({
      query: (params) => ({
        url: `organizations/${params.urlName}/review-list/${params.id}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['organization-reviews'],
    }),
  }),
  overrideExisting: false,
});

export default organizationReviewListApi;
export const {
  useGetOrganizationReviewsQuery,
  useCreateOrganizationReviewMutation,
  useDeleteOrganizationReviewMutation,
  useUpdateOrganizationReviewMutation,
} = organizationReviewListApi;
