import emptySplitApi from '..';

const fileManagerMetaApi = emptySplitApi.injectEndpoints({
  reducerPath: 'file-manager-meta',
  endpoints: (build) => ({
    getFileManagerMeta: build.query({
      query: (params) => ({
        url: `/file-manager-meta/${params.id}`,
        method: 'GET',
      }),
    }),

    createFileManagerMeta: build.mutation({
      query: (params) => ({
        url: `/file-manager-meta`,
        method: 'POST',
        body: params,
      }),
    }),

    deleteFileManagerMeta: build.mutation({
      query: (params) => ({
        url: `/file-manager-meta`,
        params,
        method: 'DELETE',
      }),
    }),

    updateFileManagerMeta: build.mutation({
      query: ({ id, ...params }) => ({
        url: `/file-manager-meta/${id}`,
        method: 'PUT',
        body: params,
      }),
    }),
  }),
});

export default fileManagerMetaApi;
export const {
  useGetFileManagerMetaQuery,
  useCreateFileManagerMetaMutation,
  useDeleteFileManagerMetaMutation,
  useUpdateFileManagerMetaMutation,
} = fileManagerMetaApi;
