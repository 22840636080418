import classNames from 'classnames';
import { forwardRef } from 'react';
import Spin from 'style-guide/Spin';
import ButtonWrapper from './ButtonWrapper';

const LoadingSpinner = <Spin className='loading mr--4' width={16} height={16} />;

const Button = forwardRef(
  ({ children, suffix, prefix, size, variant, loading, className, to, type = 'button', ...buttonProps }, ref) => {
    const isTypeObject = typeof children === 'object';
    const buttonClassName = classNames('d-flex align-items-center', className, variant);

    return (
      <ButtonWrapper {...buttonProps} to={to} className={buttonClassName} ref={ref} type={type}>
        {loading && !isTypeObject ? LoadingSpinner : null}
        {prefix && <span className='d-flex align-items-center mr--8'>{prefix}</span>}
        {loading && isTypeObject ? LoadingSpinner : children}
        {suffix && <span className='d-flex align-items-center ml--8'>{suffix}</span>}
      </ButtonWrapper>
    );
  }
);

export default Button;
