import classNames from 'classnames';
import testTypes from 'common/test-types';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AddQuestion from './AddQuestion';
import AssessmentList from './AssessmentList';
import AssessmentWrapper from './style/AssessmentWrapper';

const Assessment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentType = searchParams.get('assessmentType');

  useEffect(() => {
    const typeData = testTypes.getTypeData(assessmentType);
    if (!typeData && assessmentType) {
      setSearchParams({ assessmentType: testTypes.types.TestsTypeTrueFalse });
    }
  }, [assessmentType, searchParams, setSearchParams]);

  return (
    <AssessmentWrapper className='row'>
      <div className={classNames('col-12 card-list-box', { 'assessment-list': !assessmentType })}>
        {assessmentType ? <AddQuestion /> : <AssessmentList />}
      </div>
    </AssessmentWrapper>
  );
};
export default Assessment;
