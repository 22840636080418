import styled from 'styled-components';

const MultipleAnswerWrapper = styled.div`
  .label {
    margin-bottom: 12px;
  }

  .options-box {
    margin-bottom: 16px;
    .trash-icon-wrapper {
      margin-left: 8px;
      margin-top: 4px;
    }
  }
  .multiple-answer-item {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .checkbox-wrapper {
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
    background-color: ${({ theme }) => theme.colors.white[100]};
    box-sizing: border-box;
    border-radius: 4px;

    .checkbox {
      margin-left: 12px;
    }

    .multiple-answer-input {
      border: none;
      outline: none;
      height: 30px;
      margin-left: 8px;
      flex: 1;
    }
  }

  .has-error {
    border: 1px solid ${({ theme }) => theme.colors.error[600]};
    background-color: ${({ theme }) => theme.colors.error[200]};
  }
  .success {
    border: 1px solid ${({ theme }) => theme.colors.primary[600]};
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`;

export default MultipleAnswerWrapper;
