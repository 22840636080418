import { createSlice } from '@reduxjs/toolkit';

const audioTranscriptSlice = createSlice({
  name: 'audioTranscript',
  initialState: { text: '' },
  reducers: {
    setAudioTranscriptText: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { setAudioTranscriptText } = audioTranscriptSlice.actions;
export default audioTranscriptSlice.reducer;
