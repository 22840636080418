import USER_COURSE_TYPES from './user-course-types';
import USER_LIBRARY_TYPES from './user-library-types';
import USER_ORGANIZATION_TYPES from './user-organization-types';
import ENTITY_TYPES from './entity-types';

const { organization, library, course } = ENTITY_TYPES;

const constants = {
  Administrator: 'Administrator',

  _OrganizationViewManage: 'view.manage',
  _OrganizationViewCreate: 'view.create',
  _OrganizationViewAdd: 'view.add',
  _OrganizationViewRemove: 'view.remove',
  _OrganizationManage: 'manage',

  _LibraryViewManage: 'view.manage',
  _LibraryViewCreate: 'view.create',
  _LibraryViewAdd: 'view.add',
  _LibraryViewRemove: 'view.remove',
  _LibraryManage: 'manage',

  _CourseManage: 'manage',
  _CourseDetailsView: 'details.view',
  _CourseDetailsViewSave: 'details.view.save',
  _CourseDetailsEdit: 'details.edit',
  _CourseDetailsDelete: 'details.delete',
  _CourseDetailsReview: 'details.review',

  _SectionExpertResourceEdit: 'section.expertResource.edit',
  _SectionExpertResourceAdd: 'section.expertResource.add',

  _CourseVersionEdit: 'version.edit',
  _CourseVersionDelete: 'version.delete',
  _CourseVersionAccept: 'version.accept',

  _OrganizationAdminOrCoAdmin: 'admin.coAdmin',
  _LibraryAdminOrCoAdmin: 'admin.coAdmin',
  _CourseAdminOrCoAdmin: 'admin.coAdmin',
  _CourseStudent: 'student',
};

const getPermissionsArray = (type = null) => {
  const permissions = {
    [organization]: {
      [constants._OrganizationViewManage]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants._OrganizationViewCreate]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants._OrganizationViewAdd]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants._OrganizationViewRemove]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants._OrganizationManage]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants._OrganizationAdminOrCoAdmin]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [constants.Administrator]: [USER_ORGANIZATION_TYPES.administrator.level],
    },
    [library]: {
      [constants._LibraryViewManage]: [
        USER_LIBRARY_TYPES.administrator.level,
        USER_LIBRARY_TYPES.coAdministrator.level,
      ],
      [constants._LibraryViewCreate]: [
        USER_LIBRARY_TYPES.administrator.level,
        USER_LIBRARY_TYPES.coAdministrator.level,
      ],
      [constants._LibraryViewAdd]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [constants._LibraryViewRemove]: [
        USER_LIBRARY_TYPES.administrator.level,
        USER_LIBRARY_TYPES.coAdministrator.level,
      ],
      [constants._LibraryManage]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [constants._LibraryAdminOrCoAdmin]: [
        USER_LIBRARY_TYPES.administrator.level,
        USER_LIBRARY_TYPES.coAdministrator.level,
      ],
      [constants.Administrator]: [USER_LIBRARY_TYPES.administrator.level],
    },
    [course]: {
      [constants._CourseManage]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
      ],
      [constants._CourseDetailsView]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.reviewer.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
      [constants._CourseDetailsViewSave]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
      [constants._CourseDetailsEdit]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
      [constants._CourseDetailsDelete]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
      ],
      [constants._SectionExpertResourceEdit]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
      [constants._SectionExpertResourceAdd]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
      [constants._CourseVersionEdit]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
      [constants._CourseAdminOrCoAdmin]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
      ],
      [constants._CourseVersionDelete]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
      ],
      [constants._CourseVersionAccept]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
      ],
      [constants._CourseStudent]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
      [constants._CourseDetailsReview]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.reviewer.level,
      ],
      [constants.Administrator]: [USER_COURSE_TYPES.administrator.level],
    },
  };

  return type ? permissions[type] : permissions;
};

const hasPermission = (type, permission, typeId) => {
  if (!type || !permission) {
    return false;
  }

  return getPermissionsArray(type)[permission].includes(typeId);
};

const USER_PERMISSION = {
  ...constants,
  hasPermission,
  getPermissionsArray,
};

export default USER_PERMISSION;
