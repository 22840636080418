import styled from 'styled-components';
import Divider from './DividerWithText';

const DividerWithText = styled(Divider)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px 0;
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary[200]};
  }
  &:before {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary[200]};
  }
  &:not(:empty)::before {
    margin-right: 18px;
  }

  &:not(:empty)::after {
    margin-left: 18px;
  }
`;

export default DividerWithText;
