import styled from 'styled-components';

const IdleClockWrapper = styled.div`
  margin-bottom: 16px;

  .clock {
    width: 30px;
    height: 30px;
    font-size: 500%;
    position: relative;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary[800]};
    display: inline-block;
    color: ${({ theme }) => theme.colors.white[100]};
  }

  .clock::before,
  .clock::after {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    border-radius: 0.5em 0 0 0.5em;
    transform-origin: center right;
    animation-duration: ${(props) => `${props.duration}ms`};
  }

  .clock::before {
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.primary[800]};
    animation-name: mask;
    animation-timing-function: steps(1);
  }

  .clock::after {
    background-color: ${({ theme }) => theme.colors.white[100]};
    animation-name: rotate;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes mask {
    50%,
    100% {
      background-color: ${({ theme }) => theme.colors.white[100]};
      transform: rotate(0.5turn);
    }
  }
`;

export default IdleClockWrapper;
