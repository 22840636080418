import emptySplitApi from '..';

export const fileManagerApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getFiles: build.query({
      query: (params) => ({
        url: '/file-manager',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
      providesTags: ['getFiles'],
    }),

    removeFile: build.mutation({
      query: (params) => ({
        url: `/file-manager/${params.id}?type=all`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['getFiles'],
    }),

    uploadFile: build.mutation({
      query: (params) => ({
        url: `/file-manager?type=all&keepContentType=true`,
        method: 'POST',
        body: params.data,
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFilesQuery, useRemoveFileMutation, useUploadFileMutation } = fileManagerApi;
export default fileManagerApi;
