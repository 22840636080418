import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const AddSocialMediaWrapper = styled.div`
  .add-social-media-modal-root {
    width: 484px;

    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: 288px;
    }

    .list-root {
      .list-item-root {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .left-box {
          margin-top: 2px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
            margin-bottom: 22px;
          }

          .icon-box {
            margin-right: 12px;
            width: 30px;
            height: 30px;
          }

          .title {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.dark[80]};
          }
        }

        .right-box {
          display: flex;
          align-items: flex-start;

          @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
            width: 100%;
          }

          .form-component {
            @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
              width: 100%;
            }
          }

          input {
            width: 316px;
            padding-right: 30px !important;

            @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
              width: 100%;
            }
          }

          .close-button {
            position: absolute;
            right: 40px;
            outline: none;
            border: none;
            background-color: transparent;
            margin: 11px 2px 2px 2px;
            padding: 0;

            &:hover {
              box-shadow: none;
            }

            @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
              right: 30px;
            }

            .icon {
              width: 16px;
              height: 16px;
              color: ${({ theme }) => theme.colors.dark[100]};
              fill: currentColor;
            }
          }
        }
      }
    }
  }
`;

export default AddSocialMediaWrapper;
