import { getDomainTracking } from 'configs/localStorage';
import MODAL_TYPES from 'constants/modals';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  useGetAdminUsersQuery,
  useLazyGetAdminUsersQuery,
  useVerifyUserMutation,
} from 'store/services/superAdmin/AdminUsers';
import { showModal } from 'store/slices/modal';
import Button from 'style-guide/Button';
import { Download } from 'style-guide/Icons';
import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';
import { message } from 'style-guide/ToastMessages';
import params from '../helper/helperFunction';
import getColumns from './columns';
import AdminUserWrapper from './style/AdminUserWrapper';

const AdminUsers = () => {
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const domainId = getDomainTracking().id;
  const domainKey = getDomainTracking().key;

  const { data } = useGetAdminUsersQuery(params({ page, domainId, searchParams }, domainKey), {
    refetchOnMountOrArgChange: true,
  });

  const isSuperAdmin = data?.isSuperAdmin;
  const [getUsersData, { isLoading }] = useLazyGetAdminUsersQuery();

  const download = async () => {
    await getUsersData({ domainId, domainKey, exportExcel: 1 })
      .then(({ data: result }) => {
        const a = document.createElement('a');
        a.href = encodeURI(`data:text/csv;charset=utf-8,${result?.csvData}`);
        a.download = 'Users List.csv';
        a.click();
        a.remove();
      })
      .catch((error) => message.error(error));
  };

  const [verifyUser] = useVerifyUserMutation();

  const showAddUserModal = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.ADMIN_ADD_USER_MODAL,
        params: {
          modal: { title: 'Add user', $top: 90 },
        },
      })
    );
  };

  return (
    <AdminUserWrapper>
      <div className='d-flex justify-content-end add-button'>
        <Button
          loading={isLoading}
          variant='secondary'
          className='mr--16'
          onClick={() => download()}
          prefix={<Download />}
        >
          Download users
        </Button>
        <Button variant='secondary' onClick={showAddUserModal}>
          Add users
        </Button>
      </div>
      <Table
        columns={getColumns({
          verifyUser,
          page,
          isSuperAdmin,
        })}
        scroll={{ x: 1400 }}
        rowKey={(row) => row.id}
        data={data?.rows}
      />
      <Pagination total={data?.count || 0} current={page} onChange={setPage} className='pagination' />
    </AdminUserWrapper>
  );
};

export default AdminUsers;
