import Button from 'style-guide/Button';
import ConfirmationModalWrapper from './style/ConfirmationModalWrapper';

const ConfirmationModal = ({ hideModal, params: { onOk, onCancel, title } }) => (
  <ConfirmationModalWrapper className='row'>
    <div className='col-12 d-flex justify-content-center pb--40'>{title}</div>
    <div className='col-12 d-flex justify-content-center'>
      <Button
        className='ph--40 mr--16'
        variant='secondary'
        onClick={() => {
          hideModal();
          onCancel?.();
        }}
      >
        Cancel
      </Button>
      <Button
        className='ph--48'
        variant='primary'
        onClick={() => {
          hideModal();
          onOk?.();
        }}
      >
        Yes
      </Button>
    </div>
  </ConfirmationModalWrapper>
);

export default ConfirmationModal;
