const Upload = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.00008 5.41412V10.9999H7.00008V5.41412L3.70718 8.70701L2.29297 7.2928L8.00008 1.58569L13.7072 7.2928L12.293 8.70701L9.00008 5.41412Z'
      fill='none'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M2 15V13H14V15H2Z' fill='none' />
  </svg>
);

export default Upload;
