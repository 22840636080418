import LABEL_STATUS from 'common/label-status';
import { CheckCircleOutlined, CircleMinus, CloseCircleOutlined, SyncOutlined, Warning } from 'style-guide/Icons';

const iconProps = { height: 10, width: 10 };
const labels = {
  [LABEL_STATUS.DEFAULT]: { colorNumber: 20, icon: CircleMinus },
  [LABEL_STATUS.ERROR]: { colorNumber: 40, icon: CloseCircleOutlined },
  [LABEL_STATUS.PROCESSING]: { colorNumber: 60, icon: SyncOutlined },
  [LABEL_STATUS.SUCCESS]: { colorNumber: 80, icon: CheckCircleOutlined },
  [LABEL_STATUS.WARNING]: { colorNumber: 100, icon: Warning },
};

export const handleTypeProps = ({ type, theme }) => {
  const color = theme?.colors?.status;
  const label = labels[type];

  const Icon = label?.icon;
  const labelColor = color?.[label?.colorNumber];
  return { color: labelColor, icon: <Icon {...iconProps} /> };
};
