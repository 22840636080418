import { ENTITY_TYPES } from 'common';
import { useEffect, useMemo, useState } from 'react';
import { useGetUnjoinedCoursesQuery, useJoinUserCoursesMutation } from 'store/services/superAdmin/AdminCourses';
import Button from 'style-guide/Button';
import Select from 'style-guide/Select';
import { message } from 'style-guide/ToastMessages';
import JoinUserToCoursesModalWrapper from './style/JoinUserToCoursesModalWrapper';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const JoinUserToCoursesModal = ({ hideModal, params: { id } }) => {
  const { data } = useGetUnjoinedCoursesQuery({ id });
  const [joinUser, { isSuccess, isError }] = useJoinUserCoursesMutation();
  const [coursesId, setCoursesId] = useState([]);

  const options = useMemo(
    () =>
      data?.map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      })),
    [data]
  );

  useEffect(() => {
    if (isSuccess) {
      hideModal();
      message.success('User has been successfully Joined to Learning Communities!');
    }
    if (isError) {
      message.error('Something went wrong!');
    }
  }, [isSuccess, isError, hideModal]);

  return (
    <JoinUserToCoursesModalWrapper>
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={options}
        onChange={(value) => setCoursesId(value.map((e) => e.id))}
        placeholder={`Select the  ${LEARNING_COMMUNITY.singular}`}
      />
      <div className='d-flex justify-content-center mt--24'>
        <Button variant='secondary' className='mr--8' onClick={() => hideModal()}>
          Cancel
        </Button>
        <Button variant='primary' onClick={() => joinUser({ courses: coursesId, userId: id })}>
          Submit
        </Button>
      </div>
    </JoinUserToCoursesModalWrapper>
  );
};

export default JoinUserToCoursesModal;
