import { useNavigate } from 'react-router-dom';

import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';

import PATHS from 'constants/paths';

import HelmetComponent from 'Components/Helmet';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import NotFoundWrapper from './style/NotFoundWrapper';

const NotFound = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(PATHS.HOME);
  };

  return (
    <NotFoundWrapper className='row justify-content-center align-items-center text-align-center'>
      <HelmetComponent title='Not Found' />
      <div className='col not-found-root'>
        <Title variant={2} color={theme().colors.primary[800]} className='title'>
          Oops...
        </Title>
        <Title variant={5} className='sub-title'>
          Page is not found
        </Title>
        <Text $variant={2} className='info'>
          The page you are looking for might be removed or temporarily unavailable
        </Text>
        <div className='mt--16 d-flex justify-content-center'>
          <Button variant='primary' onClick={navigateToHome}>
            Go To Home
          </Button>
        </div>
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
