import styled from 'styled-components';
import dropdownList from './DropdownList';

const DropdownList = styled(dropdownList)`
  &.rc-menu {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]} !important;
    border-radius: 4px;
    li {
      padding: 6px 8px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.colors.white[100]};
      cursor: pointer;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.dark[80]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary[50]};
      }
      &:nth-child(1) {
        box-shadow: none;
      }
      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }
`;

export default DropdownList;
