import styled from 'styled-components';
import checkBox from './CheckBox';

const CheckBox = styled(checkBox)`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &.checkbox {
    position: relative;
    line-height: 0;
    input[type='checkBox'] {
      border-radius: 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 2px solid ${({ theme }) => theme.colors.dark[25]};
      width: 15px;
      height: 15px;
      margin: 0;
      box-sizing: border-box;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:checked {
        background-color: ${({ theme }) => theme.colors.primary[800]};
        border: none;
        &:disabled {
          background-color: ${({ theme }) => theme.colors.dark[25]};
        }
      }
      &:disabled {
        border-color: ${({ theme }) => theme.colors.dark[25]};
      }
    }
    .checked {
      z-index: 10;
      position: absolute;
      inset: 0;
      top: 4px;
      left: 2px;
    }
  }
`;
export default CheckBox;
