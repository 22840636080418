import { useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import SCREEN_SIZES from 'constants/screen-sizes';

function usePlatform() {
  const { innerWidth } = useWindowSize();
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
  });

  useEffect(() => {
    if (innerWidth <= SCREEN_SIZES.MD) {
      setScreenSize({ isTablet: false, isMobile: true });
    } else if (innerWidth >= SCREEN_SIZES.MD && innerWidth < SCREEN_SIZES.XL) {
      setScreenSize({ isMobile: false, isTablet: true });
    } else {
      setScreenSize({ isMobile: false, isTablet: false });
    }
  }, [innerWidth]);

  return screenSize;
}

export default usePlatform;
