export const itemClickHandle = (item, editor, setLinkModalVisible) => {
  if (item === 'quote') {
    editor.can().unsetBlockquote()
      ? editor.chain().unsetBlockquote().focus().run()
      : editor.chain().setBlockquote().focus().run();
    return;
  }
  if (item === 'highlight') {
    !editor.isActive('highlight')
      ? editor.chain().setHighlight().focus().run()
      : editor.chain().unsetHighlight().focus().run();
    return;
  }
  if (item === 'link' && editor?.isFocused) {
    setLinkModalVisible(true);
  }
};

export const typographyItemClickHandle = (item, editor) => {
  if (item === 'title1') {
    editor.chain().focus().toggleHeading({ level: 3 }).run();
    return;
  }
  if (item === 'title2') {
    editor.chain().focus().toggleHeading({ level: 2 }).run();
    return;
  }
  if (item === 'paragraph') {
    editor.chain().setParagraph().run();
  }
};
