import { createSlice } from '@reduxjs/toolkit';

const entitySidebarSlice = createSlice({
  name: 'entitySidebar',
  initialState: { isOpen: false },
  reducers: {
    openSidebar(state) {
      return { ...state, isOpen: true };
    },
    closeSidebar(state) {
      return { ...state, isOpen: false };
    },
    toggleEntitySidebar(state) {
      return { ...state, isOpen: !state.isOpen };
    },
  },
});

export const { toggleEntitySidebar, openSidebar, closeSidebar } = entitySidebarSlice.actions;
export default entitySidebarSlice.reducer;
