const TypographyGroupIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9688 8.125H12.0312C11.9453 8.125 11.875 8.19531 11.875 8.28125V10.4688C11.875 10.5547 11.9453 10.625 12.0312 10.625H12.9688C13.0547 10.625 13.125 10.5547 13.125 10.4688V9.375H14.2969V15.625H13.3984C13.3125 15.625 13.2422 15.6953 13.2422 15.7812V16.7188C13.2422 16.8047 13.3125 16.875 13.3984 16.875H16.6016C16.6875 16.875 16.7578 16.8047 16.7578 16.7188V15.7812C16.7578 15.6953 16.6875 15.625 16.6016 15.625H15.7031V9.375H16.875V10.4688C16.875 10.5547 16.9453 10.625 17.0312 10.625H17.9688C18.0547 10.625 18.125 10.5547 18.125 10.4688V8.28125C18.125 8.19531 18.0547 8.125 17.9688 8.125ZM12.8125 5.78125V3.28125C12.8125 3.19531 12.7422 3.125 12.6562 3.125H2.03125C1.94531 3.125 1.875 3.19531 1.875 3.28125V5.78125C1.875 5.86719 1.94531 5.9375 2.03125 5.9375H3.125C3.21094 5.9375 3.28125 5.86719 3.28125 5.78125V4.53125H6.5625V15.4688H4.76562C4.67969 15.4688 4.60938 15.5391 4.60938 15.625V16.7188C4.60938 16.8047 4.67969 16.875 4.76562 16.875H9.92188C10.0078 16.875 10.0781 16.8047 10.0781 16.7188V15.625C10.0781 15.5391 10.0078 15.4688 9.92188 15.4688H8.125V4.53125H11.4062V5.78125C11.4062 5.86719 11.4766 5.9375 11.5625 5.9375H12.6562C12.7422 5.9375 12.8125 5.86719 12.8125 5.78125Z'
      fill='#818181'
    />
  </svg>
);

export default TypographyGroupIcon;
