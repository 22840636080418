const LEARNING_COMMUNITY_SECTIONS = {
  STORY: {
    id: 1,
    name: 'Story',
    value: 'story',
    key: 'mc',
    icon: 'book',
  },
  INITIAL_THOUGHTS: {
    id: 2,
    name: 'Initial Thoughts',
    value: 'initial-thoughts',
    key: 'it',
    icon: 'brain',
  },
  EXPERT_RESOURCES: {
    id: 3,
    name: 'Resources',
    value: 'resources',
    key: 'er',
    icon: 'lighter',
  },
  NEW_INSIGHTS: {
    id: 4,
    name: 'New Insights',
    value: 'new-insights',
    key: 'ni',
    icon: 'lump',
  },
  ASSESSMENT: {
    id: 5,
    name: 'Assessment',
    value: 'assessment',
    key: 't',
    icon: 'test',
  },
};

export default LEARNING_COMMUNITY_SECTIONS;
