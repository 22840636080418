import SCREEN_SIZES from 'constants/screen-sizes';
import Menu from 'style-guide/Menu';
import styled from 'styled-components';

const MenuWrapper = styled(Menu)`
  &.rc-menu {
    margin-top: 8px;
    border-radius: 8px;
  }
  .rc-menu-item {
    padding: 8px !important;
    white-space: unset;
    &:first-of-type {
      &:hover {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
  .notification-content {
    margin: 0 16px;
    word-break: break-all;
    box-sizing: border-box;
    max-width: 300px;
    width: max-content;
    display: block;
    @media only screen and (max-width: ${SCREEN_SIZES.SM}px) {
      max-width: calc(100vw - 160px);
    }
  }
  .check-icon {
    path {
      fill: ${({ theme }) => theme.colors.primary[800]};
    }
  }
  .clock-icon,
  .check-icon {
    height: 32px;
    margin-left: 8px;
  }
`;

export default MenuWrapper;
