const MultipleChoice = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <circle opacity='0.15' cx='12' cy='12' r='8' fill='#E8AF00' />
    <circle cx='12' cy='12' r='7' stroke='#E8AF00' strokeWidth='2' />
    <circle cx='12' cy='12.0007' r='3.52807' fill='#E8AF00' />
  </svg>
);

export default MultipleChoice;
