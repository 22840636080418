import moment from 'moment';
import Button from 'style-guide/Button';
import Text from 'style-guide/Typography/Text';

const EntityAdminFooter = ({ url, lastChange, price, accessCodeResalePrice }) => (
  <>
    <div className='d-flex justify-content-between'>
      <Text className='last-updated-content' $variant={2}>
        Last edited: {moment(lastChange).fromNow()}
      </Text>
      <Text $variant={2}>{accessCodeResalePrice ? `$${accessCodeResalePrice}` : price}</Text>
    </div>
    <Button to={url} variant='secondary' className='mt--16' $block>
      Edit
    </Button>
  </>
);
export default EntityAdminFooter;
