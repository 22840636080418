import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';

import { ENTITY_TYPES } from 'common';
import { Eye, Lock, Pencil, Trash } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';

const { ORGANIZATION } = ENTITY_TYPES;

const coursePath = (item) => `/${ORGANIZATION.key}/${item.id}/${item.urlName}/organizer/homepage`;
const courseSettingsPath = (item) => `/${ORGANIZATION.key}/manage/edit-settings/${item.id}/${item.urlName}`;

const setModalTitleText = (modalTitle, item) => {
  switch (modalTitle) {
    case 'delete':
      return (
        <Text $variant={2} $dark={100}>
          You want to delete the organization?
        </Text>
      );
    case 'archive':
      return (
        <Text $variant={2} $dark={100}>
          {`You want to ${item.isArchive === true ? 'unarchive' : 'archive'} the organization?`}
        </Text>
      );
    default:
      return null;
  }
};

const showConfirmationModal = (dispatch, deleteDepartment, updateArchive, item, title) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: <Title>Are you sure</Title>,
        },
        body: {
          title: setModalTitleText(title, item),
          onOk: () => {
            switch (title) {
              case 'delete':
                deleteDepartment({ id: item.id });
                break;
              case 'archive':
                updateArchive({
                  courseId: item.id,
                  isArchive: !item.isArchive,
                  mode: 'organization',
                });
                break;
              default:
                null;
            }
          },
        },
      },
    })
  );
};

export const getOrganizationsColumns = ({ setCourseUserData, dispatch, deleteDepartment, updateArchive }) => [
  {
    title: <SearchDropdown title='Name' name='name' />,
    dataIndex: 'name',
    key: 'name',
    width: 170,
  },
  {
    title: <Title variant={5}>Administrator</Title>,
    dataIndex: 'administratorName',
    key: 'administratorName',
    width: 170,
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
    width: 140,
  },
  {
    title: <Title variant={5}>Payment Type</Title>,
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
    width: 150,
  },
  {
    title: <SearchDropdown title='Price' name='price' />,
    dataIndex: 'price',
    key: 'price',
    width: 130,
  },
  {
    title: <Title variant={5}>Status</Title>,
    dataIndex: 'status',
    key: 'status',
    render: (_index, item) => <Text>{item.status === true ? '___' : ''}</Text>,
    width: 100,
  },
  {
    title: <Title variant={5}>Archive</Title>,
    dataIndex: 'isArchive',
    key: 'isArchive',
    width: 100,
    render: (_index, item) => <Text>{item.isArchive === true ? 'Archived' : ''}</Text>,
  },
  {
    title: <Title variant={5}>Actions</Title>,
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <div className='d-flex align-items-center'>
        <Tooltip placement='topRight' overlay='View Organization'>
          <Eye className='eye pointer action-icon' onClick={() => setCourseUserData(item, coursePath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Edit Organization'>
          <Pencil className='pencil pointer action-icon' onClick={() => setCourseUserData(item, courseSettingsPath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Remove Organization'>
          <Trash
            className='trash pointer action-icon'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'delete')}
          />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Archive Organization'>
          <Lock
            className='lock pointer'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'archive')}
          />
        </Tooltip>
      </div>
    ),
    width: 170,
  },
];

export const COURSES_CONST = {
  NAME: 'name',
  PRICE: 'price',
  COURSES: 'courses',
  LIBRARIES: 'libraries',
  ORGANIZATIONS: 'organizations',
};
