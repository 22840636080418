import styled from 'styled-components';

const CloneEntityModalWrapper = styled.div`
  .buttons {
    gap: 12px;
  }
  .question-text {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 32px;
  }
`;
export default CloneEntityModalWrapper;
