import useWindowSize from 'hooks/useWindowSize';

import ECFLogo from 'assets/ECF/headerLogo.png';
import hippoLogo from 'assets/HippoCampus/hippo-logo.png';
import hippoSmall from 'assets/HippoCampus/hippo-small-logo.png';
import SSALogo from 'assets/SSA/SSALogo.png';

import peerTreeSmall from 'assets/images/peerTreeLogo.svg';
import peerTreeLogo from 'assets/images/peerTreeSmall.svg';

import fscLogo from 'assets/FSC/logo.png';
import { DOMAIN_KEYS } from 'common';

import CSMLogo from 'assets/CSM/logo.png';
import nucleusLogo from 'assets/Nucleus/logo.png';
import pearCoreLogo from 'assets/PearCore/logo.png';
import vanderbiltLogo from 'assets/Vanderbilt/logo.png';

import politicalIslamLogo from 'assets/PoliticalIslam/logo.png';

import mlkLogo from 'assets/Hrights/logo.png';
import meLogo from 'assets/ME/logo.png';

import emLogo from 'assets/EM/logo.png';
import fbaLogo from 'assets/FBA/fbaLogo.png';
import mh360Logo from 'assets/MH360/360logo.png';
import SAQLogo from 'assets/SAQ/SAQ-logo.png';
import spacesLogo from 'assets/Spaces/logo.png';
import tvgLogo from 'assets/TVGuest/logo.png';
import workVibesLogo from 'assets/WorkVibes/logo.png';

import SCREEN_SIZES from 'constants/screen-sizes';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import Image from 'style-guide/Image';

// TODO: add other customers here as well

const GetCustomersLogo = () => {
  const { innerWidth } = useWindowSize();
  const { data } = useGetDomainTracking();
  const isMobile = innerWidth < SCREEN_SIZES.MD;

  switch (data.key) {
    case DOMAIN_KEYS.peertree:
      return <Image className='peer-tree-logo' src={isMobile ? peerTreeSmall : peerTreeLogo} alt='peer-tree' />;
    case DOMAIN_KEYS.hippoCampus:
      return <Image className='hippo-logo' src={isMobile ? hippoSmall : hippoLogo} alt='hippo-campus' />;
    case DOMAIN_KEYS.ssa:
      return <Image className='ssa-logo' src={SSALogo} alt='ssa' />;
    case DOMAIN_KEYS.vanderbilt:
      return <Image className='vanderbilt-logo' src={vanderbiltLogo} alt='vanderbilt' />;
    case DOMAIN_KEYS.fsc:
      return <Image className='fsc-logo' src={fscLogo} alt='Freddie Scott' />;
    case DOMAIN_KEYS.hrights:
      return <Image className='mlk-logo' src={mlkLogo} alt='MLK' />;
    case DOMAIN_KEYS.nucleus:
      return <Image className='nucleus-logo' src={nucleusLogo} alt='Nucleus' />;
    case DOMAIN_KEYS.pear:
      return <Image className='pear-logo' src={pearCoreLogo} alt='Pear Core' />;
    case DOMAIN_KEYS.csm:
      return <Image className='csm-logo' src={CSMLogo} alt='CSM' />;
    case DOMAIN_KEYS.politicalislam:
      return <Image className='political-islam-logo' src={politicalIslamLogo} alt='Political Islam' />;
    case DOMAIN_KEYS.ecf:
      return <Image className='ecf-logo' src={ECFLogo} alt='ECF' />;
    case DOMAIN_KEYS.workvibes:
      return <Image className='workvibes-logo' src={workVibesLogo} alt='workvibes' />;
    case DOMAIN_KEYS.me:
      return <Image className='me-logo' src={meLogo} alt='ME' />;
    case DOMAIN_KEYS.tvguest:
      return <Image className='tvg-logo' src={tvgLogo} alt='TVG' />;
    case DOMAIN_KEYS.spaces:
      return <Image className='spaces-logo' src={spacesLogo} alt='e|spaces' />;
    case DOMAIN_KEYS.em:
      return <Image className='em-logo' src={emLogo} alt='EM' />;
    case DOMAIN_KEYS.fba:
      return <Image className='fba-logo' src={fbaLogo} alt='FBA' />;
    case DOMAIN_KEYS.mh360:
      return <Image className='mh360-logo' src={mh360Logo} alt='MH360' />;
    case DOMAIN_KEYS.saq:
      return <Image className='saq-logo' src={SAQLogo} alt='saq' />;
    default:
      return <Image className='peer-tree-logo' src={isMobile ? peerTreeSmall : peerTreeLogo} alt='peer-tree' />;
  }
};

export default GetCustomersLogo;
