const SOCIAL_PROFILE = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  WECHAT: 'wechat',
  REDDIT: 'reddit',
  SNEPCHAT: 'snapchat',
  PINTEREST: 'pinterest',
  VIMEO: 'vimeo',
};

export default SOCIAL_PROFILE;
