import styled from 'styled-components';

const DropdownBodyWrapper = styled.div`
  .dropdown-body-box {
    width: 300px;
    padding: 0;
    margin-top: 6px;
    box-shadow: 0px 2px 6px ${({ theme }) => theme.colors.boxShadow[15]};
    .item-box {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.white[100]};
      box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.boxShadow[10]};
      padding: 8px 12px;
      gap: 8px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.background[100]};
      }
    }
    .active {
      background-color: ${({ theme }) => theme.colors.secondary[50]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary[50]};
      }
    }
    .disabled {
      cursor: default;
      .text {
        opacity: 0.5;
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.white[100]};
      }
    }
  }
`;

export default DropdownBodyWrapper;
