const PDF = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.5 19C6.1 19 5.75 18.85 5.45 18.55C5.15 18.25 5 17.9 5 17.5V3.5C5 3.1 5.15 2.75 5.45 2.45C5.75 2.15 6.1 2 6.5 2H20.5C20.9 2 21.25 2.15 21.55 2.45C21.85 2.75 22 3.1 22 3.5V17.5C22 17.9 21.85 18.25 21.55 18.55C21.25 18.85 20.9 19 20.5 19H6.5ZM6.5 17.5H20.5V3.5H6.5V17.5ZM3.5 22C3.1 22 2.75 21.85 2.45 21.55C2.15 21.25 2 20.9 2 20.5V5H3.5V20.5H19V22H3.5Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path
      d='M8 8H9.45104C9.69053 8 9.90654 8.03439 10.0991 8.10317C10.2963 8.17196 10.463 8.27249 10.5992 8.40476C10.7401 8.53175 10.8481 8.69048 10.9232 8.88095C11.003 9.06614 11.043 9.28042 11.043 9.52381C11.043 9.79365 11.0007 10.0291 10.9162 10.2302C10.8316 10.4259 10.7142 10.5899 10.564 10.7222C10.4137 10.8492 10.2353 10.9444 10.0286 11.0079C9.82671 11.0714 9.606 11.1032 9.36651 11.1032H8.75369V13H8V8ZM8.75369 10.4286H9.1552C9.31486 10.4286 9.46043 10.4153 9.59192 10.3889C9.7281 10.3624 9.84549 10.3175 9.94411 10.254C10.0427 10.1852 10.1202 10.0952 10.1766 9.98413C10.2329 9.86772 10.2611 9.71958 10.2611 9.53968C10.2611 9.38095 10.2353 9.24603 10.1836 9.13492C10.1319 9.02381 10.0615 8.93386 9.97228 8.86508C9.88306 8.7963 9.7821 8.74603 9.6694 8.71429C9.5567 8.68254 9.43695 8.66667 9.31016 8.66667H8.75369V10.4286Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path
      d='M13.2488 8C13.5728 8 13.871 8.05026 14.1434 8.15079C14.4157 8.25132 14.6505 8.40212 14.8477 8.60318C15.045 8.79894 15.1976 9.04762 15.3056 9.34921C15.4183 9.6455 15.4747 9.99206 15.4747 10.3889C15.4747 10.8598 15.4089 11.2619 15.2774 11.5952C15.1459 11.9233 14.9675 12.1931 14.7421 12.4048C14.5214 12.6111 14.2608 12.7619 13.9602 12.8571C13.6644 12.9524 13.3521 13 13.0234 13H11.7696V8H13.2488ZM12.5233 12.3175H12.96C13.176 12.3175 13.385 12.291 13.5869 12.2381C13.7935 12.1799 13.9767 12.0794 14.1363 11.9365C14.296 11.7884 14.4228 11.5899 14.5167 11.3413C14.6153 11.0926 14.6646 10.7751 14.6646 10.3889C14.6646 9.83862 14.5237 9.41799 14.242 9.12698C13.9649 8.83069 13.5916 8.68254 13.122 8.68254H12.5233V12.3175Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path d='M19 8V8.68254H17.077V10.2063H18.845V10.881H17.077V13H16.3233V8H19Z' fill='#333333' fillOpacity='0.8' />
  </svg>
);

export default PDF;
