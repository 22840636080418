import styled, { css } from 'styled-components';
import TitleComponent from './Title';

const Title = styled(TitleComponent)`
  ${({ theme, color }) => css`
    color: ${color || theme.colors.dark[100]};
    font-weight: 600;
    &.typography--title-variant-h1 {
      font-size: 38px;
      line-height: 49px;
    }

    &.typography--title-variant-h2 {
      font-size: 30px;
      line-height: 38px;
    }

    &.typography--title-variant-h3 {
      font-size: 24px;
      line-height: 32px;
    }

    &.typography--title-variant-h4 {
      font-size: 20px;
      line-height: 28px;
    }

    &.typography--title-variant-h5 {
      font-size: 16px;
      line-height: 24px;
    }

    ${({ $ellipsis, $lineClamp }) =>
      $ellipsis &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: ${$lineClamp || 1};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  `}
`;

export default Title;
