import emptySplitApi from '..';

const adminStaticPagesApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminStaticPages: build.query({
      query: (params) => ({
        url: `admin/static-pages`,
        method: 'GET',
        params,
      }),
      providesTags: ['static-pages'],
    }),
    updateStaticPage: build.mutation({
      query: (params) => ({
        url: `admin/static-pages/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['static-pages'],
    }),
  }),
});

export default adminStaticPagesApi;
export const { useGetAdminStaticPagesQuery, useUpdateStaticPageMutation } = adminStaticPagesApi;
