import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'style-guide/Button';
import Text from 'style-guide/Typography/Text';
import { handleButtonText } from '../helper';

const NewJoinedUser = ({ userType, status, EntityTypeUrl, progressInfo }) => {
  const navigate = useNavigate();
  const { courseComplete, progressPercent } = progressInfo || {};
  const userProgressScore = `${progressPercent === '0.00' ? 0 : progressPercent}% score`;

  const buttonProps = useMemo(
    () => handleButtonText(status, courseComplete, progressPercent, EntityTypeUrl),
    [EntityTypeUrl, courseComplete, progressPercent, status]
  );
  return (
    <>
      <div className='d-flex justify-content-between mv--16 footer-container'>
        <Text className='user-type-text' $variant={2}>
          {userType}
        </Text>
        {progressPercent ? (
          <Text className='user-type-text' $variant={2}>
            {userProgressScore}
          </Text>
        ) : null}
      </div>
      <Button variant='secondary' $block className='mt--16' onClick={() => navigate(buttonProps.url)}>
        {buttonProps.buttonText}
      </Button>
    </>
  );
};

export default NewJoinedUser;
