import { yupResolver } from '@hookform/resolvers/yup';
import MODAL_TYPES from 'constants/modals';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAdminCreateUserMutation } from 'store/services/superAdmin/AdminUsers';
import { hideModal } from 'store/slices/modal';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import FormItems from 'style-guide/FormItems';
import { message } from 'style-guide/ToastMessages';
import fields from './fields';
import schema from './schema';
import AdminAddUserWrapper from './style/AdminAddUserWrapper';

const defaultValues = { email: '', password: '', confirmPassword: '', accessTypeId: '1' };

const AdminAddUser = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues, resolver: yupResolver(schema) });

  const [createUser, { isLoading, isSuccess }] = useAdminCreateUserMutation();

  const dispatch = useDispatch();
  const onFormSubmit = (formData) => {
    createUser(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(hideModal({ type: MODAL_TYPES.ADMIN_ADD_USER_MODAL }));
      message.success('User has been successfully added!');
    }
  }, [dispatch, isSuccess]);

  return (
    <AdminAddUserWrapper>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <FormItems fields={fields({ register, control })} errors={errors} />
        <Button variant='primary' $block type='submit' loading={isLoading}>
          Sign Up
        </Button>
      </Form>
    </AdminAddUserWrapper>
  );
};
export default AdminAddUser;
