const TRANSACTION_TYPES = {
  organization: 1,
  library: 2,
  course: 3,
  organizationAccessCodes: 4,
  libraryAccessCodes: 5,
  courseAccessCodes: 6,
};

export default TRANSACTION_TYPES;
