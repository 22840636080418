import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const EditBioWrapper = styled.div`
  .edit-bio-modal-root {
    .textarea {
      width: 456px;
      height: 134px;
      resize: none;
      padding: 10px 12px;
      border: 1px solid;
      border: 1px solid #edeff1;
      border-radius: 4px;
      font-size: 14px;
      line-height: 17px;
      margin: 1px;
      outline: none;
      color: ${({ theme }) => theme.colors.dark[60]};

      @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
        width: 260px;
      }

      &:focus {
        margin: 0px;
        border: 2px solid ${({ theme }) => theme.colors.secondary[200]};
      }

      &::placeholder {
        font-style: italic;
      }
    }
  }
`;

export default EditBioWrapper;
