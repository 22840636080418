const Twitter = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.0002 0.5C6.71724 0.5 0.000244141 7.217 0.000244141 15.5C0.000244141 23.783 6.71724 30.5 15.0002 30.5C23.2832 30.5 30.0002 23.783 30.0002 15.5C30.0002 7.217 23.2832 0.5 15.0002 0.5ZM21.8491 12.1954C21.8557 12.343 21.8589 12.4913 21.8589 12.6404C21.8589 17.1898 18.3959 22.436 12.063 22.4363H12.0632H12.063C10.1187 22.4363 8.30934 21.8663 6.78567 20.8897C7.05507 20.9215 7.32927 20.9373 7.6069 20.9373C9.22006 20.9373 10.7046 20.3871 11.8831 19.4635C10.3759 19.4356 9.10516 18.4402 8.66663 17.0722C8.87651 17.1125 9.09235 17.1344 9.31367 17.1344C9.62793 17.1344 9.93234 17.0921 10.2216 17.0131C8.64625 16.6977 7.4595 15.3055 7.4595 13.6383C7.4595 13.6227 7.4595 13.6087 7.45996 13.5943C7.9239 13.8523 8.45445 14.0075 9.01933 14.0249C8.09488 13.408 7.4872 12.3536 7.4872 11.159C7.4872 10.5282 7.65771 9.93726 7.95343 9.42845C9.65127 11.5117 12.1887 12.8818 15.0501 13.0258C14.9911 12.7736 14.9606 12.5108 14.9606 12.2407C14.9606 10.3401 16.5026 8.79811 18.4039 8.79811C19.3943 8.79811 20.2888 9.21674 20.9171 9.88599C21.7014 9.73126 22.438 9.4447 23.1033 9.05034C22.8459 9.85394 22.3002 10.5282 21.5893 10.9546C22.2858 10.8713 22.9495 10.6866 23.5664 10.4124C23.1056 11.103 22.5213 11.7095 21.8491 12.1954Z'
      fill='#607D8B'
    />
  </svg>
);

export default Twitter;
