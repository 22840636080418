import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import NodeView from './ShortCode/NodeView';

const VideoExtension = Node.create({
  name: 'video', // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.children[0].src,
        renderHTML: (element) => element.src,
      },
      'data-resource-id': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-resource-id'),
        renderHTML: (element) => element['data-resource-id'],
      },
      controls: {
        default: true,
        parseHTML: () => true,
        renderHTML: () => true,
      },
      type: {
        default: null,
        parseHTML: (element) => {
          const { tagName } = element;
          return tagName.toLowerCase();
        },
      },
      id: {
        default: null,
        renderHTML: (element) => element.id,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeView);
  },

  renderHTML({ node }) {
    const { type, id } = node.attrs;
    return ['short-code', { type, id }];
  },

  parseHTML() {
    return [{ tag: 'video' }];
  },
});

export default VideoExtension;
