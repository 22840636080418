const ScaledResponse = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect x='9' y='5' width='12' height='3' rx='1.5' fill='#008699' />
    <rect x='3' y='5' width='3' height='3' rx='1.5' fill='#00B3CC' />
    <rect x='3' y='11' width='3' height='3' rx='1.5' fill='#00B3CC' />
    <rect x='3' y='17' width='3' height='3' rx='1.5' fill='#00B3CC' />
    <rect x='9' y='11' width='12' height='3' rx='1.5' fill='#008699' />
    <rect x='9' y='17' width='12' height='3' rx='1.5' fill='#008699' />
  </svg>
);

export default ScaledResponse;
