import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AWS3_FILE_MANAGER } from 'configs/env-vars';
import { message } from 'style-guide/ToastMessages';
import downloadFile from './downloadFile';

const downloadFileS3 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: AWS3_FILE_MANAGER,
  }),
  endpoints: (builder) => ({
    downloadFileS3: builder.mutation({
      query: ({ url, name }) => ({
        url,
        responseHandler: async (response) => {
          if (response.status === 404) {
            message.error('File not found');
            return;
          }
          const blob = await response.blob();
          downloadFile(name, blob);
        },
      }),
    }),
  }),
});

export const { useDownloadFileS3Mutation } = downloadFileS3;
export default downloadFileS3;
