const ACTION_TYPES = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  RESTORE: 'restore',
};

const VERSION_REVIEW_ACTION_TYPES = {
  ...ACTION_TYPES,
  hasAction: (action) => Object.values(ACTION_TYPES).includes(action),
};

export default VERSION_REVIEW_ACTION_TYPES;
