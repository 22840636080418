const accessTypes = {
  AccessTypePrivate: 1,
  AccessTypePublic: 2,
};

const getAccessTypes = () => ({
  [accessTypes.AccessTypePrivate]: 'Private',
  [accessTypes.AccessTypePublic]: 'Public',
});

const ACCESS_TYPES = {
  ...accessTypes,
  getAccessTypes,
};

export default ACCESS_TYPES;
