import styled from 'styled-components';

const MenuWrapper = styled.div`
  .menu-button {
    border: none;
    background-color: transparent;
    padding: 6px;
    height: 32px;
    width: 32px;
  }
  .menu-button-selected {
    background-color: ${({ theme }) => theme.colors.secondary[50]};
  }
  button,
  svg {
    cursor: pointer;
  }
  button {
    &.dashed {
      border: 1px dashed rgb(82, 161, 128);
      height: 40px;
      width: 40px;
      background: ${({ theme }) => theme.colors.white[100]};
      border-radius: 50%;
    }
  }
`;

export default MenuWrapper;
