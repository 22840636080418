import useInfiniteScroll from 'hooks/useInfiniteScroll';
import usePrevious from 'hooks/usePrevious';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { paginate } from 'utils/helperFunctions';

const useGetInfiniteScrollData = ({ request, hasNextPage, minPrice, maxPrice, ratingValue, status, role }) => {
  const [page, setPage, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore: hasNextPage,
  });

  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('q') || undefined;
  const letter = searchParams.get('letter');
  const prevSearchText = usePrevious(searchText);

  useEffect(() => {
    if (prevSearchText !== searchText) {
      setPage(0);
      scrollerRef.current?.scrollTo?.({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [prevSearchText, scrollerRef, searchText, setPage]);

  useEffect(() => {
    request({
      ...paginate({ page: 0 }),
      searchText,
      letterSearch: letter && letter !== 'all' ? letter : '',
      maxPrice,
      minPrice,
      ratings: JSON.stringify(ratingValue?.map((el) => Number(el))),
      status,
      role,
    });
  }, [maxPrice, minPrice, ratingValue, request, role, searchText, status, letter]);

  useEffect(() => {
    if (page !== 0) {
      request({
        ...paginate({ page }),
        letterSearch: letter && letter !== 'all' ? letter : '',
        searchText,
        maxPrice,
        minPrice,
        ratings: JSON.stringify(ratingValue?.map((el) => Number(el))),
        status,
        role,
      });
    }
  }, [request, page, searchText, maxPrice, minPrice, ratingValue, status, role, letter]);

  return { loaderRef, scrollerRef };
};

export default useGetInfiniteScrollData;
