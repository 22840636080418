import AssessmentCard from 'Components/InitialThoughtCard';
import Button from 'style-guide/Button';

import Title from 'style-guide/Typography/Title';

const RetakeAssessment = ({ categoryUrl, answeredTestsCount }) => (
  <div className='retake-assessment-section'>
    <AssessmentCard
      title={
        <div className='title align-items-center justify-content-between'>
          <Title variant={5}>
            {answeredTestsCount >= 5
              ? 'Oops, you have reached the limit for attempts to retake the assessment'
              : 'You can retake the assessment 5 times'}
          </Title>
          {answeredTestsCount >= 5 ? null : (
            <Button variant='primary' to={categoryUrl} disabled>
              Retake
            </Button>
          )}
        </div>
      }
    />
  </div>
);

export default RetakeAssessment;
