import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';
import container from './Container';

const Container = styled(container)`
  padding: 25px 10px 10px 20px;

  @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
    padding: 10px 10px 10px 40px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    padding: 10px 10px 10px 20px;
  }
`;

export default Container;
