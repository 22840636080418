import noData from 'assets/images/noData.png';
import classNames from 'classnames';
import { DASHBOARD_CATEGORIES } from 'constants/DropdownOptions/dashboard';
import { useMemo } from 'react';
import { useGetDepartmentsQuery } from 'store/services/departments';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import { useGetLearningCommunitiesQuery } from 'store/services/learningCommunities';
import { useGetOrganizationsQuery } from 'store/services/organizations';
import Image from 'style-guide/Image';
import ExploreAllCardsSkeleton from 'views/Explore/Body/ExploreCardsSkeleton';
import CardList from './CardList';

const defaultParams = { limit: 4, offset: 0 };

const All = ({ search, maxPrice, minPrice, ratingValue, colSpan, category }) => {
  const isLimitedUser = useIsLimitedUser();
  const params = useMemo(
    () => ({
      ...defaultParams,
      minPrice,
      maxPrice,
      searchText: search,
      ratings: ratingValue?.length ? JSON.stringify(ratingValue.map((el) => Number(el))) : undefined,
      role: isLimitedUser ? 'member' : null,
    }),
    [isLimitedUser, maxPrice, minPrice, ratingValue, search]
  );

  const { data: learningCommunitiesData, isLoading: isLoadingLC } = useGetLearningCommunitiesQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const { data: departmentsData, isLoading: isLoadingDepartment } = useGetDepartmentsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const { data: organizationsData, isLoadingOrganization } = useGetOrganizationsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const hasData =
    learningCommunitiesData?.rows?.length || departmentsData?.rows?.length || organizationsData?.rows?.length;

  const isLoading = isLoadingLC || isLoadingDepartment || isLoadingOrganization;

  return (
    <div
      className={classNames({
        'd-flex align align-items-center justify-content-center': !hasData && !isLoading,
      })}
    >
      {!hasData ? (
        <div>
          {isLoading ? (
            <ExploreAllCardsSkeleton cardSize={colSpan} category={category} />
          ) : (
            <div className='card-box-all '>
              <Image src={noData} />
            </div>
          )}
        </div>
      ) : (
        <div className='all-container'>
          <CardList data={learningCommunitiesData} category={DASHBOARD_CATEGORIES[0].value} colSpan={colSpan} />
          <CardList data={departmentsData} category={DASHBOARD_CATEGORIES[1].value} colSpan={colSpan} />
          <CardList data={organizationsData} category={DASHBOARD_CATEGORIES[2].value} colSpan={colSpan} />
        </div>
      )}
    </div>
  );
};

export default All;
