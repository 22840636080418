import classNames from 'classnames';
import MODAL_TYPES from 'constants/modals';
import { useCallback, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { showModal } from 'store/slices/modal';

import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';
import SlideItem from './SlideItem';

const SlidesList = ({ activeFieldIndex, setActiveFieldIndex, fieldArrayMethods, editorRef, isMember }) => {
  const dispatch = useDispatch();

  const { fields, append, remove, move, update } = fieldArrayMethods;

  const onAddSlide = () => {
    append({ title: '', audio: null, slideName: '' });
    setActiveFieldIndex(fields.length + 1);
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    move(source.index, destination.index);
  };

  const onRemoveSlide = useCallback(
    (index) => {
      if (fields?.[activeFieldIndex]?.title) {
        dispatch(
          showModal({
            type: MODAL_TYPES.CONFIRMATION,
            params: {
              modal: {
                title: 'Confirmation',
              },
              body: {
                title: <Title variant={5}>Are you sure you want to delete?</Title>,
                onOk: () => {
                  remove(index);
                },
              },
            },
          })
        );
      } else {
        remove(index);
      }
    },
    [activeFieldIndex, dispatch, fields, remove]
  );

  useEffect(() => {
    if (fields.length && fields.length === activeFieldIndex) {
      setActiveFieldIndex((item) => item - 1);
    }
  }, [activeFieldIndex, fields.length, setActiveFieldIndex]);

  useEffect(() => {
    const editor = editorRef?.current;
    if (editor) {
      editor.selection?.select(editor?.getBody(), true);
      editor.selection?.collapse(false);
    }
  }, [activeFieldIndex, editorRef]);

  return (
    <div className='slides-section-box'>
      {!isMember ? (
        <Button variant='secondary' $block className='mb--12' onClick={onAddSlide}>
          Add Slides
        </Button>
      ) : null}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='slide-list'>
          {(provided) => (
            <div
              className={classNames('slides-list', { 'member-view': isMember })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((field, index) => (
                <SlideItem
                  id={field.id}
                  key={field.slideId || index}
                  update={update}
                  field={field}
                  index={index}
                  isLastItem={fields.length === 1}
                  activeFieldIndex={activeFieldIndex}
                  setActiveFieldIndex={setActiveFieldIndex}
                  onRemoveSlide={onRemoveSlide}
                  isMember={isMember}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SlidesList;
