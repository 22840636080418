import MenuItem from './MenuItem';
import MenuWrapper from './MenuWrapper';

const Menu = ({ actions, className }) => (
  <MenuWrapper className={className}>
    {actions.map((action) => (
      <MenuItem key={action.name} {...action} />
    ))}
  </MenuWrapper>
);

export default Menu;
