import noData from 'assets/images/noData.png';
import { USER_COURSE_TYPES } from 'common';
import { useParams } from 'react-router-dom';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Help } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Skeleton from 'style-guide/Skeleton';
import Tooltip from 'style-guide/Tooltip';
import DropdownBody from './DropdownBody';

const { organizer } = USER_COURSE_TYPES.userCourseRole;

const EmptyPage = ({ isLoading }) => {
  const { role } = useParams();

  const isOrganizer = role === organizer;

  return isLoading ? (
    <div className='row'>
      <div className='col-8 offset-2'>
        <Skeleton skeleton={{ width: '100%', height: 280, count: 3 }} />
      </div>
    </div>
  ) : (
    <div className='empty-data d-flex flex-column'>
      <Image src={noData} />
      {isOrganizer ? (
        <DropdownV2 overlay={<DropdownBody />}>
          <Button variant='secondary' className='add-question-button justify-content-center'>
            Add Question
          </Button>
        </DropdownV2>
      ) : null}
      <div className='question-icon-wrapper'>
        <Tooltip
          $helpTooltip
          placement='top'
          overlay='This page includes a variety of summary question formats (true/false, multiple choice, essay question, and others) that enable you to ask the Community Member questions that summarize their recall or application of information.'
        >
          <Help />
        </Tooltip>
      </div>
    </div>
  );
};

export default EmptyPage;
