const UserOutlined = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M20.1206 17.8969C19.6784 16.8496 19.0368 15.8984 18.2315 15.0961C17.4286 14.2915 16.4775 13.65 15.4307 13.207C15.4213 13.2023 15.412 13.2 15.4026 13.1953C16.8627 12.1406 17.812 10.4227 17.812 8.48438C17.812 5.27344 15.2104 2.67188 11.9995 2.67188C8.78853 2.67188 6.18696 5.27344 6.18696 8.48438C6.18696 10.4227 7.13618 12.1406 8.59634 13.1977C8.58696 13.2023 8.57759 13.2047 8.56821 13.2094C7.51821 13.6523 6.57603 14.2875 5.76743 15.0984C4.96285 15.9013 4.32135 16.8524 3.87837 17.8992C3.44319 18.924 3.20848 20.0228 3.18696 21.1359C3.18634 21.161 3.19073 21.1858 3.19987 21.2091C3.20901 21.2324 3.22272 21.2537 3.2402 21.2716C3.25767 21.2895 3.27855 21.3037 3.30161 21.3134C3.32467 21.3231 3.34944 21.3281 3.37446 21.3281H4.78071C4.88384 21.3281 4.96587 21.2461 4.96821 21.1453C5.01509 19.3359 5.74165 17.6414 7.02603 16.357C8.35493 15.0281 10.1198 14.2969 11.9995 14.2969C13.8792 14.2969 15.644 15.0281 16.9729 16.357C18.2573 17.6414 18.9838 19.3359 19.0307 21.1453C19.0331 21.2484 19.1151 21.3281 19.2182 21.3281H20.6245C20.6495 21.3281 20.6743 21.3231 20.6973 21.3134C20.7204 21.3037 20.7413 21.2895 20.7587 21.2716C20.7762 21.2537 20.7899 21.2324 20.7991 21.2091C20.8082 21.1858 20.8126 21.161 20.812 21.1359C20.7885 20.0156 20.5565 18.9258 20.1206 17.8969ZM11.9995 12.5156C10.9237 12.5156 9.91118 12.0961 9.14946 11.3344C8.38774 10.5727 7.96821 9.56016 7.96821 8.48438C7.96821 7.40859 8.38774 6.39609 9.14946 5.63437C9.91118 4.87266 10.9237 4.45312 11.9995 4.45312C13.0752 4.45312 14.0877 4.87266 14.8495 5.63437C15.6112 6.39609 16.0307 7.40859 16.0307 8.48438C16.0307 9.56016 15.6112 10.5727 14.8495 11.3344C14.0877 12.0961 13.0752 12.5156 11.9995 12.5156Z'
      fill='white'
    />
  </svg>
);

export default UserOutlined;
