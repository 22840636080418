import PAYMENT_TYPES from './payment-types';
import STRING_UTILS from './string-utils';

const { PaymentTypePublicFree, PaymentTypePublicPaid, PaymentTypePrivatePrePaid, paidPlanTypes } = PAYMENT_TYPES;
const { cleanUpPrice } = STRING_UTILS;

const ENTITY_TYPES = {
  libraries: 'libraries',
  library: 'library',
  courses: 'courses',
  course: 'course',
  organization: 'Organization',
  organizations: 'Organizations',

  // new ToDo
  DEPARTMENT: {
    singular: 'Collection',
    plural: 'Collections',
    key: 'collections',
    lowercase: 'collection',
  },
  LEARNING_COMMUNITY: {
    singular: 'Learning Community',
    plural: 'Learning Communities',
    key: 'learning-communities',
    lowercase: 'learning community',
  },
  ORGANIZATION: {
    singular: 'Organization',
    plural: 'Organizations',
    key: 'organizations',
    lowercase: 'organization',
  },
  MEMBER: {
    singular: 'Member',
    plural: 'Members',
    key: 'members',
    short: 'member',
  },
  INVITED: {
    singular: 'Invitation',
    plural: 'Invitations',
    key: 'invitations',
  },
  SUBMISSIONS: {
    singular: 'Submission',
    plural: 'Submissions',
    key: 'submissions',
  },
};

export const ENTITIES = [
  ENTITY_TYPES.LEARNING_COMMUNITY,
  ENTITY_TYPES.DEPARTMENT,
  ENTITY_TYPES.ORGANIZATION,
  ENTITY_TYPES.MEMBER,
  ENTITY_TYPES.INVITED,
];

export const getOldEntitiesType = (type) => {
  switch (type) {
    case ENTITY_TYPES.LEARNING_COMMUNITY.key:
      return {
        singular: ENTITY_TYPES.course,
        plural: ENTITY_TYPES.courses,
      };
    case ENTITY_TYPES.DEPARTMENT.key:
      return {
        singular: ENTITY_TYPES.library,
        plural: ENTITY_TYPES.libraries,
      };
    case ENTITY_TYPES.ORGANIZATION.key:
      return {
        singular: ENTITY_TYPES.organization,
        plural: ENTITY_TYPES.organizations,
      };
    default:
      return null;
  }
};

export const getEntityName = (type) => ENTITIES.find((e) => e.key === type);

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION } = ENTITY_TYPES;

export const isEntity = (entity, type) => entity === type;

export const isEntityName = (value) =>
  value === ENTITY_TYPES.LEARNING_COMMUNITY.key ||
  value === ENTITY_TYPES.DEPARTMENT.key ||
  value === ENTITY_TYPES.ORGANIZATION.key;

const subscriptionPriceText = (plan) => {
  const planPrice = cleanUpPrice({ string: plan?.price, symbol: '$' });
  const oneMonth = 1;

  return plan?.billingFrequency === oneMonth
    ? `${planPrice} per month`
    : `${planPrice} for every ${plan?.billingFrequency} months`;
};

export const getOrgPrice = ({ paymentTypeId, price, paidPlanTypeId, subscriptionPlan }) => {
  const isRecurring = paidPlanTypeId === paidPlanTypes.recurring;
  const cleanedPrice = cleanUpPrice({ string: price, symbol: '$' });

  switch (paymentTypeId) {
    case PaymentTypePublicFree:
      return 'Free';
    case PaymentTypePublicPaid:
      return isRecurring ? subscriptionPriceText(subscriptionPlan) : cleanedPrice;
    case PaymentTypePrivatePrePaid:
      return 'Prepaid';

    default:
      return cleanedPrice;
  }
};

export const getFollowEntities = (data, type) => {
  if (!data) {
    return {};
  }

  if (data?.libraryId || isEntity(LEARNING_COMMUNITY.key, type)) {
    const organizationData = data.library?.organization || null;
    const libData = data.library || null;
    return {
      [LEARNING_COMMUNITY.key]: data.isJoined ? null : data,
      [DEPARTMENT.key]: data.library?.isJoined ? null : libData,
      [ORGANIZATION.key]: organizationData?.isJoined ? null : organizationData,
    };
  }
  if (data?.organizationId || isEntity(DEPARTMENT.key, type)) {
    const organizationData = data.organization || null;
    return {
      [DEPARTMENT.key]: data.isJoined ? null : data,
      [ORGANIZATION.key]: organizationData?.isJoined ? null : organizationData,
    };
  }
  if (isEntity(ORGANIZATION.key, type)) {
    return { [ORGANIZATION.key]: data };
  }

  return {};
};

export default ENTITY_TYPES;
