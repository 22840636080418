import { useEffect } from 'react';

import Button from 'style-guide/Button';
import { Facebook, Google } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGoogleUserInfoMutation } from 'store/services/googleUserInfo';
import { useAuthenticationUserMutation } from 'store/services/users';
import { isAuth } from 'store/slices/authorizations';
import facebookAuthentication from 'utils/facebookAuthentication';
import googleAuthentication from 'utils/googleAuthentication';
import loggingUserRedirectPaths from 'views/Authorizations/SignIn/helper';
import SocialMediaLoginButtonsWrapper from './style/SocialMediaLoginButtonsWrapper';

const SocialMediaLoginButtons = ({ text }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [authenticationUser, { isSuccess, data }] = useAuthenticationUserMutation();
  const [googleUserInfo] = useGoogleUserInfoMutation();

  useEffect(() => {
    if (isSuccess && !data?.user?.verification) {
      dispatch(isAuth({ isAuth: true, userData: data }));

      loggingUserRedirectPaths(data?.user, navigate);
    }
  }, [data, dispatch, isSuccess, navigate]);

  return (
    <SocialMediaLoginButtonsWrapper>
      <Button
        $block
        prefix={<Facebook />}
        className='sign-up-with-facebook'
        onClick={() => facebookAuthentication(authenticationUser)}
      >
        <Text $variant={3}>{text} with Facebook</Text>
      </Button>
      <Button
        $block
        prefix={<Google />}
        className='sign-up-with-google'
        onClick={() => googleAuthentication(authenticationUser, googleUserInfo, navigate)}
      >
        <Text $variant={3}>{text} with Google</Text>
      </Button>
    </SocialMediaLoginButtonsWrapper>
  );
};

export default SocialMediaLoginButtons;
