import { Input } from 'style-guide/Input';

const fields = (register) => [
  {
    name: 'email',
    label: 'Email',
    component: <Input name='email' placeholder='Enter your email' {...register('email')} />,
  },
  {
    name: 'password',
    label: 'Password',
    component: <Input name='password' type='password' placeholder='Enter your password' {...register('password')} />,
  },
];

export default fields;
