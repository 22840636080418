import { generatePath } from 'react-router-dom';

const entityPath = '/:entity/:id/manage/create/:urlName/:section';

export const generateEntityPagePath = (entity, id, urlName, section) =>
  generatePath(entityPath, {
    entity,
    id,
    urlName,
    section,
  });
