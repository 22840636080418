import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  height: 100vh;
  .not-found-root {
    max-width: 318px;
    .title {
      margin-bottom: 8px;
    }
    .sub-title {
      margin-bottom: 8px;
    }
    .info {
      margin-bottom: 20px;
    }
    .primary {
      display: initial;
    }
  }
`;

export default NotFoundWrapper;
