import classNames from 'classnames';
import MODAL_TYPES from 'constants/modals';
import { useDispatch } from 'react-redux';
import { useDownloadFileS3Mutation } from 'store/services/downloadFile/downloadFileS3';
import { hideModal } from 'store/slices/modal';
import Button from 'style-guide/Button';
import { Download } from 'style-guide/Icons';
import Presentation from '../Presentation';
import FileReader from './ViewTypes/FileReader';
import ViewActivity from './ViewTypes/ViewActivity';
import ViewAudio from './ViewTypes/ViewAudio';
import ViewVideo from './ViewTypes/ViewVideo';
import ResourcesWrapper from './style/ResourcesWrapper';

export const ChooseView = ({ fileType, resource, type }) => {
  switch (fileType) {
    case 'activity':
      return <ViewActivity resource={resource} />;
    case 'presentation':
      return <Presentation resource={resource} />;
    case 'video':
      return <ViewVideo resource={resource} />;
    case 'audio':
      return <ViewAudio resource={resource} />;
    default:
      return <FileReader resource={resource} type={type} />;
  }
};

const Resources = ({ params = {} }) => {
  const { resource, type } = params;
  const formatStart = resource.lastIndexOf('.');
  const fileFormat = resource.slice(formatStart + 1);
  const dispatch = useDispatch();
  const notDownloadableTypes = ['activity', 'url', 'presentation'].includes(type);

  const [downloadFileS3] = useDownloadFileS3Mutation();
  const fileName = resource.substring(resource.lastIndexOf('/') + 1);

  const closeModal = () => dispatch(hideModal({ type: MODAL_TYPES.RESOURCES }));

  const handleDownload = () => {
    downloadFileS3({ url: resource, name: fileName });
    closeModal();
  };

  return (
    <ResourcesWrapper className='row'>
      <div className='col-12 view-container'>
        <ChooseView fileType={type} resource={resource} type={fileFormat} />
      </div>
      <div
        className={classNames(
          'button-section col-12 col-md-12 d-flex flex-row align-items-center justify-content-end',
          {
            'buttons-wrapper': !notDownloadableTypes,
          }
        )}
      >
        {!notDownloadableTypes ? (
          <Button variant='secondary' className='mr--24' onClick={handleDownload} prefix={<Download />}>
            Download
          </Button>
        ) : null}
      </div>
    </ResourcesWrapper>
  );
};

export default Resources;
