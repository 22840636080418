import testTypes from 'common/test-types';
import FillBlank from './FillBlank';
import MultipleAnswer from './MultipleAnswer';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';
import ResponseMatching from './ResponseMatching';
import ScaledResponse from './ScaledResponse';
import ShortEssay from './ShortEssay';
import ShortEssayScored from './ShortEssayScored';
import TrueFalseAnswer from './TrueFalseAnswer';

const LearnerCardBody = ({ type, answer, index, control, setValue, questionId, answeredTest }) => {
  switch (type) {
    case testTypes.types.TestsTypeTrueFalse:
      return (
        <TrueFalseAnswer
          answer={answer}
          index={index}
          type={type}
          questionId={questionId}
          control={control}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsTypeMultipleChoice:
      return (
        <MultipleChoiceAnswer
          answer={answer}
          index={index}
          type={type}
          questionId={questionId}
          control={control}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsTypeMultipleResponse:
      return (
        <MultipleAnswer
          answer={answer}
          index={index}
          control={control}
          type={type}
          questionId={questionId}
          setValue={setValue}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsTypeFreeText:
      return (
        <ShortEssay
          index={index}
          type={type}
          questionId={questionId}
          setValue={setValue}
          control={control}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsTypeResponseMatching:
      return (
        <ResponseMatching
          answer={answer}
          type={type}
          questionId={questionId}
          setValue={setValue}
          index={index}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsScaledResponseScored:
      return <ScaledResponse answer={answer} index={index} />;

    case testTypes.types.TestsTypeCloseExercise:
      return (
        <FillBlank
          control={control}
          index={index}
          type={type}
          setValue={setValue}
          questionId={questionId}
          answeredTest={answeredTest}
        />
      );

    case testTypes.types.TestsTypeScoredEssay:
      return (
        <ShortEssayScored
          index={index}
          type={type}
          questionId={questionId}
          setValue={setValue}
          control={control}
          answeredTest={answeredTest}
        />
      );

    default:
      return null;
  }
};

export default LearnerCardBody;
