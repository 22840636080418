const MessageSuccess = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='9' cy='9' r='9' fill='#7CB342' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.46967 9.96967L12.4393 6L13.5 7.06066L8.46967 12.091L4.93934 8.625L6 7.56434L8.46967 9.96967Z'
      fill='white'
    />
  </svg>
);

export default MessageSuccess;
