import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserIsAuth } from 'store/selectors/user';
import { showModal } from 'store/slices/modal';

import { PAYMENT_TYPES, USER_COURSE_TYPES } from 'common';
import MODAL_TYPES from 'constants/modals';
import PATHS from 'constants/paths';
import { setRedirectUrlToLocalStorage } from 'utils/helperFunctions';

import classNames from 'classnames';
import Button from 'style-guide/Button';
import { Lock, Rate } from 'style-guide/Icons';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

const { member } = USER_COURSE_TYPES.userCourseRole;
const CARD_BUTTONS_TEXTS = {
  PRIVATE: 'Private',
  LEARN_MORE: 'Learn more',
};

const NewNotJoinedUser = ({
  priceForEntity,
  areParentEntitiesFree,
  rating,
  type,
  id,
  urlName,
  name,
  paymentTypeId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(getUserIsAuth);

  const areParentEntitiesPaid = priceForEntity === 'Free' && !areParentEntitiesFree;
  const isPrivate = !!PAYMENT_TYPES.privatePaymentTypes.find((e) => e.id === paymentTypeId);
  const buttonText = isPrivate ? CARD_BUTTONS_TEXTS.PRIVATE : CARD_BUTTONS_TEXTS.LEARN_MORE;
  const buttonIcon = isPrivate ? <Lock height={12} width={14} /> : null;
  const homepageUrl = `/${type}/${id}/${urlName}/'${member}'/homepage`;

  const showJoinModal = (params, request) => {
    setRedirectUrlToLocalStorage(homepageUrl);
    if (!isAuth) {
      navigate(PATHS.SIGN_IN);
    } else {
      dispatch(
        showModal({
          type: MODAL_TYPES.JOIN_ENTITIES,
          params: {
            modal: {
              title: <Title>Join {name} </Title>,
            },
            body: {
              type,
              urlName,
              name,
              onOk: () => {
                request(params);
              },
            },
          },
        })
      );
    }
  };

  return (
    <>
      <div
        className={classNames(
          'd-flex footer-container',
          areParentEntitiesPaid ? 'justify-content-end' : 'justify-content-between'
        )}
      >
        {areParentEntitiesPaid ? null : <Text $variant={3}>{priceForEntity}</Text>}
        {rating !== null && (
          <div className='d-flex'>
            <Rate fill={theme().colors.dark[100]} />
            <Text $variant={3}>{rating}</Text>
          </div>
        )}
      </div>
      <Button variant='secondary' $block className='mt--16' onClick={showJoinModal} prefix={buttonIcon}>
        {buttonText}
      </Button>
    </>
  );
};

export default NewNotJoinedUser;
