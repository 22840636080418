const ZoomIn = () => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_6925_67857)' filter='url(#filter0_d_6925_67857)'>
      <path d='M30 4H6V28H30V4Z' fill='white' fillOpacity='0.01' />
      <path
        d='M16.5 23C21.1944 23 25 19.1944 25 14.5C25 9.8056 21.1944 6 16.5 6C11.8056 6 8 9.8056 8 14.5C8 19.1944 11.8056 23 16.5 23Z'
        stroke='white'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path d='M16.5 11.5V17.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5 14.5H19.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M22.6113 20.6113L26.854 24.854'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_6925_67857'
        x='0'
        y='0'
        width='36'
        height='36'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6925_67857' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_6925_67857' result='shape' />
      </filter>
      <clipPath id='clip0_6925_67857'>
        <rect width='24' height='24' fill='white' transform='translate(6 4)' />
      </clipPath>
    </defs>
  </svg>
);

export default ZoomIn;
