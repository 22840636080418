import classNames from 'classnames';
import useCurrentPath from 'hooks/useCurrentPath ';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import { getFooterMode } from 'utils/getCustomersMode';
import FooterComponent from 'views/Home/Footer';
import showFooterPaths from './helper/showFooterPaths';
import FooterWrapper from './style/FooterWrapper';

const Footer = () => {
  const { data } = useGetDomainTracking();
  const mode = getFooterMode(data.key);

  const showFooter = useCurrentPath(showFooterPaths);

  if (!showFooter) {
    return null;
  }

  return (
    <FooterWrapper className={classNames({ 'dark-mode': mode === 'dark' })}>
      <div className='footer-root'>
        <FooterComponent />
      </div>
    </FooterWrapper>
  );
};

export default Footer;
