import { SYSTEM_LIMITS } from 'common';
import { useEffect } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Button from 'style-guide/Button';
import { Plus, Trash } from 'style-guide/Icons';
import { Input, TextArea } from 'style-guide/Input';
import Select from 'style-guide/Select';
import Text from 'style-guide/Typography/Text';
import ScaledResponseWrapper from './style/ScaledResponserWrapper';

const {
  COURSE_SECTIONS: { TESTS },
} = SYSTEM_LIMITS;

const ScaledResponse = ({ control }) => {
  const [searchParams] = useSearchParams();
  const questionId = searchParams.get('questionId');
  const { fields, remove, update, insert } = useFieldArray({
    control,
    name: 'answer',
  });

  const answer = useWatch({ name: 'answer', control });
  const filterFields = fields.filter((el) => el.key !== 'top' && el.key !== 'bottom');

  useEffect(() => {
    if (!questionId && !filterFields[0].id) {
      filterFields.forEach((field, index) => {
        update(index, { key: String(index + 1), value: field.value, id: field.id });
      });
    }
  }, [filterFields, questionId, update]);

  return (
    <ScaledResponseWrapper>
      <div className='row'>
        <div className='col-12'>
          <Text $variant={3} $dark={60} className='label'>
            *Fill the answer choices
          </Text>
        </div>
        <div className='col-12'>
          <Button
            variant='link'
            prefix={<Plus />}
            onClick={() => insert(fields.length - 2, { key: String(fields.length - 1), value: '' })}
            disabled={fields.length >= TESTS.ANSWER.LIST.max + 2 || questionId}
          >
            Add New Option
          </Button>
        </div>
        <div className='col-12 options-box'>
          {filterFields.map((field, index) => (
            <div key={field.id} className='d-flex align-items-center'>
              <div className='d-flex align-items-center item-box'>
                <Controller
                  control={control}
                  name={`answer.${index}.key`}
                  render={({ field: { ref } }) => (
                    <Select
                      className='item-select'
                      placeholder={index + 1}
                      value={String(index + 1)}
                      isDisabled
                      ref={ref}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`answer.${index}.value`}
                  render={({ field: { ref, onChange, value } }) => (
                    <Input
                      className='item-input'
                      placeholder='Response text goes here'
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      maxLength={TESTS.VALUE.max}
                      ref={ref}
                    />
                  )}
                />
              </div>
              {fields.length > TESTS.ANSWER.LIST.min + 2 && !questionId ? (
                <div className='trash-icon-wrapper'>
                  <Button variant='text' $danger onClick={() => remove(index)}>
                    <Trash />
                  </Button>
                </div>
              ) : null}
            </div>
          ))}

          {!questionId || (questionId && answer?.[0]?.id) ? (
            <div className='secondary-input-box'>
              <Controller
                control={control}
                name={`answer.${fields.length - 2}.value`}
                render={({ field: { ref, onChange, value } }) => (
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    className='item-input'
                    type='text'
                    placeholder='Scaled Response “top scored response” feedback text goes here'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                    maxLength={TESTS.VALUE.max}
                  />
                )}
              />

              <Controller
                control={control}
                name={`answer.${fields.length - 1}.value`}
                render={({ field: { ref, onChange, value } }) => (
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    className='item-input'
                    type='text'
                    placeholder='Scaled Response “bottom scored response” feedback text goes here'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                    maxLength={TESTS.VALUE.max}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </ScaledResponseWrapper>
  );
};
export default ScaledResponse;
