const Search = (props) => (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.53337 15C4.85147 15 1.8667 12.0152 1.8667 8.33333C1.8667 4.65144 4.85147 1.66667 8.53337 1.66667C12.2153 1.66667 15.2 4.65144 15.2 8.33333C15.2 9.87393 14.6775 11.2925 13.7999 12.4214L18.2893 16.9107L17.1108 18.0893L12.6214 13.5999C11.4925 14.4774 10.074 15 8.53337 15ZM13.5334 8.33333C13.5334 11.0948 11.2948 13.3333 8.53337 13.3333C5.77194 13.3333 3.53337 11.0948 3.53337 8.33333C3.53337 5.57191 5.77194 3.33333 8.53337 3.33333C11.2948 3.33333 13.5334 5.57191 13.5334 8.33333Z'
      fill='white'
    />
  </svg>
);

export default Search;
