import { DOMAIN_KEYS } from 'common';

// mode for Header
export const getHeaderMode = (key) => {
  const isDark = [
    DOMAIN_KEYS.politicalislam,
    DOMAIN_KEYS.c2c,
    DOMAIN_KEYS.pear,
    DOMAIN_KEYS.hrights,
    DOMAIN_KEYS.nucleus,
    DOMAIN_KEYS.fsc,
    DOMAIN_KEYS.ssa,
    DOMAIN_KEYS.spaces,
    DOMAIN_KEYS.boyens,
  ].includes(key);

  return isDark ? 'dark' : 'light';
};

// mode for Footer
export const getFooterMode = (key) => {
  const isLight = [
    DOMAIN_KEYS.peertree,
    DOMAIN_KEYS.fba,
    DOMAIN_KEYS.hippoCampus,
    DOMAIN_KEYS.vanderbilt,
    DOMAIN_KEYS.ecf,
    DOMAIN_KEYS.workvibes,
    DOMAIN_KEYS.csm,
    DOMAIN_KEYS.tvguest,
    DOMAIN_KEYS.em,
    DOMAIN_KEYS.mh360,
    DOMAIN_KEYS.saq,
  ].includes(key);

  return isLight ? 'light' : 'dark';
};
