import { ENTITY_TYPES } from 'common';
import LearningCommunityCard from 'Components/NewCards/LearningCommunityCard';
import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';

const LearningCommunities = ({ data, setLCPage, LCPage, colSpan }) => {
  if (!data?.total || data?.total <= 0) {
    return null;
  }

  return (
    <>
      <Title variant={5} className='pl--8 pt--16 pb--32'>{`${ENTITY_TYPES.LEARNING_COMMUNITY.plural} (${
        data?.total || 0
      })`}</Title>
      <div className='row cards mb--16"'>
        {data?.rows?.map((el) => (
          <div key={el.id} className={colSpan}>
            <LearningCommunityCard data={el} />
          </div>
        ))}
      </div>
      {data?.total > data?.rows?.length ? (
        <Button
          variant='link'
          children='See More'
          className='p--0 mb--16 ml--24'
          onClick={() => setLCPage({ ...LCPage, offset: LCPage.offset + LCPage.limit })}
        />
      ) : null}
    </>
  );
};

export default LearningCommunities;
