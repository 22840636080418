import { Controller } from 'react-hook-form';
import CheckBox from 'style-guide/CheckBox';

const getSettingsColumns = ({ control }) => [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Receive via Site',
    dataIndex: 'onTop',
    key: 'onTop',
    width: 150,
    align: 'center',
    render: (_index, item) => (
      <div className='d-flex justify-content-center'>
        <Controller
          control={control}
          name={`settings.${item.id}.onTop`}
          render={({ field }) => (
            <CheckBox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
          )}
        />
      </div>
    ),
  },
  {
    title: 'Receive via Email',
    dataIndex: 'byEmail',
    key: 'byEmail',
    width: 150,
    align: 'center',
    render: (_index, item) => (
      <div className='d-flex justify-content-center'>
        <Controller
          control={control}
          name={`settings.${item.id}.byEmail`}
          render={({ field }) => (
            <CheckBox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
          )}
        />
      </div>
    ),
  },
];

export default getSettingsColumns;
