const Warning = (props) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_4522_262)'>
      <path
        d='M6 0.749992C3.10078 0.749992 0.75 3.10077 0.75 5.99999C0.75 8.89921 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89921 11.25 5.99999C11.25 3.10077 8.89922 0.749992 6 0.749992ZM6 10.3594C3.59297 10.3594 1.64062 8.40702 1.64062 5.99999C1.64062 3.59296 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59296 10.3594 5.99999C10.3594 8.40702 8.40703 10.3594 6 10.3594Z'
        fill='#212121'
      />
      <path
        d='M5.4375 8.06249C5.4375 8.21168 5.49676 8.35475 5.60225 8.46024C5.70774 8.56573 5.85082 8.62499 6 8.62499C6.14918 8.62499 6.29226 8.56573 6.39775 8.46024C6.50324 8.35475 6.5625 8.21168 6.5625 8.06249C6.5625 7.91331 6.50324 7.77023 6.39775 7.66475C6.29226 7.55926 6.14918 7.49999 6 7.49999C5.85082 7.49999 5.70774 7.55926 5.60225 7.66475C5.49676 7.77023 5.4375 7.91331 5.4375 8.06249ZM5.71875 6.74999H6.28125C6.33281 6.74999 6.375 6.7078 6.375 6.65624V3.46874C6.375 3.41718 6.33281 3.37499 6.28125 3.37499H5.71875C5.66719 3.37499 5.625 3.41718 5.625 3.46874V6.65624C5.625 6.7078 5.66719 6.74999 5.71875 6.74999Z'
        fill='#212121'
      />
    </g>
    <defs>
      <clipPath id='clip0_4522_262'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Warning;
