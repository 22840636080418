import classNames from 'classnames';
import MenuComponent, { MenuItem, SubMenu } from 'rc-menu';
import { forwardRef } from 'react';
import { Down } from 'style-guide/Icons';
import MenuWrapper from './MenuWrapper';

const expandIcon = (props) => <Down className={classNames({ 'open-icon': props.isOpen }, 'sub-menu-icon')} />;

const Menu = forwardRef(({ children, mode, $variant, logo, ...props }, ref) => (
  <MenuWrapper
    mode={mode}
    $variant={$variant}
    className={classNames({ 'menu-dark-mode': $variant === 'dark' })}
    ref={ref}
  >
    <MenuComponent {...props} mode={mode} expandIcon={expandIcon}>
      {children}
    </MenuComponent>
  </MenuWrapper>
));

export { MenuItem, SubMenu };
export default Menu;
