import styled from 'styled-components';

const AdminUserWrapper = styled.div`
  width: 100%;
  .status-title {
    width: 100px;
  }
  .rc-table {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 36px;
    }
  }

  .verified-check {
    path {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
  }

  .remove-icon {
    path {
      fill: ${({ theme }) => theme.colors.error[400]};
    }
  }
  .reActivate-user {
    path {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
  }

  .eye-icon {
    path {
      fill: ${({ theme }) => theme.colors.tertiary[800]};
    }
  }

  .search-icon {
    path {
      fill: ${({ theme }) => theme.colors.secondary[400]};
    }
  }

  .user-remove-icon {
    path {
      stroke: ${({ theme }) => theme.colors.error[400]};
    }
  }

  .user-add-icon {
    path {
      stroke: ${({ theme }) => theme.colors.primary[600]};
    }
  }

  .title {
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .search-dropdown {
    background-color: ${({ theme }) => theme.colors.secondary[200]};
    padding: 8px;
    border-radius: 4px;
  }

  .dropdown-buttons {
    margin-top: 8px;
    .search-button {
      margin-right: 8px;
    }
  }

  .pagination {
    .rc-pagination-item {
      width: initial !important;
    }
  }

  .add-button {
    margin: 28px;
  }

  .deleted-user {
    path {
      fill: ${({ theme }) => theme.colors.dark[25]};
    }
  }
`;

export default AdminUserWrapper;
