import { ENTITY_STATUS } from 'common';
import { useSearchParams } from 'react-router-dom';
import CheckBox from 'style-guide/CheckBox';
import Text from 'style-guide/Typography/Text';
import FilterWrapper from './style/FilterWrapper';

const Filter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  return (
    <FilterWrapper className='d-flex align-items-center'>
      <div className='published'>
        <CheckBox
          name={ENTITY_STATUS.PUBLISHED.value}
          checked={status === ENTITY_STATUS.PUBLISHED.value}
          onChange={(e) => {
            e.target.checked
              ? searchParams.set('status', ENTITY_STATUS.PUBLISHED.value)
              : searchParams.delete('status');
            setSearchParams(searchParams);
          }}
          label={
            <Text $variant={1} $dark={75} className='text'>
              {ENTITY_STATUS.PUBLISHED.name}
            </Text>
          }
        />
      </div>
      <div className='draft'>
        <CheckBox
          name={ENTITY_STATUS.DRAFT.value}
          checked={status === ENTITY_STATUS.DRAFT.value}
          onChange={(e) => {
            e.target.checked ? searchParams.set('status', ENTITY_STATUS.DRAFT.value) : searchParams.delete('status');
            setSearchParams(searchParams);
          }}
          label={
            <Text $variant={1} $dark={75} className='text'>
              {ENTITY_STATUS.DRAFT.name}
            </Text>
          }
        />
      </div>
    </FilterWrapper>
  );
};

export default Filter;
