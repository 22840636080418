import classNames from 'classnames';
import { forwardRef } from 'react';
import { Check } from 'style-guide/Icons';

const CheckBox = forwardRef(({ className, onChange, checked, name, label, children, ...props }, ref) => (
  <div className={classNames(className, 'checkbox')}>
    <label htmlFor={name}>
      <input ref={ref} id={name} onChange={onChange} checked={!!checked} name={name} {...props} type='checkbox' />
      {checked ? <Check className='checked' /> : null}
      {children || label}
    </label>
  </div>
));

CheckBox.defaultProps = {
  onChange: () => {},
};

export default CheckBox;
