import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const ResourcesWrapper = styled.div`
  min-width: 600px;
  #header-bar {
    display: none;
  }
  .my-doc-viewer-style {
    #image-img {
      max-width: 100%;
    }
  }
  .view-container {
    margin-bottom: 8px;
    .file-box {
      height: 100%;
      min-height: 600px;
    }
  }
  img {
    width: 100%;
    height: 400px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
    margin: 12px;
    min-width: 600px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    height: 240px;
    min-width: 300px;
  }
  .buttons-wrapper {
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      justify-content: center !important;
    }
  }

  .urlContainer {
    word-wrap: break-word;
  }

  .url {
    color: ${({ theme }) => theme.colors.dark[100]};
    font-weight: 400;
    font-size: 14px;
    &:hover {
      border-bottom: 1px solid ${({ theme }) => theme.colors.dark[100]};
    }
  }

  .file-box {
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      height: 1000px;
    }
  }

  .activity-view {
    height: auto;
    max-height: calc(100vh - 260px);
    min-height: 150px;
    border: 1px solid ${({ theme }) => theme.colors.dark[25]};
    border-radius: 4px;
    padding: 4px 12px;
    overflow: auto;
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      height: 500px;
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: revert !important;
    }
  }

  .video-view {
    height: auto;
    width: 100%;
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      margin-top: 20px;
    }
  }

  .audio-view {
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      margin-top: 50%;
    }
  }
`;

export default ResourcesWrapper;
