/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useCallback } from 'react';

const EXCLUDED_TARGETS = ['TEXTAREA', 'INPUT'];

const useKeyPress = ({
  keyFn = {},
  event = 'keyup',
  eventKey = 'code',
  pressState,
  keysToPrevent = [],
  enableExclude = true,
  condition = true,
}) => {
  // array of keys to handle
  const keysToHandle = Object.keys(keyFn);

  // initialize
  const keyPressState = useRef(pressState);

  // sync pressState value change outside of the hook
  useEffect(() => {
    keyPressState.current = pressState;
  }, [pressState]);

  const downHandler = useCallback(
    (e) => {
      const eventCode = e[eventKey];
      if (keysToPrevent.includes(eventCode)) {
        e.preventDefault();
      }
      e.stopPropagation();
      const eventTarget = e.target.tagName;
      const isTrustedTarget = !EXCLUDED_TARGETS.includes(eventTarget);
      const withCtrl = keyFn?.[eventCode]?.ctrl;
      const withShift = keyFn?.[eventCode]?.shift;
      const ctrlKey = e.ctrlKey || e.metaKey;
      const { shiftKey } = e;
      const rightKey = keysToHandle.includes(eventCode);
      const pressedCtrl = withCtrl ? ctrlKey : rightKey;
      const pressedShift = withShift ? shiftKey : rightKey;
      const pressed = pressedCtrl && pressedShift && rightKey;

      if ((enableExclude || isTrustedTarget) && pressed) {
        const { fn } = keyFn[eventCode];
        const currentState = !keyPressState.current;
        keyPressState.current = currentState;
        fn({ currentState, e });
      }
    },
    [keyFn, keysToHandle, enableExclude, keysToPrevent]
  );

  useEffect(() => {
    if (condition) {
      window.addEventListener(event, downHandler);
    }

    return () => {
      window.removeEventListener(event, downHandler);
    };
  }, [condition, downHandler, event]);

  return keyPressState.current;
};

export default useKeyPress;
