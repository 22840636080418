import SCREEN_SIZES from 'constants/screen-sizes';
import styled, { css } from 'styled-components';

const AuthorizationsWrapper = styled.div`
  border-radius: 8px;
  padding: 48px 0;

  .container {
    margin: 0;
  }
  .verification-text {
    text-align: center;
  }
  .authorization-title {
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
  }
  .container-box {
    border-radius: 8px;
    margin: 0 auto;
    width: 490px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    .authorization-box {
      padding: 20px;
    }
    .authorization-button {
      margin-top: 20px;
      &.sign-in-button {
        margin-top: 40px;
      }
    }
    .authorization-link-button {
      padding-left: 4px;
      height: inherit;
    }
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: 328px;
    }
  }
  .link-button {
    &.forgot-button {
      margin-top: 20px;
    }
    cursor: pointer;
  }

  ${({ $isDropdown }) =>
    $isDropdown &&
    css`
      margin-top: 15px;
      height: inherit;
      padding: 0px;
      background-color: ${({ theme }) => theme.colors.white[100]};
      .container-box {
        width: 430px;
      }
    `}
`;

export default AuthorizationsWrapper;
