import RcCollapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import CollapseIcon from 'style-guide/Icons/DropdownRight';
import styled from 'styled-components';

const Collapse = styled(RcCollapse)`
  background-color: #edeff1 !important;
  box-shadow: 0px 1px 0px #ffffff;
  border: none !important;
  .rc-collapse-header:hover {
    background-color: ${({ theme }) => theme.colors.dark[15]} !important;
  }
  .rc-collapse-item-active {
    background-color: ${({ theme }) => theme.colors.dark[25]};
  }
  .rc-collapse-header-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.dark[80]};
  }

  /* transition for collapse icon */
  .rc-collapse-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collapse-icon {
    margin-right: 16px;
  }
  .active {
    &.collapse-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
  }
  .not-active {
    &.collapse-icon {
      transition: transform 0.2s;
    }
  }

  /* animation for collapse effect */
  .rc-collapse-content-active {
    &.rc-collapse-content {
      padding: 0px 36px;
    }
    .rc-collapse-content-box {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      animation-name: open_collapse;
      animation-duration: 0.1s;
      animation-fill-mode: forwards;
    }
  }
  .rc-collapse-content-hidden {
    display: block;
    .rc-collapse-content-box {
      animation-name: close_collapse;
      animation-duration: 0.1s;
      animation-fill-mode: forwards;
    }
  }
  @keyframes open_collapse {
    from {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
    }
    to {
      margin-top: 16px;
      margin-bottom: 16px;
      height: auto;
    }
  }
  @keyframes close_collapse {
    from {
      margin-top: 16px;
      margin-bottom: 16px;
      height: auto;
    }
    to {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
    }
  }
`;

const expandIcon = ({ isActive }) => <CollapseIcon className={`collapse-icon ${isActive ? 'active' : 'not-active'}`} />;

Collapse.defaultProps = {
  expandIcon,
};

export { Panel };
export default Collapse;
