import EntityCardList from 'Components/EntityCardList';
import { ENTITY_TYPES } from 'common';
import { useLazyGetDepartmentsQuery } from 'store/services/departments';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import useGetInfiniteScrollData from './useGetInfiniteScrollData';

const { DEPARTMENT } = ENTITY_TYPES;

const Departments = ({ cardsCount, colSpan, minPrice, maxPrice, ratingValue }) => {
  const [request, { data, isLoading }] = useLazyGetDepartmentsQuery();
  const hasNextPage = data?.total > data?.rows?.length;

  const isLimitedUser = useIsLimitedUser();

  const { scrollerRef, loaderRef } = useGetInfiniteScrollData({
    request,
    hasNextPage,
    minPrice,
    maxPrice,
    ratingValue,
    role: isLimitedUser ? 'member' : null,
  });

  return (
    <div className='body-root'>
      <EntityCardList
        data={data?.rows}
        total={data?.total}
        category={DEPARTMENT.key}
        isLoading={isLoading}
        scrollerRef={scrollerRef}
        hasNextPage={hasNextPage}
        loaderRef={loaderRef}
        isOrganizer={false}
        cardsCount={cardsCount}
        colSpan={colSpan}
      />
    </div>
  );
};

export default Departments;
