import PATHS from 'constants/paths';
import { lazy } from 'react';

const Audience = lazy(() => import('views/CreateEntities/Audience'));
const LearningCommunityManager = lazy(() => import('views/CreateEntities/LearningCommunityManager'));
const OrganizationManager = lazy(() => import('views/CreateEntities/OrganizationManager'));
const DepartmentManager = lazy(() => import('views/CreateEntities/DepartmentManager'));

const entitiesMenagePageRoutes = [
  {
    element: <LearningCommunityManager />,
    path: PATHS.LEARNING_COMMUNITY_MANAGER_ENTITIES,
  },
  {
    element: <DepartmentManager />,
    path: PATHS.DEPARTMENT_MANAGER_ENTITIES,
  },
  {
    element: <OrganizationManager />,
    path: PATHS.ORGANIZATION_MANAGER_ENTITIES,
  },

  {
    element: <Audience />,
    path: PATHS.CREATE_ENTITiES_PAYMENT_METHOD,
  },
];

export default entitiesMenagePageRoutes;
