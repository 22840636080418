import noData from 'assets/images/noData.png';
import PATHS from 'constants/paths';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import getEntitiesName from 'utils/getEntitiesName';

import { createNewEntity, setEntityURL } from 'Components/EntityCreateButton/helper';
import { useCallback } from 'react';
import { useCreateDepartmentMutation } from 'store/services/departments';
import { useCreateLearningCommunitiesMutation } from 'store/services/learningCommunities';
import { useCreateOrganizationMutation } from 'store/services/organizations';
import Image from 'style-guide/Image';
import { navigateEToExploreEntity } from 'views/NewExplore/helpers';
import NoData from './NoData';

const NoDataComponent = ({ isAdmin, category }) => {
  const [createLearningCommunity] = useCreateLearningCommunitiesMutation();
  const [createDepartment] = useCreateDepartmentMutation();
  const [createOrganization] = useCreateOrganizationMutation();
  const navigate = useNavigate();

  const navigateToEntityUpdatePage = useCallback(
    (value, id, entityURLName) => {
      navigate(setEntityURL(value, id, entityURLName));
    },
    [navigate]
  );

  const { pathname } = useLocation();
  const shortName = getEntitiesName(category, 'singular');

  if (pathname === generatePath(PATHS.EXPLORE, { category })) {
    return (
      <div className='card-box d-flex align-items-center justify-content-center'>
        <Image src={noData} />
      </div>
    );
  }

  return isAdmin ? (
    <NoData
      title='There is no data here'
      text={`Create your own ${shortName} to teach`}
      buttonText='Create now'
      onClick={() => {
        createNewEntity(
          createLearningCommunity,
          createDepartment,
          createOrganization,
          category,
          navigateToEntityUpdatePage
        );
      }}
    />
  ) : (
    <NoData
      title='There is no data here'
      text={`Find and join existing ${shortName}`}
      buttonText='Explore'
      onClick={() => {
        navigate(navigateEToExploreEntity(category));
      }}
    />
  );
};

export default NoDataComponent;
