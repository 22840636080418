const Youtube = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13.1317 18.3104L18.011 15.5002L13.1317 12.6899V18.3104Z' fill='#607D8B' />
    <path
      d='M15 0.5C6.717 0.5 0 7.217 0 15.5C0 23.783 6.717 30.5 15 30.5C23.283 30.5 30 23.783 30 15.5C30 7.217 23.283 0.5 15 0.5ZM24.3727 15.5153C24.3727 15.5153 24.3727 18.5574 23.9868 20.0243C23.7705 20.8272 23.1374 21.4603 22.3345 21.6764C20.8676 22.0625 15 22.0625 15 22.0625C15 22.0625 9.14772 22.0625 7.66548 21.661C6.86256 21.445 6.22948 20.8117 6.01318 20.0087C5.62706 18.5574 5.62706 15.5 5.62706 15.5C5.62706 15.5 5.62706 12.4582 6.01318 10.9913C6.22925 10.1883 6.8779 9.53969 7.66548 9.32362C9.13239 8.9375 15 8.9375 15 8.9375C15 8.9375 20.8676 8.9375 22.3345 9.33896C23.1374 9.55502 23.7705 10.1883 23.9868 10.9913C24.3883 12.4582 24.3727 15.5153 24.3727 15.5153Z'
      fill='#607D8B'
    />
  </svg>
);

export default Youtube;
