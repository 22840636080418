import { transformErrorResponse, transformResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const cleanUpInitialThoughts = (result, isOrganizer) =>
  result.map(({ id, question, position, initialThoughtsKeywords, answers }) =>
    isOrganizer
      ? {
          id,
          question,
          position,
          initialThoughtsId: id,
          initialThoughtsKeywords,
        }
      : {
          initialThoughtsKeywords,
          initialThoughtsId: id,
          answerId: answers?.id || 0,
          answers: answers?.answer || '',
          questionId: id,
          question,
        }
  );

export const initialThoughtsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getInitialThoughts: build.query({
      query: (params) => ({
        url: `courses/${params.courseId}/sections/initial-thoughts/${params.chapterId}/`,
        params,
      }),
      transformErrorResponse,
      transformResponse: (result, _meta, arg) => {
        const { isOrganizer } = arg;
        const data = cleanUpInitialThoughts(result, isOrganizer);
        transformResponse(data);
        return data;
      },
    }),
    createInitialThoughts: build.mutation({
      query: (params) => ({
        url: `courses/${params.courseId}/sections/initial-thoughts/${params.chapterId}`,
        method: 'POST',
        params: { role: params.role },
        body: params.result.map((e, index) => ({ ...e, position: index + 1 })),
      }),
      transformResponse: (data) => {
        transformResponse(data);
        return data;
      },
      transformErrorResponse,
    }),
    deleteInitialThoughts: build.mutation({
      query: (params) => ({
        url: `courses/${params.courseId}/sections/initial-thoughts/${params.chapterId}/${params.result.id}`,
        method: 'DELETE',
        body: params.result,
      }),
      transformResponse: (data) => {
        transformResponse(data);
        return data;
      },
      transformErrorResponse,
    }),
    updateInitialThoughts: build.mutation({
      query: (params) => ({
        url: `courses/${params.courseId}/sections/initial-thoughts/${params.chapterId}`,
        method: 'PATCH',
        body: params,
        params: { role: params.role },
      }),
      transformResponse: (data) => {
        transformResponse(data);
        return data;
      },
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetInitialThoughtsQuery,
  useLazyGetInitialThoughtsQuery,
  useDeleteInitialThoughtsMutation,
  useCreateInitialThoughtsMutation,
  useUpdateInitialThoughtsMutation,
} = initialThoughtsApi;
export default initialThoughtsApi;
