import CategoriesName from './CategoriesName';
import CategoriesSection from './CategoriesSection';
import CategoriesWrapper from './style/CategoriesWrapper';

const Categories = ({ activeCategory, changeCategory, options, onClick, searchParams, ...props }) => (
  <CategoriesWrapper>
    <div className='filter-categories'>
      {options.map(({ name, value, disabled }) => (
        <CategoriesSection
          key={value}
          activeCategory={activeCategory}
          value={value}
          changeCategory={changeCategory}
          onClick={onClick}
          disabled={disabled}
          searchParams={searchParams}
          sectionProps={props}
        >
          <CategoriesName activeCategory={activeCategory} value={value}>
            {name}
          </CategoriesName>
        </CategoriesSection>
      ))}
    </div>
  </CategoriesWrapper>
);

export default Categories;
