const Paperclip = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.6532 2.60796L3.14309 7.95674C1.72296 9.33529 1.72296 11.5704 3.14309 12.9489C4.56323 14.3275 6.86572 14.3275 8.28586 12.9489L14.5306 6.88698L16 8.31332L9.75522 14.3753C7.52357 16.5416 3.90537 16.5416 1.67373 14.3753C-0.55791 12.209 -0.55791 8.6967 1.67373 6.5304L7.18383 1.18162C8.80685 -0.393873 11.4383 -0.393873 13.0613 1.18162C14.6843 2.75711 14.6843 5.31149 13.0613 6.88698L7.55117 12.2358C6.53679 13.2204 4.89215 13.2204 3.87777 12.2358C2.86339 11.2511 2.86339 9.65459 3.87777 8.66991L9.38788 3.32113L10.8572 4.74747L5.34713 10.0963C5.14426 10.2932 5.14426 10.6125 5.34713 10.8094C5.55001 11.0064 5.87894 11.0064 6.08181 10.8094L11.5919 5.46064C12.4034 4.6729 12.4034 3.39571 11.5919 2.60796C10.7804 1.82021 9.4647 1.82021 8.6532 2.60796Z'
      fill='#333333'
    />
  </svg>
);

export default Paperclip;
