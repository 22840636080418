/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getAccessToken, loadState, saveLocalStorage } from 'configs/localStorage';

const data = getAccessToken();
const { user, adminUser } = loadState();
const initialState = { isAuth: !!data, user, adminUser };
const authorizationsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    isAuth(state, action) {
      const {
        payload: { userData, isAuth },
      } = action;
      state.isAuth = isAuth;
      if (isAuth) {
        state.user = userData.user;
        state.adminUser = userData.adminUser;
        const { isDomainSuperAdmin, isSuperAdmin } = userData.user || {};
        saveLocalStorage({
          adminUser: isDomainSuperAdmin || isSuperAdmin ? userData.user : null,
          user: userData.user,
        });
        return;
      }
      state.user = null;
      state.adminUser = null;
      saveLocalStorage({
        adminUser: null,
        user: null,
      });
    },
  },
});

export const { isAuth } = authorizationsSlice.actions;
export default authorizationsSlice.reducer;
