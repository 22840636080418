export const SECTIONS_HELP_TEXT = {
  story: {
    member:
      'Story page features a story, testimonial, or case-study that introduces Members to new ideas. These new ideas may include problems to be solved – or solutions to problems. The Story may be told with audio, video, images, and text content. When Members complete the Story, they use the Continue button to proceed to the next page.',
    organizer:
      'Add audio, video, or text-based content that tells the Community Members a story, case study, or problem that matters to them.',
  },
  'initial-thoughts': {
    member:
      'The Initial Thoughts page includes questions designed to help Members think about the subject of the story, case study or problem. Members use the space provided to privately journal their thoughts. Other Members cannot see the responses. When complete, use the Continue button to proceed.',
    organizer:
      'Use questions to help the Community Members think about the subject of the story, case study, or problem.',
  },
  resources: {
    member: `Resources are examples of how Community Members, the Community Organizer, or subject matter experts would respond to the same question. Members may use the Add Resource button to create and share ideas with other Community Members. When complete, use the Continue button to proceed.`,
    organizer: `Resources are sourced from you, your Community Members, or subject matter experts you have selected. 
    Resources may be audio, video, text, spreadsheets, or hyperlinks to pages within your Community, or other websites.`,
  },
  'new-insights': {
    member:
      'The New Insights page offers Members the opportunity to review their response to the Initial Thoughts question, reflect on their ideas prior to viewing the Resources, then journal their new ideas with the benefit of these new insights. When complete, use the Continue button to proceed.',
    organizer:
      'The New Insights page offers the Member the opportunity to respond to the Initial Thoughts question again, with the benefit of their new insights.',
  },

  assessment: {
    member: `You must provide an answer for each question to unlock subsequent content modules, demonstrate completion of a prescribed 
    volume of content and or unlock a printable certificate of participation or completion. Be certain to press Save to finalize your assessment.`,
    organizer: `This page includes a variety of summary question formats (true/false, multiple choice, essay question, and others) that enable you to ask the 
      Community Member questions that summarize their recall or application of information.`,
  },
};

export const getHelpText = (section, role) => SECTIONS_HELP_TEXT[section][role];
