import testTypes from 'common/test-types';
import Title from 'style-guide/Typography/Title';
import FillBlank from './FillBlank';
import MultipleAnswer from './MultipleAnswer';
import MultipleBlanks from './MultipleBlanks';
import MultipleChoice from './MultipleChoice';
import ResponseMatching from './ResponseMatching';
import ScaledResponse from './ScaledResponse';
import ShortEssayScored from './ShortEssayScored';
import TrueFalse from './TrueFalse';

const GetAnswersComponent = ({ assessmentType, watch, setValue, control }) => {
  switch (Number(assessmentType)) {
    case testTypes.types.TestsTypeTrueFalse:
      return <TrueFalse watch={watch} setValue={setValue} />;

    case testTypes.types.TestsTypeMultipleChoice:
      return <MultipleChoice setValue={setValue} control={control} />;

    case testTypes.types.TestsTypeMultipleResponse:
      return <MultipleAnswer setValue={setValue} control={control} />;

    case testTypes.types.TestsTypeCloseExercise:
      return <FillBlank control={control} setValue={setValue} />;

    case testTypes.types.TestsTypeDragAndDrop:
      return <MultipleBlanks control={control} setValue={setValue} />;

    case testTypes.types.TestsTypeResponseMatching:
      return <ResponseMatching watch={watch} setValue={setValue} control={control} />;

    case testTypes.types.TestsTypeScoredEssay:
      return <ShortEssayScored />;

    case testTypes.types.TestsScaledResponseScored:
      return <ScaledResponse watch={watch} setValue={setValue} control={control} />;

    default:
      return null;
  }
};

const Answers = ({ searchParams, watch, setValue, control }) => {
  const assessmentType = searchParams.get('assessmentType');

  return (
    <>
      <Title variant={5} className='answer-title'>
        Answer
      </Title>
      <GetAnswersComponent assessmentType={assessmentType} watch={watch} setValue={setValue} control={control} />
    </>
  );
};

export default Answers;
