import { Input } from 'style-guide/Input';

const ResponseMatching = ({ answer }) => (
  <div className='response-matching-wrapper'>
    {answer.map((el) =>
      el?.option1 ? (
        <div key={el.id} className='d-flex flex-row inputs-wrapper'>
          <Input className='response-matching-input' value={el.option1} disabled />
          <Input className='response-matching-input second-option' value={el.option2} disabled />
        </div>
      ) : null
    )}
  </div>
);

export default ResponseMatching;
