const Folder = (props) => (
  <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 4C16 2.89543 15.1046 2 14 2H8.00179C8.04192 1.99978 8.02918 1.98428 7.94123 1.87732C7.91963 1.85104 7.89349 1.81925 7.86248 1.78082C7.83182 1.74281 7.74603 1.63225 7.67056 1.535C7.61708 1.46609 7.56879 1.40385 7.54898 1.37885C6.83294 0.475451 6.12 0 5 0H2C0.89543 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H14C15.1046 14 16 13.1046 16 12V4ZM14 4V12H2V2H5C5.38424 2 5.60702 2.14857 5.9816 2.62116C5.99337 2.63601 6.02712 2.67952 6.06918 2.73374C6.14956 2.83735 6.26027 2.98006 6.30583 3.03654C6.80869 3.65991 7.27649 3.99614 7.99465 3.99999L14 4Z'
      fill='#333333'
    />
  </svg>
);

export default Folder;
