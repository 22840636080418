import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';
import TrueFalseWrapper from './style/TrueFalseWrapper';

const TrueFalse = ({ watch, setValue }) => {
  const answer = watch('answer');
  const trueAnswer = answer?.[0]?.isTrue;
  const falseAnswer = answer?.[1]?.isTrue;

  const setAnswerValue = (value) => {
    setValue(
      'answer',
      [
        { answer: 'true', isTrue: value === 'true' },
        { answer: 'false', isTrue: value === 'false' },
      ],
      { shouldValidate: true, shouldDirty: true }
    );
  };

  return (
    <TrueFalseWrapper>
      <Text $variant={3} $dark={60} className='label'>
        *Check the answer that is correct
      </Text>
      <div onChange={(e) => setAnswerValue(e.target.value, setValue)} className='true-false-container'>
        <div className='radio-button-box'>
          <Radio value='true' checked={!!trueAnswer} readOnly name='true-false'>
            <Text $variant={2} $dark={100}>
              True
            </Text>
          </Radio>
        </div>
        <div className='radio-button-box'>
          <Radio value='false' checked={!!falseAnswer} readOnly name='true-false'>
            <Text $variant={2} $dark={100}>
              False
            </Text>
          </Radio>
        </div>
      </div>
    </TrueFalseWrapper>
  );
};
export default TrueFalse;
