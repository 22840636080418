import { message } from 'style-guide/ToastMessages';
import emptySplitApi from './services';

const { updateQueryData, upsertQueryData, prefetch, resetApiState, invalidateTags } = emptySplitApi.util;

const infiniteScrollMiddleware = {
  keepUnusedDataFor: 0,
  serializeQueryArgs: ({ endpointName }) => endpointName,
  merge: (currentCache, newItems, { arg }) => {
    if (!arg.offset || currentCache?.total !== newItems?.total) {
      return newItems;
    }

    currentCache.rows.push(...newItems.rows);
  },
};

export { infiniteScrollMiddleware, invalidateTags, prefetch, resetApiState, updateQueryData, upsertQueryData };

export const transformErrorResponse = (response) => {
  if (response.data) {
    message.error(response.data.message);
  }
};

export const transformResponse = ({ message: info }) => {
  if (info) {
    message.success(info);
  }
};
