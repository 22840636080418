import styled from 'styled-components';

const SocialMediaLoginButtonsWrapper = styled.div`
  .sign-up-with-facebook {
    margin-bottom: 20px;
    background-color: #3b51b1;
    .icon {
      line-height: 0;
    }
    span {
      color: ${({ theme }) => theme.colors.white[100]};
    }
  }
  .sign-up-with-google {
    background-color: ${({ theme }) => theme.colors.white[100]};
    border: 1px solid ${({ theme }) => theme.colors.dark[25]};
    margin-bottom: 40px;
    .icon {
      line-height: 0;
    }
  }
`;

export default SocialMediaLoginButtonsWrapper;
