import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useChangePasswordMutation } from 'store/services/users';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import schema from './schema';
import ChangePasswordWrapper from './style/ChangePasswordWrapper';

const ChangePassword = ({ hideModal, params }) => {
  const [changePassword, { isLoading, isSuccess, isError, error }] = useChangePasswordMutation();
  const {
    trigger,
    watch,
    handleSubmit,
    register,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
  const { email } = params;
  const newPasswordField = watch('newPassword');
  const confirmPasswordField = watch('confirmPassword');
  const hasChanged = Object.keys(dirtyFields).length;
  const hasError = !errors.oldPassword && !errors.newPassword && !errors.confirmPassword;
  const isValidForm = hasError && hasChanged === 3;
  const isConfirmedPassword = newPasswordField && confirmPasswordField && newPasswordField !== confirmPasswordField;

  useEffect(() => {
    isConfirmedPassword ? trigger(['newPassword', 'confirmPassword']) : clearErrors('confirmPassword');
  }, [clearErrors, isConfirmedPassword, trigger]);

  useEffect(() => {
    if (isSuccess) {
      message.success('You have successfully updated the password!');
      hideModal();
      return;
    }
    if (isError) {
      const errorMessage = error?.data?.message;
      message.error(errorMessage);
    }
  }, [isSuccess, isError, error, hideModal]);

  const onFormSubmit = (data) => {
    changePassword({
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      email,
    });
  };

  return (
    <ChangePasswordWrapper>
      <div className='change-password-modal-root'>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Input
            type='password'
            name='oldPassword'
            label='Old Password'
            required
            error={errors?.oldPassword}
            placeholder='Write your old password'
            {...register('oldPassword')}
          />
          <Input
            type='password'
            name='newPassword'
            label='New Password'
            required
            error={errors?.newPassword}
            placeholder='At least 6 characters'
            {...register('newPassword')}
          />
          <Input
            type='password'
            className='input'
            label='Confirm New Password'
            required
            error={errors?.confirmPassword}
            placeholder='Confirm new password'
            {...register('confirmPassword')}
          />
          <div className='d-flex justify-content-center pt--16'>
            <Button variant='secondary' className='mr--16 ph--40' onClick={hideModal}>
              Cancel
            </Button>
            <Button variant='primary' className='ph--48' type='submit' loading={isLoading} disabled={!isValidForm}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    </ChangePasswordWrapper>
  );
};

export default ChangePassword;
