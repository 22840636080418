import styled from 'styled-components';

const FilterWrapper = styled.div`
  .text {
    cursor: pointer;
    padding-left: 5px;
  }
  .draft {
    padding-left: 22px;
  }
`;

export default FilterWrapper;
