import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import UnderlineExtension from '@tiptap/extension-underline';
import AudioExtension from './Audio';
import BlockquoteExtension from './Blockquote';
import DocExtension from './Doc';
import HighlightExtension from './Highlight';
import IframeExtension from './Iframe';
import ImageExtension from './Image';
import LinkExtension from './Link';
import PdfExtension from './Pdf';
import PlaceholderExtension from './Placeholder';
import { shortCode } from './ShortCode';
import StarterKitExtension from './StarterKit';
import VideoExtension from './Video';
import YoutubeExtension from './Youtube';
import SearchAndReplace from './searchAndReplace';

const extensions = [
  PlaceholderExtension,
  BlockquoteExtension,
  StarterKitExtension,
  HighlightExtension,
  UnderlineExtension,
  SearchAndReplace,
  YoutubeExtension,
  IframeExtension,
  ImageExtension,
  VideoExtension,
  AudioExtension,
  LinkExtension,
  PdfExtension,
  DocExtension,
  shortCode,
  Document,
  Text,
];

export default extensions;
