import { forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import { Input } from 'style-guide/Input';

const SearchComponent = forwardRef(({ setVisible, field, handleSubmit, name, setFocus }, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFormSubmit = (formData) => {
    searchParams.set(name, formData[name].trim());
    setSearchParams(searchParams);
    setVisible(false);
  };

  const resetSearch = () => {
    if (searchParams.get(name)) {
      searchParams.delete(name);
      setSearchParams(searchParams);
    }
    field.onChange('');
    setFocus(name);
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='search-dropdown' ref={ref}>
        <Input {...field} autoFocus />
        <div className='d-flex justify-content-between dropdown-buttons'>
          <Button variant='primary' type='submit' $block className='search-button'>
            Search
          </Button>
          <Button variant='secondary' $block onClick={resetSearch}>
            Reset
          </Button>
        </div>
      </div>
    </Form>
  );
});

export default SearchComponent;
