import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const PresentationWrapper = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
    width: 664px;
    height: 588px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    width: 324px;
    height: 596px;
  }

  .presentation-root {
    position: relative;

    .content-box {
      display: flex;

      .slides-section-box {
        width: 267px;

        .member-view {
          height: 520px !important;
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            height: 472px !important;
          }
        }

        .slides-list {
          border: 1px solid ${({ theme }) => theme.colors.dark[15]};
          border-radius: 2px;
          height: 583px;
          overflow-y: auto;
          overflow-x: hidden;
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            height: 520px !important;
          }

          .slide {
            border-bottom: 1px solid ${({ theme }) => theme.colors.dark[15]};

            &:hover {
              input {
                background: ${({ theme }) => theme.colors.background[100]};
              }

              .trash-icon {
                visibility: visible;
              }
            }

            .dndIcon {
              padding-left: 8px;
              visibility: hidden;
            }
            &:hover {
              .dndIcon {
                visibility: visible;
              }
              background: ${({ theme }) => theme.colors.background[100]};
            }

            .trash-icon {
              visibility: hidden;
              margin-right: 12px;
              cursor: pointer;

              path {
                fill: ${({ theme }) => theme.colors.error[600]};
              }
            }
          }

          .slide-name-box {
            input {
              text-overflow: ellipsis;
            }
          }

          .slide-name-box > div:first-child {
            margin: 12px 0 !important;
          }

          .isActiveSlide {
            background: ${({ theme }) => theme.colors.secondary[50]};
            input {
              background: ${({ theme }) => theme.colors.secondary[50]};
            }

            &:hover {
              background: ${({ theme }) => theme.colors.secondary[50]};
              input {
                background: ${({ theme }) => theme.colors.secondary[50]};
              }
            }
          }
        }
      }

      .editor-section-box {
        width: 698px;
        height: 530px;
        margin-left: 20px;
        position: relative;
        .tox-tinymce {
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            height: 420px !important;
          }
        }

        .editor-skeleton-root {
          width: 94%;
          height: 532px;
          border: 1px solid ${({ theme }) => theme.colors.dark[15]};
          border-radius: 8px;
          padding: 20px;
        }
      }
    }
  }

  .presentation-member-view {
    height: 464px;
    margin-bottom: 20px;
    padding-right: 8px;
    overflow: auto;
    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      height: 520px;
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: revert !important;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }

  .menu-block {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.white[100]};
  }

  .description-editor {
    height: 548px;
    border: 1px solid ${({ theme }) => theme.colors.dark[15]};
    border-radius: 8px;
    padding: 12px;
    overflow-y: auto;
  }
`;

export default PresentationWrapper;
