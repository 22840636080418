import classNames from 'classnames';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import { closeSidebar } from 'store/slices/entitySidebar';
import Button from 'style-guide/Button';

const CategoriesSection = ({
  activeCategory,
  value,
  children,
  onClick,
  changeCategory,
  disabled,
  sectionProps,
  searchParams,
}) => {
  const dispatch = useDispatch();
  const { innerWidth } = useWindowSize();

  const isSmallScreen = innerWidth <= SCREEN_SIZES.XL;

  return (
    <Button
      key={value}
      onClick={(e) => {
        searchParams?.delete('q');
        changeCategory?.(value);
        isSmallScreen && dispatch(closeSidebar());
        onClick?.(e);
      }}
      variant='link'
      disabled={disabled}
      className={classNames('filter-category justify-content-start', {
        'active-border': activeCategory === value,
      })}
      {...sectionProps}
    >
      {children}
    </Button>
  );
};

export default CategoriesSection;
