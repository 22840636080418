import { Node, mergeAttributes } from '@tiptap/core';

const IframeExtension = Node.create({
  name: 'vimeo', // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: false, // so we can drag the video
  atom: false, // is a single unit

  addAttributes() {
    return {
      ...this?.parent?.(),
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute('src'),
      },
      alt: {
        parseHTML: (element) => element.getAttribute('alt'),
      },
    };
  },

  addNodeView() {
    return ({ node }) => {
      const iframe = document.createElement('iframe');
      iframe.src = node.attrs.src;

      iframe.frameBorder = '0';
      iframe.className = 'responsive-iframe';

      const div = document.createElement('div');
      div.className = 'container';
      div.append(iframe);

      return {
        dom: div,
      };
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes({ ...HTMLAttributes })];
  },
});

export default IframeExtension;
