import 'rc-tooltip/assets/bootstrap.css';
import styled, { css } from 'styled-components';
import TooltipComponent from './Tooltip';

const Tooltip = styled(TooltipComponent)`
  opacity: 1 !important;
  z-index: 1100 !important;
  .rc-tooltip-arrow {
    display: none;
  }
  .rc-tooltip-inner {
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '100%')};
    display: ${({ $disabled }) => ($disabled ? 'none' : 'inherit')};
    background-color: ${({ $backgroundColor, theme }) => $backgroundColor || theme.colors.secondary[600]};
    border: none;
    box-shadow: none;
    min-height: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: ${({ $padding }) => $padding || '5px 10px'};
    ${({ $helpTooltip }) =>
      $helpTooltip &&
      css`
        text-align: center;
        max-width: 250px;
        margin-right: 30px;
      `}
  }
`;

export default Tooltip;
