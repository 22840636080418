import styled from 'styled-components';

const AddQuestionWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
  .choose-question-box {
    .label {
      margin-bottom: 4px;
    }
    .dropdown-children {
      margin-right: 10px;
    }
  }
  .checkbox-label {
    padding-left: 8px;
    padding-right: 16px;
    width: 150px;
  }
  .save-button {
    margin-left: 20px;
    width: 80px;
  }

  button {
    &.link {
      height: inherit;
      svg {
        width: 9px;
        height: 9px;
      }
    }
  }
  .feedback-title {
    margin-bottom: 12px;
  }
  .add-feedback-button {
    .icon-button {
      display: flex;
    }
  }
  .link {
    padding-left: 0;
  }
  .image-caption-box {
    flex: 1;
    margin-bottom: 20px;
  }
  .add-hyperlink-button,
  .answer-title,
  .question-title,
  .add-feedback-button,
  .choose-question-box {
    margin-bottom: 20px;
  }
  .add-hyperlink-button {
    margin-top: 4px;
  }
  .hyperlink-text {
    padding-left: 10px;
    padding-right: 8px;
  }
  .navigate {
    padding-right: 10px;
  }
  .trash-icon-button {
    margin-top: 20px;
  }
  .button-section {
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 20px;
    right: 0;
  }

  .short-essay-textarea {
    textarea {
      resize: none !important;
      width: 100% !important;
      overflow-y: auto;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 4px;
        border-radius: 5px;
        -webkit-appearance: none;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #d9d9d9;
      }
    }
  }

  .no-image-placeholder {
    height: 60px;
    width: 60px;
    border-radius: 8px;
    object-fit: cover;
  }

  .image-wrapper {
    height: 60px;
    width: 60px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }

    .zoom-btn {
      height: 60px;
      width: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      border: none;
      background-color: inherit;
      padding: 0;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s, visibility 0.5s, background-color 0.5s;
    }

    &:hover {
      .zoom-btn {
        background-color: #54624d;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .image-modal {
    height: 200px;
    width: 200px;
  }

  .img-upload-container {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: end;

    .img-upload-btn {
      margin-left: 16px;
    }
  }

  .remove-img-btn {
    color: ${({ theme }) => theme.colors.error[600]};
    border-color: ${({ theme }) => theme.colors.error[600]};

    :hover {
      background-color: ${({ theme }) => theme.colors.error[400]};
      color: ${({ theme }) => theme.colors.white[100]};
    }

    :disabled {
      background-color: ${({ theme }) => theme.colors.secondary[200]};
      border-color: ${({ theme }) => theme.colors.secondary[200]};
      color: ${({ theme }) => theme.colors.dark[60]};
    }
  }

  .menu-block {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.white[100]};
  }

  .description-editor {
    height: 220px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 4px;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white[100]};
  }
`;

export default AddQuestionWrapper;
