import 'rc-menu/assets/index.css';
import styled, { css } from 'styled-components';
import './index.css';

const MenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white[100]};

  .rc-menu {
    border: 1px solid ${({ theme }) => theme.colors.white[100]};
    box-shadow: none;
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.white[100]};
  }

  .rc-menu-item,
  .rc-menu-submenu > .rc-menu-submenu-title {
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.boxShadow[10]};
    }
  }

  .rc-menu-submenu-active {
    .rc-menu-submenu-title {
      background-color: ${({ theme }) => theme.colors.boxShadow[10]};
      border-radius: 8px;
    }
  }

  .rc-menu-item,
  .rc-menu-submenu {
    border-radius: 8px;
  }

  .rc-menu-item-selected,
  .rc-menu-submenu-selected > .rc-menu-submenu-title {
    color: ${({ theme }) => theme.colors.primary[600]};
    background-color: ${({ theme }) => theme.colors.primary[50]};
    path {
      fill: ${({ theme }) => theme.colors.primary[600]};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
  }

  .rc-menu-sub {
    .rc-menu-item {
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.boxShadow[10]};
      padding-left: 24px !important;
      margin: 0 !important;
      border-radius: 0;
    }
  }

  .rc-menu-item-disabled,
  .rc-menu-submenu-disabled > .rc-menu-submenu-title {
    color: ${({ theme }) => theme.colors.boxShadow[30]} !important;
    background-color: transparent !important;
    cursor: initial;
    path {
      fill: ${({ theme }) => theme.colors.boxShadow[30]} !important;
    }
  }

  .sub-menu-icon {
    position: absolute;
    right: 16px;
    margin-top: 2px;
  }

  ${(props) => css`
    .open-icon {
      transform: rotate(${props.mode === 'inline' ? '180deg' : '270deg'});
    }
  `}

  ${(props) =>
    props?.mode === 'horizontal' &&
    css`
      display: flex;
      align-items: center;

      .rc-menu {
        display: flex;
        align-items: center;
        border: none !important;
      }

      .rc-menu-item,
      .rc-menu-submenu {
        margin: 0 !important;
      }

      .rc-menu-submenu-title {
        border-bottom: 1px solid transparent;
        margin: 0;
      }

      .rc-menu-item-active,
      .rc-menu-submenu-active > .rc-menu-submenu-title {
        color: ${({ theme }) => theme.colors.primary[600]};
        background-color: transparent !important;
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary[600]} !important;
        border-radius: 0;
        path {
          fill: ${({ theme }) => theme.colors.primary[600]};
        }
      }

      .rc-menu-submenu-active {
        background-color: transparent;
      }

      .rc-menu-item-selected {
        background-color: transparent !important;
        .rc-menu-submenu-title {
          background-color: ${({ theme }) => theme.colors.white[100]} !important;
        }
      }

      .rc-menu-overflow-item {
        border-radius: 0;
        border-bottom: 1px solid transparent;
        margin: 0;
        .rc-menu-submenu-title {
          margin: 0;
        }
    `}
  }

  ${({ $variant }) =>
    $variant === 'dark' &&
    css`
      background-color: ${({ theme }) => theme.colors.darkBackground[100]};
      .rc-menu {
        background-color: ${({ theme }) => theme.colors.darkBackground[100]};
        border: 1px solid ${({ theme }) => theme.colors.darkBackground[100]};
      }

      .rc-menu-item,
      .rc-menu-submenu > .rc-menu-submenu-title {
        color: ${({ theme }) => theme.colors.white[60]};
        path {
          fill: ${({ theme }) => theme.colors.white[60]};
        }
      }

      .rc-menu-item-active,
      .rc-menu-submenu-active > .rc-menu-submenu-title {
        color: ${({ theme }) => theme.colors.white[100]};
        background-color: ${({ theme }) => theme.colors.darkBackground[100]} !important;
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
      }

      .rc-menu-sub {
        .rc-menu-item {
          background-color: ${({ theme }) => theme.colors.boxShadow[50]};
        }
        .rc-menu-item-active {
          background-color: ${({ theme }) => theme.colors.boxShadow[50]} !important;
        }
      }

      .rc-menu-item-selected,
      .rc-menu-submenu-selected > .rc-menu-submenu-title {
        color: ${({ theme }) => theme.colors.white[100]};
        background-color: ${({ theme }) => theme.colors.primary[600]} !important;
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.primary[600]};
        }
      }

      .rc-menu-item-disabled,
      .rc-menu-submenu-disabled > .rc-menu-submenu-title {
        color: ${({ theme }) => theme.colors.white[25]} !important;
        background-color: transparent !important;
        cursor: initial;
        path {
          fill: ${({ theme }) => theme.colors.white[25]} !important;
        }
      }

      ${(props) =>
        props.mode === 'horizontal' &&
        css`
          .rc-menu-item,
          .rc-menu-submenu > .rc-menu-submenu-title,
          .rc-menu-overflow-item {
            color: ${({ theme }) => theme.colors.white[100]};
            border-bottom: 2px solid ${({ theme }) => theme.colors.darkBackground[100]};
            path {
              fill: ${({ theme }) => theme.colors.white[100]};
            }
          }

          .rc-menu-item-active,
          .rc-menu-submenu-active {
            border-bottom: 2px solid ${({ theme }) => theme.colors.white[100]} !important;
          }

          .rc-menu-item-selected,
          .rc-menu-submenu-selected > .rc-menu-submenu-title {
            color: ${({ theme }) => theme.colors.white[100]};
            background-color: ${({ theme }) => theme.colors.darkBackground[100]} !important;
            path {
              fill: ${({ theme }) => theme.colors.white[100]};
            }
          }
        `}
    `}
`;

export default MenuWrapper;
