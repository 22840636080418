const HELPERS = {
  roundByStep: (value, step = 1) => {
    const inv = 1 / step;
    return Math.round(value * inv) / inv;
  },

  calculateAverage: (array) =>
    array &&
    array.length &&
    array.reduce((acc, current) => {
      // eslint-disable-next-line no-param-reassign
      acc += current.rating;
      return acc;
    }, 0) / array.length,

  arrayMove: (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  },

  getVimeoUrl: (url) => {
    const match = url.match(/vimeo\.com\/(\d+)/);
    if (match) {
      return `https://player.vimeo.com/video/${match[1]}`;
    }
    const playerMatch = url.match(/player\.vimeo\.com\/video\/(\d+)/);
    if (playerMatch) {
      return url;
    }

    return null;
  },

  getUserFullName: ({ firstName, lastName }) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return `${firstName}`;
    }

    if (lastName) {
      return `${lastName}`;
    }

    return '';
  },

  getUserPublicName: ({ firstName, lastName, userName }) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    if (firstName) {
      return `${firstName}`;
    }

    if (lastName) {
      return `${lastName}`;
    }

    if (userName) {
      return `${userName}`;
    }

    return 'Unknown user';
  },
};

export default HELPERS;
