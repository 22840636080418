const Facebook = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.0001 0.5C6.71586 0.5 0 7.24393 0 15.5628C0 23.0239 5.40783 29.2029 12.4984 30.3994V18.7053H8.87992V14.4971H12.4984V11.3941C12.4984 7.79377 14.6882 5.83179 17.8871 5.83179C19.4191 5.83179 20.7357 5.94643 21.1179 5.99692V9.76024L18.8993 9.76132C17.16 9.76132 16.8247 10.5911 16.8247 11.8092V14.4949H20.9748L20.4335 18.7032H16.8247V30.5C24.2463 29.593 30 23.2565 30 15.5585C30 7.24393 23.2841 0.5 15.0001 0.5Z'
      fill='#607D8B'
    />
  </svg>
);

export default Facebook;
