import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';

import { generateEntityPagePath } from 'Components/App/Helpers/entityPagesRoute/pathGenerateHelper';
import { formStepsId } from 'Components/EntityFormSections/Stepper/helper';
import { ENTITY_TYPES } from 'common';
import { Eye, Lock, Pencil, Trash } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';

const { DEPARTMENT } = ENTITY_TYPES;

const coursePath = (item) => `/${DEPARTMENT.key}/${item.id}/${item.urlName}/organizer/homepage`;
const courseSettingsPath = (item) => generateEntityPagePath(DEPARTMENT.key, item.id, item.urlName, formStepsId.naming);

const setModalTitleText = (modalTitle, item) => {
  switch (modalTitle) {
    case 'delete':
      return (
        <Text $variant={2} $dark={100}>
          You want to delete the {DEPARTMENT.lowercase}?
        </Text>
      );
    case 'archive':
      return (
        <Text $variant={2} $dark={100}>
          {`You want to ${item.isArchive === true ? 'unarchive' : 'archive'} the ${DEPARTMENT.lowercase}?`}
        </Text>
      );
    default:
      return null;
  }
};
const showConfirmationModal = (dispatch, deleteDepartment, updateArchive, item, title) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: <Title>Are you sure</Title>,
        },
        body: {
          title: setModalTitleText(title, item),
          onOk: () => {
            switch (title) {
              case 'delete':
                deleteDepartment({ id: item.id });
                break;
              case 'archive':
                updateArchive({
                  courseId: item.id,
                  isArchive: !item.isArchive,
                  mode: 'library',
                });
                break;
              default:
                null;
            }
          },
        },
      },
    })
  );
};
export const getDepartmentsColumns = ({ setCourseUserData, dispatch, deleteDepartment, updateArchive }) => [
  {
    title: <SearchDropdown title='Name' name='name' />,
    dataIndex: 'name',
    key: 'name',
    width: 170,
  },
  {
    title: <Title variant={5}>Administrator</Title>,
    dataIndex: 'administratorName',
    key: 'administratorName',
    width: 170,
  },
  {
    title: <DropDownFilter name='accessTypeId' firstParam='Private' secondParam='Public' />,
    dataIndex: 'accessTypeId',
    key: 'accessTypeId',
    width: 140,
  },
  {
    title: <Title variant={5}>Payment Type</Title>,
    dataIndex: 'paymentTypeId',
    key: 'paymentTypeId',
    width: 180,
  },
  {
    title: <SearchDropdown title='Price' name='price' />,
    dataIndex: 'price',
    key: 'price',
    width: 130,
  },
  {
    title: <Title variant={5}>Status</Title>,
    dataIndex: 'status',
    key: 'status',
    render: (_index, item) => (
      <div className='row'>
        <div className='col-12 justify-content-center' style={{ marginLeft: 'px' }}>
          <Text>{item.status === true ? '___' : ''}</Text>
        </div>
      </div>
    ),
    width: 100,
  },
  {
    title: <Title variant={5}>Archive</Title>,
    dataIndex: 'isArchive',
    key: 'isArchive',
    width: 120,
    render: (_index, item) => <Text>{item.isArchive === true ? 'Archived' : ''}</Text>,
  },
  {
    title: <Title variant={5}>Actions</Title>,
    dataIndex: 'Actions',
    key: 'Actions',
    render: (_index, item) => (
      <div className='d-flex align-items-center'>
        <Tooltip placement='topRight' overlay='View Library'>
          <Eye className='eye pointer action-icon' onClick={() => setCourseUserData(item, coursePath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Edit Library'>
          <Pencil className='pencil pointer action-icon' onClick={() => setCourseUserData(item, courseSettingsPath)} />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Remove Library'>
          <Trash
            className='trash pointer action-icon'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'delete')}
          />
        </Tooltip>
        <Tooltip placement='topRight' overlay='Archive Library'>
          <Lock
            className='lock pointer'
            onClick={() => showConfirmationModal(dispatch, deleteDepartment, updateArchive, item, 'archive')}
          />
        </Tooltip>
      </div>
    ),
    width: 170,
  },
];
export const COURSES_CONST = { NAME: 'name', PRICE: 'price', COURSES: 'courses', LIBRARIES: 'libraries' };
