// eslint-disable-next-line import/no-extraneous-dependencies

import { message } from 'style-guide/ToastMessages';
import emptySplitApi from '..';

export const notificationApi = emptySplitApi.injectEndpoints({
  reducerPath: 'notification',
  endpoints: (build) => ({
    getNotificationsSettings: build.query({
      query: (params) => ({
        url: '/notifications/settings',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => {
        const notificationsSettingsData = {};
        const notificationsSettingsList = [...response.admins, ...response.learners];

        notificationsSettingsList?.forEach((e) => {
          notificationsSettingsData[e.id] = { onTop: e.onTop, byEmail: e.byEmail };
        });
        return { notificationsSettingsData, response };
      },
      providesTags: ['notifications-settings'],
    }),

    updateNotificationSettings: build.mutation({
      query: (params) => ({
        url: '/notifications/settings',
        method: 'PUT',
        body: params,
      }),
      transformResponse: (response) => {
        message.success(response.message || 'Notification settings successfully has been updated');
        return response;
      },
      invalidatesTags: ['notifications-settings'],
    }),
  }),
});

export const { useGetNotificationsSettingsQuery, useUpdateNotificationSettingsMutation } = notificationApi;
