import { forwardRef } from 'react';

const variantsMapping = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
};

const Title = forwardRef((props, ref) => {
  const { variant, children, className, ...titleProps } = props;
  const TitleComponent = variant && variant in variantsMapping ? variantsMapping[variant] : 'h4';
  return (
    <TitleComponent className={`typography--title-variant-${TitleComponent} ${className}`} {...titleProps} ref={ref}>
      {children}
    </TitleComponent>
  );
});

export default Title;
