import { ENTITY_TYPES } from 'common';
import PATHS from 'constants/paths';
import { useMemo } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import All from 'views/Explore/Body/All';
import Departments from './Departments';
import Favorites from './Favorites';
import LearningCommunities from './LearningCommunities';
import Members from './Members';
import Organization from './Organization';

const COMPONENTS = {
  [ENTITY_TYPES.LEARNING_COMMUNITY.key]: LearningCommunities,
  [ENTITY_TYPES.DEPARTMENT.key]: Departments,
  [ENTITY_TYPES.ORGANIZATION.key]: Organization,
  [ENTITY_TYPES.MEMBER.key]: Members,
  all: All,
  favorites: Favorites,
};

const Body = () => {
  const cardsCount = 15;
  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const colSpan = 'col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 card';

  const search = searchParams.get('q') || undefined;
  const rating = searchParams.get('rating') || undefined;
  const maxPrice = searchParams.get('max-price') || undefined;
  const minPrice = searchParams.get('min-price') || undefined;

  if (!COMPONENTS[category]) {
    return <Navigate to={PATHS.NOT_FOUND} replace />;
  }

  const ratingValue = useMemo(() => (rating ? rating.split('-') : []), [rating]);
  const Component = useMemo(() => COMPONENTS[category], [category]);

  return (
    <Component
      key={category}
      search={search}
      maxPrice={maxPrice}
      minPrice={minPrice}
      ratingValue={ratingValue}
      category={category}
      cardsCount={cardsCount}
      colSpan={colSpan}
    />
  );
};

export default Body;
