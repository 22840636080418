import { EditorProvider as Editor } from '@tiptap/react';
import classNames from 'classnames';

const EditorProvider = ({ className, ...props }) => (
  <Editor
    {...props}
    editorProps={{
      ...(props?.editorProps || {}),
      attributes: { class: classNames('editor', className), translate: 'yes' },
    }}
  />
);

export default EditorProvider;
