import styled from 'styled-components';
import userAvatar from './UserAvatar';

const UserAvatar = styled(userAvatar)`
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[200]};

  .name {
    margin: 0;
  }

  img,
  .name {
    border-radius: 100%;
    width: 100%;
    height: 100%;
  }
`;

export default UserAvatar;
