import { ENTITY_TYPES } from 'common';
import EntityCardList from 'Components/EntityCardList';

import { useLazyGetExploreMembersQuery } from 'store/services/members';
import useGetInfiniteScrollData from './useGetInfiniteScrollData';

const Members = ({ cardsCount }) => {
  const [request, { data = {}, isLoading }] = useLazyGetExploreMembersQuery();
  const hasNextPage = data.total > data.rows?.length;
  const { scrollerRef, loaderRef } = useGetInfiniteScrollData({ request, hasNextPage });

  return (
    <div className='body-root'>
      <EntityCardList
        data={data?.rows}
        total={data?.total}
        category={ENTITY_TYPES.MEMBER.key}
        isLoading={isLoading}
        scrollerRef={scrollerRef}
        hasNextPage={hasNextPage}
        loaderRef={loaderRef}
        isOrganizer={false}
        cardsCount={cardsCount}
        colSpan='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 card'
      />
    </div>
  );
};

export default Members;
