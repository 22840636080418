import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';
import IdleClock from './IdleClock';
import IdleTimeoutModalWrapper from './style/IdleTimeoutModalWrapper';

const IdleTimeoutModal = ({ hideModal, params }) => {
  const resetTimer = () => {
    params.reset();
    hideModal();
  };

  return (
    <IdleTimeoutModalWrapper>
      <Title variant={5} className='modal-text'>
        Your session is about to expire, are you still there?
      </Title>
      <IdleClock duration={params.promptBeforeIdle} />
      <div className='d-flex justify-content-center'>
        <Button variant='primary' onClick={resetTimer} className='ph--24'>
          Yes, I am here
        </Button>
      </div>
    </IdleTimeoutModalWrapper>
  );
};

export default IdleTimeoutModal;
