const files = {
  oga: 'audio',
  ogg: 'audio',
  flv: 'video',
  avi: 'video',
  mov: 'video',
  MOV: 'video',
  mpeg: 'video',
  mp3: 'audio',
  mp4: 'video',
  m4v: 'video',
  swf: 'video',
  doc: 'doc',
  docx: 'doc',
  pdf: 'pdf',
  ppt: 'ppt',
  pptx: 'ppt',
  jpg: 'image',
  jpe: 'image',
  jpeg: 'image',
  JPG: 'image',
  gif: 'video',
  png: 'image',
  xls: 'excel',
  xlsx: 'excel',
};

const FILE_TYPE = {
  hasFile: (format) => files[format] || null,
};

export default FILE_TYPE;
