const MessageError = (props) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='9' cy='9' r='9' fill='#E53935' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 10.0607L5.78033 13.2803L4.71967 12.2197L7.93934 9L4.71967 5.78033L5.78033 4.71967L9 7.93934L12.2197 4.71967L13.2803 5.78033L10.0607 9L13.2803 12.2197L12.2197 13.2803L9 10.0607Z'
      fill='white'
    />
  </svg>
);

export default MessageError;
