import { FOOTER_DATA } from 'common';
import { Link } from 'react-router-dom';

import GetCustomersLogo from 'Components/GetCustomersLogo';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import { Email, FacebookIcon, Linkedin, Map, Phone, Twitter } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';

const { FIRST_COL_DATA, SECOND_COL_DATA } = FOOTER_DATA;

const Footer = () => {
  const { data } = useGetDomainTracking();
  const { address, supportEmail, phone, facebook, linkedin, twitter } = data;
  const currentYear = new Date().getFullYear();

  return (
    <div className='footer-container'>
      <div className='logo-box'>
        <Link to='/'>
          <GetCustomersLogo />
        </Link>
        <Text $variant={3} $dark={80} className='year-box'>
          {currentYear} Peer-Tree LLC. All Rights Reserved.
        </Text>
      </div>
      <div className='column-box'>
        <div className='first-column'>
          {FIRST_COL_DATA.map(({ name, url }) => (
            <Text $variant={2} $dark={80} className='link' key={name}>
              <Link to={url} children={name} />
            </Text>
          ))}
        </div>
        <div className='second-column'>
          {SECOND_COL_DATA.map(({ name, url }) => (
            <Text $variant={2} $dark={80} className='link' key={name}>
              <Link to={url} children={name} />
            </Text>
          ))}
        </div>
        <div className='third-column'>
          {supportEmail ? (
            <div className='link'>
              <Email />
              <a className='text' href={`mailto: ${supportEmail}`}>
                {supportEmail}
              </a>
            </div>
          ) : null}
          {phone ? (
            <div className='link'>
              <Phone />
              <Text $variant={2} $dark={80} className='text'>
                {phone}
              </Text>
            </div>
          ) : null}
          {address ? (
            <div className='link'>
              <Map className='map-icon' />
              <Text $variant={2} $dark={80} className='text'>
                {address}
              </Text>
            </div>
          ) : null}
          <div className='link'>
            {twitter ? (
              <a className='icon' target='_blank' href={twitter} rel='noreferrer'>
                <Twitter />
              </a>
            ) : null}
            {linkedin ? (
              <a className='icon' target='_blank' href={linkedin} rel='noreferrer'>
                <Linkedin />
              </a>
            ) : null}
            {facebook ? (
              <a className='icon' target='_blank' href={facebook} rel='noreferrer'>
                <FacebookIcon />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
