import { TextArea } from 'style-guide/Input';
import Tag from 'style-guide/Tag';

const ShortEssayScored = ({ answer }) => (
  <div className='essay-scored-wrapper'>
    {answer.map((item) => (
      <Tag $keyword key={item.id} className='essay-scored-keyword'>
        {item.answer}
      </Tag>
    ))}
    <TextArea className='short-essay-textarea' disabled />
  </div>
);

export default ShortEssayScored;
