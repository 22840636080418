import { NodeViewWrapper } from '@tiptap/react';
import { useEffect } from 'react';
import { useCreateFileManagerMetaMutation, useGetFileManagerMetaQuery } from 'store/services/fileManagerMetaApi';
import createShortCodeElement from '../helper/createShortCodeElement';

/* eslint-disable jsx-a11y/media-has-caption */
const NodeView = ({ node }) => {
  const { attrs } = node;
  const [create, { data: createData }] = useCreateFileManagerMetaMutation();

  useEffect(() => {
    if (attrs['data-resource-id']) {
      create({ recourseId: attrs['data-resource-id'] });
    }
  }, [attrs, create]);

  const { data = {} } = useGetFileManagerMetaQuery({ id: attrs.id }, { skip: attrs['data-resource-id'] });
  const element = createShortCodeElement(attrs.type || createData?.type, {
    src: data.src || createData?.src || attrs.src,
    id: attrs.id || createData?.metaId,
  });

  return (
    <NodeViewWrapper>
      {(element && data?.src) || createData?.src || attrs?.src ? (
        <div dangerouslySetInnerHTML={{ __html: element }} />
      ) : (
        `[${attrs.type || createData?.type} id='${attrs.id || createData?.metaId}']`
      )}
    </NodeViewWrapper>
  );
};

export default NodeView;
