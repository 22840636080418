import Button from 'style-guide/Button';
import Text from 'style-guide/Typography/Text';

const EntityJoinedUser = ({ userType, url }) => (
  <>
    <div className='mv--16 footer-container'>
      <Text $variant={2}>{userType}</Text>
    </div>
    <Button to={url} variant='secondary' className='mt--16' $block>
      Start
    </Button>
  </>
);

export default EntityJoinedUser;
