const DOMAIN_KEYS = {
  peertree: 'peertree',
  boyens: 'boyens',
  hippoCampus: 'hippoCampus',
  politicalislam: 'politicalIslam',
  vanderbilt: 'vanderbilt',
  c2c: 'c2c',
  pear: 'pear',
  hrights: 'hrights',
  nucleus: 'nucleus',
  fsc: 'fsc',
  ecf: 'ecf',
  csm: 'csm',
  ssa: 'ssa',
  workvibes: 'workvibes',
  me: 'me',
  tvguest: 'tvguest',
  spaces: 'spaces',
  em: 'em',
  fba: 'fba',
  mh360: 'mh360',
  saq: 'saq',
};

export default DOMAIN_KEYS;
