const ArrowBottom = (props) => (
  <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 8.58579V0H7V8.58579L10.2929 5.29289L11.7071 6.70711L6 12.4142L0.292892 6.70711L1.70711 5.29289L5 8.58579Z'
      fill='#333333'
    />
  </svg>
);

export default ArrowBottom;
