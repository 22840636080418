const Instagram = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.8711 15.5C17.8711 17.0857 16.5857 18.3711 15 18.3711C13.4143 18.3711 12.1289 17.0857 12.1289 15.5C12.1289 13.9143 13.4143 12.6289 15 12.6289C16.5857 12.6289 17.8711 13.9143 17.8711 15.5Z'
      fill='#607D8B'
    />
    <path
      d='M21.7148 10.4203C21.5768 10.0463 21.3566 9.70776 21.0705 9.4299C20.7926 9.1438 20.4543 8.92361 20.0801 8.7856C19.7766 8.66772 19.3207 8.52742 18.4809 8.4892C17.5725 8.44777 17.3001 8.43884 15.0003 8.43884C12.7003 8.43884 12.4279 8.44754 11.5197 8.48897C10.6799 8.52742 10.2238 8.66772 9.9205 8.7856C9.54628 8.92361 9.20776 9.1438 8.93013 9.4299C8.64403 9.70776 8.42384 10.0461 8.2856 10.4203C8.16772 10.7238 8.02742 11.1799 7.9892 12.0197C7.94777 12.9279 7.93884 13.2003 7.93884 15.5003C7.93884 17.8001 7.94777 18.0725 7.9892 18.9809C8.02742 19.8207 8.16772 20.2766 8.2856 20.5801C8.42384 20.9543 8.6438 21.2926 8.9299 21.5705C9.20776 21.8566 9.54605 22.0768 9.92027 22.2148C10.2238 22.3329 10.6799 22.4732 11.5197 22.5114C12.4279 22.5528 12.7 22.5615 15.0001 22.5615C17.3003 22.5615 17.5727 22.5528 18.4807 22.5114C19.3204 22.4732 19.7766 22.3329 20.0801 22.2148C20.8313 21.925 21.425 21.3313 21.7148 20.5801C21.8327 20.2766 21.973 19.8207 22.0114 18.9809C22.0528 18.0725 22.0615 17.8001 22.0615 15.5003C22.0615 13.2003 22.0528 12.9279 22.0114 12.0197C21.9732 11.1799 21.8329 10.7238 21.7148 10.4203ZM15.0003 19.923C12.5574 19.923 10.5772 17.9429 10.5772 15.5001C10.5772 13.0572 12.5574 11.0772 15.0003 11.0772C17.4429 11.0772 19.4232 13.0572 19.4232 15.5001C19.4232 17.9429 17.4429 19.923 15.0003 19.923ZM19.5981 11.9359C19.0273 11.9359 18.5645 11.4731 18.5645 10.9023C18.5645 10.3315 19.0273 9.86867 19.5981 9.86867C20.1689 9.86867 20.6317 10.3315 20.6317 10.9023C20.6315 11.4731 20.1689 11.9359 19.5981 11.9359Z'
      fill='#607D8B'
    />
    <path
      d='M15 0.5C6.717 0.5 0 7.217 0 15.5C0 23.783 6.717 30.5 15 30.5C23.283 30.5 30 23.783 30 15.5C30 7.217 23.283 0.5 15 0.5ZM23.5613 19.0511C23.5197 19.968 23.3739 20.594 23.161 21.1419C22.7135 22.2989 21.7989 23.2135 20.6419 23.661C20.0942 23.8739 19.468 24.0194 18.5513 24.0613C17.6328 24.1032 17.3394 24.1133 15.0002 24.1133C12.6608 24.1133 12.3676 24.1032 11.4489 24.0613C10.5322 24.0194 9.90601 23.8739 9.35829 23.661C8.78334 23.4447 8.26286 23.1057 7.83257 22.6674C7.39449 22.2374 7.05551 21.7167 6.83922 21.1419C6.62636 20.5942 6.48056 19.968 6.4389 19.0513C6.39656 18.1326 6.38672 17.8392 6.38672 15.5C6.38672 13.1608 6.39656 12.8674 6.43867 11.9489C6.48033 11.032 6.6259 10.406 6.83876 9.85806C7.05505 9.28334 7.39426 8.76263 7.83257 8.33257C8.26263 7.89426 8.78334 7.55528 9.35806 7.33899C9.90601 7.12613 10.532 6.98056 11.4489 6.93867C12.3674 6.89679 12.6608 6.88672 15 6.88672C17.3392 6.88672 17.6326 6.89679 18.5511 6.9389C19.468 6.98056 20.094 7.12613 20.6419 7.33876C21.2167 7.55505 21.7374 7.89426 22.1677 8.33257C22.6057 8.76286 22.9449 9.28334 23.161 9.85806C23.3741 10.406 23.5197 11.032 23.5616 11.9489C23.6034 12.8674 23.6133 13.1608 23.6133 15.5C23.6133 17.8392 23.6034 18.1326 23.5613 19.0511Z'
      fill='#607D8B'
    />
  </svg>
);

export default Instagram;
