const Reddit = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.1414 20.6181C17.4694 21.2901 16.0295 21.53 15.0034 21.53C13.9743 21.53 12.5374 21.2901 11.8654 20.6181C11.7063 20.459 11.4514 20.459 11.2925 20.6181C11.1334 20.7772 11.1334 21.0321 11.2925 21.191C12.3575 22.256 14.4035 22.34 15.0034 22.34C15.6033 22.34 17.6463 22.256 18.7145 21.191C18.8703 21.0319 18.8703 20.7769 18.7145 20.6181C18.5554 20.459 18.3004 20.459 18.1414 20.6181Z'
      fill='#607D8B'
    />
    <path
      d='M13.1247 17.063C13.1247 16.202 12.4227 15.5 11.5617 15.5C10.7009 15.5 9.99866 16.202 9.99866 17.063C9.99866 17.9239 10.7009 18.6261 11.5617 18.6261C12.4227 18.6261 13.1247 17.9241 13.1247 17.063Z'
      fill='#607D8B'
    />
    <path
      d='M15 0.5C6.717 0.5 0 7.217 0 15.5C0 23.783 6.717 30.5 15 30.5C23.283 30.5 30 23.783 30 15.5C30 7.217 23.283 0.5 15 0.5ZM23.703 17.5009C23.736 17.7169 23.754 17.936 23.754 18.158C23.754 21.5239 19.836 24.2511 15.003 24.2511C10.1699 24.2511 6.25191 21.5239 6.25191 18.158C6.25191 17.933 6.26999 17.711 6.30295 17.4949C5.541 17.153 5.00999 16.3881 5.00999 15.5C5.00999 14.294 5.98801 13.313 7.19696 13.313C7.78496 13.313 8.31596 13.544 8.70895 13.9221C10.2269 12.8271 12.3271 12.1279 14.664 12.0679C14.664 12.0379 15.756 6.83591 15.756 6.83591C15.7771 6.73405 15.837 6.64685 15.924 6.59009C16.011 6.5331 16.116 6.51501 16.2181 6.53607L19.8541 7.30992C20.1091 6.79402 20.6339 6.43697 21.2489 6.43697C22.113 6.43697 22.812 7.13597 22.812 8C22.812 8.86403 22.113 9.56303 21.2489 9.56303C20.4119 9.56303 19.734 8.90294 19.6951 8.07507L16.4399 7.38202L15.444 12.0711C17.739 12.1519 19.8001 12.8479 21.294 13.928C21.687 13.5472 22.221 13.313 22.812 13.313C24.0211 13.313 24.9989 14.291 24.9989 15.5C24.9989 16.394 24.462 17.1589 23.703 17.5009Z'
      fill='#607D8B'
    />
    <path
      d='M18.438 15.5C17.577 15.5 16.875 16.202 16.875 17.063C16.875 17.9239 17.577 18.6261 18.438 18.6261C19.2989 18.6261 20.0011 17.9239 20.0011 17.063C20.0011 16.202 19.2991 15.5 18.438 15.5Z'
      fill='#607D8B'
    />
  </svg>
);

export default Reddit;
