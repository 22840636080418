import styled from 'styled-components';

const ResponseMatchingWrapper = styled.div`
  margin-bottom: 20px;
  .label {
    margin-bottom: 12px;
  }

  .inputs-wrapper {
    margin-top: 4px;
  }

  .response-matching-input {
    margin-bottom: 4px;
    flex: 1;
    .input-content {
      border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
      border-radius: 4px;
    }
  }

  .second-option {
    margin-left: 20px;
  }

  .trash-icon-wrapper {
    margin-left: 8px;
    button {
      margin-top: 22px;
    }
  }

  .shuffleText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 12px;
  }
`;

export default ResponseMatchingWrapper;
