const MultipleBlanks = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect x='4' y='5' width='7.52946' height='4' rx='2' fill='#00B3CC' />
    <rect x='12.4723' y='5' width='7.52946' height='4' rx='2' fill='#008699' />
    <rect x='4' y='10' width='16.0001' height='4' rx='2' fill='#00B3CC' />
    <rect x='4' y='15' width='16.0001' height='4' rx='2' fill='#00B3CC' />
  </svg>
);

export default MultipleBlanks;
