const SCREEN_SIZES = {
  XS: 0,
  SM: 576,
  MD: 767,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

export default SCREEN_SIZES;
