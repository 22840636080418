import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const FileReader = ({ resource, type }) => (
  <div className='file-box'>
    <DocViewer
      pluginRenderers={DocViewerRenderers}
      documents={[{ uri: resource, fileType: type }]}
      config={{
        header: {
          disableFileName: true,
        },
      }}
      theme={{
        disableThemeScrollbar: false,
      }}
    />
  </div>
);

export default FileReader;
