import Footer from 'Components/Footer';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Header from 'style-guide/Header';

import CreateNewButton from 'Components/EntityCreateButton';
import Layout from 'Components/Layouts/layout';
import Content from 'Components/Layouts/layout/Content';
import classNames from 'classnames';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import PageSpin from 'style-guide/Spin/PageSpin';

const AppRouterLayout = () => {
  const { entities, role } = useParams();
  const isEntityManagePage = entities && role;
  const { innerWidth } = useWindowSize();
  const { isAuth } = useSelector((state) => state.authorizationsSlice);

  const { pathname } = useLocation();
  const isSettingsPage = pathname.includes('manage');

  const showCreateButton = SCREEN_SIZES.XL <= innerWidth && isAuth && !isEntityManagePage && !isSettingsPage;

  return (
    <Layout className={classNames({ 'admin-page': pathname.startsWith('/admin/') })}>
      <Header />
      <Content>
        <Suspense fallback={<PageSpin />}>
          <Outlet />
        </Suspense>
        {showCreateButton ? <CreateNewButton /> : null}
      </Content>
      <Footer />
    </Layout>
  );
};

export default AppRouterLayout;
