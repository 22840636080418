const UserMinus = (props) => (
  <svg width='27' height='31' viewBox='0 0 27 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g>
      <path
        d='M14 12C16.7614 12 19 9.76142 19 7C19 4.23858 16.7614 2 14 2C11.2386 2 9 4.23858 9 7C9 9.76142 11.2386 12 14 12Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.40997 22C5.40997 18.13 9.26 15 14 15C14.96 15 15.89 15.13 16.76 15.37'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 18L18 17.96'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_3801_2889'
        x='-2'
        y='0'
        width='32'
        height='32'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3801_2889' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3801_2889' result='shape' />
      </filter>
    </defs>
  </svg>
);

export default UserMinus;
