import { generatePath } from 'react-router-dom';

import { ENTITY_TYPES } from 'common';
import PATHS from 'constants/paths';

const getUrlPathName = (key, userName) => {
  switch (key) {
    case 'profile':
      return generatePath(PATHS.PROFILE, { userName });
    case 'My Invitations':
      return PATHS.INVITED_USER_LEARNING_COMMUNITY;
    case ENTITY_TYPES.MEMBER.key:
      return generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.MEMBER.key });
    case 'adminDashboard':
      return '/admin/user';
    case 'logOut':
      return '/';

    default:
      return PATHS.NOT_FOUND;
  }
};

export default getUrlPathName;
