const Globe = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM13.8517 9.33167C13.6256 9.38258 13.3894 9.43259 13.1437 9.48108C12.7408 9.56059 12.3321 9.63231 11.9188 9.69527C11.7942 10.9693 11.529 12.1369 11.1432 13.1118C12.4943 12.2792 13.4904 10.9259 13.8517 9.33167ZM11.9968 7.65743C11.9628 5.85912 11.662 4.19938 11.1432 2.8882C12.6563 3.82059 13.7242 5.40603 13.9538 7.25163C13.5915 7.34273 13.1907 7.43324 12.7565 7.51892C12.5055 7.56844 12.2522 7.6147 11.9968 7.65743ZM9.99975 7.91178C9.33847 7.96918 8.67003 8 8 8C7.32931 8 6.66105 7.96914 6.00025 7.91164C6.01 6.19203 6.30405 4.5963 6.79782 3.44417C7.21659 2.46704 7.68983 2 8 2C8.31017 2 8.78341 2.46704 9.20218 3.44417C9.69596 4.59634 9.99001 6.19213 9.99975 7.91178ZM4.08108 9.69444C3.67051 9.63167 3.26448 9.56021 2.86392 9.481C2.61531 9.43184 2.37656 9.38116 2.14781 9.32948C2.50867 10.9247 3.50505 12.2788 4.85682 13.1118C4.47094 12.1367 4.20567 10.9688 4.08108 9.69444ZM4.00324 7.65639C4.03727 5.85847 4.33806 4.19913 4.85682 2.8882C3.34506 3.81974 2.2778 5.40314 2.04685 7.24657C2.45231 7.34435 2.90179 7.44976 3.25191 7.519C3.5002 7.5681 3.75074 7.61398 4.00324 7.65639ZM9.87667 9.92839C9.25626 9.97514 8.6296 10 8 10C7.36987 10 6.74334 9.97512 6.12332 9.9283C6.25467 10.927 6.48657 11.8296 6.79782 12.5558C7.21659 13.533 7.68983 14 8 14C8.31017 14 8.78341 13.533 9.20218 12.5558C9.51342 11.8296 9.74532 10.9271 9.87667 9.92839Z'
      fill='#333333'
    />
  </svg>
);

export default Globe;
