const QuoteIcon = (props) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9.00008 18.1666H11.5001L13.1667 14.8333V9.83325H8.16675V14.8333H10.6667L9.00008 18.1666ZM15.6667 18.1666H18.1667L19.8334 14.8333V9.83325H14.8334V14.8333H17.3334L15.6667 18.1666Z'
      fill='#818181'
    />
  </svg>
);

export default QuoteIcon;
