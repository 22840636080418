import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDomainTracking } from 'configs/localStorage';

const googleUserInfoApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.googleapis.com/oauth2/v3/',
  }),
  endpoints: (builder) => ({
    googleUserInfo: builder.mutation({
      query: (params) => ({
        url: `userinfo?access_token=${params.token}`,
        method: 'GET',
      }),

      transformResponse: (response, _meta, arg) => {
        const { email, sub } = response;
        const { key: domainKey } = getDomainTracking();

        arg.request({
          domainKey,
          email,
          googleId: sub,
          password: sub,
        });
      },
    }),
  }),
});

export const { useGoogleUserInfoMutation } = googleUserInfoApi;
export default googleUserInfoApi;
