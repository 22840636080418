import PATHS from 'constants/paths';
import { lazy } from 'react';
import Assessment from 'views/LearningCommunity/Categories/Assessment';

const AddQuestion = lazy(() => import('views/LearningCommunity/Categories/Assessment/AddQuestion'));
const GetCategories = lazy(() => import('views/LearningCommunity/Categories'));
const LearningCommunity = lazy(() => import('views/LearningCommunity'));

const entitiesPageRoutes = [
  {
    element: <LearningCommunity />,
    path: PATHS.LEARNING_COMMUNITY,
    children: [
      {
        path: PATHS.LEARNING_COMMUNITY_CATEGORY,
        element: <GetCategories />,
      },
      {
        path: PATHS.LEARNING_COMMUNITY_ASSESSMENT,
        element: <Assessment />,
      },
      {
        path: PATHS.LEARNING_COMMUNITY_CATEGORY_EDIT_QUESTION,
        element: <AddQuestion />,
      },
    ],
  },
];

export default entitiesPageRoutes;
