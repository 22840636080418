export const cleanFormValue = (value) =>
  value
    ?.toString()
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/\s/g, '-')
    ?.replace(/[^a-zA-Z0-9-]/g, '');

export const cleanEntityUrlName = (value) =>
  value
    ?.toString()
    ?.toLowerCase()
    ?.replace(/\s/g, '-')
    ?.replace(/[^a-zA-Z0-9-]/g, '');
