import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import Image from 'style-guide/Image';
import { toAwsUserImage } from 'utils/images';

const UserName = ({ userName }) =>
  userName ? (
    <p className='name d-flex align-items-center justify-content-center user-img'>{userName[0].toUpperCase()}</p>
  ) : null;

const UserAvatar = forwardRef(({ className, picture, userName, fromAws = true, ...props }, ref) => {
  const [isError, setIsError] = useState(false);

  return (
    <div className={classNames(className, 'user-avatar')} ref={ref} {...props}>
      {picture && !isError ? (
        <Image src={fromAws ? toAwsUserImage(picture) : picture} onError={() => setIsError(true)} alt='avatar' />
      ) : (
        <UserName userName={userName} />
      )}
    </div>
  );
});

export default UserAvatar;
