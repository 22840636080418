import { createGlobalStyle } from 'styled-components';
import SCREEN_SIZES from 'constants/screen-sizes';

const GlobalStyle = createGlobalStyle`
  body {
    *{
      font-family: ${({ theme }) => theme.fonts?.mainFont};
    }
    background-color: #f6f7f8;
    height: 100vh;
    margin: 0;
    overflow-x: hidden;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  .overflow {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    ::-webkit-scrollbar {
      width: 8px;
      border-radius: 5px;
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.secondary[200]};
    }
  }
  
  .full-height {
    min-height: 100%
  } 

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

 .invisible {
    visibility: hidden;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  a {
    text-decoration: none;
  }
  .container {
    @media (min-width: ${SCREEN_SIZES.XS}px) {
      max-width: 300px;
    }
    @media (min-width: ${SCREEN_SIZES.SM}px) {
      max-width: 540px;
    }
    @media (min-width: ${SCREEN_SIZES.MD}px) {
      max-width: 720px;
    }
    @media (min-width: ${SCREEN_SIZES.LG}px) {
      max-width: 960px;
    }
    @media (min-width: ${SCREEN_SIZES.XL}px ) {
      max-width: 1140px;
    }
  }
`;

export default GlobalStyle;
