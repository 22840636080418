import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setEditedCardIdSlice } from 'store/slices/assessment';
import { showModal } from 'store/slices/modal';

import { ENTITY_TYPES, LEARNING_COMMUNITY_SECTIONS, TEST_TYPES } from 'common';
import MODAL_TYPES from 'constants/modals';

import { Pencil, Trash } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Skeleton from 'style-guide/Skeleton';
import Title from 'style-guide/Typography/Title';

import AssessmentCard from 'Components/InitialThoughtCard';
import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';
import imagePlaceholder from 'assets/images/imagePlaceholder.png';
import Text from 'style-guide/Typography/Text';
import OrganizerCardBody from '../OrganizerCardBody';

const { TestsTypeCloseExercise, TestsTypeDragAndDrop } = TEST_TYPES.types;
const { ASSESSMENT } = LEARNING_COMMUNITY_SECTIONS;
const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const Question = ({ hasBlankType, question, type }) => (
  <Title className='card-title' variant={5}>
    {hasBlankType ? (
      <div
        dangerouslySetInnerHTML={{ __html: question }}
        className={classNames(TestsTypeDragAndDrop === type ? 'blanks-card-question' : null)}
      />
    ) : (
      question
    )}
  </Title>
);

const Card = ({ assessmentInfo = {}, deleteQuestion, index, chapterId, courseId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { urlName, role } = useParams();
  const [isLoadImage, setIsLoadImage] = useState(true);
  const ref = useRef();

  const { id, type, question, text, image, answer, description } = assessmentInfo;

  const lastQuestionId = useSelector((state) => state.assessment.lastQuestionId);

  useEffect(() => {
    if (lastQuestionId === id) {
      setTimeout(() => {
        dispatch(setEditedCardIdSlice(null));
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [dispatch, id, lastQuestionId]);

  const onDeleteQuestion = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRMATION,
        params: {
          modal: {
            title: 'Confirmation',
          },
          body: {
            title: <Title variant={5}>Are you sure you want to delete?</Title>,
            onOk: () => {
              deleteQuestion({ chapterId, courseId, questionId: id });
            },
          },
        },
      })
    );
  };

  const tinymceBlankTypes = [TestsTypeCloseExercise, TestsTypeDragAndDrop];
  const hasBlankType = tinymceBlankTypes.includes(type);
  return (
    <AssessmentCard
      ref={ref}
      icon={
        <div className='d-flex'>
          <Pencil
            className='pencil-icon'
            onClick={() => {
              navigate(
                `/${LEARNING_COMMUNITY.key}/${courseId}/${urlName}/${role}/${chapterId}/${ASSESSMENT.value}/${id}?assessmentType=${type}`
              );
            }}
          />
          <Trash className='trash-icon' onClick={onDeleteQuestion} />
        </div>
      }
    >
      <div className='card-box'>
        <div className='d-flex flex-column'>
          {image ? (
            <div className='image-box'>
              {isLoadImage ? <Skeleton skeleton={{ width: 200, height: 200 }} /> : null}
              <Image
                src={image}
                onLoad={() => setIsLoadImage(false)}
                defaultImage={imagePlaceholder}
                className={classNames('image', { 'd-none': isLoadImage })}
                alt='assessment'
              />
            </div>
          ) : null}
          {description && (
            <Text $variant={2} $dark>
              {description}
            </Text>
          )}
          {text && <SimplifiedEditor editable={false} content={text} />}

          <Question hasBlankType={hasBlankType} question={question} type={type} />
          <OrganizerCardBody type={type} answer={answer} index={index} />
        </div>
      </div>
    </AssessmentCard>
  );
};
export default Card;
