import classNames from 'classnames';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';

const TrueFalseAnswer = ({ answer, index }) => {
  const trueAnswer = answer?.[0]?.isTrue;
  const falseAnswer = answer?.[1]?.isTrue;

  return (
    <div className='true-false-wrapper'>
      <div className={classNames('radio-button-box', { success: trueAnswer })}>
        <Radio disabled={!trueAnswer} checked={trueAnswer} readOnly value='true' name={`answer${index}`}>
          <Text $variant={2} $dark>
            True
          </Text>
        </Radio>
      </div>
      <div className={classNames('radio-button-box', { success: falseAnswer })}>
        <Radio disabled={!falseAnswer} checked={falseAnswer} readOnly value='false' name={`answer${index}`}>
          <Text $variant={2} $dark>
            False
          </Text>
        </Radio>
      </div>
    </div>
  );
};

export default TrueFalseAnswer;
