import classNames from 'classnames';
import { forwardRef } from 'react';

const Radio = forwardRef(({ className, children, value = 'radio', ...props }, ref) => (
  <div className={classNames(className, 'd-flex align-items-center radio-button')}>
    <label from={value}>
      <span className='radio-wrapper d-flex align-items-center'>
        <input type='radio' value={value} {...props} ref={ref} />
        <span className='radio-children'>{children}</span>
      </span>
    </label>
  </div>
));

export default Radio;
