import emptySplitApi from '..';
// TODO
const uploadUserProfileImageApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    userProfileImage: build.mutation({
      query: (params) => ({
        url: `/amazon-s3?folder=${params.folder}`,
        method: 'POST',
        body: params.data,
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useUserProfileImageMutation } = uploadUserProfileImageApi;
export default uploadUserProfileImageApi;
