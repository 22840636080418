import classNames from 'classnames';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const ButtonWrapper = forwardRef(({ className, to, type, disabled, ...buttonProps }, ref) =>
  to ? (
    <Link to={to} {...buttonProps} className={classNames({ 'disabled-link': disabled }, className)} ref={ref} />
  ) : (
    <button {...buttonProps} className={className} ref={ref} type={type} disabled={disabled} />
  )
);

export default ButtonWrapper;
