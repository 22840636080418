import { ENTITY_TYPES } from 'common';

const getEntitiesName = (category, type) => {
  switch (category) {
    case ENTITY_TYPES.LEARNING_COMMUNITY.key:
      return ENTITY_TYPES.LEARNING_COMMUNITY[type];
    case ENTITY_TYPES.DEPARTMENT.key:
      return ENTITY_TYPES.DEPARTMENT[type];
    case ENTITY_TYPES.ORGANIZATION.key:
      return ENTITY_TYPES.ORGANIZATION[type];
    default:
      return ENTITY_TYPES.LEARNING_COMMUNITY.singular;
  }
};

export default getEntitiesName;
