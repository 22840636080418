import classNames from 'classnames';
import testTypes from 'common/test-types';
import MODAL_TYPES from 'constants/modals';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showModal } from 'store/slices/modal';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import { initialFormValue } from '../AddQuestion/answerValidationSchema';
import DropdownBodyWrapper from './DropdownBodyWrapper';

const DropdownBody = ({ isDirty, reset, setHasOpenModal }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentAssessmentType = searchParams.get('assessmentType');
  const ASSESSMENT_TYPES = testTypes.getTypes();

  const chooseAssessmentType = (type) => {
    reset?.(initialFormValue(type));
    setSearchParams({ assessmentType: type });
  };

  const showConfirmationModal = (type) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRMATION,
        params: {
          modal: {
            title: 'Confirmation',
          },
          body: {
            title: (
              <Title variant={5} className='text-align-center'>
                You have unsaved changes, are you sure you want to leave?
              </Title>
            ),
            onOk: () => {
              chooseAssessmentType(type);
              setHasOpenModal(false);
            },
            onCancel: () => {
              setHasOpenModal(false);
            },
          },
        },
      })
    );
    setHasOpenModal(true);
  };

  return (
    <DropdownBodyWrapper>
      <ul className='dropdown-body-box'>
        {ASSESSMENT_TYPES.map((item) => {
          const { name, type, Icon } = item;

          return (
            <li
              key={type}
              className={classNames('item-box d-flex align-items-center', {
                active: Number(currentAssessmentType) === type,
              })}
              onClick={() => (isDirty ? showConfirmationModal(type) : chooseAssessmentType(type))}
            >
              <Icon />
              <Text $variant={2} className='text'>
                {name}
              </Text>
            </li>
          );
        })}
      </ul>
    </DropdownBodyWrapper>
  );
};

export default DropdownBody;
