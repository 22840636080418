import styled from 'styled-components';

const AdminAddUserWrapper = styled.div`
  width: 400px;
  .first-radio-button {
    margin-right: 20px;
  }
`;

export default AdminAddUserWrapper;
