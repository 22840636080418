import styled from 'styled-components';

const SuperAdminStaticPagesWrapper = styled.div`
  .icon {
    &:hover {
      cursor: pointer;
    }
    path {
      fill: ${({ theme }) => theme.colors.dark[60]};
    }
  }
  .pencil {
    path {
      fill: ${({ theme }) => theme.colors.tertiary[800]};
    }
  }
  .content-cell {
    white-space: normal !important;
    .content {
      width: 900px;
    }
  }
  .actions-icons {
    padding-right: 8px;
  }
`;

export default SuperAdminStaticPagesWrapper;
