import { forwardRef } from 'react';

import imagePlaceholder from 'assets/images/imagePlaceholder.png';

const Image = forwardRef(({ className, alt = 'image', defaultImage = imagePlaceholder, ...props }, ref) => (
  <img
    className={className}
    alt={alt}
    onError={({ currentTarget }) => {
      const current = currentTarget;
      current.onerror = null;
      current.src = defaultImage;
    }}
    {...props}
    ref={ref}
  />
));

export default Image;
