import styled from 'styled-components';
import radio from './Radio';

const Radio = styled(radio)`
  label {
    cursor: pointer;
  }
  .radio-children {
    font-size: 14px;
    padding-left: 8px;
  }
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    margin: 0;
    border: 2px solid ${({ theme }) => theme.colors.dark[25]};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    &:before {
      content: '';
      border-radius: 50%;
      width: 6px;
      height: 6px;
      margin-left: auto;
      margin-right: auto;
    }
    &:disabled {
      border-color: ${({ theme }) => theme.colors.dark[25]};
    }
    &:checked {
      display: flex;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.primary[50]};
      border-color: ${({ theme }) => theme.colors.primary[800]};
      &:before {
        background-color: ${({ theme }) => theme.colors.primary[800]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.dark[25]};
        border-color: ${({ theme }) => theme.colors.dark[25]};
        &:before {
          background-color: ${({ theme }) => theme.colors.dark[25]};
        }
      }
    }
  }
`;

export default Radio;
