import { FB_APP_ID } from 'configs/env-vars';
import { getDomainTracking } from 'configs/localStorage';
import { message } from 'style-guide/ToastMessages';

const authentication = (id, request) => {
  window.FB.api(
    `/${id}/?fields=id,email&scope=email`,
    'GET',
    {
      scope: 'email',
      return_scopes: true,
    },
    (response) => {
      const { email } = response;
      if (email) {
        const { key: domainKey } = getDomainTracking();
        request({
          email,
          fbId: id,
          password: id,
          domainKey,
        });
        return;
      }
      message.error('Please, make sure that the Facebook account you provided is verified.');
    }
  );
};

const facebookAuthentication = (request) => {
  const facebookScript = document.createElement('script');

  facebookScript.src = 'https://connect.facebook.net/en_US/sdk.js';
  facebookScript.crossorigin = 'anonymous';
  facebookScript.async = true;
  facebookScript.defer = true;
  facebookScript.onload = () => {
    window.FB.init({
      appId: FB_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v13.0',
    });
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') window.FB.logout();
      window.FB.login((data) => {
        const userID = data?.authResponse?.userID;
        if (userID) {
          authentication(userID, request);
        }
      });
    });
  };

  document.head.appendChild(facebookScript);
};

export default facebookAuthentication;
