import classNames from 'classnames';
import Text from 'style-guide/Typography/Text';

const FormItem = ({ component, error, className, label, children, required, ...props }) => {
  const formItemClassName = classNames(className, 'form-component', { error });

  return (
    <div className={formItemClassName} {...props}>
      {label ? (
        <div className='mb--4'>
          {required ? (
            <Text $variant={2} className='required-symbol mr--4'>
              *
            </Text>
          ) : null}
          <label className='form-item-label'>{label}</label>
        </div>
      ) : null}
      <div>{component || children}</div>
      {error ? <div className='required-error'>{error.message}</div> : null}
    </div>
  );
};

FormItem.defaultProps = {
  className: '',
};

export default FormItem;
