import ReactSelect from 'react-select';
import { Down } from 'style-guide/Icons';
import styled from 'styled-components';

const Select = styled(ReactSelect)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: 4px;
  path {
    fill: ${({ theme }) => theme.colors.dark[60]};
  }
  .select__input {
    color: ${({ theme }) => theme.colors.secondary[400]} !important;
  }
  .select__control {
    outline: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border: 1px solid ${({ theme }) => theme.colors.status[20]};
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary[200]};
    }
    min-height: 35px !important;
  }
  .select__control--is-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.primary[600]};
  }
  .select__single-value {
    color: ${({ theme }) => theme.colors.secondary[400]};
    font-size: 14px;
    font-weight: 500;
  }
  .select__indicators {
    padding-right: 14px;
  }
  .select__placeholder {
    letter-spacing: 0em;
    color: ${({ theme }) => theme.colors.disabled[60]};
  }
  &.select--is-disabled {
    .select__single-value,
    .select__placeholder {
      box-shadow: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.dark[25]};
    }
    path {
      fill: ${({ theme }) => theme.colors.dark[25]};
    }
  }

  .css-1s2u09g-control {
    border: none;
  }

  .select__menu {
    border: none;
    .css-26l3qy-menu {
      box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]};
      box-sizing: border-box;
    }
    .select__menu-list {
      padding: 0;
      color: ${({ theme }) => theme.colors.dark[80]};
      .select__option {
        cursor: pointer;
        &:nth-child(1) {
          box-shadow: none;
        }
        box-shadow: 0px -1px 0px ${({ theme }) => theme.colors.boxShadow[15]};
      }
      .select__option--is-selected {
        color: ${({ theme }) => theme.colors.dark[80]};
        background-color: ${({ theme }) => theme.colors.secondary[50]};
      }
    }

    .select__option--is-focused {
      background-color: ${({ theme }) => theme.colors.secondary[50]};
    }
  }
  .select__indicator-separator {
    display: none;
  }
`;

const DropdownIndicator = () => <Down />;

Select.defaultProps = {
  classNamePrefix: 'select',
  placeholder: 'Select the type of your question',
  components: { DropdownIndicator },
};

export default Select;
