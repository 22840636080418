const userLibraryTypes = {
  administrator: { name: 'Administrator', level: 1 },
  coAdministrator: { name: 'Co-Administrator', level: 2 },
  member: { name: 'Member', level: 3 },
};

const getUpperLevel = (level) => Object.values(userLibraryTypes).find((type) => type.level === level - 1);

const getLowerLevel = (level) => Object.values(userLibraryTypes).find((type) => type.level === level + 1);

const getTypeFromId = (id) => Object.values(userLibraryTypes).find((type) => type.level === id);

const getManagerLevels = () =>
  Object.values(userLibraryTypes)
    .filter((t) => t.level <= userLibraryTypes.coAdministrator.level)
    .map((t) => t.level);

const getStudentLevels = () =>
  Object.values(userLibraryTypes)
    .filter((t) => t.level > userLibraryTypes.coAdministrator.level)
    .map((t) => t.level);

const isAdmin = (level) => level === userLibraryTypes.administrator.level;

const isManager = (level) => {
  const managerLevels = getManagerLevels();
  return managerLevels.includes(level);
};

const isStudent = (level) => {
  const studentLevels = getStudentLevels();
  return studentLevels.includes(level);
};

const getMembersTypeList = () => Object.values(userLibraryTypes);

const USER_LIBRARY_TYPES = {
  ...userLibraryTypes,
  getMembersTypeList,
  getUpperLevel,
  getLowerLevel,
  getTypeFromId,
  getManagerLevels,
  getStudentLevels,
  isAdmin,
  isManager,
  isStudent,
};

export default USER_LIBRARY_TYPES;
