import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import DropdownV2 from 'style-guide/DropdownV2';
import { Bell, Close, Hamburger, Heart } from 'style-guide/Icons';
import UserAvatar from 'style-guide/UserAvatar';

import classNames from 'classnames';
import { loadState } from 'configs/localStorage';
import DROPDOWN_OPTIONS from 'constants/DropdownOptions/avatar';
import PATHS from 'constants/paths';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';
import { getUserData, getUserIsAuth } from 'store/selectors/user';
import useLogout from 'store/services/helperHooks/useLogout';
import { useGetNotificationQuery } from 'store/services/notifications';
import Button from 'style-guide/Button';
import NotificationMenu from 'style-guide/Header/NotificationMenu';
import SignInButton from 'style-guide/Header/SignInButton';
import getUrlPathName from 'style-guide/Header/getUrlPathName';

const RightBox = ({ setIsOpenRightSection, isOpenRightSection }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { category = '' } = useParams();
  const isAuth = useSelector(getUserIsAuth);

  const [logOut] = useLogout();
  const { data = {} } = useGetNotificationQuery(
    { limit: 5, offset: 0, sort: 'desc' },
    { skip: !isAuth, refetchOnMountOrArgChange: true }
  );

  const hasUnreadNotification = data?.notifications?.some((element) => !element.read);
  const isActiveFavorite = pathname === generatePath(PATHS.EXPLORE, { category }) && category === 'favorites';

  const { adminUser } = loadState();
  const isAuthSuperAdmin = adminUser?.accessToken;

  const { profileImage, userName, firstName, lastName } = useSelector(getUserData) || {};

  const name = firstName || lastName || userName;

  const authorizationsSlice = useSelector((state) => state?.authorizationsSlice);
  const { isAuth: isLoggedIn } = authorizationsSlice;

  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= SCREEN_SIZES.MD;
  const isTablet = innerWidth >= SCREEN_SIZES.MD && innerWidth < SCREEN_SIZES.XL;

  const hasHamburgerIcon = isTablet || isMobile;

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {!isLoggedIn ? <SignInButton /> : null}
      {isLoggedIn ? (
        <div className='d-flex align-items-center'>
          <Button
            data-testid='heart-element'
            variant='text'
            size='large'
            $shape
            to={generatePath(PATHS.EXPLORE, { category: 'favorites' })}
            className={classNames({ 'active-icon': isActiveFavorite }, 'header-icon')}
          >
            <Heart className='heart-icon' width={20} height={18} />
          </Button>

          <DropdownV2 overlay={<NotificationMenu data={data} />} space={10} data-testid='bell-element'>
            <Button
              variant='text'
              size='large'
              $shape
              className={classNames(hasHamburgerIcon ? 'mh--4' : 'mh--8', ' header-icon')}
            >
              <Bell className='bell-icon' width={24} height={24} hasNewNotification={hasUnreadNotification} />
            </Button>
          </DropdownV2>
          {!hasHamburgerIcon ? (
            <DropdownV2
              options={DROPDOWN_OPTIONS(isAuthSuperAdmin)}
              onClickItem={(item) => {
                const url = getUrlPathName(item, userName);
                if (item === 'logOut') {
                  logOut();
                }
                if (url !== pathname) {
                  navigate(url);
                }
              }}
              space={10}
            >
              <UserAvatar picture={profileImage} userName={name} />
            </DropdownV2>
          ) : null}
          {hasHamburgerIcon ? (
            <div>
              {isOpenRightSection ? (
                <Button variant='text' size='large' $shape onClick={() => setIsOpenRightSection(false)}>
                  <Close className='close-icon' />
                </Button>
              ) : (
                <Button
                  variant='text'
                  size='large'
                  $shape
                  className='header-icon'
                  onClick={() => setIsOpenRightSection(true)}
                >
                  <Hamburger className='hamburger-icon' width={28} height={28} />
                </Button>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default RightBox;
