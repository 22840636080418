import styled from 'styled-components';

const NotificationsSettingsWrapper = styled.div`
  padding: 40px 80px;
  .table {
    margin: 20px 0;
  }
`;

export default NotificationsSettingsWrapper;
