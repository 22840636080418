const Top = (props) => (
  <svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 3.41421L1.70711 8.70711L0.292892 7.29289L7 0.585785L13.7071 7.29289L12.2929 8.70711L7 3.41421Z'
      fill='#333333'
    />
  </svg>
);

export default Top;
