import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const EditPaymentMethodWrapper = styled.div`
  .compony-box {
    margin-left: 16px;
  }
  .edit-payment-method-modal-root {
    width: 760px;

    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      width: 660px;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: 288px;
    }

    .top-box {
      display: flex;
      gap: 20px;

      @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
        display: block;
      }

      .left-box {
        width: 100%;

        .date-box {
          .react-datepicker__triangle {
            left: -140px !important;

            @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
              left: -100px !important;
            }
          }
          .react-datepicker__month-container {
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
          }
          .react-datepicker__header {
            background-color: ${({ theme }) => theme.colors.secondary[50]};
          }

          .react-datepicker__day--selected {
            background-color: ${({ theme }) => theme.colors.primary[600]};
            color: ${({ theme }) => theme.colors.dark[100]};
          }
          .react-datepicker__day--keyboard-selected {
            background-color: ${({ theme }) => theme.colors.primary[600]};
            color: ${({ theme }) => theme.colors.dark[100]};
          }

          .react-datepicker__input-container {
            input {
              width: 100%;
              border-radius: 8px;
              padding: 6px 12px;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              box-sizing: border-box;
              background-color: ${({ theme }) => theme.colors.white[100]};
              border: 1px solid ${({ theme }) => theme.colors.status[20]};
              ::placeholder {
                color: ${({ theme }) => theme.colors.disabled[60]};
              }
              &:not(:placeholder-shown) {
                border-color: ${({ theme }) => theme.colors.primary[600]};
              }
              &:focus {
                outline: none;
                border-color: ${({ theme }) => theme.colors.primary[600]};
              }
              &:hover {
                border-color: ${({ theme }) => theme.colors.primary[200]};
              }
            }
          }
        }
      }
      .right-box {
        width: 100%;

        .select-box {
          margin-bottom: 20px;
          .label {
            margin-bottom: 5px;
          }

          .select__single-value {
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.dark[80]};
          }

          .select__placeholder {
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.dark[60]};
          }
        }
      }
    }

    .bottom-box {
      .card-inputs {
        display: flex;
        gap: 20px;

        @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
          display: block;
        }

        .left-box {
          width: 100%;
        }
        .right-box {
          width: 100%;
        }
      }
    }

    .terms-and-conditions-box {
      margin-top: 20px;
      height: 42px;

      .terms-and-conditions {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .required-error {
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.error[400]};
    }
  }
`;

export default EditPaymentMethodWrapper;
