import { generateEntityPagePath } from 'Components/App/Helpers/entityPagesRoute/pathGenerateHelper';
import {
  cleanEntityUrlName,
  cleanFormValue,
} from 'Components/EntityCreationFlow/EntityFormSections/DescriptionForm/DescriptionFormHelper';
import { formStepsId } from 'Components/EntityFormSections/Stepper/helper';
import { ENTITY_TYPES } from 'common';

const { LEARNING_COMMUNITY, DEPARTMENT } = ENTITY_TYPES;
const descriptionInitialValue = '<p>description</p>';

export const setEntityURL = (entity, id, entityName) =>
  generateEntityPagePath(entity, id, cleanEntityUrlName(entityName), formStepsId.naming);

export const setEntityMutation = (value, createLC, createDep, createOrg) => {
  switch (value) {
    case LEARNING_COMMUNITY.key:
      return createLC;
    case DEPARTMENT.key:
      return createDep;
    default:
      return createOrg;
  }
};

export const createNewEntity = (createLC, createDep, creteOrg, value, navigate) => {
  const entityMutation = setEntityMutation(value, createLC, createDep, creteOrg);
  const entityName = `untitled ${new Date()[Symbol.toPrimitive]('number').toString()}`;

  entityMutation({
    urlName: cleanEntityUrlName(entityName),
    name: cleanFormValue(entityName),
    isArchive: value === ENTITY_TYPES.LEARNING_COMMUNITY.key,
    description: descriptionInitialValue,
  }).then(({ data }) => navigate(value, data.id, entityName));
};
