import emptySplitApi from '..';

const adminDepartmentsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminDepartments: build.query({
      query: (params) => ({
        url: `admin/libraries`,
        method: 'GET',
        params,
      }),
      providesTags: ['admin-departments', 'isArchive'],
    }),
    deleteDepartment: build.mutation({
      query: (params) => ({
        url: `admin/libraries/${params.id}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['admin-departments'],
    }),
  }),
});

export default adminDepartmentsApi;
export const { useGetAdminDepartmentsQuery, useDeleteDepartmentMutation } = adminDepartmentsApi;
