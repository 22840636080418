import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import emptySplitApi from './services';

import getYouTubeVideoDurationApi from './services/getYouTubeVideoDuration';
import modernNestApi from './services/nestApi';
import getUnsplashApi from './services/unsplash';

export function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        thunk,
        emptySplitApi.middleware,
        getYouTubeVideoDurationApi.middleware,
        getUnsplashApi.middleware,
        modernNestApi.middleware
      ),
    preloadedState,
  });

  setupListeners(store.dispatch);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}

const store = configureAppStore();

export const { dispatch } = store;
export default store;
