import styled, { css } from 'styled-components';
import TextComponent from './Text';

const getVariant = (props) => {
  switch (props.$variant) {
    case 1:
      return css`
        font-size: 12px;
        line-height: 20px;
      `;
    case 2:
      return css`
        font-size: 14px;
        line-height: 20px;
      `;
    case 3:
      return css`
        font-size: 16px;
        line-height: 24px;
      `;
    case 4:
      return css`
        font-size: 20px;
        line-height: 28px;
      `;
    default:
      return css`
        font-size: 16px;
        line-height: 24px;
      `;
  }
};
const Text = styled(TextComponent)`
  ${({ theme, weight, fontStyle, color }) => css`
    color: ${color || theme.colors.dark[100]};
    font-weight: ${weight || 400};
    font-style: ${fontStyle || 'normal'};
    ${(props) => getVariant(props)}
    ${({ $ellipsis, $lineClamp }) =>
      $ellipsis &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: ${$lineClamp || 1};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      `}
  `}
`;
export default Text;
