import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';

import { Pencil } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Title from 'style-guide/Typography/Title';

import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';
import Text from 'style-guide/Typography/Text';
import SearchDropdown from '../SearchDropdown';

const showStaticPagesModal = (dispatch, item) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.STATIC_PAGES_MODAL,
      params: {
        modal: {
          title: (
            <div className='col-12'>
              <Title>Edit static page content</Title>
            </div>
          ),
        },
        body: {
          item,
        },
      },
    })
  );
};

export const getStaticPagesColumns = ({ dispatch }) => [
  {
    title: <SearchDropdown title='Name' name='key' />,
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: <Title variant={5}>Content</Title>,
    dataIndex: 'content',
    key: 'content',
    className: 'content-cell',
    render: (_index, item) => (
      <Text $ellipsis $variant={2}>
        <SimplifiedEditor content={item?.content} editable={false} />
      </Text>
    ),
  },
  {
    title: (
      <Title variant={5} className='header-actions'>
        Actions
      </Title>
    ),
    dataIndex: 'Actions',
    key: 'Actions',
    width: 150,
    render: (_index, item) => (
      <Tooltip placement='topRight' overlay='Edit Static page'>
        <Pencil className='pencil pointer' onClick={() => showStaticPagesModal(dispatch, item)} />
      </Tooltip>
    ),
  },
];

export const COURSES_CONST = {
  NAME: 'name',
  STATIC_PAGES: 'key',
};
