import React from 'react';
import FormItem from 'style-guide/FormItem';

const FormItems = ({ fields, errors, className }) =>
  Array.isArray(fields)
    ? fields?.map((field) => (
        <FormItem
          label={field.label}
          required={field.required}
          error={errors[field.name]}
          className={field.className ? field.className : className}
          component={field.component}
          key={field.name}
        />
      ))
    : null;

FormItems.defaultProps = {
  fields: [],
  errors: {},
  className: '',
};

export default FormItems;
