import Text from 'style-guide/Typography/Text';

const ErrorMessageView = ({ errorMessage }) => (
  <>
    <Text $variant={1}>Request has failed!</Text>
    <br />
    <Text $variant={1}>{errorMessage}</Text>
  </>
);

export default ErrorMessageView;
