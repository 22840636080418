/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'configs/localStorage';

const { user } = loadState();
const initialState = { bankAccount: null, userData: user || {} };

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeBankAccount(state, action) {
      const {
        payload: { bankAccount },
      } = action;

      state.bankAccount = bankAccount;
    },
    changeUserData(state, action) {
      const {
        payload: { userData },
      } = action;
      state.userData = userData;
    },
  },
});

export const { changeBankAccount, changeUserData } = profileSlice.actions;
export default profileSlice.reducer;
