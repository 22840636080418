import styled from 'styled-components';

const MultipleChoiceWrapper = styled.div`
  .label {
    margin-bottom: 12px;
  }

  .options-box {
    margin-bottom: 16px;
    .trash-icon-wrapper {
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }

  .radio-button-box {
    cursor: pointer;
    flex: 1;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 8px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
    background-color: ${({ theme }) => theme.colors.white[100]};
    box-sizing: border-box;
    border-radius: 4px;

    .multiple-choice-input {
      border: none;
      outline: none;
      height: 30px;
      flex: 1;
    }

    label {
      margin-left: 10px;
      width: 100%;
      height: 100%;
      .radio-button {
        display: flex;
        align-items: center;
      }
    }
  }

  .has-error {
    border: 1px solid ${({ theme }) => theme.colors.error[600]};
    background-color: ${({ theme }) => theme.colors.error[200]};
  }
  .success {
    border: 1px solid ${({ theme }) => theme.colors.primary[600]};
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`;

export default MultipleChoiceWrapper;
