import { ENTITY_TYPES } from 'common';
import { useParams } from 'react-router-dom';
import Companies from './Companies';
import SuperAdminCourses from './CoursesTable/SuperAdminCourses';
import SuperAdminDepartments from './DepartmentsTable/SuperAdminDepartments';
import SuperAdminEmailTracking from './EmailTracking/SuperAdminEmailTracking';
import SuperAdminOrganizations from './OrganizationsTable/SuperAdminOrganizations';
import SuperAdminStaticPages from './StaticPages/SuperAdminStaticPages';
import AdminUsers from './UsersTable';

const { ORGANIZATION, DEPARTMENT } = ENTITY_TYPES;

const SuperAdminTable = () => {
  const { category } = useParams();
  switch (category.toLowerCase()) {
    case 'users':
      return <AdminUsers />;
    case `${DEPARTMENT.key}`:
      return <SuperAdminDepartments />;
    case 'companies':
      return <Companies />;
    case `${ORGANIZATION.key}`:
      return <SuperAdminOrganizations />;
    case 'static-pages':
      return <SuperAdminStaticPages />;
    case 'email_tracking':
      return <SuperAdminEmailTracking />;
    default:
      return <SuperAdminCourses />;
  }
};

export default SuperAdminTable;
