import EntityCard from 'Components/NewCards/ColDepCard';
import { ENTITY_TYPES } from 'common';
import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';

const { ORGANIZATION } = ENTITY_TYPES;

const Organization = ({ data, setORGPage, ORGPage, colSpan }) => {
  if (!data?.total || data?.total <= 0) {
    return null;
  }

  return (
    <>
      <Title variant={5} className='pl--8 pv--32'>{`${ENTITY_TYPES.ORGANIZATION.plural} (${data?.total || 0})`}</Title>
      <div className='row cards mb--16'>
        {data?.rows?.map((el) => (
          <div key={el.id} className={colSpan}>
            <EntityCard data={el} entityType={ORGANIZATION.key} />
          </div>
        ))}
      </div>
      {data?.total > data?.rows?.length ? (
        <Button
          variant='link'
          children='See More'
          className='p--0 mb--16 ml--24'
          onClick={() => setORGPage({ ...ORGPage, offset: ORGPage.offset + ORGPage.limit })}
        />
      ) : null}
    </>
  );
};

export default Organization;
