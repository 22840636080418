const ArrowTop = (props) => (
  <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 4.41421V13H5V4.41421L1.70711 7.70711L0.292892 6.29289L6 0.585785L11.7071 6.29289L10.2929 7.70711L7 4.41421Z'
      fill='#333333'
    />
  </svg>
);

export default ArrowTop;
