import EntityCardList from 'Components/EntityCardList';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import { useLazyGetLearningCommunitiesQuery } from 'store/services/learningCommunities';
import useGetInfiniteScrollData from './useGetInfiniteScrollData';

const LearningCommunities = ({ cardsCount, colSpan, minPrice, maxPrice, ratingValue }) => {
  const [request, { data = {}, isLoading }] = useLazyGetLearningCommunitiesQuery();
  const hasNextPage = data.total > data.rows?.length;

  const isLimitedUser = useIsLimitedUser();

  const { scrollerRef, loaderRef } = useGetInfiniteScrollData({
    request,
    hasNextPage,
    minPrice,
    maxPrice,
    ratingValue,
    role: isLimitedUser ? 'member' : null,
  });

  return (
    <div className='body-root'>
      <EntityCardList
        data={data?.rows}
        total={data?.total}
        category='learning-communities'
        isLoading={isLoading}
        scrollerRef={scrollerRef}
        hasNextPage={hasNextPage}
        loaderRef={loaderRef}
        isOrganizer={false}
        cardsCount={cardsCount}
        colSpan={colSpan}
      />
    </div>
  );
};

export default LearningCommunities;
