import { string, object } from 'yup';

const checkValidation = (item) => {
  const number = Number(item);
  const isValidNumber = number && number > 0 && number === Math.round(number);

  if (item === '' || (item.startsWith('+') && isValidNumber)) {
    return true;
  }
  return false;
};

const schema = object({
  phone: string().test('phone number', 'Phone number is invalid', (value) => checkValidation(value)),
});

export default schema;
