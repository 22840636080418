const removeWhitespace = (s) => s.replace(/\s/g, '');
const firstCharToUpper = (s) => s.charAt(0).toUpperCase() + s.slice(1);
const firstCharToLower = (s) => s.charAt(0).toLowerCase() + s.slice(1);
const snakeToCamel = (s) => s.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));
const camelToSnake = (s) => {
  const upperChars = s.match(/([A-Z])/g);
  if (!upperChars) return s;

  let str = s.toString();
  for (let i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(new RegExp(upperChars[i]), `_${upperChars[i].toLowerCase()}`);
  }

  return str.slice(0, 1) === '_' ? str.slice(1) : str;
};
const snakeToPascal = (s) => firstCharToUpper(snakeToCamel(s));

const getLastWord = (title, lowercase) => {
  const wordsArray = title.split(' ');
  return lowercase ? wordsArray[wordsArray.length - 1].toLowerCase() : wordsArray[wordsArray.length - 1];
};

const htmlToText = (htmlText) => {
  const doc = new DOMParser().parseFromString(htmlText, 'text/html');
  return doc.body.textContent || '';
};

function getUrlFromHtmlString(tagName, text = '') {
  const parser = new DOMParser();
  const document = parser.parseFromString(text, 'text/html');
  const node = document.querySelector(tagName);

  if (node) {
    return {
      type: node.nodeName,
      src: node.src,
    };
  }

  return {};
}

const cleanUpPrice = ({ string = '', fixedCount = 0, symbol, empty, ignoreFree }) => {
  if (empty && string === null) {
    return 0;
  }

  const index = string && +string[0] ? 0 : 1;
  const price = string?.slice?.(index, string.length).replace(/,/g, '');
  if ((!empty && +price === 0) || !string) {
    if (ignoreFree) {
      return 0;
    }
    return 'Free';
  }

  const fixed = Number(price).toFixed(fixedCount);

  if (symbol) {
    return `${symbol}${fixed}`;
  }

  return fixed;
};

const removeSymbolFromPrice = (price) => (price && price[0] === '$' ? +price.substr(1) : +price);
const deCodeEmail = (email) => email.replace(/ /g, '+');

const createMarkup = (string) => ({ __html: string });

function countWords(s) {
  let string = s;
  string = string.replace(/(^\s*)|(\s*$)/gi, '');
  string = string.replace(/[ ]{2,}/gi, ' ');
  string = string.replace(/\n /, '\n');
  return string.split(' ').filter((str) => str !== '').length;
}

const removeEmptyParagraph = (text) => {
  const removeRegexP = /<p><\/p>/g;
  return text.replace(removeRegexP, '<br>');
};

const cleanUpTinymceHtmlForTipTap = (content) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = content;

  // Find all video and audio nodes within <p> elements
  const mediaNodes = tempElement.querySelectorAll('p>video, p>audio, p>img, p>iframe');

  // Remove all video and audio nodes
  mediaNodes.forEach((mediaNode) => {
    // Replace the parent <p> element with its content
    mediaNode.parentNode.parentNode.replaceChild(mediaNode, mediaNode.parentNode);
  });

  return tempElement.innerHTML;
};

const STRING_UTILS = {
  removeWhitespace,
  firstCharToLower,
  firstCharToUpper,
  snakeToCamel,
  camelToSnake,
  snakeToPascal,
  getLastWord,
  htmlToText,
  cleanUpPrice,
  cleanUpTinymceHtmlForTipTap,
  removeSymbolFromPrice,
  getUrlFromHtmlString,
  deCodeEmail,
  createMarkup,
  removeEmptyParagraph,
  countWords,
};

export default STRING_UTILS;
