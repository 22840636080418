import styled from 'styled-components';

const EntityWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.status[20]};
  border-radius: 8px;
  box-shadow: 4px -4px 8px ${({ theme }) => theme.colors.boxShadow[15]};
  position: relative;
  background: ${({ theme }) => theme.colors.white[100]};
  .entity-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .entity-profile-photo {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.status[20]};
  }

  .entity-description-container {
    min-height: 52px;
  }
  .entity-short-description {
    min-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .course-data {
    min-height: 24px;
  }
  .user-image-container {
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer-container {
    height: 20px;
  }
  .navigate-button {
    display: block !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .image-button {
    &:hover {
      background-color: transparent !important;
    }
  }
  .full-heart {
    path {
      fill: ${({ theme }) => theme.colors.error[200]};
    }
  }
  .empty-heart {
    path {
      fill: ${({ theme }) => theme.colors.gray[40]};
    }
  }
  .last-updated-content {
    min-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default EntityWrapper;
