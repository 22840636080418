const VERSION_FLAGS = {
  CREATE: 'c',
  UPDATE: 'u',
  DELETE: 'd',
};

const version_flags = {
  ...VERSION_FLAGS,
  hasFlag: (flag) => Object.values(VERSION_FLAGS).includes(flag),
};

export default version_flags;
