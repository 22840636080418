/* eslint-disable no-useless-escape */

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordErrorMessage = `Password must start with upper-case and contain symbol, number and lowercase.`;

const VALIDATORS = {
  validateEmail: (email) => Boolean(email.match(emailRegex)),
  validatePassword: (value) => {
    const specialChar = /[^A-Za-z 0-9]/g;
    const number = /[0-9]/g;
    const upperCase = /[A-Z]/g;
    const lowerCase = /[a-z]/g;
    const isValid = specialChar.test(value) && number.test(value) && upperCase.test(value[0]) && lowerCase.test(value);

    return { isValid, errorMessage: isValid ? '' : passwordErrorMessage };
  },
};

export default VALIDATORS;
