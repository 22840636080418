import emptySplitApi from '..';

export const emailTrackingApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getEmailTracking: build.query({
      query: (body) => ({
        url: '/email-tracking',
        method: 'GET',
        params: body,
      }),
    }),
  }),
});

export const { useLazyGetEmailTrackingQuery } = emailTrackingApi;
