import { generatePath } from 'react-router-dom';
import PATHS from 'constants/paths';
import ENTITY_TYPES from './entity-types';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION } = ENTITY_TYPES;

const FIRST_COL_DATA = [
  {
    name: LEARNING_COMMUNITY.plural,
    url: generatePath(PATHS.EXPLORE, {
      category: LEARNING_COMMUNITY.key,
    }),
  },
  {
    name: DEPARTMENT.plural,
    url: generatePath(PATHS.EXPLORE, { category: DEPARTMENT.key }),
  },
  {
    name: ORGANIZATION.plural,
    url: generatePath(PATHS.EXPLORE, { category: ORGANIZATION.key }),
  },
];

const SECOND_COL_DATA = [
  { name: 'About', url: '/about' },
  { name: 'Services', url: '/services' },
  { name: 'Refund Policy', url: '/refund-policy' },
];

const FOOTER_DATA = {
  FIRST_COL_DATA,
  SECOND_COL_DATA,
};

export default FOOTER_DATA;
