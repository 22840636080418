import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ACCESS_TYPES, ENTITY_TYPES, PAYMENT_TYPES, STRING_UTILS, USER_COURSE_TYPES } from 'common';
import { getFollowEntities, getOldEntitiesType, isEntity } from 'common/entity-types';

import { prefetch } from 'store/redux-toolkit-common';
import { getUserData } from 'store/selectors/user';
import { useEnrollMutation } from 'store/services/accessCode';
import { useGetDepartmentQuery } from 'store/services/departments';
import { useGetLearningCommunityQuery } from 'store/services/learningCommunities';
import { useGetOrganizationQuery } from 'store/services/organizations';

import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import Text from 'style-guide/Typography/Text';
import PaymentModal from '../PaymentModal';
import AccessCodeField from './AccessCodeField';
import AccessCodeSkeleton from './AccessCodeSkeleton';
import JoinEntitiesWrapper from './style/JoinEntitiesWrapper';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION } = ENTITY_TYPES;
const { PaymentTypePrivatePaid, PaymentTypePublicPaid, paidPlanTypes } = PAYMENT_TYPES;
const { cleanUpPrice, removeWhitespace } = STRING_UTILS;

const { member } = USER_COURSE_TYPES.userCourseRole;

const selectFromResult = (result) => (result.data ? { ...result, data: result.data.data } : {});

const showField = (entity) => entity && !entity.isJoined;
const { AccessTypePrivate } = ACCESS_TYPES;

const JoinEntities = ({ hideModal, params: { type, urlName, name } }) => {
  const formMethods = useForm({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [disabled, setDisabled] = useState({});

  const userData = useSelector(getUserData);
  const userId = userData?.id;

  const dispatch = useDispatch();

  const {
    data: learningCommunityData,
    isFetching: isLearningCommunityDataFetching,
    isLoading: isLearningCommunityDataLoading,
  } = useGetLearningCommunityQuery(
    { urlName, userId, includeLibrary: true },
    { skip: !isEntity(LEARNING_COMMUNITY.key, type), refetchOnMountOrArgChange: true, selectFromResult }
  );

  const {
    data: departmentData,
    isFetching: isDepartmentDataFetching,
    isLoading: isDepartmentDataLoading,
  } = useGetDepartmentQuery(
    { urlName, userId, includeOrganization: true },
    { skip: !isEntity(DEPARTMENT.key, type), refetchOnMountOrArgChange: true }
  );

  const {
    data: organizationData,
    isFetching: isOrganizationDataFetching,
    isLoading: isOrganizationDataLoading,
  } = useGetOrganizationQuery(
    { urlName, userId },
    { skip: !isEntity(ORGANIZATION.key, type), refetchOnMountOrArgChange: true }
  );
  const [enroll, { isSuccess, isLoading: isEnrollLoading }] = useEnrollMutation();

  const data = learningCommunityData || departmentData || organizationData;
  const isFetching = isOrganizationDataFetching || isDepartmentDataFetching || isLearningCommunityDataFetching;
  const isLoading = isOrganizationDataLoading || isDepartmentDataLoading || isLearningCommunityDataLoading;

  const oldType = getOldEntitiesType(type).singular.toLowerCase();

  const params = { [`${oldType}Id`]: data?.id, type: oldType, userId, urlName, invitationId: data?.invitationId };

  const onFormSubmit = (formData) => {
    enroll({ ...formData, ...params });
  };

  const followEntities = getFollowEntities(data, type);

  const [showModal, setShowModal] = useState(false);
  const onToggleModal = () => setShowModal((item) => !item);

  const entities = Object.values(followEntities).filter((n) => n);

  const totalPrice = entities.reduce((accumulator, currentValue) => {
    const isPrivateAccessType = currentValue?.accessTypeId === AccessTypePrivate;
    const price = isPrivateAccessType
      ? currentValue?.accessCodeResalePrice
      : cleanUpPrice({ string: currentValue?.price, ignoreFree: true });

    return accumulator + +price;
  }, 0);

  const isPrivatePaid = entities.some((e) => e.paymentTypeId === PaymentTypePrivatePaid);
  const isPublicPaid = entities.some((e) => e.paymentTypeId === PaymentTypePublicPaid);

  const hasPaymentModal = isPublicPaid || isPrivatePaid;
  const parentOrgData = followEntities[ORGANIZATION.key];
  const isRecurringPayment = parentOrgData?.paidPlanTypeId === paidPlanTypes.recurring;
  const isSubscribed = parentOrgData?.isSubscribed;

  const isSubmitDisabled = Object.values(disabled).some((e) => e);

  useEffect(() => {
    if (isSuccess) {
      const redirectPathName = `/${type}/${data?.id}/${urlName}/${member}/homepage`;
      hideModal();
      setShowModal(false);
      if (pathname === redirectPathName) {
        const endpointName = `get${removeWhitespace(name)}`;
        return dispatch(prefetch(endpointName, { urlName, userId }, { force: true }));
      }

      navigate(redirectPathName);
    }
  }, [data?.id, dispatch, hideModal, isSuccess, name, navigate, pathname, type, urlName, userId]);

  if (isFetching || isLoading) {
    return <AccessCodeSkeleton />;
  }

  const showOrganizationNavigateText =
    isRecurringPayment && !isSubscribed && oldType !== ORGANIZATION.singular.toLowerCase();

  // TODO: after adding the function of joining an organization, make a link
  const navigateLink = (
    <Link
      to={`${ORGANIZATION.key}/${parentOrgData?.id}/${parentOrgData?.urlName}/member/homepage`}
      onClick={hideModal}
      className='navigate-link'
    >
      Link here
    </Link>
  );

  return (
    <JoinEntitiesWrapper>
      {showOrganizationNavigateText ? (
        <Text>
          This {name} belongs to an organization which has a subscription plan. Please join the organization and, you
          can get an access to this {name}. {navigateLink} to join organization
        </Text>
      ) : (
        <Form onSubmit={formMethods.handleSubmit(onFormSubmit)}>
          {showField(parentOrgData) ? (
            <AccessCodeField
              type='organization'
              entityName={ORGANIZATION.singular}
              data={parentOrgData}
              formMethods={formMethods}
              setDisabled={setDisabled}
            />
          ) : null}
          {showField(followEntities[DEPARTMENT.key]) ? (
            <AccessCodeField
              type='library'
              entityName={DEPARTMENT.singular}
              data={followEntities[DEPARTMENT.key]}
              formMethods={formMethods}
              setDisabled={setDisabled}
            />
          ) : null}
          {showField(followEntities[LEARNING_COMMUNITY.key]) ? (
            <AccessCodeField
              entityName={LEARNING_COMMUNITY.singular}
              type='course'
              data={followEntities[LEARNING_COMMUNITY.key]}
              formMethods={formMethods}
              setDisabled={setDisabled}
            />
          ) : null}
          <div className='row button-section'>
            <div className='col d-flex justify-content-center'>
              <Button variant='secondary' onClick={hideModal}>
                Cancel
              </Button>
              <Button
                variant='primary'
                className='ml--8'
                disabled={isSubmitDisabled}
                type={hasPaymentModal ? 'button' : 'submit'}
                onClick={hasPaymentModal ? onToggleModal : null}
              >
                Join now
              </Button>
            </div>
          </div>
        </Form>
      )}
      <PaymentModal
        showModal={showModal}
        onToggleModal={onToggleModal}
        name={data?.name}
        onOk={(paymentInfo) => {
          const values = formMethods.getValues();
          enroll({ ...values, ...paymentInfo, ...params, planId: isRecurringPayment?.planId });
        }}
        price={totalPrice}
        isSuccess={isSuccess}
        entityName={name}
        isEnrollLoading={isEnrollLoading}
      />
    </JoinEntitiesWrapper>
  );
};

export default JoinEntities;
