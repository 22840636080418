import DropdownV2 from 'style-guide/DropdownV2';
import BoldIcon from 'style-guide/Icons/RichEditor/Bold';
import BulListIcon from 'style-guide/Icons/RichEditor/BulList';
import DropdownIcon from 'style-guide/Icons/RichEditor/Dropdown';
import Highlight from 'style-guide/Icons/RichEditor/Highlight';
import ItalicIcon from 'style-guide/Icons/RichEditor/Italic';
import LinkIcon from 'style-guide/Icons/RichEditor/Link';
import NumListIcon from 'style-guide/Icons/RichEditor/NumList';
import QuoteIcon from 'style-guide/Icons/RichEditor/Quote';
import StrikeThroughIcon from 'style-guide/Icons/RichEditor/StrikeThrought';
import TypographyGroupIcon from 'style-guide/Icons/RichEditor/TypographyGroup';
import UnderlineIcon from 'style-guide/Icons/RichEditor/Underline';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import { itemClickHandle, typographyItemClickHandle } from './helper';

const fixedMenuActions = ({ editor, setLinkModalVisible }) => [
  {
    name: 'Heading 2',
    title: 'Format to H2',
    icon: (
      <DropdownV2
        options={[
          {
            name: <Text weight={500}>Title 1</Text>,
            value: 'title1',
          },
          {
            name: <Title variant={3}>Title 2</Title>,
            value: 'title2',
          },
          {
            name: <Text>Paragraph</Text>,
            value: 'paragraph',
          },
        ]}
        onClickItem={(item) => typographyItemClickHandle(item, editor)}
      >
        <TypographyGroupIcon />
      </DropdownV2>
    ),
    borderAfter: true,
  },
  {
    name: 'bold',
    title: 'Format Bold',
    icon: <BoldIcon />,
    onSelect: () => editor.chain().focus().toggleMark('bold').run(),
  },
  {
    name: 'italic',
    title: 'Format Italic',
    icon: <ItalicIcon />,
    onSelect: () => editor.chain().focus().toggleMark('italic').run(),
  },
  {
    name: 'underline',
    title: 'Format Underline',
    icon: <UnderlineIcon />,
    onSelect: () => editor.chain().focus().toggleMark('underline').run(),
  },
  {
    name: 'strikethrough',
    title: 'Format StrikeThrough',
    icon: <StrikeThroughIcon />,
    onSelect: () => editor.chain().focus().toggleMark('strike').run(),
    borderAfter: true,
  },
  {
    name: 'bulleted-list',
    title: 'Toggle Bullete List',
    icon: <BulListIcon />,
    onSelect: () => editor.chain().focus().toggleBulletList().run(),
  },
  {
    name: 'ordered-list',
    title: 'Toggle Ordered List',
    icon: <NumListIcon />,
    onSelect: () => editor.chain().focus().toggleOrderedList().run(),
    borderAfter: true,
  },
  {
    name: 'dropdown',
    title: 'Toggle Dropdown List',
    icon: (
      <DropdownV2
        options={[
          {
            name: (
              <>
                <QuoteIcon width={20} height={20} /> <Text className='ml--8'>Block Quote</Text>
              </>
            ),
            value: 'quote',
          },
          {
            name: (
              <>
                <Highlight width={20} height={20} /> <Text className='ml--8'>Highlight</Text>
              </>
            ),
            value: 'highlight',
          },
          {
            name: (
              <>
                <LinkIcon /> <Text className='ml--8'>Link</Text>
              </>
            ),
            value: 'link',
          },
        ]}
        onClickItem={(item) => itemClickHandle(item, editor, setLinkModalVisible)}
      >
        <DropdownIcon />
      </DropdownV2>
    ),
  },
];

export default fixedMenuActions;
