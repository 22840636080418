import { Controller } from 'react-hook-form';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import FormItem from 'style-guide/FormItem';
import { TextArea } from 'style-guide/Input';
import Rate from 'style-guide/Rate';
import ReviewModalWrapper from './style/ReviewModalWrapper';

const ReviewModal = ({ control, hideModal, disabled, onFormSubmit }) => (
  <ReviewModalWrapper>
    <Form onSubmit={onFormSubmit}>
      <FormItem className='d-flex justify-content-center'>
        <Controller name='rating' control={control} render={({ field }) => <Rate {...field} />} />
      </FormItem>
      <FormItem>
        <Controller
          name='comment'
          control={control}
          render={({ field }) => <TextArea {...field} placeholder='Write a review' />}
        />
      </FormItem>
      <div className='col-12 d-flex justify-content-center action-buttons'>
        <Button variant='secondary' onClick={hideModal}>
          Cancel
        </Button>
        <Button variant='primary' type='submit' disabled={disabled}>
          Save
        </Button>
      </div>
    </Form>
  </ReviewModalWrapper>
);

export default ReviewModal;
