const Loading = (props) => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.5029 10.5C17.5029 11.8845 17.0924 13.2378 16.3232 14.389C15.554 15.5401 14.4608 16.4373 13.1817 16.9672C11.9026 17.497 10.4952 17.6356 9.1373 17.3655C7.77943 17.0954 6.53215 16.4287 5.55318 15.4497C4.57421 14.4708 3.90753 13.2235 3.63743 11.8656C3.36734 10.5078 3.50596 9.1003 4.03577 7.82122C4.56559 6.54213 5.46279 5.44888 6.61394 4.67971C7.76508 3.91054 9.11846 3.5 10.5029 3.5'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export default Loading;
