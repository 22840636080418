import RateComponent from 'rc-rate';
import styled from 'styled-components';
import { Rate as RateIcon } from 'style-guide/Icons';

const Rate = styled(RateComponent)`
  &.rc-rate {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    font-style: normal;
    outline: none;
  }
  .rc-rate-rtl {
    direction: rtl;
  }
  .rc-rate-disabled .rc-rate-star {
    cursor: default;
  }
  .rc-rate-disabled .rc-rate-star:before,
  .rc-rate-disabled .rc-rate-star-content:before {
    cursor: default;
  }
  .rc-rate-star {
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    line-height: 1.5;
    path {
      stroke: ${({ theme }) => theme.colors.secondary[200]};
    }
  }
  .rc-rate-rtl .rc-rate-star {
    margin-right: 0;
    margin-left: 8px;
    float: right;
  }
  .rc-rate-star-first,
  .rc-rate-star-second {
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }
  .rc-rate-star-first {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }
  .rc-rate-rtl .rc-rate-star-first {
    right: 0;
    left: auto;
  }
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-half .rc-rate-star-second {
    opacity: 1;
  }
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-full .rc-rate-star-second {
    path {
      fill: ${({ theme }) => theme.colors.yellow[400]};
      stroke: ${({ theme }) => theme.colors.yellow[400]};
    }
  }
  .rc-rate-star-half:hover .rc-rate-star-first,
  .rc-rate-star-full:hover .rc-rate-star-second {
    path {
      fill: ${({ theme }) => theme.colors.yellow[400]};
      stroke: ${({ theme }) => theme.colors.yellow[400]};
    }
  }
`;

Rate.defaultProps = {
  character: <RateIcon height={19.4} width={21} />,
};

export default Rate;
