import LEARNING_COMMUNITY_SECTIONS from './course-sections';

const VERSION_TARGETS = {
  ...LEARNING_COMMUNITY_SECTIONS,
  VOLUME: {
    id: 6,
    value: 'volume',
    name: 'Volume',
  },
  CHAPTER: {
    id: 7,
    value: 'chapter',
    name: 'Chapter',
  },
};

const version_targets = {
  ...VERSION_TARGETS,
  hasTarget: (target) => Object.values(VERSION_TARGETS).includes(target),
};

export default version_targets;
