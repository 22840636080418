import styled from 'styled-components';

const EntitySearchWrapper = styled.div`
  width: 328px;
  .search-input {
    path {
      fill: ${({ theme }) => theme.colors.dark[60]};
    }
  }
`;

export default EntitySearchWrapper;
