import { transformErrorResponse, transformResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const learningCommunityMembersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getLearningCommunityMembers: build.query({
      query: (params) => ({
        url: `/courses/${params.urlName}/members`,
        params,
      }),
      transformResponse: ({ total, rows }, _arg, { invited, isContributor }) => {
        if (isContributor) {
          const data = rows.filter((el) => el.isPaidEligible);

          const membersData = data.reduce(
            (result, element) => {
              result[element.revenueShare !== 0 ? 'activeContributors' : 'candidateContributors'].push(element);
              return result;
            },
            { activeContributors: [], candidateContributors: [] }
          );

          return membersData;
        }
        if (invited) {
          return {
            total,
            rows: rows.map((e) => ({ ...e, invitationId: e.userCourseInvitationId })),
          };
        }

        return { total, rows };
      },
      providesTags: ['learning-community-members'],
    }),

    updateLearningCommunityMembersRevenue: build.mutation({
      query: (params) => ({
        url: `/courses/${params.urlName}/members`,
        method: 'POST',
        body: params.dataWithUserId,
      }),
      transformResponse: (data) => {
        transformResponse(data);

        return data;
      },
      transformErrorResponse,
    }),

    updateLearningCommunityMembersType: build.mutation({
      query: (params) => ({
        url: `/courses/${params.urlName}/members/${params.userId}`,
        method: 'PUT',
        body: { type: params.level },
      }),
      invalidatesTags: ['learning-community-members'],
    }),
    deleteLearningCommunityMembers: build.mutation({
      query: (params) => ({
        url: `/courses/${params.urlName}/members/${params.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['learning-community-members'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLearningCommunityMembersQuery,
  useUpdateLearningCommunityMembersTypeMutation,
  useDeleteLearningCommunityMembersMutation,
  useUpdateLearningCommunityMembersRevenueMutation,
} = learningCommunityMembersApi;
