import noData from 'assets/images/noData.png';
import NoDataWrapper from 'Components/NoData/style/NoDataWrapper';

import Button from 'style-guide/Button';
import Image from 'style-guide/Image';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

const NoDataComponent = ({ title, text, buttonText, onClick }) => (
  <NoDataWrapper>
    <Image src={noData} />
    {title ? <Title variant={5}>{title}</Title> : null}
    {text ? (
      <Text $variant={3} $dark={80} className='text'>
        {text}
      </Text>
    ) : null}
    {buttonText ? (
      <Button variant='primary' onClick={onClick}>
        {buttonText}
      </Button>
    ) : null}
  </NoDataWrapper>
);

export default NoDataComponent;
