import { SYSTEM_LIMITS, VALIDATORS } from 'common';
import { object, ref, string } from 'yup';

const { password } = SYSTEM_LIMITS.INPUTS;
const { validatePassword } = VALIDATORS;
const getErrorMessage = (field) => validatePassword(field.value).errorMessage;

const schema = object({
  oldPassword: string().trim().required('Old Password is required'),
  newPassword: string()
    .nullable()
    .trim()
    .required('New Password is required')
    .min(password.min, `Password must be at least ${password.min} characters`)
    .test(
      '',
      (field) => getErrorMessage(field),
      (value) => validatePassword(value).isValid
    ),
  confirmPassword: string()
    .nullable()
    .trim()
    .required('Confirm the New Password')
    .oneOf([ref('newPassword')], 'Two passwords that you enter are inconsistent!'),
}).required();

export default schema;
