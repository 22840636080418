import ACCESS_TYPES from './access-types';

const { AccessTypePublic, AccessTypePrivate } = ACCESS_TYPES;

const paymentTypes = {
  PaymentTypePublicFree: 1,
  PaymentTypePublicPaid: 2,
  PaymentTypePrivatePrePaid: 3,
  PaymentTypePrivatePaid: 4,
};

const paidPlanTypes = {
  lump: 1,
  recurring: 2,
};

const getPaymentTypes = () => ({
  [paymentTypes.PaymentTypePublicFree]: {
    id: paymentTypes.PaymentTypePublicFree,
    name: 'Free',
    accessTypeId: AccessTypePublic,
  },
  [paymentTypes.PaymentTypePublicPaid]: {
    id: paymentTypes.PaymentTypePublicPaid,
    name: 'Paid',
    accessTypeId: AccessTypePublic,
  },
  [paymentTypes.PaymentTypePrivatePrePaid]: {
    id: paymentTypes.PaymentTypePrivatePrePaid,
    name: 'Prepaid',
    accessTypeId: AccessTypePrivate,
  },
  [paymentTypes.PaymentTypePrivatePaid]: {
    id: paymentTypes.PaymentTypePrivatePaid,
    name: 'Paid',
    accessTypeId: AccessTypePrivate,
  },
});

const getPaymentTypeName = (code) => getPaymentTypes()[code].name || '';

const publicPaymentTypes = [
  getPaymentTypes()[paymentTypes.PaymentTypePublicFree],
  getPaymentTypes()[paymentTypes.PaymentTypePublicPaid],
];

const privatePaymentTypes = [
  getPaymentTypes()[paymentTypes.PaymentTypePrivatePrePaid],
  getPaymentTypes()[paymentTypes.PaymentTypePrivatePaid],
];

const isPrivatePaid = ({ accessTypeId, paymentTypeId }) =>
  accessTypeId === AccessTypePrivate && paymentTypeId === paymentTypes.PaymentTypePrivatePaid;

const isPrivatePrePaid = ({ accessTypeId, paymentTypeId }) =>
  accessTypeId === AccessTypePrivate && paymentTypeId === paymentTypes.PaymentTypePrivatePrePaid;

const isFree = ({ accessTypeId, paymentTypeId }) =>
  accessTypeId === AccessTypePublic && paymentTypeId === paymentTypes.PaymentTypePublicFree;

const isPublicPaid = ({ accessTypeId, paymentTypeId }) =>
  accessTypeId === AccessTypePublic && paymentTypeId === paymentTypes.PaymentTypePublicPaid;

const PAYMENT_TYPES = {
  ...paymentTypes,
  privatePaymentTypes,
  publicPaymentTypes,
  paidPlanTypes,
  getPaymentTypes,
  getPaymentTypeName,
  isPrivatePrePaid,
  isPrivatePaid,
  isPublicPaid,
  isFree,
};

export default PAYMENT_TYPES;
