import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const IdleTimeoutModalWrapper = styled.div`
  height: 136px;
  width: 240px;
  padding-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZES.SM}px) {
    width: 300px;
    height: 140px;
  }
  .modal-text {
    text-align: center;
    padding-bottom: 16px;
  }
`;

export default IdleTimeoutModalWrapper;
