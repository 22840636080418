const Trash = (props) => (
  <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.928571 14.2222C0.928571 15.2 1.76429 16 2.78571 16H10.2143C11.2357 16 12.0714 15.2 12.0714 14.2222V3.55556H0.928571V14.2222ZM2.78571 5.33333H10.2143V14.2222H2.78571V5.33333ZM9.75 0.888889L8.82143 0H4.17857L3.25 0.888889H0V2.66667H13V0.888889H9.75Z'
      fill='black'
    />
  </svg>
);

export default Trash;
