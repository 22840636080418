import { createSlice } from '@reduxjs/toolkit';

const initialState = { lastQuestionId: null };

const assessment = createSlice({
  name: 'setEditedCardIdSlice',
  initialState,
  reducers: {
    setEditedCardIdSlice(state, action) {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.lastQuestionId = payload;
    },
  },
});

export const { setEditedCardIdSlice } = assessment.actions;
export default assessment.reducer;
