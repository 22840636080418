import { useDispatch, useSelector } from 'react-redux';

import MODAL_TYPES from 'constants/modals';
import useLocationChange from 'hooks/useLocationChange';
import { hideModal, showModal } from 'store/slices/modal';
import Modal from 'style-guide/Modal';
import ViewCourseSettings from 'views/SuperAdmin/CoursesTable/ViewCourseSettings';
import StaticPagesModal from 'views/SuperAdmin/StaticPages/StaticPagesModal';
import AddSocialMedia from './AddSocialMedia';
import AdminAddUser from './AdminAddUser';
import ChangePassword from './ChangePassword';
import CloneEntityModal from './CloneEntityModal';
import ConfirmationModal from './ConfirmationModal';
import EditBio from './EditBio';
import EditPaymentMethod from './EditPaymentMethod';
import EditProfile from './EditProfile';
import FileManager from './FileManager';
import IdleTimeoutModal from './IdleTimeout/IdleTimeoutModal';
import JoinEntities from './JoinEntities';
import PasswordResetSuccessModal from './PasswordResetSuccessModal';
import Presentation from './Presentation';
import Resources from './Resources';
import DepartmentReviewModal from './ReviewModal/DepartmentReviewModal';
import LearningCommunityReviewModal from './ReviewModal/LearningCommunityReviewModal';
import OrganizationReviewModal from './ReviewModal/OrganizationReviewModal';
import JoinUserToCoursesModal from './SuperAdmin/JoinUserToCoursesModal';
import UserModifyModal from './SuperAdmin/UserModifyModal';
import ViewMembership from './ViewMembership';
import ContactInformation from './ContactInformation';

const BodyRenderer = {
  [MODAL_TYPES.RESET_PASSWORD]: PasswordResetSuccessModal,
  [MODAL_TYPES.EDIT_PROFILE]: EditProfile,
  [MODAL_TYPES.ADD_SOCIAL_MEDIA]: AddSocialMedia,
  [MODAL_TYPES.EDIT_PAYMENT_METHOD]: EditPaymentMethod,
  [MODAL_TYPES.CHANGE_PASSWORD]: ChangePassword,
  [MODAL_TYPES.CONTACT_INFORMATION]: ContactInformation,
  [MODAL_TYPES.EDIT_BIO]: EditBio,
  [MODAL_TYPES.VIEW_MEMBERSHIP]: ViewMembership,
  [MODAL_TYPES.FILE_MANAGER]: FileManager,
  [MODAL_TYPES.CONFIRMATION]: ConfirmationModal,
  [MODAL_TYPES.PRESENTATION]: Presentation,
  [MODAL_TYPES.JOIN_ENTITIES]: JoinEntities,
  [MODAL_TYPES.LEARNING_COMMUNITY_REVIEW]: LearningCommunityReviewModal,
  [MODAL_TYPES.DEPARTMENT_REVIEW]: DepartmentReviewModal,
  [MODAL_TYPES.ORGANIZATION_REVIEW]: OrganizationReviewModal,
  [MODAL_TYPES.RESOURCES]: Resources,
  [MODAL_TYPES.IDLE_TIMER]: IdleTimeoutModal,
  [MODAL_TYPES.CLONE_ENTITY]: CloneEntityModal,
  [MODAL_TYPES.JOIN_USER_TO_COURSES]: JoinUserToCoursesModal,
  [MODAL_TYPES.USER_MODIFY]: UserModifyModal,
  [MODAL_TYPES.VIEW_COURSE_SETTINGS]: ViewCourseSettings,
  [MODAL_TYPES.STATIC_PAGES_MODAL]: StaticPagesModal,
  [MODAL_TYPES.ADMIN_ADD_USER_MODAL]: AdminAddUser,
};

const ModalRenderer = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modalSlice);
  const activeModal = modals.find((m) => m);
  const visible = !!activeModal;

  const locationChanged = () => {
    if (visible) {
      dispatch(hideModal({ type: activeModal?.type }));
    }
  };
  useLocationChange(locationChanged);

  return visible
    ? modals.map((modal) => {
        const { type, params } = modal;
        const Body = BodyRenderer[type];
        // todo
        const closeModal = () => dispatch(hideModal({ type: modal.type }));

        return (
          <Modal visible={visible} onClose={closeModal} {...params?.modal} key={modal.type}>
            <Body params={params?.body} hideModal={closeModal} showModal={showModal} />
          </Modal>
        );
      })
    : null;
};

export default ModalRenderer;
