import { handleTypeProps } from './helper';
import LabelWrapper from './LabelWrapper';

const Label = ({ type, children, icon }) => (
  <LabelWrapper $type={type} className='d-inline pv--2 ph--8'>
    {icon ? <span className='pr--4'>{handleTypeProps({ type })?.icon}</span> : null}
    {children}
  </LabelWrapper>
);

export default Label;
