import styled from 'styled-components';

const Form = styled.form`
  &.two-column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    .two-column-form-item {
      width: 50%;
      &:nth-child(even) {
        padding-left: 10px;
      }
      &:nth-child(odd) {
        padding-right: 10px;
      }
    }
  }
`;

export default Form;
