import { Left, Right } from 'style-guide/Icons';

const itemRender = (_current, type, originalElement) => {
  if (type === 'prev') {
    return (
      <span className='arrow'>
        <Left className='left' />
        Prev
      </span>
    );
  }
  if (type === 'next') {
    return (
      <span className='arrow'>
        Next
        <Right className='right' />
      </span>
    );
  }
  return originalElement;
};

export default itemRender;
