export const API_ROOT = process.env.REACT_APP_API_ROOT || 'http://localhost:3030/';

export const API_ROOT_V2 = process.env.REACT_APP_API_ROOT_V2 || 'http://localhost:3040/';

export const WS_API_ROOT = process.env.REACT_APP_API_ROOT || 'http://localhost:3030/';

export const AWS3_FOLDER = process.env.REACT_APP_AWS3_FOLDER || 'demo';

export const AWS3 = process.env.REACT_APP_AWS3
  ? `${process.env.REACT_APP_AWS3}${AWS3_FOLDER}/`
  : `https://peertreev2.s3.amazonaws.com/${AWS3_FOLDER}/`;

export const AWS3_FILE_MANAGER = process.env.REACT_APP_AWS3
  ? `${process.env.REACT_APP_AWS3}/`
  : `https://peertreev2.s3.amazonaws.com/`;

export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID || '327745665005652';

export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || '549522000696-e0fs4gf3m1gkb5u2pa5k5bfa2n53rq5r.apps.googleusercontent.com';

export const GOOGLE_CLIENT_API_KEY =
  process.env.REACT_APP_GOOGLE_CLIENT_API_KEY || 'AIzaSyCZf6xIMIzPbTTA53Co6zYS_N3Uy1u4H2M';

export const TINYMCE_API_KEY =
  process.env.REACT_APP_TINYMCE_API_KEY || '5s4fxwmltp88wu1sbuua3nn6nfe1x2xzzh3ididaw8l11b71';

export const UNSPLASH_API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY || 'QvsrZhPbvyFJsfUoBgjtEdcjb4TW6vDZ9D-1QH_G3ug';

export const { REACT_APP_GA_TRACKING_ID } = process.env;
