import { transformErrorResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

const adminUsersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminUsers: build.query({
      query: (params) => ({
        url: `/admin/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['admin-user'],
      transformErrorResponse,
    }),
    getAdminData: build.query({
      query: (params) => ({
        url: `admin/users/${params.id}`,
        method: 'GET',
        params,
      }),
    }),
    deleteAdminUser: build.mutation({
      query: (params) => ({
        url: `/admin/users/${params.id}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['admin-user'],
    }),
    reActivateAdminUser: build.mutation({
      query: (params) => ({
        url: `/admin/users/${params.id}`,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['admin-user'],
    }),
    setUserPosition: build.mutation({
      query: (params) => ({
        url: `/admin/users/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['admin-user'],
    }),
    verifyUser: build.mutation({
      query: ({ params, body }) => ({
        url: `/admin/users/${body.id}`,
        method: 'PATCH',
        body,
        params,
      }),
      invalidatesTags: ['admin-user'],
    }),
    adminCreateUser: build.mutation({
      query: (params) => ({ url: `/admin/users`, method: 'POST', body: params }),
      invalidatesTags: ['admin-user'],
      transformErrorResponse,
    }),
    updateUserAccess: build.mutation({
      query: ({ body, params }) => ({
        url: `/admin/users/${body.id}`,
        method: 'PATCH',
        body,
        params,
      }),
      invalidatesTags: ['admin-user'],
    }),
  }),
});

export default adminUsersApi;
export const {
  useGetSuperAdminCoursesQuery,
  useLazyGetAdminDataQuery,
  useGetAdminUsersQuery,
  useVerifyUserMutation,
  useDeleteAdminUserMutation,
  useReActivateAdminUserMutation,
  useSetUserPositionMutation,
  useAdminCreateUserMutation,
  useLazyGetAdminUsersQuery,
  useUpdateUserAccessMutation,
} = adminUsersApi;
