/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMembersMutation } from 'store/services/profile';
import { changeBankAccount } from 'store/slices/profile';

import Button from 'style-guide/Button';
import CheckBox from 'style-guide/CheckBox';
import DatePicker from 'style-guide/DatePicker';
import Form from 'style-guide/Form';
import FormItem from 'style-guide/FormItem';
import { Input } from 'style-guide/Input';
import Radio from 'style-guide/Radio';
import Select from 'style-guide/Select';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';
import ErrorMessageView from '../ErrorMessageView';
import { LEFT_INFO_ITEMS, RIGHT_INFO_ITEMS } from './constants';
import schema from './schema';
import EditPaymentMethodWrapper from './style/EditPaymentMethodWrapper';

const EditPaymentMethod = ({ hideModal, params }) => {
  const dispatch = useDispatch();
  const [updatePaymentMethod, { data, isLoading, isError, error }] = useMembersMutation();

  const { user, bankAccount, isAdminViewingOtherProfile } = params;
  const { bankAccountInfo, states } = bankAccount || {};
  const defaultValues = bankAccountInfo || {};

  const options = useMemo(
    () =>
      states?.map((state) => ({
        value: state.name,
        label: state.stateFullName,
        id: state.id,
      })),
    []
  );

  const getBankFieldValue = (formData, fieldName) => {
    if (formData[fieldName].includes('*')) {
      return defaultValues[`real${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`];
    }

    return formData[fieldName];
  };

  const defaultState = () => {
    if (defaultValues.state) {
      return {
        id: defaultValues.state.id,
        label: defaultValues.state.stateFullName,
        value: defaultValues.state.name,
      };
    }

    if (defaultValues.stateId) {
      return states
        ?.filter((state) => state.id === defaultValues.stateId)
        .map((state) => ({
          value: state.name,
          label: state.stateFullName,
          id: state.id,
        }));
    }

    return null;
  };

  const [tosAgreed, setTosAgreed] = useState({
    value: defaultValues?.tosAgreed,
    isDirty: false,
  });
  const [bankInfoChangedNotCorrect, setBankInfoChangedNotCorrect] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bankAccountNumber: defaultValues.bankAccountNumber,
      bankRoutingNumber: defaultValues.bankRoutingNumber,
      accountType: defaultValues.accountType,
      bankFirstName: defaultValues.firstName,
      bankLastName: defaultValues.lastName,
      bankAddress: defaultValues.address,
      bankZipCode: defaultValues.zipCode,
      bankEmail: defaultValues.email,
      bankCity: defaultValues.city,
      companyName: defaultValues.companyName,
    },
    mode: 'onChange',
  });

  const watchBankAccountNumber = watch('bankAccountNumber');
  const watchBankRoutingNumber = watch('bankRoutingNumber');

  // set bankInfoChangedNotCorrect to true if bankAccountNumber or bankRoutingNumber is changed and includes '*'
  // we did this to disable button and don't let user to submit values that shouldn't be sent
  useEffect(() => {
    setBankInfoChangedNotCorrect(
      (watchBankAccountNumber !== defaultValues.bankAccountNumber && watchBankAccountNumber.includes('*')) ||
        (watchBankRoutingNumber !== defaultValues.bankRoutingNumber && watchBankRoutingNumber.includes('*'))
    );
  }, [watchBankAccountNumber, watchBankRoutingNumber]);

  const {
    field: { onChange: changeBankStateName, ref: bankStateNameRef },
    fieldState: { error: stateError },
  } = useController({
    name: 'bankStateName',
    control,
    defaultValue: defaultState(),
  });

  const {
    field: { onChange: changeBankDob, value: bankDobValue, ref: bankDobRef },
  } = useController({
    name: 'bankDob',
    control,
    defaultValue: defaultValues.dob ? new Date(defaultValues.dob) : new Date(),
  });

  const {
    field: { onChange: changeComponyType, value: accountTypeValue },
  } = useController({
    name: 'accountType',
    control,
    defaultValue: defaultValues.dob ? new Date(defaultValues.dob) : new Date(),
  });

  const onFormSubmit = (formData) => {
    const actualData = {
      ...formData,
      bankAccountNumber: isAdminViewingOtherProfile
        ? getBankFieldValue(formData, 'bankAccountNumber')
        : formData.bankAccountNumber,
      bankRoutingNumber: isAdminViewingOtherProfile
        ? getBankFieldValue(formData, 'bankRoutingNumber')
        : formData.bankRoutingNumber,
      bankStateName: Array.isArray(formData.bankStateName)
        ? formData.bankStateName[0].value
        : formData.bankStateName.value,
      bankDob: formData.bankDob.toISOString(),
    };

    updatePaymentMethod({
      type: 'bankAccount',
      userId: user.id,
      tosAgreed: tosAgreed.value,
      ...actualData,
    });
  };

  useEffect(() => {
    if (isError) {
      const errorMessage = error?.data?.message;
      message.error(<ErrorMessageView errorMessage={errorMessage} />);
    }

    if (!isLoading && data) {
      dispatch(
        changeBankAccount({
          bankAccount: { ...bankAccount, bankAccountInfo: data },
        })
      );
      hideModal();
      message.success('You have successfully updated the profile!');
    }
  }, [data, isLoading, isError, error]);

  const hasError = Object.keys(errors).length === 0;
  const disabled = isDirty && hasError;

  const handleChangeRadio = (e) => {
    const { checked, value } = e.target;
    if (checked) changeComponyType(value);
  };

  return (
    <EditPaymentMethodWrapper>
      <div className='edit-payment-method-modal-root'>
        <FormItem>
          <div className='row'>
            <div className='col-12 d-flex'>
              <Radio
                name='accountType'
                value='individual'
                checked={accountTypeValue === 'individual'}
                onChange={handleChangeRadio}
              >
                Individual
              </Radio>
              <Radio
                className='compony-box'
                name='accountType'
                value='company'
                checked={accountTypeValue === 'company'}
                onChange={handleChangeRadio}
              >
                Company
              </Radio>
            </div>
          </div>
        </FormItem>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <div className='top-box'>
            <div className='left-box'>
              {LEFT_INFO_ITEMS.map((item) => {
                const { name, type, label, placeholder, required } = item;
                return (
                  <Input
                    type={type}
                    key={name}
                    error={errors?.[name]}
                    name={name}
                    label={label}
                    required={required}
                    placeholder={placeholder}
                    {...register(name)}
                  />
                );
              })}
              {accountTypeValue === 'company' ? (
                <Input
                  label='Company Name'
                  error={errors?.companyName}
                  required
                  placeholder='Write here'
                  {...register('companyName')}
                />
              ) : (
                <div className='date-box'>
                  <FormItem
                    label='Date of Birth'
                    error={errors?.bankDob ? 'Please, input the Date of Birth' : ''}
                    required
                  >
                    <DatePicker
                      ref={bankDobRef}
                      placeholderText='Write here'
                      selected={bankDobValue}
                      onChange={changeBankDob}
                    />
                  </FormItem>
                </div>
              )}
            </div>
            <div className='right-box'>
              {RIGHT_INFO_ITEMS.map((item) => {
                const { name, type, label, placeholder, required } = item;
                return (
                  <Input
                    type={type}
                    name={name}
                    key={name}
                    label={label}
                    required={required}
                    error={errors?.[name]}
                    placeholder={placeholder}
                    {...register(name)}
                  />
                );
              })}
              <div className='select-box'>
                <FormItem label='State *' error={stateError?.message ? { message: 'Please, input the State' } : ''}>
                  <Select
                    ref={bankStateNameRef}
                    placeholder='Write here'
                    options={options}
                    defaultValue={defaultState()}
                    onChange={changeBankStateName}
                  />
                </FormItem>
              </div>
            </div>
          </div>
          <div className='bottom-box'>
            <div className='card-inputs'>
              <div className='left-box'>
                <Input
                  type='text'
                  error={errors?.bankAccountNumber}
                  name='bankAccountNumber'
                  label='Bank Account Number'
                  required
                  placeholder='Write here'
                  {...register('bankAccountNumber')}
                />
              </div>
              <div className='right-box'>
                <Input
                  type='text'
                  name='bankRoutingNumber'
                  error={errors?.bankRoutingNumber}
                  label='Bank Routing Number'
                  required
                  placeholder='Write here'
                  {...register('bankRoutingNumber')}
                />
              </div>
            </div>
          </div>
          <div className='terms-and-conditions-box'>
            <div className='terms-and-conditions'>
              <CheckBox
                checked={tosAgreed.value}
                onChange={(e) => setTosAgreed({ value: e.target.checked, isDirty: true })}
              />
              <Text
                $variant={3}
                className='pointer'
                onClick={() => setTosAgreed({ value: !tosAgreed.value, isDirty: true })}
              >
                I read & agree to the Terms and Conditions *
              </Text>
            </div>
            {!tosAgreed.value && tosAgreed.isDirty ? (
              <Text $variant={1} className='required-error'>
                The terms and conditions must be accepted.
              </Text>
            ) : null}
          </div>
          <div className='d-flex justify-content-center pt--24'>
            <Button variant='secondary' type='button' className='ph--40 mr--16' onClick={hideModal}>
              Cancel
            </Button>
            <Button
              variant='primary'
              className='ph--48'
              type='submit'
              loading={isLoading}
              disabled={!disabled || !tosAgreed.value || bankInfoChangedNotCorrect}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </EditPaymentMethodWrapper>
  );
};

export default EditPaymentMethod;
