import styled from 'styled-components';

const SupperAdminCoursesWrapper = styled.div`
  .icon {
    &:hover {
      cursor: pointer;
    }
    path {
      fill: ${({ theme }) => theme.colors.dark[60]};
    }
  }
  .eye,
  .pencil {
    path {
      fill: ${({ theme }) => theme.colors.tertiary[800]};
    }
  }
  .trash,
  .lock {
    path {
      fill: ${({ theme }) => theme.colors.error[400]};
    }
  }
  .settings,
  .paperclip {
    path {
      fill: ${({ theme }) => theme.colors.dark[60]};
    }
  }
  .access-type-drop-down {
    width: 140px;
    height: 100px;
    background-color: ${({ theme }) => theme.colors.dark[80]};
    border-radius: 20px;
    padding: 8px;
  }
  .access-type-text {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.white[100]};
  }
  .check-icon {
    border: 1.5px solid ${({ theme }) => theme.colors.white[100]};
    fill: ${({ theme }) => theme.colors.white[100]};
  }

  td {
    max-width: 600px;
    white-space: normal;
  }
`;

export default SupperAdminCoursesWrapper;
