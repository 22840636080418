import CardsSkeleton from 'Components/EntityCardList/CardsSkeleton';
import NoData from 'Components/NoData';

const EmptyCard = ({ isLoading, isHomePage, category, isOrganizer, cardsCount, colSpan }) => (
  <div className='empty-element'>
    {isLoading ? (
      <CardsSkeleton category={category} cardSize={colSpan} count={cardsCount} isHomePage={isHomePage} />
    ) : (
      <div className='d-flex justify-content-center align-items-center pt--96'>
        <NoData isAdmin={isOrganizer} category={category} />
      </div>
    )}
  </div>
);

export default EmptyCard;
