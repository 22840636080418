import CardsSkeleton from 'Components/EntityCardList/CardsSkeleton';

const ExploreCardsSkeleton = ({ cardSize, category }) => (
  <div className='explore-skeleton-cards-box'>
    <CardsSkeleton count={4} category={category} cardSize={cardSize} />
    <CardsSkeleton count={4} category={category} cardSize={cardSize} />
    <CardsSkeleton count={4} category={category} cardSize={cardSize} />
  </div>
);

export default ExploreCardsSkeleton;
