import { USER_ACCESS_TYPES } from 'common';
import PATHS from 'constants/paths';
import { generatePath } from 'react-router-dom';

import { removeRedirectUrlToLocalStorage } from 'utils/helperFunctions';

const loggingUserRedirectPaths = (user, navigate) => {
  const redirectUrl = localStorage.getItem('redirectUrl');
  removeRedirectUrlToLocalStorage();
  if (user?.isSuperAdmin || user?.isDomainSuperAdmin) {
    navigate(`/admin/Users`);
    return;
  }
  if (user?.accessTypeId === USER_ACCESS_TYPES.LIMITED.id) {
    navigate(generatePath(PATHS.PROFILE, { userName: user.userName }));
    return;
  }
  navigate(redirectUrl || PATHS.HOME, { replace: true });
};

export default loggingUserRedirectPaths;
