import { TEST_TYPES } from 'common';
import { array, boolean, object, string } from 'yup';

const {
  TestsTypeScoredEssay,
  TestsTypeDragAndDrop,
  TestsTypeMultipleResponse,
  TestsTypeMultipleChoice,
  TestsTypeCloseExercise,
  TestsTypeResponseMatching,
  TestsScaledResponseScored,
  TestsTypeTrueFalse,
  TestsTypeFreeText,
} = TEST_TYPES.types;

const value = {
  [TestsTypeScoredEssay]: '',
  [TestsTypeDragAndDrop]: { correctAnswers: [], wrongAnswers: [] },
  [TestsTypeMultipleResponse]: [
    { answer: '', isTrue: false },
    { answer: '', isTrue: false },
  ],
  [TestsTypeMultipleChoice]: [
    { answer: '', isTrue: false },
    { answer: '', isTrue: false },
  ],
  [TestsTypeCloseExercise]: [
    { answer: '', isTrue: true },
    { answer: '', isTrue: true },
  ],
  [TestsTypeResponseMatching]: [
    { option1: '', option2: '' },
    { option1: '', option2: '' },
  ],
  [TestsScaledResponseScored]: [
    { key: '1', value: '' },
    { key: '2', value: '' },
    { key: 'top', value: '' },
    { key: 'bottom', value: '' },
  ],
  [TestsTypeTrueFalse]: [
    { answer: 'true', isTrue: false },
    { answer: 'false', isTrue: false },
  ],
};

const answerInitialValue = (type) => (type in value ? value[type] : []);

const initialFormValue = (type) => ({
  question: '',
  answer: answerInitialValue(+type),
  hasBlank: false,
  text: '',
  description: '',
  switchForm: false,
  value: '',
  key: '',
  feedback: { correct: { text: '' }, wrong: { text: '' } },
});

const answerValidationSchema = (type) => {
  switch (Number(type)) {
    case TestsTypeTrueFalse:
      return {
        question: string().required('This field is required'),
        answer: array()
          .of(object({ answer: string(), isTrue: boolean() }))
          .required('This field is required')
          .test('You must choose one option', (fields) => fields?.some((field) => field.isTrue)),
      };

    case TestsTypeMultipleChoice:
      return {
        question: string().required('This field is required'),
        answer: array()
          .of(
            object({
              answer: string().trim().required(),
              isTrue: boolean(),
            })
          )
          .required('This field is required')
          .test('You must choose one option', (fields) => fields?.some((field) => field.isTrue)),
      };

    case TestsTypeMultipleResponse:
      return {
        question: string().required('This field is required'),
        answer: array()
          .of(
            object({
              answer: string().trim().required(),
              isTrue: boolean(),
            })
          )
          .required('This field is required')
          .test('You must choose a minimum of one option', (fields) => fields?.some((field) => field.isTrue)),
      };

    case TestsTypeCloseExercise:
      return {
        hasBlank: boolean().test('Empty blank', (field) => field),
        question: string().required('This field is required'),
        answer: array()
          .of(
            object({
              answer: string().trim().required(),
              isTrue: boolean(),
            })
          )
          .required('This field is required'),
      };

    case TestsTypeDragAndDrop:
      return {
        hasBlank: boolean().test('Empty blank', (field) => field),
        question: string().required('This field is required'),
        answer: object({
          correctAnswers: array().of(
            object({
              name: string(),
              answer: string().trim().required(),
            })
          ),
          wrongAnswers: array().of(
            object({
              answer: string().required(),
            })
          ),
        }),
      };
    case TestsTypeResponseMatching:
      return {
        question: string().required('This field is required'),
        answer: array().of(
          object({
            option1: string().trim().max(50).required(),
            option2: string().trim().max(50).required(),
          })
        ),
      };

    case TestsTypeFreeText:
      return {
        question: string().required('This field is required'),
      };

    case TestsTypeScoredEssay:
      return {
        question: string().required('This field is required'),
        answer: string().required('This field is required'),
      };

    case TestsScaledResponseScored:
      return {
        question: string().required('This field is required'),
        answer: array()
          .of(
            object({
              key: string().trim().required(),
              value: string(),
            })
          )
          .required('This field is required')
          .test('This field is required', (fields) =>
            fields.filter((field) => field.key !== 'top' && field.key !== 'bottom').every((field) => field.value.trim())
          ),
      };

    default:
      return string();
  }
};

export { initialFormValue };
export default answerValidationSchema;
