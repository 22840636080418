import noImage from 'assets/images/noImage.png';
import { useState } from 'react';
import Button from 'style-guide/Button';
import { ZoomIn } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Modal from 'style-guide/Modal';
import { message } from 'style-guide/ToastMessages';
import Title from 'style-guide/Typography/Title';
import ImageModalWrapper from './style/ImageModalWrapper';

const ImageUpload = ({ image, setValue, upload }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const hasImage = !!image;

  const openImageModal = () => {
    setModalOpen(true);
  };

  const removeImage = () => {
    setValue('image', '');
    message.success('Image successfully removed');
  };

  return (
    <>
      <Title variant={5}>Question Image</Title>
      <div className='img-upload-container'>
        <div className='image-wrapper'>
          <Image src={hasImage ? image : noImage} className='no-image-placeholder' />
          {hasImage ? (
            <button type='button' className='zoom-btn' onClick={openImageModal}>
              <ZoomIn height={24} width={24} />
            </button>
          ) : null}
        </div>

        <Button variant='primary' type='button' className='img-upload-btn' onClick={upload}>
          {hasImage ? 'Update image' : 'Add an image'}
        </Button>
        <Button
          type='button'
          variant='secondary'
          className='remove-img-btn ml--8 ph--40'
          onClick={removeImage}
          disabled={!hasImage}
        >
          Delete
        </Button>
      </div>

      <Modal visible={isModalOpen} onClose={() => setModalOpen(false)} className='image-modal'>
        <ImageModalWrapper>
          <Title className='modal-title'>Question Image</Title>
          <div className='modal-image-container'>
            <Image src={image || noImage} />
          </div>
        </ImageModalWrapper>
      </Modal>
    </>
  );
};

export default ImageUpload;
