const FacebookIcon = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.9178 8.54369L12.6069 10.699H10.9726V15.9097C10.6151 15.9694 10.2493 16 9.87672 16C9.50412 16 9.13835 15.9694 8.78083 15.9097V10.699H7V8.54369H8.78083V6.90097C8.78083 5.03251 9.82742 4 11.4301 4C12.1973 4 13 4.14563 13 4.14563V5.98058H12.1151C11.2438 5.98058 10.9726 6.55584 10.9726 7.14563V8.54369H12.9178Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default FacebookIcon;
