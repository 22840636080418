import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PATHS from 'constants/paths';
import SCREEN_SIZES from 'constants/screen-sizes';
import useOutsideClick from 'hooks/useOutsideClick';
import useWindowSize from 'hooks/useWindowSize';

import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';

import SignIn from 'views/Authorizations/SignIn';

const SignInButton = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const { innerWidth } = useWindowSize();
  const ref = useRef(null);
  const excludedRef = useRef(null);

  useOutsideClick({
    ref,
    excludedRef,
    cb: () => setVisible(false),
  });

  const isDropDown = pathname !== PATHS.SIGN_IN && pathname !== PATHS.SIGN_UP && innerWidth > SCREEN_SIZES.MD;

  return (
    <div className='d-flex authentication-buttons'>
      {isDropDown ? (
        <DropdownV2 visible={visible} overlay={<SignIn ref={excludedRef} isDropdown={isDropDown} />}>
          <Button variant='link' className='linkButton' ref={ref} onClick={() => setVisible((item) => !item)}>
            Sign in
          </Button>
        </DropdownV2>
      ) : (
        <Button variant='link' className='linkButton' to={PATHS.SIGN_IN}>
          Sign in
        </Button>
      )}
      <Button variant='primary' className='sign-up' to={PATHS.SIGN_UP}>
        Sign up
      </Button>
    </div>
  );
};

export default SignInButton;
