import classNames from 'classnames';
import { useState } from 'react';

import { Draggable } from 'react-beautiful-dnd';
import { DragAndDrop, Trash } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';

const SlideItem = ({
  activeFieldIndex,
  setActiveFieldIndex,
  index,
  field,
  update,
  onRemoveSlide,
  isLastItem,
  isMember,
  id,
}) => {
  const [slideValue, setSlideValue] = useState(`slide ${index + 1}`);

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!!isMember}>
      {(provided, snapshot) => {
        // TODO: remove this piece of code if the problem is resolved (https://github.com/atlassian/react-beautiful-dnd/issues/1881)
        if (snapshot.isDragging) {
          provided.draggableProps.style.left = provided.draggableProps.style.offsetLeft;
          provided.draggableProps.style.top = provided.draggableProps.style.offsetTop;
        }
        return (
          <div className='row ' {...provided.draggableProps} ref={provided.innerRef}>
            <div
              className={classNames('col-12 d-flex align-items-center slide', {
                isActiveSlide: activeFieldIndex === index,
              })}
            >
              {!isMember ? (
                <div {...provided.dragHandleProps} className='icon-wrapper'>
                  <DragAndDrop className='dndIcon' />
                </div>
              ) : null}

              <div className='slide-name-box col-11 d-flex align-items-center  justify-content-between'>
                <Input
                  readOnly={!!isMember}
                  style={{ borderStyle: 'none' }}
                  value={field.slideName || slideValue}
                  placeholder=''
                  onClick={() => setActiveFieldIndex(index)}
                  onChange={(e) => {
                    update(activeFieldIndex, { ...field, slideName: e.target.value });
                    setSlideValue(e.target.value);
                  }}
                />
                {isLastItem || isMember ? null : <Trash onClick={() => onRemoveSlide(index)} className='trash-icon' />}
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default SlideItem;
