const UnderlineIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.0938 15.7031H3.90625C3.82031 15.7031 3.75 15.7695 3.75 15.8516V17.0391C3.75 17.1211 3.82031 17.1875 3.90625 17.1875H16.0938C16.1797 17.1875 16.25 17.1211 16.25 17.0391V15.8516C16.25 15.7695 16.1797 15.7031 16.0938 15.7031ZM10 14.2188C11.3555 14.2188 12.6289 13.6895 13.5898 12.7305C14.5508 11.7715 15.0781 10.4961 15.0781 9.14062V3.04688C15.0781 2.91797 14.9727 2.8125 14.8438 2.8125H13.6719C13.543 2.8125 13.4375 2.91797 13.4375 3.04688V9.14062C13.4375 11.0352 11.8945 12.5781 10 12.5781C8.10547 12.5781 6.5625 11.0352 6.5625 9.14062V3.04688C6.5625 2.91797 6.45703 2.8125 6.32812 2.8125H5.15625C5.02734 2.8125 4.92188 2.91797 4.92188 3.04688V9.14062C4.92188 10.4961 5.45117 11.7695 6.41016 12.7305C7.36914 13.6914 8.64453 14.2188 10 14.2188Z'
      fill='#818181'
    />
  </svg>
);

export default UnderlineIcon;
