import classNames from 'classnames';
import Text from 'style-guide/Typography/Text';

const CategoriesName = ({ activeCategory, value, children, className = '' }) => (
  <Text
    $variant={5}
    className={classNames(className, {
      active: activeCategory === value,
    })}
  >
    {children}
  </Text>
);

export default CategoriesName;
