import FILE_MANAGER from 'constants/fileManager';
import moment from 'moment';

export const paginate = ({ page, limit = 24 }) => ({ limit, offset: page * limit });

export const setRedirectUrlToLocalStorage = (redirectUrl) => {
  localStorage.setItem('redirectUrl', redirectUrl);
};
export const removeRedirectUrlToLocalStorage = () => {
  localStorage.removeItem('redirectUrl');
};

export const setErrorToLocalStorage = (errorName, errorText) => {
  localStorage.setItem(errorName, errorText);
};

export const getErrorFromLocalStorage = (errorName) => localStorage.getItem(errorName);

export const removeErrorFromLocalStorage = (errorName) => {
  localStorage.removeItem(errorName);
};

export const removeArrayItem = (arr, value) => arr.filter((e) => e !== value);

// return html markup depending on uploaded file type
export const getWrapper = ({ selectedFile }) => {
  const { type, fullPath, name } = selectedFile;

  switch (type) {
    case 'image':
      return `<img src='${fullPath}' alt=${name} width='100%'/>`;
    case 'video':
      return `<video controls > <source src='${fullPath}' /> </video >`;
    case 'audio':
      return `<audio controls > <source src='${fullPath}' /> </audio >`;
    case 'pdf':
      return `<a href='${fullPath}' target="_blank">${name}</a>`;
    default:
      return `<a href='${fullPath}' download>${name}</a>`;
  }
};

export const priceToNumberFloat = (price) => {
  if (!price) return 0;
  const formattedPrice = price.toString().replace('$', '').replace(/,/g, '');
  const formattedPriceFloat = parseFloat(formattedPrice);
  return parseFloat(formattedPriceFloat.toFixed(2));
};

export const getSelectedFileManager = (value) => {
  const selectedValue = FILE_MANAGER.find((e) => e.value === value);
  return selectedValue || {};
};

export const getAnswerClassName = (userAnswer, isCorrectAnswer, answer) => {
  if (userAnswer?.includes(answer.id)) {
    if (isCorrectAnswer) return 'success';
    return 'has-error';
  }
};

export function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getTimeDifference = (date) => moment(date)?.fromNow(true);
