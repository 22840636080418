export const loadState = (item = 'state') => {
  try {
    const serializedState = localStorage.getItem(item);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return {};
  }
};

export const saveLocalStorage = (data) => {
  try {
    const state = loadState();
    const serializedState = JSON.stringify({
      ...state,
      ...data,
    });
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // ignore errors
  }
};

export const getAccessToken = () => {
  const data = loadState();
  if (document.location.pathname.startsWith('/admin/')) {
    return data?.adminUser?.accessToken || '';
  }
  return data?.user?.accessToken || '';
};

export const getDomainTracking = () => {
  const data = loadState()?.domainTracking;
  return data || {};
};
