import { ENTITY_STATUS } from 'common';
import styled, { css } from 'styled-components';
import tag from './Tag';

const handleColorType = ({ $status, theme }) => {
  switch ($status) {
    case ENTITY_STATUS.EXPIRED.value:
      return `background-color: ${theme.colors.error[600]}`;
    case ENTITY_STATUS.COMPLETED.value:
      return `background-color: ${theme.colors.yellow[400]}; color: ${theme.colors.dark[100]}`;
    case ENTITY_STATUS.PASSED.value:
      return `background-color: ${theme.colors.primary[800]}`;
    case ENTITY_STATUS.DRAFT.value:
      return `background-color: ${theme.colors.secondary[600]}`;
    default:
      return 'transparent';
  }
};

const getMemberStatus = ({ $pending, $joined, theme }) => {
  if ($pending) {
    return `color: ${theme.colors.secondary[800]}; background-color: ${theme.colors.secondary[50]}`;
  }
  if ($joined) {
    return `color: ${theme.colors.primary[800]}; background-color: ${theme.colors.primary[50]}`;
  }

  return 'color: transparent; background-color: transparent';
};

const Tag = styled(tag)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  line-height: 16px;
  .close-icon {
    padding-left: 10px;
    line-height: 0;
    cursor: pointer;
  }

  ${(props) =>
    props.$status &&
    css`
      display: inline-block;
      border-radius: 48px;
      padding: 7px 15px;
      color: ${props.theme.colors.white[100]};
      .close-icon {
        display: none;
      }
      ${handleColorType(props)};
    `}

  ${(props) =>
    props.$keyword &&
    css`
      display: inline-flex;
      align-items: center;
      border-radius: 2px;
      padding: 4px;
      background-color: ${props.theme.colors.secondary[50]};
      color: ${props.theme.colors.dark[80]};
      path {
        fill: ${props.theme.colors.dark[25]};
      }
      &:hover {
        background-color: ${props.theme.colors.secondary[200]};
      }
    `}

      ${(props) =>
    props.$blank &&
    css`
      box-sizing: border-box;
      border-radius: 2px;
      padding: 3px 24px;
      background-color: ${props.theme.colors.primary[200]};
      color: ${props.theme.colors.primary[800]};
      border: 1px solid ${props.theme.colors.primary[200]};
      path {
        fill: ${props.theme.colors.primary[800]};
      }
      &.closable {
        display: inline-flex;
        align-items: center;
        .close-icon {
          display: none;
        }
        &:hover {
          padding-right: 0;
          .close-icon {
            display: inline-block;
            padding-left: 6px;
            padding-right: 4px;
          }
        }
      }
      ${props.$blank === 'option' &&
      css`
        border-color: ${props.theme.colors.primary[800]};
      `}
    `} 

    ${({ $pending, theme }) =>
    $pending &&
    css`
      color: ${theme.colors.secondary[600]};
      background-color: ${theme.colors.secondary[50]};
    `}

    ${({ $pending, $joined, theme }) =>
    ($pending || $joined) &&
    css`
      box-sizing: border-box;
      height: 20px;
      font-size: 12px;
      line-height: 130%;
      border-radius: 2px;
      padding: 2px 8px;
      ${getMemberStatus({ $pending, $joined, theme })}
    `}
`;

export default Tag;
