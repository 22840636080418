import { Controller } from 'react-hook-form';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';

const RadioButtons = ({ control }) => (
  <div className='d-flex flex-row'>
    <Controller
      control={control}
      name='accessTypeId'
      render={({ field }) => (
        <Radio
          {...field}
          checked={field.value === '1'}
          value='1'
          onChange={(e) => field.onChange(e.target.value)}
          className='first-radio-button'
        >
          <Text $variant={2}>Full</Text>
        </Radio>
      )}
    />
    <Controller
      control={control}
      name='accessTypeId'
      render={({ field }) => (
        <Radio {...field} checked={field.value === '2'} value='2' onChange={(e) => field.onChange(e.target.value)}>
          <Text $variant={2}>Limited</Text>
        </Radio>
      )}
    />
  </div>
);

export default RadioButtons;
