import { object, string } from 'yup';

const schema = object({
  facebook: string()
    .nullable()
    .matches(/^https:\/\/www\.facebook\.com\/[a-z0-9-_.]+\/?$/, {
      message: 'Invalid facebook format',
      excludeEmptyString: true,
    }),
  instagram: string()
    .nullable()
    .matches(/^https:\/\/www\.instagram\.com\/[a-z0-9-_.]+\/?$/, {
      message: 'Invalid instagram format',
      excludeEmptyString: true,
    }),
  linkedin: string()
    .nullable()
    .matches(/^https:\/\/www\.linkedin\.com\/in\/[a-z0-9-_.]+\/?$/, {
      message: 'Invalid linkedin format',
      excludeEmptyString: true,
    }),
  pinterest: string()
    .nullable()
    .matches(/^https:\/\/www\.pinterest\.com\/[a-z0-9-_.]+\/?$/, {
      message: 'Invalid pinterest format',
      excludeEmptyString: true,
    }),
  reddit: string()
    .nullable()
    .matches(
      /^(?:https?:\/\/)?(?:www\.)?reddit\.com\/(?:user\/([a-zA-Z0-9_-]+)|r\/[a-zA-Z0-9_-]+\/comments\/[a-zA-Z0-9]+\/[a-zA-Z0-9_-]+\/?)$/,
      { message: 'Invalid reddit format', excludeEmptyString: true }
    ),
  snapchat: string()
    .nullable()
    .matches(/^https?:\/\/?(?:www\.)?(?:snapchat\.com\/add\/)?([a-zA-Z0-9_]{1,30})$/, {
      message: 'Invalid snapchat format',
      excludeEmptyString: true,
    }),
  twitter: string()
    .nullable()
    .matches(/^https?:\/\/?(?:www\.)?(?:x\.com\/)?([a-zA-Z0-9_]{1,30})$/, {
      message: 'Invalid twitter format',
      excludeEmptyString: true,
    }),
  vimeo: string()
    .nullable()
    .matches(/^https?:\/\/?(?:www\.)?(?:vimeo\.com\/)?[a-z0-9-_.]+\/?$/, {
      message: 'Invalid vimeo format',
      excludeEmptyString: true,
    }),
  wechat: string()
    .nullable()
    .matches(/^(?:https?:\/\/)?(?:www\.)?wechat\.com\/(?:[a-zA-Z0-9_-]+\/?)$/, {
      message: 'Invalid wechat format',
      excludeEmptyString: true,
    }),
  youtube: string()
    .nullable()
    .matches(/^(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([a-zA-Z0-9_-]+)(?:\/.*)?$/, {
      message: 'Invalid youtube format',
      excludeEmptyString: true,
    }),
});

export default schema;
