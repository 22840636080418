import { Input } from 'style-guide/Input';
import Select from 'style-guide/Select';

const ScaledResponse = ({ answer }) => {
  const { list } = answer;

  return (
    <div className='scaled-response-wrapper'>
      {list?.map((field, index) => (
        <div key={field.id} className='d-flex align-items-center item-box'>
          <Select className='item-select' placeholder={index + 1} value={String(index + 1)} />
          <Input className='item-input' placeholder='Response text goes here' value={field.answer} />
        </div>
      ))}
    </div>
  );
};

export default ScaledResponse;
