import { Filter as FilterIcon } from 'style-guide/Icons';
import ToggleFilterWrapper from '../style/ToggleFilterWrapper';

const ToggleFilter = ({ setIsOpenFilterSection }) => (
  <ToggleFilterWrapper onClick={() => setIsOpenFilterSection((item) => !item)} className='filter-icon'>
    <FilterIcon />
    <span className='filter'>Filter</span>
  </ToggleFilterWrapper>
);

export default ToggleFilter;
