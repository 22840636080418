import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Text from 'style-guide/Typography/Text';

const ResponseMatching = ({ answer, questionId, type, setValue, index: fieldIndex, answeredTest }) => {
  const { option1, option2 } = answer || {};
  const [matchAnswers, setMatchAnswers] = useState(option2 || []);

  const answers = [...matchAnswers];
  const idsDataArray = answers.map((el) => el.id);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const [reorderedAnswer] = answers.splice(result.source.index, 1);
    answers.splice(result.destination.index, 0, reorderedAnswer);

    setMatchAnswers(answers);
  };

  useEffect(() => {
    setMatchAnswers(option2);
  }, [option2]);

  useEffect(() => {
    setValue(`answers.${fieldIndex}`, { type, questionId, answer: idsDataArray });
  }, [fieldIndex, idsDataArray, questionId, setValue, type]);

  return (
    <div className='row'>
      <div className='col-6 response-matching-wrapper'>
        {option1?.map((el) => (
          <div key={el.id} className='d-flex flex-row response-matching-container inputs-wrapper'>
            <Text $variant={2} $dark={80} className='text'>
              {el.value}
            </Text>
          </div>
        ))}
      </div>
      <div className='col-6 response-matching-wrapper'>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='matching-answers'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {matchAnswers.map((el, index) => (
                  <Draggable key={el.id} draggableId={el.id.toString()} index={index} isDragDisabled={!!answeredTest}>
                    {
                      // eslint-disable-next-line no-shadow
                      (provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='d-flex flex-row inputs-wrapper'
                        >
                          <div className='response-matching-container'>
                            <Text $variant={2} $dark={80} className='text'>
                              {el.value}
                            </Text>
                          </div>
                        </div>
                      )
                    }
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ResponseMatching;
