import styled from 'styled-components';

const NotificationMenuWrapper = styled.div`
  .rc-menu {
    border-radius: 8px 8px 0 0;
  }

  .empty-state-box {
    background-color: ${({ theme }) => theme.colors.white[100]};
    margin-top: 8px;
    border-radius: 8px 8px 0 0;
    width: 360px;
    height: 240px;
  }

  .see-more {
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: 0 0 8px 8px;
  }
`;

export default NotificationMenuWrapper;
