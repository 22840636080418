import { useController } from 'react-hook-form';
import { getAnswerClassName } from 'utils/helperFunctions';
import Text from 'style-guide/Typography/Text';
import Radio from 'style-guide/Radio';
import classNames from 'classnames';

const TrueFalseAnswer = ({ answer, index, type, questionId, control, answeredTest }) => {
  const {
    field: { onChange },
  } = useController({ control, name: `answers.${index}` });

  const userAnswer = answeredTest?.userAnswers?.[questionId]?.[0];
  const isCorrectAnswer = answeredTest?.correctAnswers[questionId]?.correct;

  return (
    <div className='true-false-wrapper'>
      <div
        className={classNames(
          'radio-button-box',
          getAnswerClassName(answeredTest?.userAnswers[questionId], isCorrectAnswer, answer[0]),
          {
            answeredField: answeredTest,
          }
        )}
      >
        <Radio
          value='true'
          name={!answeredTest ? `answers.${index}` : null}
          onChange={() => onChange({ type, questionId, answer: [answer[0].id] })}
          checked={answeredTest && userAnswer === answer[0].id}
        >
          <Text $variant={2}>True</Text>
        </Radio>
      </div>
      <div
        className={classNames(
          'radio-button-box',
          getAnswerClassName(answeredTest?.userAnswers[questionId], isCorrectAnswer, answer[1]),
          {
            answeredField: answeredTest,
          }
        )}
      >
        <Radio
          value='false'
          name={!answeredTest ? `answers.${index}` : null}
          onChange={() => onChange({ type, questionId, answer: [answer[1].id] })}
          checked={answeredTest && userAnswer === answer[1].id}
        >
          <Text $variant={2}>False</Text>
        </Radio>
      </div>
    </div>
  );
};

export default TrueFalseAnswer;
