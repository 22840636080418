const Map = (props) => (
  <svg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.96927 14.0809L6 14.9995L7.03073 14.0809C10.3221 11.1477 12 8.54211 12 6.10311C12 2.64043 9.34263 0 6 0C2.65737 0 0 2.64043 0 6.10311C0 8.54211 1.67789 11.1477 4.96927 14.0809ZM6 2C8.24092 2 10 3.74787 10 6.10311C10 7.77275 8.702 9.84689 6 12.3171C3.298 9.84689 2 7.77275 2 6.10311C2 3.74787 3.75908 2 6 2ZM6 3C7.65685 3 9 4.34315 9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3ZM5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Map;
