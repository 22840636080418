import { getDomainTracking } from 'configs/localStorage';
import theme from 'style-guide/Theme';

const closeSvg = (id) => `
  <svg data-index="${id}" width="14" height="14" viewBox="0 0 14 14" fill="none" style="display: none" class='blank-close-icon' >
    <path data-index="${id}" class='icon' fill-rule="evenodd" clip-rule="evenodd" d="M7 8.23743L3.24372 11.9937L2.00629 10.7563L5.76257 7L2.00629 3.24372L3.24372 2.00628L7 5.76256L10.7563 2.00628L11.9937 3.24372L8.23744 7L11.9937 10.7563L10.7563 11.9937L7 8.23743Z" fill="#558B2F"/></svg>
`;

export const extended_valid_elements =
  'p[*],svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*]';

const { key } = getDomainTracking();
const { colors } = theme(key);

export const addBlankInContent = (editor, blankNumber, isMultiple = true) => {
  if (editor) {
    const blankStyle = `
      height: 24px;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 3px 24px;
      background-color: ${colors.primary[200]};
      color: ${colors.primary[800]};
      border: 1px solid ${colors.primary[200]};
      margin-left: 4px;
    `;

    const blank = `<span class="blank" id="${blankNumber}_bl" contenteditable='false' style="${blankStyle}">Blank ${
      isMultiple ? blankNumber : ''
    } ${closeSvg(blankNumber)}</span>`;

    editor.insertContent(blank);
  }
};

const filteredCorrectAnswers = ({ deletedBlankIndex, multiple, reset, editor }) => {
  if ((deletedBlankIndex || deletedBlankIndex === 0) && multiple) {
    reset((formValues) => ({
      ...formValues,
      question: editor.getContent(),
      answer: {
        ...formValues.answer,
        correctAnswers: formValues.answer.correctAnswers
          .filter((_el, index) => index !== deletedBlankIndex)
          .map((el, index) => (el.name !== `${index + 1}_bl` ? { ...el, name: `${index + 1}_bl` } : el)),
      },
    }));
  }
};

const getCorrectAnswers = ({ hasDeletedBlank, multiple, reset }) => {
  if (hasDeletedBlank() && multiple) {
    reset((formValues) => {
      const correctAnswers = formValues.answer.correctAnswers?.slice(0, -1);
      return {
        ...formValues,
        answer: {
          ...formValues.answer,
          correctAnswers,
        },
      };
    });
  }
};

const useQuestionHeight = ({ multiple, setScrollHeight, document }) => {
  const MULTIPLE_BLANK_HEIGHT = 148;

  if (multiple && document.body.scrollHeight > MULTIPLE_BLANK_HEIGHT) {
    setScrollHeight(document.body.scrollHeight);
  }

  if (!multiple) {
    setScrollHeight(document.body.scrollHeight);
  }
};

export const handleEditorChange = ({ editor, onChange, blankLength, setBlankLength, setHeight, multiple, reset }) => {
  const document = editor.dom.doc;
  const docNodes = document.querySelectorAll('p > [id*="bl"]');
  let deletedBlankIndex = false;
  const hasDeletedBlank = () => !deletedBlankIndex && deletedBlankIndex !== 0 && blankLength > docNodes.length;

  useQuestionHeight({ multiple, setScrollHeight: setHeight, document });

  if (blankLength !== docNodes.length) {
    docNodes.forEach((node, i) => {
      const number = i + 1;
      const blankIndex = +node.id.substr(0, 1);
      if (number !== blankIndex) {
        if (hasDeletedBlank()) {
          deletedBlankIndex = i;
        }
        // eslint-disable-next-line no-param-reassign
        node.id = `${number}_bl`;
        // eslint-disable-next-line no-param-reassign
        node.innerHTML = `Blank ${number} ${closeSvg(number)}`;
      }
    });

    filteredCorrectAnswers({ deletedBlankIndex, multiple, reset, editor });
  }

  getCorrectAnswers({ hasDeletedBlank, multiple, reset });

  const content = editor.getContent();
  onChange?.(content);
  setBlankLength(() => docNodes.length);
};

export default { handleEditorChange, closeSvg };
