import Skeleton from 'style-guide/Skeleton';
import AccessCodeSkeletonWrapper from './AccessCodeSkeletonWrapper';

const AccessCodeSkeleton = () => (
  <AccessCodeSkeletonWrapper className='row'>
    <Skeleton skeleton={{ height: 20 }} />
    <Skeleton skeleton={{ height: 20 }} />
    <Skeleton skeleton={{ height: 20 }} />
    <Skeleton skeleton={{ height: 20 }} />
    <Skeleton skeleton={{ height: 20 }} />
  </AccessCodeSkeletonWrapper>
);

export default AccessCodeSkeleton;
