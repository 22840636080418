import classNames from 'classnames';
import useKeyPress from 'hooks/useKeyPress';
import { useState } from 'react';
import { Close, Search as SearchIcon } from 'style-guide/Icons';

const Search = ({ searchValue, setSearchValue, cb }) => {
  const [isFocused, setIsFocused] = useState(false);

  useKeyPress({
    keyFn: { Enter: { fn: () => cb(searchValue), NumpadEnter: { fn: () => cb(searchValue) } } },
    event: 'keydown',
    condition: isFocused,
  });

  const changeValue = (event) => {
    const { value } = event.target;
    if (!value) {
      cb(value);
    }

    setSearchValue(value);
  };

  return (
    <div className='search-box'>
      <div className='search-icon icon'>
        <SearchIcon width={21} height={20} />
      </div>

      <div className='search-input-wrapper'>
        <input
          onChange={changeValue}
          className={classNames({ 'search-input': !searchValue })}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={searchValue}
          placeholder='Choose from uploaded files'
          type='text'
        />
        {searchValue ? (
          <div className='clear-icon icon'>
            <Close
              width={16}
              height={16}
              onClick={() => {
                setSearchValue('');
                cb('');
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Search;
