const DropdownRight = (props) => (
  <svg
    data-testid='DropdownRight'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M5.5 14L12 7.5L5.5 0.999999L5.5 14Z' fill='#333333' />
  </svg>
);

export default DropdownRight;
