const createDocLink = (attrs) => {
  const a = document.createElement('a');

  a.href = attrs.href;
  a.innerHTML = attrs.name;
  a.target = attrs.target || '_self';
  a.download = true;
  return a;
};

export default createDocLink;
