const Linkedin = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10 0C4.478 0 0 4.478 0 10C0 15.522 4.478 20 10 20C15.522 20 20 15.522 20 10C20 4.478 15.522 0 10 0ZM7.09412 15.1172H4.65866V7.79007H7.09412V15.1172ZM5.87646 6.78955H5.8606C5.04333 6.78955 4.51477 6.22696 4.51477 5.52383C4.51477 4.80484 5.05951 4.25781 5.89264 4.25781C6.72577 4.25781 7.23846 4.80484 7.25433 5.52383C7.25433 6.22696 6.72577 6.78955 5.87646 6.78955ZM15.8759 15.1172H13.4407V11.1974C13.4407 10.2122 13.0881 9.54041 12.2069 9.54041C11.5341 9.54041 11.1334 9.99359 10.9573 10.4311C10.8929 10.5876 10.8772 10.8064 10.8772 11.0254V15.1172H8.44193C8.44193 15.1172 8.47382 8.47748 8.44193 7.79007H10.8772V8.82751C11.2009 8.32825 11.7799 7.6181 13.0721 7.6181C14.6744 7.6181 15.8759 8.66531 15.8759 10.9158V15.1172Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Linkedin;
