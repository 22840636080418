import styled from 'styled-components';

const FillBlankWrapper = styled.div`
  .label {
    margin-bottom: 16px;
  }
  .field-input {
    flex: 1;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .trash-icon-wrapper {
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .has-error {
    border: 1px solid ${({ theme }) => theme.colors.error[600]};
    background-color: ${({ theme }) => theme.colors.error[200]};
  }
  .success {
    border: 1px solid ${({ theme }) => theme.colors.primary[600]};
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`;
export default FillBlankWrapper;
