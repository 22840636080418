import classNames from 'classnames';
import CheckBox from 'style-guide/CheckBox';
import Text from 'style-guide/Typography/Text';

const MultipleAnswer = ({ answer, index }) => (
  <div className='multiple-answer-wrapper'>
    {answer?.map((el) =>
      el?.answer ? (
        <div key={el.id} className={classNames('d-flex align-items-center checkbox-wrapper', { success: el?.isTrue })}>
          <CheckBox className='checkbox' name={`answer-${index}`} checked={el?.isTrue} />
          <Text className='multiple-answer-input' $dark={80} $variant={2}>
            {el?.answer}
          </Text>
        </div>
      ) : null
    )}
  </div>
);

export default MultipleAnswer;
