import { ENTITY_TYPES } from 'common';

const DASHBOARD_OPTIONS = [
  {
    name: ENTITY_TYPES.LEARNING_COMMUNITY.plural,
    value: ENTITY_TYPES.LEARNING_COMMUNITY.key,
    shortName: ENTITY_TYPES.LEARNING_COMMUNITY.short,
  },
  {
    name: ENTITY_TYPES.DEPARTMENT.plural,
    value: ENTITY_TYPES.DEPARTMENT.key,
    shortName: ENTITY_TYPES.DEPARTMENT.short,
  },
  {
    name: ENTITY_TYPES.ORGANIZATION.plural,
    value: ENTITY_TYPES.ORGANIZATION.key,
    shortName: ENTITY_TYPES.ORGANIZATION.short,
  },
];

const DASHBOARD_CATEGORIES = [
  ...DASHBOARD_OPTIONS,
  {
    name: 'Learning Community Invitations',
    shortName: 'Invited LC',
    value: 'invited',
  },
  {
    name: ENTITY_TYPES.MEMBER.plural,
    value: ENTITY_TYPES.MEMBER.key,
    shortName: ENTITY_TYPES.MEMBER.short,
  },
  {
    name: ENTITY_TYPES.SUBMISSIONS.plural,
    value: ENTITY_TYPES.SUBMISSIONS.key,
  },
  {
    name: 'Invitations',
    value: 'invitations',
  },
];

const ORGANIZER_DASHBOARD_CATEGORIES = [
  ...DASHBOARD_OPTIONS,
  {
    name: ENTITY_TYPES.SUBMISSIONS.plural,
    value: ENTITY_TYPES.SUBMISSIONS.key,
  },
  {
    name: 'Invite Members',
    value: 'invitations',
  },
];

const MEMBERS_DASHBOARD_CATEGORIES = [
  ...DASHBOARD_OPTIONS,
  {
    name: 'Learning Community Invitations',
    shortName: 'Invited LC',
    value: 'invited',
  },
];

export { DASHBOARD_CATEGORIES, MEMBERS_DASHBOARD_CATEGORIES, ORGANIZER_DASHBOARD_CATEGORIES };
export default DASHBOARD_OPTIONS;
