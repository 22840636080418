/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentChooseCourse: { courses: [], libraries: [], organizations: [] },
  chooseCoursesWithCountMembers: {},
  selectedKeys: [],
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,

  reducers: {
    resetInvitation(state) {
      state.chooseCoursesWithCountMembers = initialState.chooseCoursesWithCountMembers;
      state.currentChooseCourse = initialState.currentChooseCourse;
      state.selectedKeys = initialState.selectedKeys;
      state.currentChooseCourseId = null;
    },

    updatePayForInvitation() {},

    updateChooseCoursesWithCountMembers(state, action) {
      state.chooseCoursesWithCountMembers = action.payload;
    },

    updateCurrentChooseCourseId(state, action) {
      state.currentChooseCourseId = action.payload;
    },

    updateCurrentChooseCourseData(state, action) {
      state.currentChooseCourse = action.payload;
    },

    updateSelectedKeysData(state, action) {
      state.selectedKeys = action.payload;
    },
  },
});

export const {
  getCurrentChooseCourse,
  getChooseCoursesWithCountMembers,
  updateChooseCoursesWithCountMembers,
  resetInvitation,
  getCoursesWithParentsData,
  getCoursesWithParents,
  updateCurrentChooseCourseId,
  updateCurrentChooseCourseData,
  updateSelectedKeysData,
  getSelectedKeys,
  updateSelectedKeys,
} = invitationsSlice.actions;
export default invitationsSlice.reducer;
