const Email = (props) => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0H14C15.1046 0 16 0.89543 16 2V10C16 11.1046 15.1046 12 14 12H2C0.89543 12 0 11.1046 0 10V2C0 0.89543 0.89543 0 2 0ZM2 5V10H14V4.89443L8 8L2 5ZM2 3L8.00002 5.98803L14 3V2H2V3Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Email;
