const Twitter = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.2776 6.49683C14.7611 6.43943 15.222 6.31091 15.6506 6.12089C15.3302 6.60022 14.9251 7.02109 14.4577 7.358C14.4623 7.46032 14.4646 7.56353 14.4646 7.66709C14.4646 10.826 12.0603 14.4682 7.66373 14.4682C6.31397 14.4682 5.05725 14.0726 4 13.3942C4.18664 13.4164 4.37737 13.4277 4.56989 13.4277C5.69006 13.4277 6.72057 13.0457 7.53878 12.4045C6.49258 12.385 5.61003 11.6939 5.30592 10.7445C5.45173 10.7723 5.60129 10.7871 5.75566 10.7871C5.97331 10.7871 6.18491 10.7581 6.38527 10.7032C5.29201 10.4837 4.46775 9.51777 4.46775 8.35963C4.46775 8.34964 4.46775 8.33948 4.46793 8.32932C4.79004 8.50883 5.15885 8.61632 5.55049 8.62862C4.90947 8.20008 4.48718 7.46816 4.48718 6.63908C4.48718 6.2011 4.60519 5.79022 4.81089 5.43727C5.98989 6.88383 7.75126 7.83537 9.73776 7.93502C9.69676 7.75997 9.67555 7.57761 9.67555 7.39026C9.67555 6.07044 10.746 5 12.0658 5C12.7537 5 13.3749 5.2902 13.811 5.75457C14.3552 5.64743 14.867 5.44868 15.3288 5.17469C15.15 5.73317 14.7712 6.2011 14.2776 6.49683Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Twitter;
