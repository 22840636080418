import { TEST_TYPES } from 'common';
import { object, array, number, mixed, string } from 'yup';

const { TestsTypeCloseExercise, TestsTypeScoredEssay, TestsTypeFreeText } = TEST_TYPES.types;

const schema = object({
  answers: array()
    .of(
      object({
        type: number(),
        answer: mixed().when('type', {
          is: (value) =>
            value === TestsTypeCloseExercise || value === TestsTypeScoredEssay || value === TestsTypeFreeText,
          then: string().required('*Field is required').max(500, 'Must be maximum 500 symbols'),
          otherwise: mixed().required(),
        }),
      })
    )
    .required(),
});

export default schema;
