import { infiniteScrollMiddleware, transformErrorResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const departmentsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    departments: build.mutation({
      query: (params) => ({
        url: '/libraries',
        method: 'GET',
        params,
      }),
      transformErrorResponse,
      transformResponse: (response) => response,
    }),

    getDepartments: build.query({
      query: (params) => ({
        url: '/libraries',
        method: 'GET',
        params,
      }),
      providesTags: ['collections'],
      transformErrorResponse,
      ...infiniteScrollMiddleware,
    }),

    getDepartment: build.query({
      query: (params) => ({
        url: `/libraries/${params.urlName}`,
        method: 'GET',
        params,
      }),
      providesTags: ['department'],
      transformErrorResponse,
    }),

    createDepartment: build.mutation({
      query: (params) => ({
        url: '/libraries',
        method: 'POST',
        body: params,
      }),
      transformErrorResponse,
    }),

    updateDepartment: build.mutation({
      query: (params) => ({
        url: `/libraries/${params.id}`,
        method: 'put',
        body: params,
      }),
      transformErrorResponse,
    }),
    removeDepartment: build.mutation({
      query: (params) => ({
        url: `/libraries/${params.id}`,
        method: 'DELETE',
        body: params,
        params: {
          cascade: false,
          id: params.id,
        },
      }),
      transformErrorResponse,
    }),
    getDepartmentAuthor: build.query({
      query: (params) => ({
        url: `libraries/${params.urlName}/members`,
        method: 'GET',
        params,
      }),
      transformResponse: (result) => result.rows[0],
    }),
    getDepartmentLearningCommunities: build.query({
      query: (params) => ({
        url: `/libraries/${params.urlName}/courses`,
        method: 'GET',
        params,
      }),
      providesTags: ['joined-learning-communities'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDepartmentsMutation,
  useLazyGetDepartmentsQuery,
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  useGetDepartmentAuthorQuery,
  useCreateDepartmentMutation,
  useRemoveDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetDepartmentLearningCommunitiesQuery,
} = departmentsApi;
export default departmentsApi;
