const Check = (props) => (
  <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.24595 6.625C4.0092 6.625 3.77244 6.54323 3.59171 6.37871L1.0836 4.09575C0.722132 3.76673 0.722132 3.23375 1.0836 2.90569C1.44507 2.57667 2.02956 2.5757 2.39103 2.90473L4.24595 4.59314L8.60897 0.621768C8.97044 0.292744 9.55492 0.292744 9.9164 0.621768C10.2779 0.950791 10.2779 1.48377 9.9164 1.81279L4.90019 6.37871C4.71945 6.54323 4.4827 6.625 4.24595 6.625Z'
      fill='white'
    />
  </svg>
);

export default Check;
