/* eslint-disable no-unused-vars */
import { API_ROOT } from 'configs/env-vars';
import { getAccessToken, getDomainTracking, loadState, saveLocalStorage } from 'configs/localStorage';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import PATHS from 'constants/paths';
import { message } from 'style-guide/ToastMessages';
import {
  removeRedirectUrlToLocalStorage,
  setErrorToLocalStorage,
  setRedirectUrlToLocalStorage,
} from 'utils/helperFunctions';

const customFetchBase = async (args, api, extraOptions) => {
  const { id } = getDomainTracking();
  const getApiRoot = () => `${API_ROOT}api/v${id || 1}`;
  const result = await fetchBaseQuery({
    baseUrl: getApiRoot(),
    keepUnusedDataFor: process.env.NODE_ENV === 'test' ? 0 : 60,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  })(args, api, extraOptions);

  const { pathname, search } = window.location;

  if (result.error?.status === 401) {
    setErrorToLocalStorage('signInError', 'Looks like your password or email do not match. Please try again.');
    if (pathname === PATHS.SIGN_IN) {
      message.error('Incorrect email and/or password');
    }
    if (pathname !== PATHS.SIGN_IN) {
      const url = `${pathname}${search}`;
      saveLocalStorage({ user: null, adminUser: null });
      setRedirectUrlToLocalStorage(url);
      window.location.pathname = PATHS.SIGN_IN;
      return;
    }
  }
  if (result.error?.status === 404) {
    window.location.pathname = PATHS.NOT_FOUND;
    return;
  }

  if (pathname === PATHS.SIGN_IN && result?.error?.status === 201) {
    const { redirectUrl = null } = loadState('redirectUrl');
    if (redirectUrl) {
      removeRedirectUrlToLocalStorage();
      window.location.pathname = redirectUrl;
      return;
    }
  }

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
const emptySplitApi = createApi({
  baseQuery: customFetchBase,
  tagTypes: [
    'learning-community',
    'department',
    'organization',
    'getFiles',
    'volume',
    'organizations-members',
    'learning-community-members',
    'department-members',
    'reviews',
    'members',
    'comments',
    'enroll-user',
    'admin-departments',
    'submissions-comment',
    'admin-organizations',
    'admin-courses',
    'isArchive',
    'getPopularCourses',
    'volumes',
    'static-pages',
    'admin-user',
    'notifications',
  ],
  endpoints: () => ({}),
  overrideExisting: false,
});

export default emptySplitApi;
