const BUTTON_TYPES = {
  REJOIN: 'Rejoin',
  START: 'Start',
  CONTINUE: 'Continue',
  VISIT: 'Visit',
};

export const handleButtonText = (status, progressStatus, progressValue, EntityTypeUrl) => {
  if (status === 'expired') return { buttonText: BUTTON_TYPES.REJOIN, url: EntityTypeUrl };
  if (progressValue === '0.00') return { buttonText: BUTTON_TYPES.START, url: EntityTypeUrl };
  if (progressValue !== '0.00' && !progressStatus) return { buttonText: BUTTON_TYPES.CONTINUE, url: EntityTypeUrl };
  return { buttonText: BUTTON_TYPES.VISIT, url: EntityTypeUrl };
};
