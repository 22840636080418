import GetCustomersLogo from 'Components/GetCustomersLogo';
import classNames from 'classnames';
import PATHS from 'constants/paths';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import RightSection from 'style-guide/Header/RightSection';
import { getHeaderMode } from 'utils/getCustomersMode';
import CreationFlowNavigationButtons from './CreationFlowNavigationButtons';
import EntitiesButtons from './EntitesButtons';
import RightBox from './RightBox';
import HeaderWrapper from './style/HeaderWrapper';

const { HOME } = PATHS;

const Header = () => {
  const isLimitedUser = useIsLimitedUser();
  const { innerWidth } = useWindowSize();
  const { pathname } = useLocation();
  const [isOpenRightSection, setIsOpenRightSection] = useState(false);

  const { data } = useGetDomainTracking();
  const mode = getHeaderMode(data.key);

  const entityCreateCase = pathname.includes('create');

  return (
    <>
      <HeaderWrapper
        className={classNames(
          { 'dark-mode': mode === 'dark' },
          'd-flex align-items-center justify-content-between ph--32'
        )}
      >
        <div className='d-flex justify-content-start align-items-center'>
          {isLimitedUser ? (
            <GetCustomersLogo />
          ) : (
            <Link to={HOME}>
              <GetCustomersLogo />
            </Link>
          )}
          {SCREEN_SIZES.XL < innerWidth && <EntitiesButtons />}
        </div>

        {/* ToDo remove CreationFlowNavigationButtons component after adding secondary header */}

        {entityCreateCase ? (
          <CreationFlowNavigationButtons />
        ) : (
          <RightBox isOpenRightSection={isOpenRightSection} setIsOpenRightSection={setIsOpenRightSection} />
        )}
      </HeaderWrapper>
      <RightSection isOpen={isOpenRightSection} close={() => setIsOpenRightSection(false)} />
    </>
  );
};

export default Header;
