import { GOOGLE_CLIENT_ID } from 'configs/env-vars';

const googleAuthentication = (request, googleUserInfo) => {
  const googleScript = document.createElement('script');
  googleScript.src = 'https://accounts.google.com/gsi/client';
  googleScript.async = true;
  googleScript.defer = true;
  googleScript.onload = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      callback: (response) => {
        const token = response?.access_token;
        token && googleUserInfo({ token, request });
      },
    });
    client.requestAccessToken();
    googleScript.remove();
  };

  document.head.appendChild(googleScript);
};

export default googleAuthentication;
