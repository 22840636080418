import moment from 'moment';
import Button from 'style-guide/Button';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

export const getEmailTrackingColumns = (setUserData) => [
  {
    title: <Title variant={5}>Email</Title>,
    dataIndex: 'email',
    key: 'email',
    width: 200,
  },
  {
    title: <Title variant={5}>URL</Title>,
    dataIndex: 'url',
    key: 'url',
    width: 400,
    render: (_index, item) => (
      <Button className='p--0' variant='link' onClick={() => setUserData(item)}>
        {item?.url}
      </Button>
    ),
  },
  {
    title: <Title variant={5}>Text</Title>,
    dataIndex: 'text',
    key: 'text',
    width: 300,
    render: (item) => (
      <Tooltip placement='top' overlay={item} overlayInnerStyle={{ maxWidth: '300px' }}>
        <Title variant={5} className='email-tracking-text'>
          {item}
        </Title>
      </Tooltip>
    ),
  },

  {
    title: <Title variant={5}>Subject</Title>,
    dataIndex: 'subject',
    key: 'subject',
    width: 300,
  },
  {
    title: <Title variant={5}>status</Title>,
    dataIndex: 'status',
    key: 'status',
    width: 200,
  },
  {
    title: <Title variant={5}>updated</Title>,
    dataIndex: 'updated',
    key: 'updated',
    width: 200,
    render: (_index, item) => <Text $variant={2}>{moment(item?.updated).fromNow()}</Text>,
  },
];
