import styled from 'styled-components';

const ScaledResponseWrapper = styled.div`
  .label {
    margin-bottom: 12px;
  }

  .options-box {
    margin-bottom: 16px;
    .trash-icon-wrapper {
      margin-left: 8px;
      margin-bottom: 4px;
    }
  }

  .item-box {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    .item-select {
      margin-right: 16px;
      width: 76px;
    }

    .item-input {
      flex: 1;
    }
  }
  .secondary-input-box {
    .item-input {
      margin: 4px 0;
    }
  }
`;

export default ScaledResponseWrapper;
