const Help = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13 18H11V16H13V18Z' className='fill' fill='#558B2F' />
    <path
      className='fill'
      d='M8 10C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 12.5 13 12.75 13 15H11C11 11.75 14 12 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10H8Z'
      fill='#558B2F'
    />
    <path
      className='stroke'
      d='M3 12C3 7.03228 7.03228 3 12 3C16.9677 3 21 7.03228 21 12C21 16.9677 16.9677 21 12 21C7.03228 21 3 16.9677 3 12Z'
      stroke='#558B2F'
      strokeWidth='2'
    />
  </svg>
);

export default Help;
