/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useMembersMutation } from 'store/services/profile';
import { changeUserData } from 'store/slices/profile';
import { loadState, saveLocalStorage } from 'configs/localStorage';

import { message } from 'style-guide/ToastMessages';
import Form from 'style-guide/Form';
import FormItem from 'style-guide/FormItem';
import Button from 'style-guide/Button';
import EditBioWrapper from './style/EditBioWrapper';
import schema from './schema';

const EditBio = ({ hideModal, params }) => {
  const dispatch = useDispatch();
  const { user: storageUser } = loadState();
  const { bio } = params;
  const { user } = params;
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { bio },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const formBio = watch('bio');
  const disabled = bio === formBio || !isValid;

  const [updateBio, { data, isLoading }] = useMembersMutation();

  const onFormSubmit = (formData) => {
    const { email, firstName, lastName, profileImage, userName, id } = user;
    updateBio({
      type: 'personalInfo',
      email,
      firstName,
      lastName,
      profileImage,
      userName,
      id,
      ...formData,
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(changeUserData({ userData: { ...user, bio: data.bio } }));
      saveLocalStorage({
        user: { ...storageUser, bio: data.bio },
      });
      hideModal();
      message.success('You have successfully updated the profile!');
    }
  }, [isLoading, data]);

  return (
    <EditBioWrapper>
      <div className='edit-bio-modal-root'>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <FormItem name='bio' error={errors?.bio}>
            <textarea className='textarea' name='bio' placeholder='Write a bio' {...register('bio')} />
          </FormItem>
          <div className='d-flex justify-content-center pt--16'>
            <Button variant='secondary' className='ph--40 mr--16' onClick={hideModal}>
              Cancel
            </Button>
            <Button variant='primary' loading={isLoading} className='ph--48' type='submit' disabled={disabled}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    </EditBioWrapper>
  );
};

export default EditBio;
