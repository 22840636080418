import styled from 'styled-components';

const SelectDropdownWrapper = styled.div`
  width: 300px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.secondary[50]};

  .content {
    padding: 8px 12px;
    cursor: pointer;
    .value {
      color: ${({ theme }) => theme.colors.secondary[400]};
    }
    .down-icon {
      path {
        fill: ${({ theme }) => theme.colors.dark[60]};
      }
    }
  }
`;

export default SelectDropdownWrapper;
