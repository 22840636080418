import PATHS from 'constants/paths';
import { lazy } from 'react';

const LearningCommunitiesHomepage = lazy(() => import('views/LearningCommunity/Homepage'));
const OrganizationHomePage = lazy(() => import('views/Organization'));
const DepartmentHomepage = lazy(() => import('views/Department'));

const entitiesHomepageRoutes = [
  {
    element: <LearningCommunitiesHomepage />,
    path: PATHS.LEARNING_COMMUNITY_HOMEPAGE,
  },
  {
    element: <OrganizationHomePage />,
    path: PATHS.ORGANIZATION_HOMEPAGE,
  },
  { element: <DepartmentHomepage />, path: PATHS.DEPARTMENT_HOMEPAGE },
];

export default entitiesHomepageRoutes;
