import { useController } from 'react-hook-form';

import CheckBox from 'style-guide/CheckBox';
import Text from 'style-guide/Typography/Text';

import classNames from 'classnames';
import { getAnswerClassName } from 'utils/helperFunctions';

const MultipleAnswer = ({ answer, index, control, type, questionId, answeredTest }) => {
  const {
    field: { onChange, value },
  } = useController({ control, name: `answers.${index}` });

  const userAnswer = answeredTest?.userAnswers[questionId];
  const isCorrectAnswer = answeredTest?.correctAnswers[questionId]?.correct;
  const { answer: answerValue = [] } = value || {};

  const setAnswer = (e, el) => {
    const answerData = e.target.checked ? [...answerValue, el.id] : answerValue.filter((elem) => elem !== el.id);
    onChange(
      answerData.length
        ? {
            type,
            questionId,
            answer: answerData,
          }
        : {}
    );
  };

  const valueOfAnswer = answeredTest?.userAnswers ? userAnswer : answerValue;

  return (
    <div className='multiple-answer-wrapper'>
      {answer?.map((el, ind) => (
        <div
          key={el.id}
          className={classNames(
            'd-flex align-items-center checkbox-wrapper',
            getAnswerClassName(userAnswer, isCorrectAnswer, el)
          )}
        >
          <CheckBox
            className='checkbox'
            name={`answers.${index}.answer.${ind}`}
            onChange={(e) => setAnswer(e, el)}
            checked={valueOfAnswer?.some?.((e) => e === el.id)}
          >
            <Text className='multiple-answer-input' $dark={80} $variant={2}>
              {el?.answer}
            </Text>
          </CheckBox>
        </div>
      ))}
    </div>
  );
};

export default MultipleAnswer;
