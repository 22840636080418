import { ENTITY_TYPES } from 'common';
import { saveLocalStorage } from 'configs/localStorage';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyGetEmailTrackingQuery } from 'store/services/emailTracking';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import { useLazyGetAdminDataQuery } from 'store/services/superAdmin/AdminUsers';
import { changeUserData } from 'store/slices/profile';
import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';
import { COURSES_CONST } from '../OrganizationsTable/columns';
import { getEmailTrackingColumns } from './column';
import SuperAdminEmailTrackingWrapper from './style/SuperAdminEmailTrackingWrapper';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION } = ENTITY_TYPES;

const handleEntityType = (entity) => {
  switch (entity) {
    case LEARNING_COMMUNITY.key:
      return COURSES_CONST.COURSES;
    case DEPARTMENT.key:
      return COURSES_CONST.LIBRARIES;
    case ORGANIZATION.key:
      return COURSES_CONST.ORGANIZATIONS;
    default:
  }
};

const SuperAdminEmailTracking = () => {
  const [page, setPage] = useState(1);
  const [trigger] = useLazyGetAdminDataQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: domainData } = useGetDomainTracking();

  const [getEmailTracking, { data: emailsTracking }] = useLazyGetEmailTrackingQuery();

  const setUserData = async (item) => {
    const entityTypeId = item?.url?.split('/')[1];
    const entityType = item?.url?.split('/')[0];

    await trigger({ id: entityTypeId, table: handleEntityType(entityType) }).then((data) => {
      saveLocalStorage({
        user: data?.data,
      });

      dispatch(changeUserData({ isAuth: true, userData: data?.data }));
      navigate(`/${item.url}`);
    });
  };

  useEffect(() => {
    getEmailTracking({ page, domainId: domainData?.id });
  }, [getEmailTracking, domainData, page]);

  return (
    <SuperAdminEmailTrackingWrapper className='row'>
      <div className='col-12'>
        <Table
          columns={getEmailTrackingColumns(setUserData)}
          scroll={{ x: 1200 }}
          data={emailsTracking?.rows}
          rowKey={(row) => row.id}
        />
        <Pagination total={emailsTracking?.count} current={page} onChange={setPage} className='pagination' />
      </div>
    </SuperAdminEmailTrackingWrapper>
  );
};

export default SuperAdminEmailTracking;
