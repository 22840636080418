import classNames from 'classnames';
import CheckBox from 'style-guide/CheckBox';
import Select from 'style-guide/Select';

import FILE_MANAGER from 'constants/fileManager';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import { changeValue } from './helper';

const CustomOption = ({ children, selectedFiles, ...props }) => {
  const { selectOption, label, data: value } = props;
  const isChecked = !!selectedFiles.filter((el) => el.label === label).length;

  return (
    <div className='custom-option-box' onClick={() => selectOption(value)}>
      <CheckBox checked={isChecked}>
        <Text $variant={1} color={theme().colors.dark[80]} className='title'>
          {children}
        </Text>
      </CheckBox>
    </div>
  );
};

const SelectFile = ({ selectedFiles, setSelectedFiles, isDisabled = false }) => {
  const getCustomOptions = (props) => <CustomOption {...props} selectedFiles={selectedFiles} />;
  const hasSelectValue = !!selectedFiles.length;

  return (
    <Select
      className={classNames('select-box', { 'd-none': isDisabled }, { 'has-select-value': hasSelectValue })}
      value=''
      placeholder='Select a file format'
      isSearchable={false}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
      options={FILE_MANAGER}
      onChange={(value) => changeValue(value, setSelectedFiles, selectedFiles)}
      components={{
        Option: getCustomOptions,
      }}
    />
  );
};

export default SelectFile;
