import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Input } from 'style-guide/Input';

const FillBlank = ({ index, setValue, type, questionId, answeredTest, control }) => {
  const {
    fieldState: { error },
    field: { onChange, value = answeredTest?.userAnswers?.[questionId] || '' },
  } = useController({ name: `answers.${index}.answer`, control });
  useEffect(() => {
    setValue(`answers.${index}`, { type, questionId, answer: value });
  }, [index, questionId, setValue, type, value]);
  return (
    <Input onChange={onChange} error={error} value={value} placeholder='Type your answer' disabled={answeredTest} />
  );
};

export default FillBlank;
