import MODAL_TYPES from 'constants/modals';
import { showModal } from 'store/slices/modal';
import { message } from 'style-guide/ToastMessages';
import Text from 'style-guide/Typography/Text';

export const deleteEntityModal = (remove, id, dispatch) => {
  dispatch(
    showModal({
      type: MODAL_TYPES.CONFIRMATION,
      params: {
        modal: {
          title: 'Confirmation',
        },
        body: {
          title: <Text $variant={2}>Are you sure you want to delete?</Text>,
          onOk: () => {
            remove({ id, cascade: false }).then(() => {
              message.success('Successfully deleted');
            });
          },
        },
      },
    })
  );

  return null;
};
