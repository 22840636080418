import CREATE_NEW_ENTITIES from 'constants/DropdownOptions/createNewEntities';
import { useNavigate } from 'react-router-dom';

import { useCreateDepartmentMutation } from 'store/services/departments';
import { useCreateLearningCommunitiesMutation } from 'store/services/learningCommunities';
import { useCreateOrganizationMutation } from 'store/services/organizations';

import { useCallback } from 'react';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Plus } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';
import { createNewEntity, setEntityURL } from './helper';
import CreateNewButtonWrapper from './style/CreateNewButtonWrapper';
import DropdownWrapper from './style/DropdownWrapper';

const DropdownBody = ({
  navigateToEntityUpdatePage,
  createDepartment,
  createLearningCommunity,
  createOrganization,
}) => (
  <DropdownWrapper>
    {CREATE_NEW_ENTITIES.map(({ name, value }) => (
      <li
        className='pv--12 pr--32 pl--12 mb--8'
        onClick={() =>
          createNewEntity(
            createLearningCommunity,
            createDepartment,
            createOrganization,
            value,
            navigateToEntityUpdatePage
          )
        }
        key={value}
      >
        <Plus width={16} height={16} />
        <Text $variant={3} className='ph--12  dropdown-item-text'>
          {name}
        </Text>
      </li>
    ))}
  </DropdownWrapper>
);
const CreateNewButton = () => {
  const [createLearningCommunity] = useCreateLearningCommunitiesMutation();
  const [createDepartment] = useCreateDepartmentMutation();
  const [createOrganization] = useCreateOrganizationMutation();

  const navigate = useNavigate();

  const navigateToEntityUpdatePage = useCallback(
    (value, id, entityURLName) => {
      navigate(setEntityURL(value, id, entityURLName));
    },
    [navigate]
  );

  return (
    <CreateNewButtonWrapper>
      <DropdownV2
        placement='topRight'
        overlay={
          <DropdownBody
            createNewEntity={createNewEntity}
            createLearningCommunity={createLearningCommunity}
            createDepartment={createDepartment}
            createOrganization={createOrganization}
            navigateToEntityUpdatePage={navigateToEntityUpdatePage}
          />
        }
      >
        <Button prefix={<Plus width={16} height={16} />} variant='primary'>
          Create new
        </Button>
      </DropdownV2>
    </CreateNewButtonWrapper>
  );
};
export default CreateNewButton;
