import PATHS from 'constants/paths';
import { lazy } from 'react';

const StaticPages = lazy(() => import('views/StaticPages'));

const staticPageRoutes = [
  {
    path: PATHS.ABOUT,
    element: <StaticPages staticPageKey={PATHS.ABOUT} />,
  },
  {
    path: PATHS.SERVICES,
    element: <StaticPages staticPageKey={PATHS.SERVICES} />,
  },
  {
    path: PATHS.REFOUND_POLICY,
    element: <StaticPages staticPageKey={PATHS.REFOUND_POLICY} />,
  },
];

export default staticPageRoutes;
