/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import camera from 'assets/images/camera.svg';
import { loadState, saveLocalStorage } from 'configs/localStorage';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserProfileImageMutation } from 'store/services/amazon';
import { useMembersMutation } from 'store/services/profile';
import { changeUserData } from 'store/slices/profile';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import Image from 'style-guide/Image';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import UserAvatar from 'style-guide/UserAvatar';
import ErrorMessageView from '../ErrorMessageView';
import schema from './schema';
import EditProfileWrapper from './style/EditProfileWrapper';

const handleUpload = ({ event, setProfilePicture }) => {
  const [file] = event.target.files;
  if (event.target.files[0].type.split('/')[0] !== 'image') {
    return;
  }
  if (file) {
    setProfilePicture({
      src: URL.createObjectURL(file),
      file: event.target.files[0],
    });
  }
};

const EditProfile = ({ hideModal, params }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);

  const [updateProfile, { data, isLoading, isError, error }] = useMembersMutation();

  const [
    addImageToAmazon,
    { data: amazonData, isLoading: amazonIsLoading, isError: amazonIsError, error: amazonError },
  ] = useUserProfileImageMutation();
  const { user: storageUser } = loadState();
  const { user } = params;
  const profileName = params.firstName || params.lastName || params.userName;
  const defaultValues = {
    firstName: params?.firstName,
    lastName: params?.lastName,
    userName: params?.userName,
  };
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const onFormSubmit = (values) => {
    const { firstName, lastName, userName } = values;

    if (profilePicture) {
      const formData = new FormData();
      formData.append('file', profilePicture.file);

      addImageToAmazon({ folder: 'profilePictures/users', data: formData });
    } else {
      updateProfile({
        firstName,
        lastName,
        userName,
        id: user.id,
        type: 'personalInfo',
      });
    }
  };

  const onClose = () => {
    profilePicture && setProfilePicture(null);
    reset();
    hideModal();
  };

  useEffect(() => {
    if (amazonIsError) {
      const errorMessage = amazonError?.data?.message;
      message.error(<ErrorMessageView errorMessage={errorMessage} />);
    }

    if (!amazonIsLoading && amazonData) {
      const { profileImage } = amazonData;
      const firstName = watch('firstName');
      const lastName = watch('lastName');
      const userName = watch('userName');
      updateProfile({
        type: 'personalInfo',
        firstName,
        lastName,
        userName,
        profileImage,
        id: user.id,
      });
    }
  }, [amazonData, amazonIsLoading, amazonIsError, amazonError]);

  useEffect(() => {
    if (isError) {
      const errorMessage = error?.data?.message;
      message.error(<ErrorMessageView errorMessage={errorMessage} />);
    }

    if (!isLoading && data) {
      dispatch(
        changeUserData({
          userData: {
            ...user,
            profileImage: data.profileImage,
            firstName: data.firstName,
            lastName: data.lastName,
            userName: data.userName,
          },
        })
      );
      saveLocalStorage({
        user: {
          ...storageUser,
          profileImage: data.profileImage,
          firstName: data.firstName,
          lastName: data.lastName,
          userName: data.userName,
        },
      });
      onClose();
      message.success('You have successfully updated the profile!');
      navigate(`/user/${data.userName}`);
    }
  }, [isLoading, data, isError]);

  const hesError = Object.keys(errors).length === 0;
  const disabled = !(isDirty || profilePicture) || !hesError;

  return (
    <EditProfileWrapper>
      <div className='edit-profile-modal-root'>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <div className='avatar-box'>
            <UserAvatar
              fromAws={!profilePicture}
              className='avatar'
              picture={profilePicture ? profilePicture.src : params.profileImage}
              userName={profileName}
            />
            <Input
              id='file'
              name='file'
              style={{ display: 'none' }}
              type='file'
              onChange={(event) =>
                handleUpload({
                  event,
                  setProfilePicture,
                })
              }
              accept='image/*'
            />
            <label htmlFor='file'>
              <Image className='camera' src={camera} />
            </label>
          </div>
          <Input
            type='text'
            name='firstName'
            error={errors?.firstName}
            label='First Name'
            placeholder='Write here'
            {...register('firstName')}
          />
          <Input
            type='text'
            error={errors?.lastName}
            name='lastName'
            label='Last Name'
            placeholder='Write here'
            {...register('lastName')}
          />
          <Input
            required
            type='text'
            error={errors?.userName}
            label='Username'
            placeholder='Write here'
            {...register('userName')}
          />
          <div className='d-flex justify-content-center pt--16'>
            <Button variant='secondary' className='ph--40 mr--16' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              loading={amazonIsLoading || isLoading}
              className='ph--48'
              type='submit'
              disabled={disabled}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </EditProfileWrapper>
  );
};

export default EditProfile;
