const VERSION_TYPES = {
  VersionTypeMainContent: {
    key: 'VersionTypeMainContent',
    type: 1,
    url: 'story',
    name: 'Story',
  },
  VersionTypeInitialThoughts: {
    key: 'VersionTypeInitialThoughts',
    type: 2,
    url: 'initial-thoughts',
    name: 'Initial Thoughts',
  },
  VersionTypeExpertResources: {
    key: 'VersionTypeExpertResources',
    type: 3,
    url: 'resources',
    name: 'Resources',
  },
  VersionTypeNewInsights: {
    key: 'VersionTypeNewInsights',
    type: 4,
    url: 'new-insights',
    name: 'New Insights',
  },
  VersionTypeTest: {
    key: 'VersionTypeTest',
    type: 5,
    url: 'assess',
    name: 'Assess',
  },
  VersionTypeVolume: {
    key: 'VersionTypeVolume',
    type: 6,
    url: 'volume',
    name: 'Volume',
  },
  VersionTypeChapter: {
    key: 'VersionTypeChapter',
    type: 7,
    url: 'chapter',
    name: 'Chapter',
  },
};

const version_types = {
  ...VERSION_TYPES,
  hasType: (type) => Object.keys(VERSION_TYPES).includes(type),
  getTypeById: (id) => Object.values(VERSION_TYPES).filter((t) => t.type === id)[0],
};

export default version_types;
