import styled from 'styled-components';
import image from './Image';

const Image = styled(image)`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: ${({ $rounded }) => $rounded};
  object-fit: cover;
`;

export default Image;
