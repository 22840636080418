import React from 'react';

const SideBar = React.forwardRef(({ className, children, showSidebar = true }, ref) =>
  showSidebar ? (
    <aside className={className} ref={ref}>
      <div className='side-bar-content p--16'>{children}</div>
    </aside>
  ) : null
);

export default SideBar;
