const createShortCodeElement = (tagName, attributes) => {
  switch (tagName) {
    case 'video':
      return `<video id='${attributes.id}' controls><source src=${attributes.src} /></video>`;
    case 'audio':
      return `<audio id='${attributes.id}' controls><source src=${attributes.src} /></audio>`;
    default:
      break;
  }
};

export default createShortCodeElement;
