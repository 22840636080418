import emptySplitApi from '..';

const favoriteApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    addFavoriteCard: build.mutation({
      query: ({ type, ...params }) => ({
        url: `/user-favorite-${type}`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) => response,
    }),

    removeFavoriteCard: build.mutation({
      query: (params) => ({
        url: `/user-favorite-${params.type}/${params.favoriteId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
    }),

    overrideExisting: false,
  }),
});

export const { useAddFavoriteCardMutation, useRemoveFavoriteCardMutation } = favoriteApi;
export default favoriteApi;
