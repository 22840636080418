const BookMark = (props) => (
  <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 16L5 13L10 16V2C10 0.89543 9.10457 0 8 0H2C0.89543 0 0 0.89543 0 2V16ZM8 12.4676L5 10.6676L2 12.4676V2H8V12.4676Z'
      fill='#333333'
    />
  </svg>
);

export default BookMark;
