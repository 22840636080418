import { TEST_TYPES } from 'common';
import FillBlank from './FillBlank';
import MultipleAnswer from './MultipleAnswer';
import MultipleBlanks from './MultipleBlanks';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';
import ResponseMatching from './ResponseMatching';
import ScaledResponse from './ScaledResponse';
import ShortEssay from './ShortEssay';
import ShortEssayScored from './ShortEssayScored';
import TrueFalseAnswer from './TrueFalseAnswer';

const COMPONENTS = {
  [TEST_TYPES.types.TestsTypeTrueFalse]: TrueFalseAnswer,
  [TEST_TYPES.types.TestsTypeMultipleChoice]: MultipleChoiceAnswer,
  [TEST_TYPES.types.TestsTypeMultipleResponse]: MultipleAnswer,
  [TEST_TYPES.types.TestsTypeFreeText]: ShortEssay,
  [TEST_TYPES.types.TestsTypeResponseMatching]: ResponseMatching,
  [TEST_TYPES.types.TestsScaledResponseScored]: ScaledResponse,
  [TEST_TYPES.types.TestsTypeCloseExercise]: FillBlank,
  [TEST_TYPES.types.TestsTypeScoredEssay]: ShortEssayScored,
  [TEST_TYPES.types.TestsTypeDragAndDrop]: MultipleBlanks,
};

const CardAnswer = ({ type, answer, index }) => {
  const Card = COMPONENTS[type];

  return <Card answer={answer} index={index} />;
};

export default CardAnswer;
