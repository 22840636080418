import ReactSelect from 'react-select';
import styled from 'styled-components';

const KeywordsSelect = styled(ReactSelect)`
  .select__control {
    border-radius: 8px;
    font-size: 16px;
    padding: 0px 12px;
    min-height: 36px;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white[100]};
    outline: 1px solid ${({ theme }) => theme.colors.status[20]};
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary[200]};
    }
    .select__input-container {
      margin: 0;
      padding: 0;
      min-height: 32px;
      input {
        padding-left: 4px !important;
        ::placeholder {
          color: ${({ theme }) => theme.colors.disabled[60]};
        }
      }
    }
    .select__value-container {
      padding: 0 !important;
      margin-bottom: 4px;
      margin-top: 4px;
      &.select__value-container--has-value {
        .select__input-container {
          margin-bottom: 0px;
        }
        .select__multi-value {
          box-sizing: border-box;
        }
      }
      .select__placeholder {
        color: ${({ theme }) => theme.colors.disabled[60]};
      }
      .select__multi-value {
        margin: 0;
        background-color: ${({ theme }) => theme.colors.secondary[50]};
        color: ${({ theme }) => theme.colors.dark[80]};
        margin-right: 4px;
        path {
          fill: ${({ theme }) => theme.colors.dark[25]};
        }
        .select__multi-value__label {
          font-size: 12px;
          font-weight: 400px;
          padding-top: 4px;
          padding-bottom: 4px;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
        }
        &:hover {
          background-color: yellow;
          .select__multi-value__label,
          .select__multi-value__remove {
            background-color: ${({ theme }) => theme.colors.secondary[200]};
          }
        }
      }
      .select__multi-value__remove {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    &.select__control--is-focused {
      border: 1px solid ${({ theme }) => theme.colors.primary[600]} !important;
      outline: none;
      box-shadow: none;
      .select__value-container {
        .select__placeholder {
          display: none;
        }
      }
    }
  }
`;

export default KeywordsSelect;
