import styled from 'styled-components';
import Dropdown from './Dropdown';

import 'rc-dropdown/assets/index.css';

const DropdownV2 = styled(Dropdown)`
  cursor: pointer;
`;

export default DropdownV2;
