const userOrganizationTypes = {
  administrator: { name: 'Administrator', level: 1 },
  coAdministrator: { name: 'Co-Administrator', level: 2 },
  member: { name: 'Member', level: 3 },
};

const getUpperLevel = (level) => Object.values(userOrganizationTypes).find((type) => type.level === level - 1);

const getLowerLevel = (level) => Object.values(userOrganizationTypes).find((type) => type.level === level + 1);

const getTypeFromId = (id) => Object.values(userOrganizationTypes).find((type) => type.level === id);

const getManagerLevels = () =>
  Object.values(userOrganizationTypes)
    .filter((t) => t.level <= userOrganizationTypes.coAdministrator.level)
    .map((t) => t.level);

const getStudentLevels = () =>
  Object.values(userOrganizationTypes)
    .filter((t) => t.level > userOrganizationTypes.coAdministrator.level)
    .map((t) => t.level);

const isAdmin = (level) => level === userOrganizationTypes.administrator.level;

const isManager = (level) => {
  const managerLevels = getManagerLevels();
  return managerLevels.includes(level);
};

const isStudent = (level) => {
  const studentLevels = getStudentLevels();
  return studentLevels.includes(level);
};

const getMembersTypeList = () => Object.values(userOrganizationTypes);

const USER_ORGANIZATION_TYPES = {
  ...userOrganizationTypes,
  getMembersTypeList,
  getUpperLevel,
  getLowerLevel,
  getTypeFromId,
  getManagerLevels,
  getStudentLevels,
  isAdmin,
  isManager,
  isStudent,
};

export default USER_ORGANIZATION_TYPES;
