import { Node } from '@tiptap/core';
import createDocLink from './helper/createDocLink';

const DocExtension = Node.create({
  name: 'document', // unique name for the Node
  group: 'inline', // belongs to the 'block' group of extensions
  inline: true,
  selectable: false, // so we can select the video
  draggable: false, // so we can drag the video
  atom: false, // is a single unit
  content: 'inline*',
  isolating: true,

  addAttributes() {
    return {
      ...this?.parent?.(),
      href: {
        default: null,
        parseHTML: (element) => element.src,
      },
      name: {
        default: null,
        parseHTML: (element) => element.name,
      },
    };
  },

  addNodeView() {
    return ({ node }) => {
      const a = createDocLink(node.attrs);

      return {
        dom: a,
      };
    };
  },

  renderHTML({ HTMLAttributes }) {
    const a = createDocLink(HTMLAttributes);
    return a;
  },
});

export default DocExtension;
