import styled from 'styled-components';

const NoDataWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* position: absolute; */
  /* top: 50%; */
  /* -webkit-transform: translateY(-50%); */
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  .main {
    margin-bottom: 8px;
  }
  .text {
    margin-bottom: 16px;
  }
`;

export default NoDataWrapper;
