import { ENTITY_TYPES } from 'common';
import { Helmet } from 'react-helmet-async';
import useGetDomainTracking from 'store/services/helperHooks/useGetDomainTracking';
import domainFavicon from './App/documentTitle';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION } = ENTITY_TYPES;

const HelmetComponent = ({ title, description = '', keywords = '' }) => {
  const { data } = useGetDomainTracking();

  const faviconUrl = domainFavicon[data?.key];

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta
        name='keywords'
        content={`learning, study, ${LEARNING_COMMUNITY.lowercase}, ${ORGANIZATION.lowercase}, ${DEPARTMENT.lowercase}, ${keywords}`}
      />
      <link rel='icon' href={faviconUrl.link || ''} />
    </Helmet>
  );
};

export default HelmetComponent;
