const CircleArrowLeft = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM12 7H7.41421L8.70711 5.70711L7.29289 4.29289L3.58579 8L7.29289 11.7071L8.70711 10.2929L7.41421 9H12V7Z'
      fill='#333333'
    />
  </svg>
);

export default CircleArrowLeft;
