import { createSlice } from '@reduxjs/toolkit';

const initialState = { scroll: true };

const scrollToTop = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    preventScroll(state, action) {
      const { payload } = action;
      state.scroll = payload;
    },
  },
});

export const { preventScroll } = scrollToTop.actions;
export default scrollToTop.reducer;
