import customers from './customers';

const DEFAULT_COLORS = {
  secondary: {
    50: '#EDEFF1',
    200: '#B3BEC4',
    400: '#7D8F9B',
    600: '#596D79',
  },
  tertiary: { 400: '#00B3CC', 800: '#008699' },
  error: { 200: '#EF9A9A', 400: '#EF5350', 600: '#E53935' },
  dark: {
    15: 'rgba(51, 51, 51, 0.15)',
    25: 'rgba(51, 51, 51, 0.38)',
    60: 'rgba(51, 51, 51, 0.6)',
    80: 'rgba(51, 51, 51, 0.8)',
    100: 'rgb(51, 51, 51)',
  },
  white: {
    15: 'rgba(255, 255, 255, 0.15)',
    25: 'rgba(255, 255, 255, 0.38)',
    60: 'rgba(255, 255, 255, 0.6)',
    80: 'rgba(255, 255, 255, 0.8)',
    100: 'rgb(255, 255, 255)',
  },
  boxShadow: {
    10: 'rgba(0, 0, 0, 0.1)',
    15: 'rgba(0, 0, 0, 0.15)',
    25: 'rgba(0, 0, 0, 0.25)',
    30: 'rgba(0, 0, 0, 0.3)',
    50: 'rgba(0, 0, 0, 0.5)',
  },
  background: { 15: '#fbfbfb', 25: '#00000040', 60: 'rgba(0, 0, 0, 0.04)', 100: '#F6F7F8' },
  darkBackground: { 100: '#021a13' },
  greenBackground: { 15: '#e9f5ee', 25: '#2b866b', 35: 'rgba(43, 134, 107, 0.3)' },
  success: { 15: '#52C41A', 25: '#52A180', 60: '#2B866B' },
  disabled: {
    15: 'rgba(0, 0, 0, 0.04)',
    25: 'rgba(228, 228, 228)',
    35: 'rgba(194, 194, 194, 0.1)',
    45: 'rgba(194, 194, 194, 0.3)',
    60: 'rgba(194, 194, 194)',
  },
  yellow: { 200: '#ffd666', 400: '#E8AF00', 600: '#B28600' },
  danger: { 800: '#D9363E', 600: '#FF4D4F', 400: '#FF7875', 200: '#FFCCC7', 100: '#ffa39e', 50: '#FFF2F0' },
  status: { 20: '#D9D9D9', 40: '#FFCCC7', 60: '#BAE0FF', 80: '#D9F7BE', 100: '#FFF1B8' },
  gray: {
    10: 'rgba(99, 99, 99, 1)',
    20: 'rgba(121, 121, 121, 1)',
    40: 'rgba(129, 129, 129, 1)',
    100: 'rgba(167, 166, 166, 1)',
  },
};

const theme = (key) => {
  const domainTheme = customers[key] ? customers[key] : customers.peertree;

  return {
    ...domainTheme,
    colors: { ...DEFAULT_COLORS, ...domainTheme.colors },
  };
};

export default theme;
