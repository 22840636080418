import RcDropdown from 'rc-dropdown';
import DropdownList from './DropdownList';

const Dropdown = ({
  className,
  options,
  overlay,
  onClickItem,
  space = 0,
  trigger = 'click',
  children,
  menuProps = {},
  ...props
}) => (
  <RcDropdown
    trigger={trigger}
    {...props}
    onOverlayClick={(e) => e.stopPropagation()}
    getPopupContainer={(content) => content.parentNode}
    overlay={
      overlay || (
        <DropdownList
          className={className}
          options={options}
          onClickItem={onClickItem}
          style={{ marginTop: `${space}px` }}
          selectable={false}
          {...menuProps}
        />
      )
    }
  >
    <span className='dropdown-children'>{children}</span>
  </RcDropdown>
);

export default Dropdown;
