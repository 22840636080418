import useWindowSize from 'hooks/useWindowSize';
import { useEffect, useMemo } from 'react';
import { generatePath, useLocation, useParams, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import { ENTITY_TYPES } from 'common';
import { isEntity } from 'common/entity-types';
import { DASHBOARD_CATEGORIES } from 'constants/DropdownOptions/dashboard';
import { alphabeticalSort } from 'constants/letters';
import PATHS from 'constants/paths';
import SCREEN_SIZES from 'constants/screen-sizes';
import Button from 'style-guide/Button';
import Filter from 'views/Dashboard/AdminPanel/filter';

import EmptyCard from 'Components/Cards/EmptyCard';
import Members from 'Components/Cards/Members';
import EntityCard from 'Components/NewCards/ColDepCard';
import LearningCommunityCard from 'Components/NewCards/LearningCommunityCard';
import Title from 'style-guide/Typography/Title';
import EntitySearch from './EntitySearch';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const COMPONENTS = {
  [DASHBOARD_CATEGORIES[0].value]: LearningCommunityCard,
  [DASHBOARD_CATEGORIES[1].value]: EntityCard,
  [DASHBOARD_CATEGORIES[2].value]: EntityCard,
  [DASHBOARD_CATEGORIES[3].value]: LearningCommunityCard,
  [DASHBOARD_CATEGORIES[4].value]: Members,
};

const EntityCardList = ({
  data,
  total = 0,
  isLoading,
  category,
  scrollerRef,
  hasNextPage,
  loaderRef,
  isOrganizer,
  isHomePage,
  cardsCount,
  colSpan,
}) => {
  const Card = useMemo(() => COMPONENTS[category], [category]);
  const categoryName = DASHBOARD_CATEGORIES.find((e) => e.value === category);
  const entitiesTitle = `${categoryName.name} ${isHomePage ? '' : `(${total})`} `;
  const { pathname } = useLocation();
  const { role } = useParams();

  const { innerWidth } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams({ letter: 'all' });
  const searchLetter = searchParams.get('letter');

  const isEntitiesPath =
    pathname === generatePath(PATHS.EXPLORE, { category }) ||
    pathname === generatePath(PATHS.DASHBOARD, { role: role || '', category });

  useEffect(() => {
    if (scrollerRef) {
      scrollerRef.current = document;
    }
  }, [scrollerRef]);

  return (
    <div className='entity-card-container'>
      {isEntitiesPath ? (
        <div
          className={classNames(
            'search-box d-flex align-items-center',
            innerWidth > SCREEN_SIZES.MD ? 'justify-content-between' : 'justify-content-center'
          )}
        >
          {isOrganizer ? (
            <div className='content-header'>
              {/* //Delete commented line after confirmation create button, otherwise keep them */}
              {/* {SCREEN_SIZES.XL <= innerWidth && isEntityName(category) ? <CreateNew /> : null} */}
              {isEntity(category, LEARNING_COMMUNITY.key) ? <Filter /> : null}
            </div>
          ) : null}
          {innerWidth > SCREEN_SIZES.MD ? <EntitySearch placeholder={`Search ${categoryName.name}`} /> : null}
        </div>
      ) : (
        <Title variant={3} className={classNames({ organizer: isOrganizer }, 'category-name m--24')}>
          {entitiesTitle}
        </Title>
      )}

      {!data?.length || isLoading ? (
        <EmptyCard
          isLoading={isLoading}
          isHomePage={isHomePage}
          isOrganizer={isOrganizer}
          category={LEARNING_COMMUNITY.key}
          cardsCount={cardsCount}
          colSpan={colSpan}
        />
      ) : (
        <div className='card-box overflow'>
          {category === 'members' ? (
            <div className='d-flex letters-section justify-content-between'>
              {alphabeticalSort.map((letter) => (
                <Button
                  variant='link'
                  onClick={() => setSearchParams({ letter: letter.toLowerCase() })}
                  className={classNames(
                    'letter-button',
                    letter.toLowerCase() === searchLetter ? 'active-button' : null
                  )}
                  key={letter}
                >
                  {letter}
                </Button>
              ))}
            </div>
          ) : null}
          <div className='row cards content-box mt--16'>
            {data?.map((el) => (
              <div className={colSpan} key={el.id}>
                <Card data={el} entityType={category} />
              </div>
            ))}
            {hasNextPage ? <div ref={loaderRef} children='loaderRef' className='invisible' /> : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default EntityCardList;
