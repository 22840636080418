import { Input } from 'style-guide/Input';
import Select from 'style-guide/Select';

const ScaledResponse = ({ answer }) => {
  const { list, feedback } = answer;

  return (
    <div className='scaled-response-wrapper'>
      {list?.map((field, index) => (
        <div key={field.id} className='d-flex align-items-center item-box'>
          <Select className='item-select' placeholder={index + 1} value={String(index + 1)} isDisabled />
          <Input className='item-input' placeholder='Response text goes here' value={field.answer} disabled />
        </div>
      ))}
      {feedback?.top?.answer ? <Input className='feedback-answer' value={feedback.top.answer} disabled /> : null}
      {feedback?.bottom?.answer ? <Input className='feedback-answer' value={feedback.bottom.answer} disabled /> : null}
    </div>
  );
};

export default ScaledResponse;
