const Pencil = (props) => (
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 0C10.5288 0 10.9869 0.209494 11.3544 0.579913L13.4217 2.64718C13.7924 3.01786 14 3.47274 14 4C14 4.52385 13.794 5.06728 13.4217 5.43959L5.89021 12.9675C5.36661 13.5715 4.62438 13.9426 3.7564 14.0016H1H0V13.0016L0.00324765 10.1647C0.0669805 9.37582 0.43373 8.64229 0.981963 8.15991L8.56093 0.581008C8.93285 0.207181 9.47538 0 10 0ZM2.35157 9.61608C2.14601 9.79788 2.01885 10.0522 2 10.2452V12.0025L3.68578 12.004C3.95369 11.9851 4.20307 11.8604 4.42749 11.6054L8.80933 7.22354L6.77751 5.19172L2.35157 9.61608ZM8.19197 3.77776L10.2235 5.80933L11.9895 4.04339L9.95824 2.01212L8.19197 3.77776Z'
      fill='#333333'
    />
  </svg>
);
export default Pencil;
