import PATHS from 'constants/paths';
import { lazy } from 'react';

const LearningCommunityManager = lazy(() => import('views/CreateLearningCommunity'));
const OrganizationManager = lazy(() => import('views/CreateOrganization'));
const DepartmentManager = lazy(() => import('views/CreateDepartments'));

const entitiesCreatePageRoutes = [
  {
    element: <LearningCommunityManager />,
    path: PATHS.LEARNING_COMMUNITY_CREATE_ENTITIES,
  },
  {
    element: <DepartmentManager />,
    path: PATHS.DEPARTMENT_CREATE_ENTITIES,
  },
  {
    element: <OrganizationManager />,
    path: PATHS.ORGANIZATION_CREATE_ENTITIES,
  },
];

export default entitiesCreatePageRoutes;
