import { forwardRef } from 'react';

const Switch = forwardRef(({ className, ...switchProps }, ref) => (
  <span className={className}>
    <label className='switch'>
      <input type='checkbox' {...switchProps} ref={ref} />
      <span className='slider round' />
    </label>
  </span>
));

export default Switch;
