import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import message from './toastMessages';

const ToastMessages = styled(ToastContainer)`
  min-height: 50px;
  &.Toastify__toast-container {
    padding: 0;
    padding-top: 50px;
    right: 35px;
  }
  .Toastify__toast {
    padding: 0;
    .Toastify__toast-body {
      div {
        &:first-of-type {
          height: 100%;
        }
      }
      margin: 0;
      padding: 4px 44px 4px 5px;
    }
  }
  .Toastify__close-button {
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    svg {
      height: 20px;
      width: 20px;
      path {
        fill: ${({ theme }) => theme.colors.dark[80]};
      }
    }
  }
  .message-container {
    display: flex;
    .left-bar {
      width: 4px;
      height: 100%;
      border-radius: 5px;
      margin-right: 12px;
      &.success {
        background-color: ${({ theme }) => theme.colors.primary[600]};
      }
      &.error {
        background-color: ${({ theme }) => theme.colors.error[600]};
      }
      &.info {
        background-color: ${({ theme }) => theme.colors.tertiary[400]};
      }
    }
    .icon {
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
    .text-container {
      display: flex;
      align-items: center;
      .text {
        max-width: 220px;
        word-wrap: break-word;
        display: inline-block;
      }
    }
  }
`;

export { message };

export default ToastMessages;
