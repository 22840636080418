import PATHS from 'constants/paths';
import { lazy } from 'react';
import SuperAdminTable from 'views/SuperAdmin/SuperAdminTable';
import { IsSuperAdminRouter } from './IsSuperAdminRouter';

const SuperAdmin = lazy(() => import('views/SuperAdmin'));
const AddCompany = lazy(() => import('views/SuperAdmin/Companies/AddCompany'));

const superAdminPaths = (user) =>
  [
    {
      element: <SuperAdmin />,
      path: PATHS.ADMIN,
      children: [
        {
          path: PATHS.ADMIN_PAGE_CATEGORY,
          element: <SuperAdminTable />,
        },
      ],
    },
    { element: <AddCompany />, path: PATHS.ADD_COMPANY },
  ].map((e) => ({
    ...e,
    element: <IsSuperAdminRouter user={user}>{e.element}</IsSuperAdminRouter>,
  }));

export default superAdminPaths;
