import { UserOutlined } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';
import { MEMBERS_NUMBER, handleIconSizes, handleInitials } from './avatarHelper';

const AvatarWithoutSource = ({ type, label, number, count }) => {
  const isLastItem = count > MEMBERS_NUMBER.last && number === MEMBERS_NUMBER.first;

  const userInitials = handleInitials(label);
  const userAdditionalCount = count - number;

  return (
    <div className='d-inline-flex justify-content-center align-items-center user-avatar avatar-color'>
      {userInitials ? (
        <Text className='initials' $variant={1}>
          {isLastItem ? `+${userAdditionalCount}` : userInitials}
        </Text>
      ) : (
        <UserOutlined {...handleIconSizes[type]} />
      )}
    </div>
  );
};
export default AvatarWithoutSource;
