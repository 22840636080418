import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'style-guide/Button';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;
const { organizer } = USER_COURSE_TYPES.userCourseRole;

const CreationFlowNavigationButtons = () => {
  const { innerWidth } = useWindowSize();
  const navigate = useNavigate();
  const { urlName, id } = useParams();
  const { pathname } = useLocation();
  const entityType = pathname.split('/')[1];

  const isMobile = innerWidth <= SCREEN_SIZES.MD;
  const learningCommunityContentUrl = `/${LEARNING_COMMUNITY.key}/${id}/${urlName}/${organizer}`;
  const entityHomePage = `/${entityType}/${id}/${urlName}/${organizer}/homepage`;
  const entityUrl = entityType === LEARNING_COMMUNITY.key ? learningCommunityContentUrl : entityHomePage;

  return (
    <div className='d-flex'>
      {isMobile ? (
        <Button variant='secondary' onClick={() => navigate(entityHomePage)}>
          Preview
        </Button>
      ) : null}
      <Button
        variant='primary'
        className='finish-button justify-content-center ml--8'
        onClick={() => navigate(entityUrl)}
      >
        Next
      </Button>
    </div>
  );
};

export default CreationFlowNavigationButtons;
