import { FILE_TYPE, SYSTEM_LIMITS } from 'common';
import { message } from 'style-guide/ToastMessages';

const { FILES: LIMITS } = SYSTEM_LIMITS;
const { hasFile } = FILE_TYPE;

export const changeValue = (value, setSelectedFiles, selectedFiles) => {
  const existValue = selectedFiles.some((el) => el.label === value.label);

  if (existValue) {
    const newValue = selectedFiles.filter((el) => el.label !== value.label);
    setSelectedFiles(newValue);
  } else {
    setSelectedFiles([...selectedFiles, value]);
  }
};

export const handleUploadFile = ({ files, uploadFile, getFiles, reqParams, defaultSelectedFile, setPage }) => {
  const data = new FormData();
  const selectedFile = files[0];

  if (!selectedFile) {
    return;
  }

  const lowerCaseFileName = selectedFile.name?.toLowerCase();
  const fileType = hasFile(lowerCaseFileName.match(/\w+$/g)[0]);

  if (fileType === null || (defaultSelectedFile && defaultSelectedFile?.[0]?.value !== fileType)) {
    message.error('Wrong file type');
    return;
  }

  if (selectedFile.name.length > LIMITS.name.max) {
    message.error('File name too long');
    return;
  }

  if (selectedFile.name.length < LIMITS.name.min) {
    message.error('File name too short');
    return;
  }

  data.append('file', selectedFile);
  uploadFile({ data })
    .then((res) => {
      if (res?.error) {
        message.error(res.error?.data?.message);
      } else {
        getFiles(reqParams);
        setPage(1);
      }
    })
    .catch((err) => {
      message.error(err.message);
    });
};

export const getParams = ({ limit, offset, searchText, selectedFiles }) => {
  const params = { limit, offset };
  if (searchText) {
    params.searchText = searchText;
  }
  if (selectedFiles?.length) {
    selectedFiles.forEach((el, index) => {
      params[`type[${index}]`] = el.value;
    });
  } else {
    params.type = 'all';
  }

  return params;
};
