import emptySplitApi from '..';

const priceApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getHighestPrice: build.query({
      query: (params) => ({
        url: `courses`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export default priceApi;
export const { useGetHighestPriceQuery } = priceApi;
