import styled, { css } from 'styled-components';
import padding from './helper';

const InputWrapper = styled.div`
  .input-label {
    width: 100%;
  }

  .input-content {
    position: relative;
  }

  .help-icon {
    path {
      &.stroke {
        stroke: ${({ theme }) => theme.colors.dark[60]} !important;
      }
      &.fill {
        fill: ${({ theme }) => theme.colors.dark[60]} !important;
      }
    }
  }

  textarea {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    height: 35px;
    overflow: hidden;
    max-width: 100%;
    min-width: 320px;
    min-height: 125px;
    background: ${({ theme }) => theme.colors.white[100]};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    width: 100%;
    padding: 7px 12px;
    margin: 0;
    color: ${({ theme }) => theme.colors.dark[80]};
    &::placeholder {
      color: ${({ theme }) => theme.colors.dark[60]};
    }
    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.secondary[200]};
      padding: 6px 11px;
      ::placeholder {
        color: transparent;
      }
    }
    &:focus-visible {
      outline: none;
    }
  }

  input {
    width: 100%;
    padding: ${(props) => padding[props.size || 'medium']};
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border: 1px solid ${({ theme }) => theme.colors.status[20]};
    &::placeholder {
      color: ${({ theme }) => theme.colors.disabled[60]};
    }
  }

  ${({ inputVariant }) =>
    inputVariant === 'primary' &&
    css`
      input {
        &:not(:placeholder-shown) {
          border-color: ${({ theme }) => theme.colors.primary[600]};
        }
        &:focus {
          outline: none;
          border-color: ${({ theme }) => theme.colors.primary[600]};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.primary[200]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.disabled[15]};
          border-color: ${({ theme }) => theme.colors.disabled[25]};
        }
      }
    `}

  ${({ inputVariant }) =>
    inputVariant === 'error' &&
    css`
      input {
        border-color: ${({ theme }) => theme.colors.danger[600]};
        &:not(:placeholder-shown) {
          border-color: ${({ theme }) => theme.colors.danger[600]};
        }
        &:focus {
          outline: none;
          border-color: ${({ theme }) => theme.colors.danger[600]};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.danger[100]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.disabled[15]};
          border-color: ${({ theme }) => theme.colors.disabled[25]};
        }
        &:disabled + svg {
          path {
            stroke: ${({ theme }) => theme.colors.disabled[60]};
            fill: ${({ theme }) => theme.colors.disabled[60]};
          }
        }
      }
      .suffix-icon {
        path {
          stroke: ${({ theme }) => theme.colors.danger[600]};
          stroke-width: 0.5;
        }
      }
    `}
  ${({ inputVariant }) =>
    inputVariant === 'info' &&
    css`
      input {
        border-color: ${({ theme }) => theme.colors.yellow[400]};
        &:not(:placeholder-shown) {
          border-color: ${({ theme }) => theme.colors.yellow[400]};
        }
        &:focus {
          outline: none;
          border-color: ${({ theme }) => theme.colors.yellow[400]};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.yellow[200]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.disabled[15]};
          border-color: ${({ theme }) => theme.colors.disabled[25]};
        }
        &:disabled + svg {
          path {
            fill: ${({ theme }) => theme.colors.disabled[60]};
          }
        }
      }
      .suffix-icon {
        path {
          fill: ${({ theme }) => theme.colors.yellow[400]};
        }
      }
    `}

      ${({ inputVariant }) =>
    inputVariant === 'success' &&
    css`
      input {
        &:not(:placeholder-shown) {
          border-color: ${({ theme }) => theme.colors.disabled[25]};
        }
        &:focus {
          outline-style: none;
          border-color: ${({ theme }) => theme.colors.success[60]};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.success[25]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.disabled[15]};
          border-color: ${({ theme }) => theme.colors.disabled[25]};
        }
        &:disabled + svg {
          path {
            fill: ${({ theme }) => theme.colors.disabled[60]};
          }
        }
      }
      .suffix-icon {
        path {
          fill: ${({ theme }) => theme.colors.success[15]};
        }
      }
    `}

  .suffix-icon, .prefix-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .suffix-icon {
    right: 12px;
  }

  .prefix-icon {
    left: 12px;
  }

  .error-message {
    color: ${({ theme }) => theme.colors.danger[600]};
  }

  .required-symbol {
    color: ${({ theme }) => theme.colors.danger[600]};
  }
`;

export default InputWrapper;
