import PATHS from 'constants/paths';
import { lazy } from 'react';
import { IsNotAuthenticated } from './IsNotAuthenticatedRoute';

// Not authenticated
const SignUp = lazy(() => import('views/Authorizations/SignUp'));
const SignIn = lazy(() => import('views/Authorizations/SignIn'));
const ForgotPassword = lazy(() => import('views/Authorizations/ForgotPassword'));
const ResetPassword = lazy(() => import('views/Authorizations/ResetPassword'));
const ActivateAccount = lazy(() => import('views/Dashboard/InviteNewMembers/RegistrationInviteUser/ActivateAccount'));

const router = [
  {
    element: <ActivateAccount />,
    path: PATHS.ACTIVATE_ACCOUNT,
  },
  {
    element: <SignUp />,
    path: PATHS.SIGN_UP,
  },
  {
    element: <SignIn />,
    path: PATHS.SIGN_IN,
  },
  {
    element: <SignIn />,
    path: PATHS.VERIFY_EMAIL,
  },
  {
    element: <ForgotPassword />,
    path: PATHS.FORGOT_PASSWORD,
  },
  {
    element: <ResetPassword />,
    path: PATHS.RESET_PASSWORD,
  },
];

const notAuthenticatedRoute = (isAuth) =>
  router.map((e) => ({
    ...e,
    element: <IsNotAuthenticated isAuth={isAuth}>{e.element}</IsNotAuthenticated>,
  }));

export default notAuthenticatedRoute;
