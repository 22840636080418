import classNames from 'classnames';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';

const MultipleChoiceAnswer = ({ answer, index }) => (
  <div className='multiple-choice-wrapper'>
    {answer?.map((el) => (
      <div
        key={el.id}
        className={classNames('radio-button-box d-flex justify-content-center', { success: el?.isTrue })}
      >
        {el?.answer ? (
          <Radio name={`answer-${index}`} disabled={!el?.isTrue} checked={el?.isTrue} readOnly>
            <Text $dark={80} $variant={2}>
              {el?.answer}
            </Text>
          </Radio>
        ) : null}
      </div>
    ))}
  </div>
);

export default MultipleChoiceAnswer;
