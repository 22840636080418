const ItalicIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.5859 3.125H7.14844C7.0625 3.125 6.99219 3.19531 6.99219 3.28125V4.53125C6.99219 4.61719 7.0625 4.6875 7.14844 4.6875H10.6875L7.64063 15.3125H4.47266C4.38672 15.3125 4.31641 15.3828 4.31641 15.4688V16.7188C4.31641 16.8047 4.38672 16.875 4.47266 16.875H12.9102C12.9961 16.875 13.0664 16.8047 13.0664 16.7188V15.4688C13.0664 15.3828 12.9961 15.3125 12.9102 15.3125H9.26562L12.3125 4.6875H15.5859C15.6719 4.6875 15.7422 4.61719 15.7422 4.53125V3.28125C15.7422 3.19531 15.6719 3.125 15.5859 3.125Z'
      fill='#818181'
    />
  </svg>
);

export default ItalicIcon;
