const NewHeartFilled = (props) => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='40' height='40' rx='20' fill='white' />
    <path
      d='M28.4242 12.7766C28.9907 13.3306 29.4437 13.9898 29.7578 14.7172C30.0836 15.4742 30.25 16.2781 30.2477 17.1055C30.2477 17.886 30.0883 18.6992 29.7719 19.5266C29.5094 20.218 29.1273 20.9352 28.6422 21.6594C27.8734 22.8055 26.8164 24.0008 25.5039 25.2125C23.3266 27.2211 21.175 28.6063 21.0836 28.6649L20.5281 29.0211C20.282 29.1781 19.9656 29.1781 19.7195 29.0211L19.1641 28.6649C19.0727 28.6086 16.9187 27.2211 14.7437 25.2125C13.4312 24.0008 12.3742 22.8055 11.6055 21.6594C11.1203 20.9352 10.7406 20.218 10.4758 19.5266C10.1594 18.6992 10 17.886 10 17.1055C10 16.2781 10.1664 15.4742 10.4922 14.7172C10.8055 13.9893 11.2586 13.33 11.8258 12.7766C12.3953 12.2188 13.0586 11.7805 13.7945 11.4758C14.5562 11.1594 15.3625 11 16.1945 11C17.35 11 18.4773 11.3164 19.457 11.9141C19.6914 12.057 19.9141 12.2141 20.125 12.3852C20.3359 12.2141 20.5586 12.057 20.793 11.9141C21.7727 11.3164 22.9 11 24.0555 11C24.8791 10.9981 25.6949 11.1598 26.4555 11.4758C27.189 11.7793 27.8573 12.2209 28.4242 12.7766Z'
      fill='#FFA39E'
    />
  </svg>
);

export default NewHeartFilled;
