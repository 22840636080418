import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const ViewMembershipWrapper = styled.div`
  .view-membership-root {
    height: calc(100vh - 228px);
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
      flex-direction: column;
    }

    .menu-box {
      flex-shrink: 0;
      padding-top: 10px;
      .filter-categories {
        padding-left: 0;
        @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
          padding-left: 10px;
        }
        @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
          padding-left: 10px;
        }
      }

      @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
        margin-right: 20px;
        margin-bottom: 20px;
      }

      @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .membership-card-box {
      padding-left: 22px;
      padding-top: 10px;
      position: relative;
      overflow: auto;
      height: calc(100vh - 228px);
      width: 630px;

      @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
        min-width: 100%;
        width: 695px;
        padding-left: 0;
      }

      @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
        min-width: 100%;
        width: 270px;
      }

      .empty-state-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .cards {
      row-gap: 16px;
      .card {
        padding: 0 8px;
      }
    }
  }
`;

export default ViewMembershipWrapper;
