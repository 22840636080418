const PPT = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.5 19C6.1 19 5.75 18.85 5.45 18.55C5.15 18.25 5 17.9 5 17.5V3.5C5 3.1 5.15 2.75 5.45 2.45C5.75 2.15 6.1 2 6.5 2H20.5C20.9 2 21.25 2.15 21.55 2.45C21.85 2.75 22 3.1 22 3.5V17.5C22 17.9 21.85 18.25 21.55 18.55C21.25 18.85 20.9 19 20.5 19H6.5ZM6.5 17.5H20.5V3.5H6.5V17.5ZM3.5 22C3.1 22 2.75 21.85 2.45 21.55C2.15 21.25 2 20.9 2 20.5V5H3.5V20.5H19V22H3.5Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path
      d='M8 8H9.52253C9.77382 8 10.0005 8.03439 10.2025 8.10317C10.4094 8.17196 10.5844 8.27249 10.7273 8.40476C10.8751 8.53175 10.9884 8.69048 11.0672 8.88095C11.151 9.06614 11.1929 9.28042 11.1929 9.52381C11.1929 9.79365 11.1485 10.0291 11.0598 10.2302C10.9712 10.4259 10.848 10.5899 10.6903 10.7222C10.5326 10.8492 10.3454 10.9444 10.1286 11.0079C9.91671 11.0714 9.68513 11.1032 9.43384 11.1032H8.79083V13H8V8ZM8.79083 10.4286H9.21211C9.37964 10.4286 9.53238 10.4153 9.67035 10.3889C9.81324 10.3624 9.93642 10.3175 10.0399 10.254C10.1434 10.1852 10.2247 10.0952 10.2838 9.98413C10.3429 9.86772 10.3725 9.71958 10.3725 9.53968C10.3725 9.38095 10.3454 9.24603 10.2912 9.13492C10.237 9.02381 10.1631 8.93386 10.0695 8.86508C9.97584 8.7963 9.8699 8.74603 9.75165 8.71429C9.63339 8.68254 9.50775 8.66667 9.37471 8.66667H8.79083V10.4286Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path
      d='M11.9553 8H13.4778C13.7291 8 13.9558 8.03439 14.1578 8.10317C14.3647 8.17196 14.5397 8.27249 14.6825 8.40476C14.8304 8.53175 14.9437 8.69048 15.0225 8.88095C15.1063 9.06614 15.1482 9.28042 15.1482 9.52381C15.1482 9.79365 15.1038 10.0291 15.0151 10.2302C14.9264 10.4259 14.8033 10.5899 14.6456 10.7222C14.4879 10.8492 14.3007 10.9444 14.0839 11.0079C13.872 11.0714 13.6404 11.1032 13.3891 11.1032H12.7461V13H11.9553V8ZM12.7461 10.4286H13.1674C13.3349 10.4286 13.4877 10.4153 13.6256 10.3889C13.7685 10.3624 13.8917 10.3175 13.9952 10.254C14.0987 10.1852 14.18 10.0952 14.2391 9.98413C14.2982 9.86772 14.3278 9.71958 14.3278 9.53968C14.3278 9.38095 14.3007 9.24603 14.2465 9.13492C14.1923 9.02381 14.1184 8.93386 14.0248 8.86508C13.9311 8.7963 13.8252 8.74603 13.7069 8.71429C13.5887 8.68254 13.463 8.66667 13.33 8.66667H12.7461V10.4286Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path d='M19 8V8.69841H17.5957V13H16.8049V8.69841H15.4228V8H19Z' fill='#333333' fillOpacity='0.8' />
  </svg>
);

export default PPT;
