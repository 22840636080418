const Highlight = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.4438 11.8933L14.1375 3.70894C14.1203 3.69165 14.0998 3.67793 14.0773 3.66857C14.0548 3.65921 14.0306 3.65439 14.0063 3.65439C13.9819 3.65439 13.9577 3.65921 13.9352 3.66857C13.9127 3.67793 13.8922 3.69165 13.875 3.70894L8.28047 9.22145C8.24526 9.25638 8.22526 9.30379 8.22483 9.35338C8.22439 9.40298 8.24354 9.45074 8.27813 9.48629L8.28047 9.48863L9.21797 10.4121L6.47109 13.1144C6.43589 13.1493 6.41589 13.1968 6.41545 13.2464C6.41501 13.2959 6.43417 13.3437 6.46875 13.3793L6.47109 13.3816L7.39688 14.2933L2.96484 18.6761H1.68984C1.58672 18.6761 1.5 18.7605 1.5 18.8636V20.1574C1.5 20.2605 1.58438 20.3449 1.6875 20.3449H9.77109C9.82031 20.3449 9.86719 20.3261 9.90234 20.291L11.6859 18.5191L12.6328 19.4519C12.65 19.4692 12.6705 19.4829 12.693 19.4923C12.7155 19.5016 12.7397 19.5065 12.7641 19.5065C12.7885 19.5065 12.8126 19.5016 12.8351 19.4923C12.8577 19.4829 12.8781 19.4692 12.8953 19.4519L15.6398 16.7425L16.5797 17.6683C16.5969 17.6856 16.6173 17.6993 16.6399 17.7087C16.6624 17.7181 16.6865 17.7229 16.7109 17.7229C16.7353 17.7229 16.7595 17.7181 16.782 17.7087C16.8045 17.6993 16.825 17.6856 16.8422 17.6683L22.4367 12.1558C22.5164 12.0855 22.5164 11.9683 22.4438 11.8933ZM9.13594 18.6621H5.38125L8.53125 15.5449L10.4086 17.3941L9.13594 18.6621ZM12.7641 17.2066L8.74688 13.248L10.3547 11.6636L14.3719 15.6222L12.7641 17.2066ZM16.7133 15.423L10.5539 9.35504L14.0063 5.95426L20.1656 12.0246L16.7133 15.423Z'
      fill='#212121'
    />
  </svg>
);

export default Highlight;
