export const LEFT_INFO_ITEMS = [
  {
    name: 'bankFirstName',
    type: 'text',
    label: 'First Name',
    placeholder: 'Write here',
    required: true,
  },
  {
    name: 'bankEmail',
    type: 'text',
    label: 'Email',
    placeholder: 'Write here',
    required: true,
  },
  {
    name: 'bankCity',
    type: 'text',
    label: 'City',
    placeholder: 'Write here',
    required: true,
  },
];

export const RIGHT_INFO_ITEMS = [
  {
    name: 'bankLastName',
    type: 'text',
    label: 'Last Name',
    placeholder: 'Write here',
    required: true,
  },
  {
    name: 'bankAddress',
    type: 'text',
    label: 'Address',
    placeholder: 'Write here',
    required: true,
  },
  {
    name: 'bankZipCode',
    type: 'text',
    label: 'Postal Code',
    placeholder: 'Write here',
    required: true,
  },
];
