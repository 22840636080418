import styled, { css } from 'styled-components';
import button from './Button';
import { getHeight, getIconSize } from './getHeight';

const Button = styled(button)`
  ${(props) => getHeight(props)}
  border: none;
  letter-spacing: 0em;
  box-sizing: border-box;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled-link {
    background-color: inherit;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.boxShadow[25]};
    path {
      fill: ${({ theme }) => theme.colors.boxShadow[25]};
    }
    .loading {
      path {
        fill: transparent;
        stroke: ${({ theme }) => theme.colors.boxShadow[25]};
      }
    }
  }

  ${({ $shape, $block }) =>
    $shape &&
    !$block &&
    css`
      ${(props) => getIconSize(props)}
      border-radius: 50%;
    `}

  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
      justify-content: center;
    `}

  ${({ theme, $ghost, $danger, variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${$ghost ? 'transparent' : theme.colors.primary[600]};
      border: ${$ghost ? `1px solid ${theme.colors.primary[600]}` : 'none'};
      .loading {
        path {
          fill: transparent;
          stroke: ${$ghost ? theme.colors.primary[600] : theme.colors.white[100]};
        }
      }
      color: ${$ghost ? theme.colors.primary[600] : theme.colors.white[100]};
      path {
        fill: ${$ghost ? theme.colors.primary[600] : theme.colors.white[100]};
      }
      &:hover,
      &:focus {
        background-color: ${$ghost ? 'transparent' : theme.colors.primary[400]};
        border: ${$ghost ? `1px solid ${theme.colors.primary[400]}` : 'none'};
        color: ${$ghost ? theme.colors.primary[400] : theme.colors.white[100]};
        path {
          fill: ${$ghost ? theme.colors.primary[400] : theme.colors.white[100]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${$ghost ? theme.colors.primary[400] : theme.colors.white[100]};
          }
        }
      }
      &:active {
        background-color: ${$ghost ? 'transparent' : theme.colors.primary[800]};
        color: ${$ghost ? theme.colors.primary[800] : theme.colors.white[100]};
        path {
          fill: ${$ghost ? theme.colors.primary[800] : theme.colors.white[100]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${$ghost ? theme.colors.primary[800] : theme.colors.white[100]};
          }
        }
      }
      &:disabled {
        background-color: ${$ghost ? 'transparent' : theme.colors.boxShadow[10]};
        border: 1px solid ${theme.colors.dark[15]};
        color: ${theme.colors.boxShadow[25]};
        path {
          fill: ${theme.colors.boxShadow[25]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.boxShadow[25]};
          }
        }
        cursor: not-allowed;
      }
      ${$danger &&
      css`
        background-color: ${$ghost ? 'transparent' : theme.colors.danger[600]};
        border-color: ${$ghost ? theme.colors.danger[600] : 'none'};
        color: ${$ghost ? theme.colors.danger[600] : theme.colors.white[100]};
        .loading {
          path {
            fill: transparent;
            stroke: ${$ghost ? theme.colors.danger[400] : theme.colors.white[100]};
          }
        }
        path {
          fill: ${$ghost ? theme.colors.danger[400] : theme.colors.white[100]};
        }
        &:hover,
        &:focus {
          background-color: ${$ghost ? 'transparent' : theme.colors.danger[400]};
          border-color: ${$ghost ? theme.colors.danger[400] : 'none'};
          color: ${$ghost ? theme.colors.danger[400] : theme.colors.white[100]};
          path {
            fill: ${$ghost ? theme.colors.danger[400] : theme.colors.white[100]};
          }
          .loading {
            path {
              fill: transparent;
              stroke: ${$ghost ? theme.colors.danger[400] : theme.colors.white[100]};
            }
          }
        }

        &:active {
          background-color: ${$ghost ? 'transparent' : theme.colors.danger[800]};
          border-color: ${$ghost ? theme.colors.danger[800] : 'none'};
          color: ${$ghost ? theme.colors.danger[800] : theme.colors.white[100]};
          path {
            fill: ${$ghost ? theme.colors.danger[800] : theme.colors.white[100]};
          }
          .loading {
            path {
              fill: transparent;
              stroke: ${$ghost ? theme.colors.danger[800] : theme.colors.white[100]};
            }
          }
        }
      `}
    `}

  ${({ variant, $dashed, theme, $ghost, $danger }) =>
    variant === 'secondary' &&
    css`
      border: 1px ${$dashed ? 'dashed' : 'solid'} ${$ghost ? theme.colors.white[100] : theme.colors.boxShadow[25]};
      background-color: ${$ghost ? 'transparent' : theme.colors.white[100]};
      .loading {
        path {
          fill: transparent;
          stroke: ${$ghost ? theme.colors.white[100] : theme.colors.dark[100]};
        }
      }
      color: ${$ghost ? theme.colors.white[100] : theme.colors.dark[100]};
      path {
        fill: ${$ghost ? theme.colors.white[100] : theme.colors.dark[100]};
      }
      &:hover,
      &:focus {
        border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.primary[400]};
        color: ${theme.colors.primary[400]};
        path {
          fill: ${theme.colors.primary[400]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.primary[400]};
          }
        }
      }
      &:active {
        border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.primary[800]};
        color: ${theme.colors.primary[800]};
        path {
          fill: ${theme.colors.primary[800]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.primary[800]};
          }
        }
      }
      &:disabled {
        background-color: ${$ghost ? 'transparent' : theme.colors.boxShadow[10]};
        border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.dark[15]};
        color: ${theme.colors.boxShadow[25]};
        path {
          fill: ${theme.colors.boxShadow[25]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.boxShadow[25]};
          }
        }
        cursor: not-allowed;
      }
      ${$danger &&
      css`
        background-color: ${$ghost ? 'transparent' : theme.colors.white[100]};
        border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.danger[600]};
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.danger[600]};
          }
        }
        color: ${theme.colors.danger[600]};
        path {
          fill: ${theme.colors.danger[600]};
        }
        &:hover,
        &:focus {
          border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.danger[400]};
          color: ${theme.colors.danger[400]};
          path {
            fill: ${theme.colors.danger[400]};
          }
          .loading {
            path {
              fill: transparent;
              stroke: ${theme.colors.danger[400]};
            }
          }
        }
        &:active {
          border: 1px ${$dashed ? 'dashed' : 'solid'} ${theme.colors.danger[400]};
          color: ${theme.colors.danger[800]};
          path {
            fill: ${theme.colors.danger[800]};
          }
        }
      `}
    `}
    
  ${({ variant, theme, $danger }) =>
    variant === 'link' &&
    css`
      background-color: inherit;
      .loading {
        path {
          fill: transparent;
          stroke: ${theme.colors.primary[600]};
        }
      }
      color: ${theme.colors.primary[600]};
      path {
        fill: ${theme.colors.primary[600]};
      }
      &:hover,
      &:focus {
        box-shadow: none;
        color: ${theme.colors.primary[400]};
        path {
          fill: ${theme.colors.primary[400]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.primary[400]};
          }
        }
      }
      &:active {
        color: ${theme.colors.primary[800]};
        path {
          fill: ${theme.colors.primary[800]};
        }
      }

      ${$danger &&
      css`
        background-color: inherit;
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.danger[600]};
          }
        }
        color: ${theme.colors.danger[600]};
        path {
          fill: ${theme.colors.danger[600]};
        }
        &:hover,
        &:focus {
          color: ${theme.colors.danger[400]};
          path {
            fill: ${theme.colors.danger[400]};
          }
          .loading {
            path {
              fill: transparent;
              stroke: ${theme.colors.danger[400]};
            }
          }
        }
        &:active {
          color: ${theme.colors.danger[800]};
          path {
            fill: ${theme.colors.danger[800]};
          }
        }
      `}
    `}

  ${({ variant, theme, $danger }) =>
    variant === 'text' &&
    css`
      background-color: inherit;
      .loading {
        path {
          fill: transparent;
          stroke: ${theme.colors.dark[100]};
        }
      }
      path {
        fill: ${theme.colors.dark[100]};
      }
      color: ${theme.colors.dark[100]};
      &:hover,
      &:focus {
        background-color: ${theme.colors.boxShadow[15]};
      }
      &:active {
        background-color: ${theme.colors.dark[25]};
      }
      &:disabled {
        background-color: inherit;
        color: ${theme.colors.boxShadow[25]};
        path {
          fill: ${theme.colors.boxShadow[25]};
        }
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.boxShadow[25]};
          }
        }
      }
      ${$danger &&
      css`
        background-color: inherit;
        .loading {
          path {
            fill: transparent;
            stroke: ${theme.colors.danger[600]};
          }
        }
        path {
          fill: ${theme.colors.danger[600]};
        }
        color: ${theme.colors.danger[600]};
        &:hover,
        &:focus {
          background-color: ${theme.colors.danger[50]};
          color: ${theme.colors.danger[400]};
          path {
            fill: ${theme.colors.danger[400]};
          }
          .loading {
            path {
              fill: transparent;
              stroke: ${theme.colors.danger[400]};
            }
          }
        }
        &:active {
          background-color: ${theme.colors.danger[50]};
          border: 1px solid ${theme.colors.danger[800]};
          color: ${theme.colors.danger[800]};
          path {
            fill: ${theme.colors.danger[800]};
          }
        }
      `}
    `}
`;

export default Button;
