import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const RightSectionWrapper = styled.div`
  display: none;

  .right-menu {
    position: fixed;
    z-index: 99999;
    top: 70px;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white[100]};
    color: ${({ theme }) => theme.colors.dark[100]};
    height: 100%;
    width: 280px;
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: calc(100% - 32px);
    }
    .rc-menu-item,
    .rc-menu-submenu-title {
      padding: 10px 16px !important;
      margin: 4px 0;
    }
    .rc-menu-submenu-inline {
      padding: 0;
    }

    .divider {
      border-bottom: 1px solid ${({ theme }) => theme.colors.dark[15]} !important;
      margin: 8px 0;
    }
  }
`;

export default RightSectionWrapper;
