import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const EditProfileWrapper = styled.div`
  .edit-profile-modal-root {
    width: 368px;

    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: 288px;
    }

    .avatar-box {
      position: relative;
      display: flex;
      justify-content: center;
      .avatar {
        width: 136px;
        height: 136px;
        margin-bottom: 20px;
        cursor: default;

        .name {
          font-size: 24px;
          font-weight: 500;
        }
      }
      .camera {
        cursor: pointer;
        background-color: white;
        position: absolute;
        border: 5px solid white;
        border-radius: 100%;
        bottom: 20px;
        right: 125px;

        @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
          right: 85px;
        }
      }
    }
  }
`;

export default EditProfileWrapper;
