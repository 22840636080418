import classNames from 'classnames';
import { Close } from 'style-guide/Icons';

const Tag = ({ className, closable, children, closeIcon, ...tagProps }) => {
  const tagClassName = classNames(className, { closable });
  return (
    <span className={tagClassName} {...tagProps}>
      {children}
      {closable ? (
        <span
          className='close-icon'
          onClick={(e) => {
            e.preventDefault();
            tagProps.onClose(e);
          }}
        >
          {closeIcon}
        </span>
      ) : null}
    </span>
  );
};

Tag.defaultProps = {
  closable: false,
  closeIcon: <Close height={14} width={14} />,
  onClose: (e) => {
    e.preventDefault();
  },
};

export default Tag;
