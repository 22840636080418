import styled from 'styled-components';

const FileManagerWrapper = styled.div`
  // TODO: after fix modal style, change 790 to 850
  width: 790px;
  .drag-file-box {
    line-height: 18px;
    border: 1px dashed ${({ theme }) => theme.colors.secondary[400]};
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 8px;
    padding: 16px 0;
    margin-bottom: 20px;
    .main {
      margin-bottom: 8px;
    }
    button {
      padding: 0;
      label {
        height: 36px;
        padding: 0 15px;
        border-radius: 8px;
      }
    }
  }

  .action-box {
    .upload {
      margin-right: 20px;
      path {
        fill: ${({ theme }) => theme.colors.primary[600]};
      }
    }
    .delete {
      path {
        fill: ${({ theme }) => theme.colors.error[600]};
      }
    }
  }

  .filter {
    margin-bottom: 20px;
  }

  .rc-table-expanded-row-fixed {
    width: fit-content !important;
  }

  .table-box {
    min-height: 360px;
    margin-bottom: 20px;
    .table-loading-box {
      position: fixed;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      svg {
        z-index: 10;
        path {
          stroke: ${({ theme }) => theme.colors.primary[800]};
        }
      }
    }
    .rc-table-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .table-name-content {
      margin-left: 12px;
    }
    .rc-table-row {
      .rc-table-cell {
        padding: 8px 16px !important;
      }
    }
  }
  .pagination-box {
    margin-bottom: 20px;
  }
  .select-box {
    margin-left: 20px;
    .select__control {
      border: none;
      width: 240px;
    }
    .select__menu {
      z-index: 999;
    }
    .select__clear-indicator {
      display: none;
    }
    .select__indicators {
      cursor: pointer;
      padding-right: 4px;
      height: 35px !important;
    }
    .select__indicator {
      position: absolute;
      width: 100%;
      justify-content: end;
      left: 0;
    }
    .select__placeholder {
      font-style: italic;
      font-size: 14px;
      line-height: 17px;
      margin-left: 12px;
      color: ${({ theme }) => theme.colors.secondary[400]};
    }
    .has-select-value {
      .select__placeholder {
        font-style: normal;
        font-weight: 500;
      }
    }
    .custom-option-box {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 12px;
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
      }
    }

    .title {
      margin-left: 8px;
    }
  }

  .search-box {
    border-radius: 32px;
    display: inline-flex;
    align-items: center;
    width: 286px;
    height: 33px;
    border: 1px solid ${({ theme }) => theme.colors.dark[25]};
    background-color: ${({ theme }) => theme.colors.white[100]};
    .icon {
      display: flex;
      align-items: center;
      height: 35px;
      &.search-icon {
        cursor: pointer;
        padding-left: 14px;
        padding-right: 10px;
        border-radius: 32px 0px 0px 32px;
      }
      &.clear-icon {
        cursor: pointer;
        height: 35px;
        padding-right: 12px !important;
        padding-left: 10px;
        border-radius: 0px 32px 32px 0px;
      }
      path {
        fill: ${({ theme }) => theme.colors.dark[60]};
      }
    }

    .search-icon-closed {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: center;
      cursor: pointer;
      border-radius: 100%;
      padding: 4px;

      path {
        fill: ${({ theme }) => theme.colors.dark[60]};
      }
    }

    .search-input-wrapper {
      width: 100%;
      display: inline-flex;
      border-radius: 0 32px 32px 0;
    }

    input {
      width: 100%;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.dark[100]};
      height: 35px;
      border: none;
      padding: 0 !important;
      font-size: 14px;
      font-weight: 400;
      &.search-input {
        border-radius: 0 32px 32px 0;
      }
      &:focus-visible {
        outline: none;
      }
      &::placeholder {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.dark[60]};
      }
    }
  }
`;

export default FileManagerWrapper;
