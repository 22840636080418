const TrueOrFalse = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.7828 7.06444C15.782 7.06365 15.7812 7.06287 15.7804 7.06208C13.0594 4.34134 8.64779 4.34134 5.92679 7.06208C3.2058 9.78282 3.2058 14.194 5.92679 16.9147C5.92758 16.9155 5.92837 16.9163 5.92916 16.9171L15.7828 7.06444Z'
      fill='#00B3CC'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.22074 16.2217C8.22085 16.2218 8.22095 16.2219 8.22106 16.222C10.9421 18.9428 15.3537 18.9428 18.0747 16.222C20.7957 13.5013 20.7957 9.09008 18.0747 6.36934C18.0746 6.36923 18.0744 6.36913 18.0743 6.36902L8.22074 16.2217Z'
      fill='#596D79'
    />
    <path
      d='M8.29059 10.4778C8.22134 10.4778 8.1521 10.4509 8.09925 10.3967L7.36572 9.64561C7.26001 9.53736 7.26001 9.362 7.36572 9.25406C7.47144 9.1458 7.64238 9.14549 7.7481 9.25374L8.29059 9.80926L9.5666 8.50261C9.67231 8.39435 9.84325 8.39435 9.94897 8.50261C10.0547 8.61086 10.0547 8.78622 9.94897 8.89448L8.48193 10.3967C8.42907 10.4509 8.35983 10.4778 8.29059 10.4778Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.1436 14.5727L14.1563 15.56C14.0487 15.6676 13.8744 15.6676 13.7669 15.56C13.6593 15.4525 13.6593 15.2782 13.7669 15.1706L14.7542 14.1833L13.7669 13.196C13.6593 13.0884 13.6593 12.9141 13.7669 12.8066C13.8744 12.699 14.0487 12.699 14.1563 12.8066L15.1436 13.7939L16.1309 12.8066C16.2384 12.699 16.4128 12.699 16.5203 12.8066C16.6278 12.9141 16.6278 13.0884 16.5203 13.196L15.533 14.1833L16.5203 15.1706C16.6278 15.2782 16.6278 15.4525 16.5203 15.56C16.4128 15.6676 16.2384 15.6676 16.1309 15.56L15.1436 14.5727Z'
      fill='white'
    />
  </svg>
);

export default TrueOrFalse;
