import { infiniteScrollMiddleware } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const getExploreMembersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getExploreMembers: build.query({
      query: (params) => ({
        url: '/members',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
      ...infiniteScrollMiddleware,
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetExploreMembersQuery } = getExploreMembersApi;
