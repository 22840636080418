import styled from 'styled-components';

const CourseSettingsModalWrapper = styled.div`
  width: 600px;
  .modal-items-content {
    overflow: auto;
    max-height: calc(100vh - 350px);
  }
  .collapse {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -20px;
  }
  .chapter-container {
    height: 40px;
    margin: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dark[15]};
  }
`;

export default CourseSettingsModalWrapper;
