import styled from 'styled-components';
import SCREEN_SIZES from 'constants/screen-sizes';

const ImageModalWrapper = styled.div`
  .modal-image-container {
    height: 292px;
    width: 504px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;

    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      height: 240px;
      width: 320px;
      margin: 24px 0;
    }
  }

  .modal-title {
    position: relative;
    bottom: 20px;
    display: inline-block;
  }

  img {
    height: 320px;
    width: 320px;
    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      height: 264px;
      width: 264px;
    }
  }
`;

export default ImageModalWrapper;
