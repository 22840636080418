import Button from 'style-guide/Button';
import { Print } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';
import LearnerCard from '../AssessmentCards/LearnerCard';
import MultipleBlanksCard from './MultipleBlanksCard';

const PanelContent = ({ questions, control, chapterId, courseId, setValue, answeredTest, categoryUrl, summary }) => (
  <>
    {questions.map((el, index) =>
      el.type === 3 ? (
        <MultipleBlanksCard
          key={el.id}
          answer={el.answer}
          index={index}
          question={el.question}
          setValue={setValue}
          assessmentInfo={el}
          answeredTest={answeredTest}
        />
      ) : (
        <LearnerCard
          key={el.id}
          index={index}
          assessmentInfo={el}
          control={control}
          chapterId={chapterId}
          courseId={courseId}
          setValue={setValue}
          answeredTest={answeredTest}
        />
      )
    )}
    <div className='preview-buttons justify-content-between'>
      <Text $dark={100} className='total-grade'>
        Total Grade: {answeredTest?.totalGrade}%
      </Text>
      <Button
        onClick={summary ? () => window.print() : null}
        variant='secondary'
        to={!summary && `${categoryUrl}?mode=preview`}
        prefix={summary && <Print />}
      >
        {summary ? 'Print Summary' : 'Close'}
      </Button>
    </div>
  </>
);

export default PanelContent;
