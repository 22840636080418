const userAccessTypes = {
  FULL: { id: 1, name: 'Full' },
  LIMITED: { id: 2, name: 'Limited' },
};

const getTypeFromId = (id) => Object.values(userAccessTypes).find((type) => type.id === id);

const USER_ACCESS_TYPES = { ...userAccessTypes, getTypeFromId };

export default USER_ACCESS_TYPES;
