import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  useCreateLearningCommunityReviewMutation,
  useUpdateLearningCommunityReviewMutation,
} from 'store/services/learningCommunities/Review';

import { message } from 'style-guide/ToastMessages';
import ReviewModal from './ReviewModal';

const LearningCommunityReviewModal = ({ params, hideModal }) => {
  const [createReview, { isSuccess, isError }] = useCreateLearningCommunityReviewMutation();
  const [updateReview, { isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateLearningCommunityReviewMutation();

  const { urlName, reviewId, comment, rating: currentRating, id, courseId } = params;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      comment: comment || '',
      rating: currentRating,
    },
  });
  const rating = watch('rating');

  const onFormSubmit = handleSubmit((formData) => {
    if (currentRating) {
      return updateReview({ reviewId, urlName, id, ...formData });
    }
    createReview({ courseId, urlName, ...formData });
  });

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      message.success(`Review successfully has been ${isUpdateSuccess ? 'updated' : 'created'}`);
      hideModal();
    }
    if (isError || isUpdateError) {
      message.error('Something went wrong!');
    }
  }, [isSuccess, isError, hideModal, isUpdateSuccess, isUpdateError]);

  return <ReviewModal onFormSubmit={onFormSubmit} control={control} disabled={!rating} hideModal={hideModal} />;
};

export default LearningCommunityReviewModal;
