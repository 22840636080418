import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFile } from 'store/slices/selectedFile';
import Button from 'style-guide/Button';
import { getWrapper } from 'utils/helperFunctions';

import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';
import { getSelectedFile } from 'store/selectors/fileManager';
import SlidesList from './SlidesList';
import PresentationWrapper from './style/PresentationWrapper';

const presentationResultPath = 'resources.presentation.result';

const Presentation = ({ onToggleModal, setValue, presentationResult, presentationData, pId, isMember, control }) => {
  const dispatch = useDispatch();
  const selectedFile = useSelector(getSelectedFile);
  const [activeFieldIndex, setActiveFieldIndex] = useState(0);

  const editorRef = useRef(null);

  const fieldArrayMethods = useFieldArray({
    control,
    name: presentationResultPath,
  });

  const titleValue = fieldArrayMethods.fields?.[activeFieldIndex]?.title;

  const { isDirty } = useFormState({ control });

  useEffect(() => {
    if (!fieldArrayMethods.fields.length) {
      setValue(presentationResultPath, presentationResult || presentationData || [{ title: '', audio: null }]);
    }
  }, [fieldArrayMethods.fields.length, presentationData, presentationResult, setValue]);

  useEffect(() => {
    if (selectedFile) {
      const addedContent = getWrapper({ selectedFile });
      if (addedContent) {
        fieldArrayMethods.update(activeFieldIndex, {
          ...fieldArrayMethods.fields[activeFieldIndex],
          title: titleValue ? titleValue + addedContent : addedContent,
        });
        dispatch(setSelectedFile({ file: null }));
      }
    }
  }, [activeFieldIndex, dispatch, fieldArrayMethods, selectedFile, titleValue]);

  const onSave = () => {
    const filteredFields = fieldArrayMethods.fields.map((el) => {
      const { title, audio, id, slideName, slideId } = el;
      return id && Number(id) ? { ...el, pId, slideId } : { title, audio, slideName, pId, slideId };
    });

    setValue(presentationResultPath, filteredFields);
    onToggleModal();
  };

  return (
    <PresentationWrapper>
      <div className='presentation-root'>
        <div className='content-box'>
          <SlidesList
            fieldArrayMethods={fieldArrayMethods}
            setActiveFieldIndex={setActiveFieldIndex}
            activeFieldIndex={activeFieldIndex}
            editorRef={editorRef}
            isMember={isMember}
          />
          <div className='editor-section-box'>
            {!isMember ? (
              <SimplifiedEditor
                content={titleValue}
                onChange={(e) => {
                  fieldArrayMethods.update(activeFieldIndex, {
                    ...fieldArrayMethods.fields[activeFieldIndex],
                    title: e,
                  });
                }}
                className='description-editor ph--12 pb--12'
              />
            ) : (
              <SimplifiedEditor className='presentation-member-view' content={titleValue} editable={false} />
            )}

            {!isMember ? (
              <div className='d-flex justify-content-end mt--24'>
                <Button variant='primary' disabled={!isDirty} className='ph--28' onClick={onSave}>
                  Save
                </Button>
              </div>
            ) : (
              <div className='col-10 offset-2 d-flex justify-content-end'>
                <Button variant='secondary' className='close-button' onClick={onToggleModal}>
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </PresentationWrapper>
  );
};

export default Presentation;
