import styled from 'styled-components';

const Layout = styled.section`
  padding-top: 70px;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  &.has-side-bar {
    flex-direction: row;
  }
  &.admin-page {
    padding-top: 0;
    height: 100vh;
  }
  .layout {
    padding-top: 0;
  }
`;

export default Layout;
