import { useController } from 'react-hook-form';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';
import classNames from 'classnames';
import { getAnswerClassName } from 'utils/helperFunctions';

const MultipleChoiceAnswer = ({ answer, index, type, questionId, control, answeredTest }) => {
  const {
    field: { onChange },
  } = useController({ control, name: `answers.${index}` });

  const userAnswer = answeredTest?.userAnswers?.[questionId];
  const isCorrectAnswer = answeredTest?.correctAnswers?.[questionId]?.correct;

  return (
    <div className='multiple-choice-wrapper'>
      {answer?.map((el) => (
        <div
          key={el.id}
          className={classNames(
            'radio-button-box d-flex align-items-center',
            getAnswerClassName(userAnswer, isCorrectAnswer, el),
            {
              answeredField: answeredTest,
            }
          )}
        >
          <Radio
            name={`answer.${index}`}
            onChange={() => onChange({ type, questionId, answer: [el.id] })}
            checked={answeredTest && Number(userAnswer) === el.id}
          >
            <Text $dark={80} $variant={2}>
              {el?.answer}
            </Text>
          </Radio>
        </div>
      ))}
    </div>
  );
};

export default MultipleChoiceAnswer;
