import { useSelector } from 'react-redux';
import endpoints from 'store/endpoints';

const useGetDomainTracking = () => {
  const getDomainTracking = endpoints.domainTracking.select(undefined);
  const domainTrackingData = useSelector(getDomainTracking);
  return domainTrackingData;
};

const useGetAdminDomain = ({ all, domainId }) => {
  const getDomain = endpoints.domainTracking.select({ all });
  const domainData = useSelector(getDomain);
  return domainData.find((e) => e.id === +domainId);
};

export { useGetAdminDomain };
export default useGetDomainTracking;
