import { ENTITY_TYPES } from 'common';
import { generateEntityPagePath } from 'Components/App/Helpers/entityPagesRoute/pathGenerateHelper';
import { formStepsId } from 'Components/EntityFormSections/Stepper/helper';

const { LEARNING_COMMUNITY } = ENTITY_TYPES;

const CARD_OPTIONS = (id, urlName, userName, communityType, hasEditPermission, isArchive) => {
  let options = [
    // TODO: remove comments after adding functionality for unenroll buttons
    // {
    //   name: 'Invite',
    //   value: '',
    // },
    // {
    //   name: 'Unenroll',
    //   value: '',
    // },
  ];
  if (hasEditPermission) {
    options = [
      {
        name: 'Settings',
        value: generateEntityPagePath(communityType, id, urlName, formStepsId.naming),
      },
      {
        name: 'Audience',
        value: generateEntityPagePath(communityType, id, urlName, formStepsId.pricing),
      },
      {
        name: 'Clone',
        value: 'clone',
      },
      {
        name: 'Delete',
        value: 'delete',
      },
      ...options,
    ];
  }
  if (communityType === LEARNING_COMMUNITY.key && hasEditPermission) {
    options = [
      {
        name: isArchive ? 'Publish' : 'Unpublish',
        value: isArchive ? 'publish' : 'unpublish',
      },
      ...options,
    ];
  }
  if (communityType === LEARNING_COMMUNITY.key) {
    options = [
      {
        name: 'Reports',
        value: `/${communityType}/${id}/${urlName}/organizer/reports`,
      },
      ...options,
    ];
  }
  return [
    ...options,
    {
      name: 'Notifications',
      value: `/${userName}/notifications`,
    },
  ];
};
export default CARD_OPTIONS;
