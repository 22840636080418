import 'rc-tooltip/assets/bootstrap.css';
import styled, { css } from 'styled-components';

const GroupAvatarWrapper = styled.div`
  .avatar-picture {
    ${({ $imgSrc }) =>
      css`
        background-image: url(${$imgSrc});
      `}
  }
  .avatar-color {
    ${({ $color }) =>
      css`
        background-color: ${$color} !important;
      `}
  }
`;

export default GroupAvatarWrapper;
