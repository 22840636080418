const categories = {
  users: 'users',
  domain: 'domain',
  course: 'course',
  library: 'library',
  organization: 'organization',
};

const actions = {
  // user actions
  signIn: 'signIn',
  register: 'register',
  forgotPassword: 'forgotPassword',
  // domain actions
  domainView: 'domainView',
  // course actions
  courseShow: 'courseShow',
  courseAccess: 'courseAccess',
  courseManage: 'courseManage',
  // library actions
  libraryShow: 'libraryShow',
  libraryManage: 'libraryManage',
  // organization actions
  organizationShow: 'organizationShow',
  organizationManage: 'organizationManage',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  categories,
  actions,
};
