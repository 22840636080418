import Title from 'style-guide/Typography/Title';
import CompaniesActions from './CompaniesActions';

const getCompaniesColumns = () => [
  {
    title: <Title variant={5}>Name</Title>,
    dataIndex: 'brandName',
    key: 'name',
  },
  {
    title: (
      <Title variant={5} className='title'>
        Domain Name
      </Title>
    ),
    dataIndex: 'domainName',
    key: 'domainName',
  },
  {
    title: <Title variant={5}>Email</Title>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <Title variant={5}>Phone</Title>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: (
      <Title variant={5} className='title'>
        Support Email
      </Title>
    ),
    dataIndex: 'supportEmail',
    key: 'supportEmail',
  },
  {
    title: (
      <Title variant={5} className='title'>
        Service Fee
      </Title>
    ),
    dataIndex: 'serviceFee',
    key: 'serviceFee',
  },
  {
    title: <Title variant={5}>Actions</Title>,
    dataIndex: 'actions',
    key: 'actions',
    render: (_index, item) => <CompaniesActions item={item} />,
  },
];

export default getCompaniesColumns;
