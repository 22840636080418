import emptySplitApi from '..';

const userProfileInfo = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    bankAccountInfo: build.query({
      query: (params) => ({
        url: '/bank-account-info',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
    }),
    members: build.mutation({
      query: (params) => ({
        url: `/members?type=${params.type}`,
        method: 'PUT',
        body: params,
      }),
      transformResponse: (response) => response,
    }),
    getMembers: build.mutation({
      query: (params) => ({
        url: `/members/${params?.userName}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useBankAccountInfoQuery, useMembersMutation, useGetMembersMutation } = userProfileInfo;
