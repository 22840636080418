/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'style-guide/ToastMessages';

const initialState = { file: null };

const selectedFileSlice = createSlice({
  name: 'selectedFile',
  initialState,
  reducers: {
    setSelectedFile(state, action) {
      const {
        payload: { file },
      } = action;
      if (file) {
        message.success('Success! Your file was uploaded');
      }
      state.file = file;
    },
  },
});

export const { setSelectedFile } = selectedFileSlice.actions;
export default selectedFileSlice.reducer;
