import { createSlice } from '@reduxjs/toolkit';

const isLoading = createSlice({
  name: 'loadingState',
  initialState: false,
  reducers: {
    setLoadingState: (_state, action) => action.payload,
  },
});

export const { setLoadingState } = isLoading.actions;
export default isLoading.reducer;
