import { forwardRef } from 'react';
import Menu, { MenuItem } from 'style-guide/Menu';

const DropdownList = forwardRef(({ options, onClickItem, ...props }, ref) => (
  <Menu {...props} ref={ref} onClick={(e) => onClickItem?.(e.key)} warnkey='menu'>
    {options?.map((item) => (item ? <MenuItem key={item.value}>{item.name}</MenuItem> : null))}
  </Menu>
));

export default DropdownList;
