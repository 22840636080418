import { lazy } from 'react';
import { IsAuthenticatedRoute } from './IsAuthenticatedRoute';

const HandleInvitationOldPath = lazy(() => import('views/Dashboard/InviteNewMembers/HandleInvitationOldPath'));
const DepartmentRedirectHelper = lazy(() => import('views/Department/DepartmentRedirectHelper'));
const LearningCommunityRedirectHelper = lazy(() => import('views/LearningCommunity/LearningCommunityRedirectHelper'));
const OrganizationRedirectHelper = lazy(() => import('views/Organization/OrganizationRedirectHelper'));

const redirectRoutes = [
  {
    element: <LearningCommunityRedirectHelper />,
    path: '/course/:urlName/access-course/:volumeId/:chapterId/:category',
  },
  {
    element: <LearningCommunityRedirectHelper />,
    path: '/course/:urlName',
  },
  {
    element: <DepartmentRedirectHelper />,
    path: '/library/:urlName',
  },
  {
    element: <OrganizationRedirectHelper />,
    path: '/organization/:urlName',
  },
  {
    element: <HandleInvitationOldPath />,
    path: '/user/:userName/invited-courses',
  },
].map((e) => ({
  ...e,
  element: <IsAuthenticatedRoute>{e.element}</IsAuthenticatedRoute>,
}));

export default redirectRoutes;
