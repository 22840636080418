const downloadFile = async (name, blob) => {
  const path = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = path;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(blob);
  a.remove();
};

export default downloadFile;
