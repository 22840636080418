import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const AccessCodeSkeletonWrapper = styled.div`
  width: 510px;
  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    width: 300px;
  }
  .react-loading-skeleton {
    margin-bottom: 4px;
  }
`;

export default AccessCodeSkeletonWrapper;
