import { number, object, string, array } from 'yup';
import answerValidationSchema from './answerValidationSchema';

const feedbackFieldValidation = object().shape({
  text: string().when('hyperlinks', (hyperlinks, field) =>
    hyperlinks?.length >= 1 ? field.required('This field is required') : field
  ),
  hyperlinks: array().of(
    object({
      text: string().when('entityId', (entityId, field) =>
        entityId === '0'
          ? field.url('Invalid URL').required('This field is required')
          : field.required('This field is required')
      ),
      entityId: string().required('This field is required'),
      type: string().required(),
    }).required()
  ),
});

const schema = (type) =>
  object()
    .shape({
      image: string().nullable(),
      text: string().nullable(),
      feedback: object().shape(
        {
          correct: feedbackFieldValidation.when('wrong.text', {
            is: (value) => value,
            then: object({
              text: string().required('This field is required'),
            }),
          }),
          wrong: feedbackFieldValidation.when('correct.text', {
            is: (value) => value,
            then: object({
              text: string().required('This field is required'),
            }),
          }),
        },
        ['wrong', 'correct']
      ),
      type: number(),
      ...answerValidationSchema(type),
    })
    .required();

export default schema;
