import { getDomainTracking } from 'configs/localStorage';
import theme from 'style-guide/Theme';

const { key } = getDomainTracking();
const { colors } = theme(key);

const content_style = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;1,300;1,400&display=swap');
  body {
    min-height: 35px;
    margin: 2px 12px;
    font-weight: 400;
    font-family: Montserrat;
  }
 .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    padding-top: 4px;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: ${colors.dark[60]};
  }
  .mce-content-body{
    font-size: 14px;
    font-weight: 400;
    background: ${colors.white[100]};
    color: ${colors.dark[80]};
  }
  p {
    min-height: 31px;
    margin: 2px 0;
    line-height: 26px;
  }
  .blank-close-icon {
    display: none;
  }

  .blank:hover {
    padding-right: 2px !important;
  }

  .blank:hover .blank-close-icon {
    margin-left: 8px !important;
    cursor: pointer !important;
    display: block !important;
  }

  .blank-close-icon path {
    fill: ${colors.primary[800]};
  }

  .mce-content-body [contentEditable=false][data-mce-selected] {
    cursor: default;
    outline: none;
  }
`;

export default content_style;
