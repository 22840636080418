const Print = (props) => (
  <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 3H3V0H13V3H14C15.1046 3 16 3.89543 16 5V10C16 11.1046 15.1046 12 14 12H13V14H3V12H2C0.89543 12 0 11.1046 0 10V5C0 3.89543 0.89543 3 2 3ZM11 3H5V2H11V3ZM14 10H13V8H3V10H2V5H14V10ZM11 10H5V12H11V10ZM13 6.5C13 6.77614 12.7761 7 12.5 7C12.2239 7 12 6.77614 12 6.5C12 6.22386 12.2239 6 12.5 6C12.7761 6 13 6.22386 13 6.5Z'
      fill='#333333'
    />
  </svg>
);

export default Print;
