import Button from 'style-guide/Button';
import Collapse, { Panel } from 'style-guide/Collapse';
import Switch from 'style-guide/Switch';
import Title from 'style-guide/Typography/Title';

import { useGetVolumesQuery, useUpdateChapterMutation } from 'store/services/superAdmin/AdminCourses';
import CourseSettingsModalWrapper from './CourseSettingsModalWrapper';

const ViewCourseSettings = ({ hideModal, params: { title, id } }) => {
  const [updateChapter] = useUpdateChapterMutation();
  const { data: volumes } = useGetVolumesQuery({ id });
  const updateVolumeChapter = (volumeParams, chapterParams) => {
    updateChapter({
      id: chapterParams.id,
      courseId: volumeParams.courseId,
      allowComment: !chapterParams?.allowComment,
    });
  };
  return (
    <CourseSettingsModalWrapper className='row transform-active '>
      <div className='col-12 d-flex justify-content-center'>{title}</div>
      <div className='col-12 justify-content-start modal-items-content pt--16 mb--12'>
        {volumes?.map((volume) => (
          <Collapse accordion className='collapse' key={volume.id}>
            <Panel header={volume?.name}>
              <div className='row'>
                {volume?.chapters.map((chapter) => (
                  <div
                    className='col-12 d-flex justify-content-between align-items-center chapter-container'
                    key={chapter.id}
                  >
                    <Title variant={5}>{chapter.name}</Title>
                    <Switch
                      $size='small'
                      checked={chapter.allowComment}
                      onChange={() => updateVolumeChapter(volume, chapter)}
                    />
                  </div>
                ))}
              </div>
            </Panel>
          </Collapse>
        ))}
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <Button
          className='ph--40'
          variant='secondary'
          onClick={() => {
            hideModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </CourseSettingsModalWrapper>
  );
};

export default ViewCourseSettings;
