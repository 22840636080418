import { SYSTEM_LIMITS } from 'common';
import { Controller, useFieldArray } from 'react-hook-form';
import Button from 'style-guide/Button';
import { Plus, Trash } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';
import FillBlankWrapper from './style/FillBlankWrapper';

const {
  COURSE_SECTIONS: { TESTS },
} = SYSTEM_LIMITS;

const FillBlank = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answer',
  });

  return (
    <FillBlankWrapper className='row'>
      <div className='col-12'>
        <Text $variant={3} dark={60} className='label'>
          *Check the answer that is correct
        </Text>
      </div>
      <div className='col-12'>
        <Button
          variant='link'
          disabled={fields.length >= TESTS.ANSWER.LIST.max}
          prefix={<Plus />}
          onClick={() => {
            append({ answer: '', isTrue: true });
          }}
        >
          Add New Answer
        </Button>
      </div>
      <div className='col-12 options-box'>
        {fields.map((field, index) => (
          <div className='row' key={field.id}>
            <div className='col d-flex align-items-center'>
              <Controller
                control={control}
                name={`answer.${index}.answer`}
                render={({ field: answerField }) => <Input {...answerField} maxLength={50} />}
              />
              {fields.length > TESTS.ANSWER.LIST.min ? (
                <div className='trash-icon-wrapper'>
                  <Button variant='text' $danger onClick={() => remove(index)}>
                    <Trash />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </FillBlankWrapper>
  );
};

export default FillBlank;
