const Facebook = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.136 8.81553L11.5317 6.30097H9.05605V4.6699C9.05605 3.98181 9.40124 3.31068 10.5101 3.31068H11.6364V1.1699C11.6364 1.1699 10.6147 1 9.63835 1C7.59853 1 6.26651 2.2046 6.26651 4.38447V6.30097H4V8.81553H6.26651V14.8947C6.72154 14.9643 7.18706 15 7.66128 15C8.1355 15 8.60102 14.9643 9.05605 14.8947V8.81553H11.136Z'
      fill='white'
    />
  </svg>
);

export default Facebook;
