const Down = (props) => (
  <svg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4621 4.0246L13.7879 5.35042L7.50001 11.6383L1.2121 5.35042L2.53792 4.0246L7.50001 8.98668L12.4621 4.0246Z'
    />
  </svg>
);

export default Down;
