const Video = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.6 14.675L17.1 10.5L10.6 6.325V14.675ZM6.5 19C6.1 19 5.75 18.85 5.45 18.55C5.15 18.25 5 17.9 5 17.5V3.5C5 3.1 5.15 2.75 5.45 2.45C5.75 2.15 6.1 2 6.5 2H20.5C20.9 2 21.25 2.15 21.55 2.45C21.85 2.75 22 3.1 22 3.5V17.5C22 17.9 21.85 18.25 21.55 18.55C21.25 18.85 20.9 19 20.5 19H6.5ZM6.5 17.5H20.5V3.5H6.5V17.5ZM3.5 22C3.1 22 2.75 21.85 2.45 21.55C2.15 21.25 2 20.9 2 20.5V5H3.5V20.5H19V22H3.5Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Video;
