import styled, { css } from 'styled-components';

import SwitchComponent from './Switch';

const Switch = styled(SwitchComponent)`
  display: flex;
  text-align: center;
  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: ${({ theme }) => theme.colors.secondary[200]};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 27px;
      width: 27px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    height: 31px;
    width: 51px;
    left: 0px;
    top: 0px;
    border-radius: 30px;
    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: ${({ theme }) => theme.colors.primary[600]};
        &:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
      &:disabled + .slider {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.dark[25]};
        color: red;
        &.round {
          &:before {
            background-color: ${({ theme }) => theme.colors.dark[25]};
            bottom: 1px;
            left: 1px;
          }
        }
        &:checked {
          &:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
            background-color: ${({ theme }) => theme.colors.dark[25]};
          }
        }
      }
    }
  }
  ${(props) =>
    props.$size === 'small' &&
    css`
      .switch {
        height: 19px;
        width: 31px;
        input {
          &:checked + .slider {
            &:before {
              -webkit-transform: translateX(20px);
              -ms-transform: translateX(20px);
              transform: translateX(12px);
            }
          }
        }
      }
      .slider {
        &:before {
          height: 15px;
          width: 15px;
          left: 2px;
          bottom: 2px;
        }
        &.round {
          border-radius: 34px;
          &:before {
            border-radius: 50%;
          }
        }
      }
    `}
`;

export default Switch;
