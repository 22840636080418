import MEMBERS_TABLE_COUNT from 'constants/membersTableCount';
import Pagination from 'rc-pagination';
import Select from 'style-guide/Select';
import Text from 'style-guide/Typography/Text';

const PaginationComponent = ({ onShowSizeChange, showSizeChanger, ...props }) => (
  <div className='pagination-container'>
    <Pagination {...props} />
    {showSizeChanger && (
      <>
        <Text $variant={2}>Show</Text>
        <Select options={MEMBERS_TABLE_COUNT} placeholder='10' className='select' onChange={onShowSizeChange} />
        <Text $variant={2}>results per page</Text>
      </>
    )}
  </div>
);

export default PaginationComponent;
