import styled from 'styled-components';

const DropdownFilterWrapper = styled.div`
  .filter-dropdown {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.secondary[200]};
    padding: 8px 16px;
    border-radius: 4px;
    button {
      margin-top: 8px;
    }
  }

  .title {
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .radio-button {
    margin-top: 10px;
  }

  .dropdown-children {
    display: flex;
    align-items: center;
  }
`;

export default DropdownFilterWrapper;
