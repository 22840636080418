const MultipleAnswer = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect x='5' y='5' width='14' height='14' rx='2' fill='#00B3CC' />
    <path
      d='M11.5043 14.8438C11.2971 14.8438 11.0899 14.7722 10.9318 14.6282L8.73722 12.6307C8.42093 12.3428 8.42093 11.8764 8.73722 11.5894C9.0535 11.3015 9.56493 11.3006 9.88121 11.5885L11.5043 13.0659L15.3219 9.59092C15.6382 9.30303 16.1496 9.30303 16.4659 9.59092C16.7822 9.87882 16.7822 10.3452 16.4659 10.6331L12.0767 14.6282C11.9186 14.7722 11.7114 14.8438 11.5043 14.8438Z'
      fill='white'
    />
  </svg>
);

export default MultipleAnswer;
