import { useSearchParams } from 'react-router-dom';
import testTypes from 'common/test-types';
import Text from 'style-guide/Typography/Text';
import { Down } from 'style-guide/Icons';
import SelectDropdownWrapper from './SelectDropdownWrapper';

const SelectDropdown = () => {
  const [searchParams] = useSearchParams();
  const currentAssessmentType = searchParams.get('assessmentType');
  const { name } = testTypes.getTypeData(currentAssessmentType) || {};

  return (
    <SelectDropdownWrapper>
      <div className='content d-flex align-items-center justify-content-between'>
        <Text $variant={3} className='value'>
          {name || 'Select the type of your question'}
        </Text>
        <Down className='down-icon' height={12} width={12} />
      </div>
    </SelectDropdownWrapper>
  );
};
export default SelectDropdown;
