import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

// react router
import { BrowserRouter } from 'react-router-dom';

import App from 'Components/App';
import { Provider } from 'react-redux';

import store from './store';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
