import { ENTITY_TYPES } from 'common';
import Skeleton from 'style-guide/Skeleton';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION, INVITED, MEMBER } = ENTITY_TYPES;

const heights = {
  [LEARNING_COMMUNITY.key]: 340,
  [DEPARTMENT.key]: 280,
  [ORGANIZATION.key]: 260,
  [INVITED.key]: 340,
  [MEMBER.key]: 190,
  favorites: 340,
  all: 340,
};

const skeletonWrapper = (children, cardSize) => <div className={cardSize}> {children}</div>;

const CardsSkeleton = ({ category, height, isHomePage = false, cardSize, count }) => (
  <div>
    {isHomePage ? (
      <div className='cards'>
        <Skeleton
          skeleton={{
            height: height || heights[category],
            className: 'card-skeleton',
            count,
            wrapper: ({ children }) => skeletonWrapper(children, cardSize),
          }}
        />
      </div>
    ) : (
      <div className='section-box'>
        <div className='skeleton-title mb--16'>
          <Skeleton skeleton={{ width: 260, height: 25 }} />
        </div>
        <div className='cards'>
          <Skeleton
            skeleton={{
              height: height || heights[category],
              count,
              wrapper: ({ children }) => skeletonWrapper(children, cardSize),
            }}
          />
        </div>
      </div>
    )}
  </div>
);

export default CardsSkeleton;
