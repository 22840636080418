import { Check, Close, SandWatch, WinnerCup } from 'style-guide/Icons';
import Image from 'style-guide/Image';

import Title from 'style-guide/Typography/Title';
import isObject from 'utils/isObject';

import imagePlaceholder from 'assets/images/imagePlaceholder.png';
import AssessmentCard from 'Components/InitialThoughtCard';

import { TEST_TYPES } from 'common';
import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';
import theme from 'style-guide/Theme';
import LearnerCardBody from '../LearnerCardBody';

const { TestsTypeCloseExercise, TestsTypeDragAndDrop } = TEST_TYPES.types;

// ToDo: need to change internal data, instead of text return description and description return text

const Question = ({ type, question }) => (
  <Title className='card-title' variant={5}>
    {TestsTypeCloseExercise === type || TestsTypeDragAndDrop === type ? (
      <div dangerouslySetInnerHTML={{ __html: question }} />
    ) : (
      question
    )}
  </Title>
);

const LearnerCard = ({ assessmentInfo = {}, index, control, setValue, answeredTest, isSubmitted }) => {
  const { id: questionId, type, question, text, image, answer, description } = assessmentInfo;
  const isCorrect = answeredTest?.correctAnswers?.[questionId]?.correct;
  const { got, outOf } = isCorrect || {};

  return (
    <AssessmentCard
      title={
        <>
          {answeredTest && isCorrect === true ? (
            <div className='d-flex align-items-center'>
              <Check className='check-icon' />
              <Title variant={5} color={theme().colors.primary[600]}>
                Correct
              </Title>
            </div>
          ) : null}
          {answeredTest && isCorrect === false ? (
            <div className='d-flex align-items-center'>
              <Close className='close-icon' />
              <Title variant={5} color={theme().colors.error[600]}>
                Incorrect
              </Title>
            </div>
          ) : null}
          {answeredTest && isCorrect === null ? (
            <div className='d-flex align-items-center'>
              <SandWatch className='pending-icon' />
              <Title variant={5} className='pending-answer'>
                Pending Review
              </Title>
            </div>
          ) : null}
          {answeredTest && isObject(isCorrect) ? (
            <div className='d-flex align-items-center'>
              <WinnerCup className='scored-icon' />
              <Title variant={5} className='scored-answer'>
                {got} out of {outOf}
              </Title>
            </div>
          ) : null}
        </>
      }
    >
      <div className='card-box'>
        <div className='d-flex flex-column'>
          {image ? (
            <div className='image-box'>
              <Image src={image} defaultImage={imagePlaceholder} className='image' alt='assessment' />
            </div>
          ) : null}
          {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
          {text && <SimplifiedEditor editable={false} content={text} />}
          <Question type={type} question={question} />

          <LearnerCardBody
            index={index}
            type={type}
            answer={answer}
            control={control}
            setValue={setValue}
            questionId={questionId}
            isSubmitted={isSubmitted}
            answeredTest={answeredTest}
          />
        </div>
      </div>
    </AssessmentCard>
  );
};
export default LearnerCard;
