const Download = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.99992 7.17157L6.99993 1.58578L8.99993 1.58578L8.99992 7.17157L12.2928 3.87868L13.707 5.29289L7.99992 11L2.29282 5.29289L3.70703 3.87868L6.99992 7.17157Z'
      fill='#7CB342'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M2 15V13H14V15H2Z' fill='none' />
  </svg>
);

export default Download;
