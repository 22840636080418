const CircleLeft = (props) => (
  <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.70711 8.29289L3.41421 5L6.70711 1.70711L5.29289 0.292892L0.585785 5L5.29289 9.70711L6.70711 8.29289ZM11.7071 8.29289L8.41421 5L11.7071 1.70711L10.2929 0.292892L5.58578 5L10.2929 9.70711L11.7071 8.29289Z'
      fill='#333333'
    />
  </svg>
);

export default CircleLeft;
