const FILE_MANAGER = [
  { label: 'Image', value: 'image', accept: 'image/*' },
  { label: 'Video', value: 'video', accept: 'video/*' },
  { label: 'Audio', value: 'audio', accept: 'audio/*' },
  { label: 'PDF', value: 'pdf', accept: '.pdf' },
  { label: 'PPT', value: 'ppt', accept: '.ppt' },
  { label: 'DOC', value: 'doc', accept: '.doc' },
  { label: 'Excel', value: 'excel', accept: '.xlsx,.xls' },
];

export default FILE_MANAGER;
