import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const LearningCommunityCardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.status[20]};
  border-radius: 8px;
  position: relative;
  background: ${({ theme }) => theme.colors.white[100]};
  &:hover {
    box-shadow: 4px -4px 8px ${({ theme }) => theme.colors.boxShadow[15]};
  }
  .footer-container {
    height: 36px;
  }
  .user-type-text {
    line-height: 22px;
  }

  .dropdown-children {
    position: absolute;
    top: 24px;
    right: 24px;
    visibility: hidden;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    @media only screen and (min-width: ${SCREEN_SIZES.XS}px) and (max-width: ${SCREEN_SIZES.XL}px) {
      visibility: visible;
    }
  }
  &:hover {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]};
    .dropdown-children {
      visibility: visible;
    }
  }
  .heart {
    position: absolute;
    top: 24px;
    right: 20px;
    .full-heart {
      path {
        fill: ${({ theme }) => theme.colors.error[200]};
      }
    }
    .empty-heart {
      path {
        fill: ${({ theme }) => theme.colors.gray[40]};
      }
    }
  }
  .background-wrapper {
    line-height: 0;
    img {
      height: 128px;
      width: 100%;
      border-radius: 8px;
    }
  }
  .course-title {
    font-weight: 500;
    height: 32px;
  }
  .course-description {
    display: block !important;
    line-height: 22px;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .lc-name {
    display: block !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    height: 32px;
    max-width: 400px;
    overflow: hidden;
  }
  .navigate-button {
    display: inline !important;
  }
  .last-updated-content {
    min-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar-container {
    min-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default LearningCommunityCardWrapper;
