const Eye = (props) => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.6976 2.85215C12.1945 1.08475 10.2967 0 8 0C5.70334 0 3.80551 1.08475 2.30242 2.85215C1.42476 3.88415 0.859203 4.91103 0.571811 5.62789L0.422729 5.99975L0.571639 6.37168C0.858688 7.08865 1.42384 8.11563 2.30131 9.14773C3.804 10.9152 5.70217 12 8 12C10.2978 12 12.196 10.9152 13.6987 9.14773C14.5762 8.11563 15.1413 7.08865 15.4284 6.37168L15.5773 5.99975L15.4282 5.62789C15.1408 4.91103 14.5752 3.88415 13.6976 2.85215ZM12.174 4.14785C12.5924 4.63975 12.9485 5.17075 13.2406 5.70117C13.301 5.81079 13.3537 5.91104 13.3988 6.00022C13.3538 6.08936 13.3011 6.18957 13.2408 6.29913C12.949 6.82949 12.5931 7.36042 12.1749 7.85227C11.0208 9.20978 9.63807 10 8 10C6.36192 10 4.97919 9.20978 3.82506 7.85227C3.4069 7.36042 3.05103 6.82949 2.75917 6.29913C2.69888 6.18957 2.64622 6.08936 2.60115 6.00022C2.64628 5.91104 2.69901 5.81079 2.75938 5.70117C3.05152 5.17075 3.40762 4.63975 3.82596 4.14785C4.98053 2.79025 6.36311 2 8 2C9.63688 2 11.0195 2.79025 12.174 4.14785ZM8 9C6.34314 9 5 7.65685 5 6C5 4.34315 6.34314 3 8 3C9.65685 3 11 4.34315 11 6C11 7.65685 9.65685 9 8 9ZM9 6C9 6.55228 8.55228 7 8 7C7.44771 7 7 6.55228 7 6C7 5.44772 7.44771 5 8 5C8.55228 5 9 5.44772 9 6Z'
      fill='#333333'
    />
  </svg>
);

export default Eye;
