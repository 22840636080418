import styled from 'styled-components';

const TrueFalseWrapper = styled.div`
  .label {
    margin-bottom: 12px;
  }
  .true-false-container {
    margin-bottom: 20px;
    .radio-button-box {
      cursor: pointer;
      width: 168px;
      height: 33px;
      margin-top: 8px;
      border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
      background-color: ${({ theme }) => theme.colors.secondary[50]};
      box-sizing: border-box;
      border-radius: 4px;
      label {
        margin-left: 10px;
      }
      label,
      .radio-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .has-error {
    border: 1px solid ${({ theme }) => theme.colors.error[600]};
    background-color: ${({ theme }) => theme.colors.error[200]};
  }
  .success {
    border: 1px solid ${({ theme }) => theme.colors.primary[600]};
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`;

export default TrueFalseWrapper;
