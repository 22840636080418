const NumListIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9688 14.8438H6.5625C6.47656 14.8438 6.40625 14.9141 6.40625 15V16.0938C6.40625 16.1797 6.47656 16.25 6.5625 16.25H17.9688C18.0547 16.25 18.125 16.1797 18.125 16.0938V15C18.125 14.9141 18.0547 14.8438 17.9688 14.8438ZM17.9688 3.75H6.5625C6.47656 3.75 6.40625 3.82031 6.40625 3.90625V5C6.40625 5.08594 6.47656 5.15625 6.5625 5.15625H17.9688C18.0547 5.15625 18.125 5.08594 18.125 5V3.90625C18.125 3.82031 18.0547 3.75 17.9688 3.75ZM17.9688 9.29688H6.5625C6.47656 9.29688 6.40625 9.36719 6.40625 9.45312V10.5469C6.40625 10.6328 6.47656 10.7031 6.5625 10.7031H17.9688C18.0547 10.7031 18.125 10.6328 18.125 10.5469V9.45312C18.125 9.36719 18.0547 9.29688 17.9688 9.29688ZM4.21875 13.9062H1.95312C1.91016 13.9062 1.875 13.9414 1.875 13.9844V14.6484C1.875 14.6914 1.91016 14.7266 1.95312 14.7266H3.36719V15.127H2.66992C2.62695 15.127 2.5918 15.1621 2.5918 15.2051V15.8691C2.5918 15.9121 2.62695 15.9473 2.66992 15.9473H3.36719V16.3672H1.95312C1.91016 16.3672 1.875 16.4023 1.875 16.4453V17.1094C1.875 17.1523 1.91016 17.1875 1.95312 17.1875H4.21875C4.26172 17.1875 4.29688 17.1523 4.29688 17.1094V13.9844C4.29688 13.9414 4.26172 13.9062 4.21875 13.9062ZM1.95312 3.67188H2.69531V6.01562C2.69531 6.05859 2.73047 6.09375 2.77344 6.09375H3.55469C3.59766 6.09375 3.63281 6.05859 3.63281 6.01562V2.96875C3.63281 2.88281 3.5625 2.8125 3.47656 2.8125H1.95312C1.91016 2.8125 1.875 2.84766 1.875 2.89062V3.59375C1.875 3.63672 1.91016 3.67188 1.95312 3.67188ZM4.21875 8.35938H1.95312C1.91016 8.35938 1.875 8.39453 1.875 8.4375V9.14062C1.875 9.18359 1.91016 9.21875 1.95312 9.21875H3.28906L1.91602 10.7363C1.89012 10.7654 1.87556 10.8029 1.875 10.8418V11.5625C1.875 11.6055 1.91016 11.6406 1.95312 11.6406H4.21875C4.26172 11.6406 4.29688 11.6055 4.29688 11.5625V10.8594C4.29688 10.8164 4.26172 10.7812 4.21875 10.7812H2.88281L4.25586 9.26367C4.28176 9.23458 4.29632 9.19714 4.29688 9.1582V8.4375C4.29688 8.39453 4.26172 8.35938 4.21875 8.35938Z'
      fill='#818181'
    />
  </svg>
);

export default NumListIcon;
