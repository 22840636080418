import emptySplitApi from '..';

const adminOrganizationsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminOrganizations: build.query({
      query: (params) => ({
        url: `admin/organizations`,
        method: 'GET',
        params,
      }),
      providesTags: ['admin-organizations', 'isArchive'],
    }),
    deleteOrganization: build.mutation({
      query: (params) => ({
        url: `admin/organizations/${params.id}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['admin-organizations'],
    }),
  }),
});

export default adminOrganizationsApi;
export const { useGetAdminOrganizationsQuery, useDeleteOrganizationMutation } = adminOrganizationsApi;
