import styled from 'styled-components';

const MultipleBlanksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .no-data {
    position: relative !important;
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }

  .wrong-answer {
    margin-top: 48px;
  }

  .wrong-answer-button {
    margin-top: 12px;
    display: flex;
    justify-content: start;
  }

  .answers-fields-wrapper {
    margin-top: 16px;
  }
`;
export default MultipleBlanksWrapper;
