import styled from 'styled-components';

const ToggleFilterWrapper = styled.div`
  &.filter-icon {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 16px;
    cursor: pointer;

    .filter {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary[600]};
    }
  }
`;

export default ToggleFilterWrapper;
