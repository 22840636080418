import { ACCESS_TYPES, ENTITY_TYPES, PAYMENT_TYPES, STRING_UTILS } from 'common';
import { getOrgPrice } from 'common/entity-types';
import { useEffect } from 'react';
import { useBuyAccessCodeMutation } from 'store/services/accessCode';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

const { isPrivatePrePaid, isPublicPaid, isFree } = PAYMENT_TYPES;
const { AccessTypePrivate } = ACCESS_TYPES;
const { ORGANIZATION } = ENTITY_TYPES;

const { cleanUpPrice } = STRING_UTILS;

const AccessCodeField = ({ data = {}, entityName, type, formMethods, setDisabled }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;

  const { name, paymentTypeId, paidPlanTypeId, subscriptionPlan, price } = data;

  const privatePrePaid = isPrivatePrePaid(data);
  const publicPaid = isPublicPaid(data);
  const free = isFree(data);

  const showAccessCode = !free && !publicPaid;

  const [{ data: result, isSuccess }] = useBuyAccessCodeMutation();

  const isPrivateAccessType = data?.accessTypeId === AccessTypePrivate;
  const isOrganization = type === ORGANIZATION.singular.toLowerCase();
  const entityPrice = isOrganization
    ? getOrgPrice({ paymentTypeId, price, paidPlanTypeId, subscriptionPlan })
    : cleanUpPrice({ string: price, symbol: '$' });

  const finalPrice = isPrivateAccessType ? `$${data?.accessCodeResalePrice}` : entityPrice;

  const field = register(`${type}_access_code`, {
    required: { value: privatePrePaid, message: 'This field is required' },
  });

  const accessCode = data[field.name];
  useEffect(() => {
    if (accessCode) {
      setValue(field.name, accessCode);
    }
  }, [accessCode, field.name, setValue]);

  const watchPrivatePrePaidField = watch(field.name);
  useEffect(() => {
    if (privatePrePaid) {
      setDisabled((items) => ({ ...items, [type]: !watchPrivatePrePaidField }));
    }
  }, [privatePrePaid, setDisabled, type, watchPrivatePrePaidField]);

  useEffect(() => {
    if (isSuccess) {
      setValue(field.name, result.code);
    }
  }, [field.name, isSuccess, result?.code, setValue]);

  return (
    <div className='row'>
      <div className='col-12'>
        <Title variant={5} className='mb--12'>
          {entityName}
        </Title>
      </div>
      <div className='col-12 d-flex justify-content-between'>
        <Text $variant={2} className='entity-name-text'>
          {name}
        </Text>
        <Title type='secondary' $dark>
          {finalPrice}
        </Title>
      </div>
      {showAccessCode ? <Input label='Access Code' error={errors[field.name]} className='flex-1' {...field} /> : null}
    </div>
  );
};

export default AccessCodeField;
