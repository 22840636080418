import styled from 'styled-components';

const DropdownComponentWrapper = styled.div`
  .search-icon {
    path {
      fill: ${({ theme }) => theme.colors.secondary[400]};
    }
  }

  .title {
    margin-right: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .search-dropdown {
    background-color: ${({ theme }) => theme.colors.secondary[200]};
    padding: 8px;
    border-radius: 4px;
    input {
      margin-bottom: 0 !important;
    }
  }
  .dropdown-children {
    width: 100%;
  }
  .dropdown-buttons {
    margin-top: 8px;
    .search-button {
      margin-right: 8px;
    }
  }
`;

export default DropdownComponentWrapper;
