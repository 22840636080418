import classNames from 'classnames';
import EntityCard from 'Components/NewCards/ColDepCard';
import LearningCommunityCard from 'Components/NewCards/LearningCommunityCard';
import { DASHBOARD_CATEGORIES } from 'constants/DropdownOptions/dashboard';
import PATHS from 'constants/paths';
import { useMemo } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';

const COMPONENTS = {
  [DASHBOARD_CATEGORIES[0].value]: LearningCommunityCard,
  [DASHBOARD_CATEGORIES[1].value]: EntityCard,
  [DASHBOARD_CATEGORIES[2].value]: EntityCard,
  [DASHBOARD_CATEGORIES[3].value]: LearningCommunityCard,
};

const CardList = ({ data, category, colSpan }) => {
  const { search } = useLocation();

  const Card = useMemo(() => COMPONENTS[category], [category]);
  const categoryName = DASHBOARD_CATEGORIES.find((e) => e.value === category);
  const total = data?.total || 0;

  return data?.rows?.length ? (
    <div className='body-root'>
      <Title variant={5} className='title'>
        {categoryName.name}
      </Title>
      <div className='overflow-wrapper'>
        <div className='row cards'>
          {data.rows.map((el) => (
            <div key={el.id} className={colSpan}>
              <Card data={el} entityType={category} />
            </div>
          ))}
        </div>
      </div>
      <Link to={generatePath(PATHS.EXPLORE, { category }) + search}>
        <Button variant='link' className={classNames('more-button', { invisible: total < 5 })}>
          See More
        </Button>
      </Link>
    </div>
  ) : null;
};

export default CardList;
