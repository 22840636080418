import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import classNames from 'classnames';
import { SYSTEM_LIMITS } from 'common';
import Button from 'style-guide/Button';
import { Minus, Plus } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import Title from 'style-guide/Typography/Title';
import AddHyperlink from './AddHyperlink';

const { TESTS } = SYSTEM_LIMITS.COURSE_SECTIONS;

const Feedback = ({ control, register, errors, hasFeedback }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const feedbackButtonText = `${showFeedback ? 'Remove' : 'Add'} Feedback`;

  useEffect(() => {
    setShowFeedback(hasFeedback);
  }, [hasFeedback]);

  return (
    <>
      <Title variant={5} className='feedback-title'>
        Feedback
      </Title>
      <Button
        variant='link'
        prefix={showFeedback ? <Minus /> : <Plus />}
        className={classNames('add-feedback-button', { open: showFeedback })}
        onClick={() => setShowFeedback((item) => !item)}
      >
        {feedbackButtonText}
      </Button>
      {showFeedback && (
        <>
          <Controller
            control={control}
            name='feedback.correct.text'
            render={({ field }) => (
              <Input
                {...field}
                error={errors?.feedback?.correct?.text}
                label='Correct Answer Feedback'
                maxLength={TESTS.VALUE.max}
              />
            )}
          />
          <AddHyperlink
            control={control}
            register={register}
            name='feedback.correct.hyperlinks'
            errors={errors.feedback?.correct?.hyperlinks}
          />
          <Controller
            control={control}
            name='feedback.wrong.text'
            render={({ field }) => (
              <Input
                {...field}
                error={errors.feedback?.wrong?.text}
                label='Wrong Answer Feedback'
                maxLength={TESTS.VALUE.max}
              />
            )}
          />
          <AddHyperlink
            control={control}
            register={register}
            name='feedback.wrong.hyperlinks'
            errors={errors.feedback?.wrong?.hyperlinks}
          />
        </>
      )}
    </>
  );
};

export default Feedback;
