import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action) {
      const { payload } = action;
      state.push(payload);
    },
    hideModal(state, action) {
      const { payload } = action;
      const index = state.findIndex((el) => el.type === payload.type);
      state.splice(index, 1);
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
