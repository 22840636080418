import { SYSTEM_LIMITS } from 'common';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';

import Button from 'style-guide/Button';
import { Plus, Trash } from 'style-guide/Icons';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';

import theme from 'style-guide/Theme';
import MultipleChoiceWrapper from './style/MultipleChoiceWrapper';

const {
  COURSE_SECTIONS: { TESTS },
} = SYSTEM_LIMITS;

const MultipleChoice = ({ control }) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'answer',
  });

  const answer = useWatch({ name: 'answer', control });

  return (
    <MultipleChoiceWrapper>
      <div className='row'>
        <div className='col-12'>
          <Text $variant={3} color={theme().colors.dark[60]} className='label'>
            *Check the answer that is correct
          </Text>
        </div>
        <div className='col-12'>
          <Button
            variant='link'
            disabled={fields.length >= TESTS.ANSWER.LIST.max}
            prefix={<Plus />}
            onClick={() => {
              append({ answer: '', isTrue: false });
            }}
          >
            Add New Option
          </Button>
        </div>

        <div className='col-12 options-box'>
          {fields.map((field, index) => (
            <div className='d-flex align-items-end' key={field.id}>
              <div className='d-flex flex-1 flex-column'>
                <Text $variant={1} color={theme().colors.dark[80]} className='d-flex justify-content-end'>
                  {answer[index]?.answer?.length || 0}/{TESTS.VALUE.max}
                </Text>
                <div className='radio-button-box d-flex align-items-center'>
                  <Controller
                    control={control}
                    name={`answer.${index}.isTrue`}
                    render={({ field: { ref, value } }) => (
                      <Radio
                        value={index}
                        ref={ref}
                        checked={value}
                        name={`answer.${index}.isTrue`}
                        onChange={() => {
                          fields.forEach((_e, i) => update(i, { ...answer[i], isTrue: index === i }));
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`answer.${index}.answer`}
                    render={({ field: answerField }) => (
                      <input
                        className='multiple-choice-input'
                        type='text'
                        maxLength={TESTS.VALUE.max}
                        placeholder='Option'
                        {...answerField}
                      />
                    )}
                  />
                </div>
              </div>
              {fields.length > TESTS.ANSWER.LIST.min ? (
                <div className='trash-icon-wrapper'>
                  <Button variant='text' $danger onClick={() => remove(index)}>
                    <Trash />
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </MultipleChoiceWrapper>
  );
};
export default MultipleChoice;
