const BoldIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.6289 9.40234C14.2852 8.71875 14.6875 7.79492 14.6875 6.7793V6.58008C14.6875 4.47852 12.9668 2.77344 10.8457 2.77344H5.06641C4.77148 2.77344 4.53125 3.01367 4.53125 3.30859V16.5723C4.53125 16.8906 4.78906 17.1484 5.10742 17.1484H11.332C13.6172 17.1484 15.4688 15.3086 15.4688 13.0371V12.8223C15.4688 11.3965 14.7383 10.1406 13.6289 9.40234ZM6.40625 4.64844H10.7949C11.9102 4.64844 12.8125 5.51562 12.8125 6.58789V6.77344C12.8125 7.84375 11.9082 8.71289 10.7949 8.71289H6.40625V4.64844ZM13.5664 13.0352C13.5664 14.2637 12.5566 15.2598 11.3105 15.2598H6.40625V10.5996H11.3105C12.5566 10.5996 13.5664 11.5957 13.5664 12.8242V13.0352Z'
      fill='#818181'
    />
  </svg>
);

export default BoldIcon;
