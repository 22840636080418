import classNames from 'classnames';
import RCInputNumber from 'rc-input-number';
import RCTextArea from 'rc-textarea';
import { forwardRef, useMemo } from 'react';
import { CheckCircleOutlined, CircleClose, CircleInfo, Help } from 'style-guide/Icons';
import theme from 'style-guide/Theme';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import InputWrapper from './InputWrapper';

const icons = { error: CircleClose, info: CircleInfo, success: CheckCircleOutlined };

const getSuffixIcon = (inputVariant, Suffix) => {
  if (Suffix) {
    return Suffix;
  }
  return icons[inputVariant];
};

const Component = forwardRef(
  (
    {
      className,
      inputVariant = 'primary',
      label,
      variant,
      required,
      size,
      error,
      helperText,
      maxLength,
      tooltip,
      Prefix,
      Suffix,
      ...props
    },
    ref
  ) => {
    const getInputVariant = error ? 'error' : inputVariant;
    const textVariant = size === 'large' ? 3 : 2;
    const SuffixIcon = getSuffixIcon(getInputVariant, Suffix);
    const inputPadding = `mb--${helperText || error ? 4 : 24}`;

    const elementTypes = useMemo(
      () => ({
        input: (
          <input
            {...props}
            className={classNames({ 'pr--32': SuffixIcon }, { 'pl--32': Prefix }, 'ph--12')}
            ref={ref}
          />
        ),
        number: <RCInputNumber {...props} ref={ref} />,
        textArea: <RCTextArea {...props} ref={ref} />,
      }),
      [Prefix, SuffixIcon, props, ref]
    );

    return (
      <InputWrapper
        className={classNames(className, variant === 'input' ? inputPadding : null)}
        inputVariant={getInputVariant}
        size={size}
      >
        {label || maxLength ? (
          <div className='d-flex align-items-center'>
            <div className='input-label d-flex justify-content-between'>
              <div className='mb--4'>
                {required ? (
                  <Text $variant={textVariant} className='required-symbol mr--4'>
                    *
                  </Text>
                ) : null}
                <Text $variant={textVariant}>{label}</Text>
                {tooltip && (
                  <Tooltip overlay={tooltip} overlayInnerStyle={{ maxWidth: '300px' }}>
                    <Help width={14} height={14} className='ml--4 help-icon' />
                  </Tooltip>
                )}
              </div>
              {maxLength ? (
                <Text $variant={1} color={theme().colors.dark[80]} className='mb--4'>
                  {props.value?.length || 0}/{maxLength}
                </Text>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className='input-content'>
          {Prefix && (
            <span className='prefix-icon d-flex'>
              <Prefix width={16} height={16} />
            </span>
          )}
          {elementTypes[variant]}
        </div>
        {helperText ? (
          <Text $variant={1} className='pl--12'>
            {helperText}
            {error?.message && ':'}
          </Text>
        ) : null}
        {error?.message ? (
          <Text $variant={1} className={classNames({ 'pl--12': !helperText }, 'error-message pl--4')}>
            {error.message}
          </Text>
        ) : null}
      </InputWrapper>
    );
  }
);

const InputNumber = forwardRef((props, ref) => <Component ref={ref} {...props} variant='number' />);

const Input = forwardRef((props, ref) => (
  <Component type='text' placeholder='Write here' ref={ref} {...props} variant='input' />
));

const TextArea = forwardRef((props, ref) => (
  <Component placeholder='Write here' ref={ref} {...props} variant='textArea' />
));

export { Input, InputNumber, TextArea };
