import NoDataComponent from 'Components/NoData/NoData';
import { Controller, useWatch } from 'react-hook-form';
import Button from 'style-guide/Button';
import { Plus, Trash } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';
import MultipleBlanksWrapper from './style/MultipleBlanksWrapper';

const WrongAnswers = ({ fields, control, setValue }) => {
  const removeField = (i) => {
    fields.splice(i, 1);
    setValue('answer.wrongAnswers', [...fields]);
  };

  return fields?.map((field, index) => (
    <div className='row' key={field.id}>
      <div className='col d-flex align-items-center'>
        <Controller
          control={control}
          name={`answer.wrongAnswers.${index}.answer`}
          render={({ field: answerField }) => <Input {...answerField} maxLength={50} />}
        />
        {fields.length > 1 && (
          <div className='ml--8'>
            <Button variant='text' $danger onClick={() => removeField(index)}>
              <Trash />
            </Button>
          </div>
        )}
      </div>
    </div>
  ));
};

const CorrectAnswers = ({ fields, control }) =>
  fields?.map((field, index) => (
    <div className='row' key={field.name}>
      <div className='col d-flex align-items-center'>
        <Controller
          control={control}
          name={`answer.correctAnswers.${index}.answer`}
          render={({ field: answerField }) => (
            <Input {...answerField} maxLength={50} label={`Correct answer of blank ${index + 1}`} />
          )}
        />
      </div>
    </div>
  ));
const MultipleBlanks = ({ control, setValue }) => {
  const answer = useWatch({ name: 'answer', control });

  return (
    <MultipleBlanksWrapper>
      <div className='col-12'>
        <Text $variant={3} dark={60}>
          *Check the answer that is correct
        </Text>
      </div>
      {answer?.correctAnswers?.length ? (
        <div className='answers-fields-wrapper'>
          <CorrectAnswers fields={answer?.correctAnswers} control={control} />
        </div>
      ) : (
        <div className='no-data'>
          <NoDataComponent />
        </div>
      )}
      <div className='col-12'>
        <Text $variant={3} dark={60} className='wrong-answer'>
          Check the answer that is wrong
        </Text>
      </div>
      <Button
        variant='link'
        prefix={<Plus />}
        className='wrong-answer-button'
        onClick={() => setValue('answer.wrongAnswers', [...answer.wrongAnswers, { answer: '' }])}
      >
        Add Wrong Answer
      </Button>
      {answer?.wrongAnswers?.length || answer?.correctAnswers?.length ? (
        <div className='answers-fields-wrapper'>
          <WrongAnswers fields={answer?.wrongAnswers} control={control} setValue={setValue} />
        </div>
      ) : (
        <div className='no-data'>
          <NoDataComponent />
        </div>
      )}
    </MultipleBlanksWrapper>
  );
};

export default MultipleBlanks;
