import { LEARNING_COMMUNITY_SECTIONS, USER_COURSE_TYPES } from 'common';
import { getChaptersList, getFirstSectionKey } from 'common/course-sections-order';

const { organizer } = USER_COURSE_TYPES.userCourseRole;
const isLockedChapter = (data) =>
  (data?.sections?.test && !data.isTestComplete) || (data.completeVideoToNavigate && !data?.areResourcesComplete);

const disabledChapter = (e, current, chapters, result, hasEditAccess, completeVideoToNavigate) => {
  const prevVolumeChapter = result?.at(-1)?.chapters?.at(-1);
  const isPrevChapterDisabled = chapters.at(-1)?.isChapterDisabled;
  const isChapterDisabled = !hasEditAccess && (isPrevChapterDisabled || isLockedChapter(e, completeVideoToNavigate));

  if (prevVolumeChapter?.isChapterDisabled || isPrevChapterDisabled) {
    return {
      showChapter: false,
      isChapterDisabled: true,
    };
  }

  return {
    showChapter:
      hasEditAccess ||
      !isPrevChapterDisabled ||
      (e.position === 0 && current.position === 0) ||
      (e.position === 0 && current.position && !isPrevChapterDisabled),
    isChapterDisabled,
  };
};

const transformVolumeResponse = (response, role) => {
  const { hasEditAccess, sectionsOrder, completeVideoToNavigate } = response?.[0] || {};
  const isOrganizer = role === organizer;
  const sectionFirstKey = getFirstSectionKey(sectionsOrder);
  const isInitialThoughtsFirst = sectionFirstKey === LEARNING_COMMUNITY_SECTIONS.INITIAL_THOUGHTS.value;

  return response?.reduce?.(
    (result, current) => [
      ...result,
      {
        ...current,
        chapters: current.chapters.reduce(
          (chapters, e) => [
            ...chapters,
            {
              ...e,
              ...disabledChapter(e, current, chapters, result, hasEditAccess, completeVideoToNavigate),
              chapterSection: getChaptersList({
                sections: e.sections,
                hasEditAccess,
                disabled: isOrganizer ? false : completeVideoToNavigate && !e?.areResourcesComplete,
                initialThoughtsFirst: isInitialThoughtsFirst,
                role,
              }),
            },
          ],
          []
        ),
      },
    ],
    []
  );
};

export default transformVolumeResponse;
