const Google = (props) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.6801 8.17778C15.6801 7.52001 15.6268 7.04001 15.5112 6.54224H8.00012V9.51109H12.409C12.3201 10.2489 11.8401 11.36 10.7734 12.1066L10.7585 12.206L13.1334 14.0458L13.2979 14.0622C14.809 12.6667 15.6801 10.6133 15.6801 8.17778Z'
      fill='#4285F4'
    />
    <path
      d='M7.99996 16C10.1599 16 11.9732 15.2889 13.2977 14.0622L10.7733 12.1066C10.0977 12.5777 9.19105 12.9066 7.99996 12.9066C5.88442 12.9066 4.08888 11.5111 3.44881 9.58223L3.355 9.5902L0.885566 11.5013L0.853271 11.5911C2.16881 14.2044 4.87104 16 7.99996 16Z'
      fill='#34A853'
    />
    <path
      d='M3.44886 9.58222C3.27997 9.08445 3.18223 8.55108 3.18223 7.99999C3.18223 7.44885 3.27997 6.91553 3.43997 6.41776L3.4355 6.31175L0.935121 4.36993L0.853313 4.40885C0.311115 5.49331 0 6.71111 0 7.99999C0 9.28888 0.311115 10.5066 0.853313 11.5911L3.44886 9.58222Z'
      fill='#FBBC05'
    />
    <path
      d='M7.99996 3.09331C9.50216 3.09331 10.5155 3.7422 11.0933 4.28446L13.351 2.08C11.9644 0.791115 10.1599 0 7.99996 0C4.87104 0 2.16881 1.79554 0.853271 4.40886L3.43993 6.41778C4.08888 4.48889 5.88442 3.09331 7.99996 3.09331Z'
      fill='#EB4335'
    />
  </svg>
);

export default Google;
