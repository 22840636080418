const Vimeo = () => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15 0.5C6.71601 0.5 0 7.2157 0 15.5C0 23.7843 6.71601 30.5 15 30.5C23.284 30.5 30 23.7843 30 15.5C30 7.2157 23.284 0.5 15 0.5ZM25.6045 10.565C25.5106 12.6317 24.0657 15.4629 21.2744 19.0558C18.3888 22.809 15.9465 24.6857 13.9476 24.6857C12.7111 24.6857 11.6642 23.5431 10.8083 21.2566C10.2371 19.1614 9.66537 17.0671 9.09453 14.9715C8.46015 12.6863 7.77882 11.5421 7.04962 11.5421C6.89125 11.5421 6.33453 11.877 5.38281 12.543L4.38476 11.2555C5.43161 10.3357 6.46557 9.41407 7.48235 8.49151C8.88031 7.28445 9.9287 6.64885 10.6287 6.5844C12.2808 6.42573 13.2979 7.55637 13.6797 9.97386C14.0922 12.5838 14.3773 14.207 14.5375 14.8417C15.0135 17.0069 15.538 18.0884 16.1095 18.0884C16.5542 18.0884 17.2226 17.3853 18.112 15.9821C19.0008 14.5774 19.4771 13.5082 19.5413 12.7756C19.668 11.563 19.1914 10.9557 18.112 10.9557C17.6032 10.9557 17.0787 11.0717 16.5404 11.304C17.5835 7.88568 19.5772 6.2244 22.5201 6.31831C24.7022 6.38245 25.73 7.7979 25.6045 10.565Z'
      fill='#607D8B'
    />
  </svg>
);

export default Vimeo;
