import styled from 'styled-components';

const StaticPagesModalWrapper = styled.div`
  width: 700px;
  max-height: 500px;
  overflow-y: auto;
  .line-container {
    border-bottom: 1px solid ${({ theme }) => theme.colors.dark[25]};
    margin-bottom: 12px;
  }

  .menu-block {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.white[100]};
  }

  .description-editor {
    height: 400px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 8px;
    overflow-y: auto;
  }
`;

export default StaticPagesModalWrapper;
