/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Check, Close } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

import imagePlaceholder from 'assets/images/imagePlaceholder.png';
import AssessmentCard from 'Components/InitialThoughtCard';
import theme from 'style-guide/Theme';

const MultipleBlanksCard = ({ answer = [], question, setValue, index, assessmentInfo, answeredTest }) => {
  const { type, id: questionId, text, image } = assessmentInfo;
  const [blanksCount, setBlanksCount] = useState(0);

  const initialBlanksCount = question?.match(/<span/g)?.length || [].length;
  const isCorrect = answeredTest?.correctAnswers?.[questionId]?.correct;
  const answersIds = answeredTest?.userAnswers?.[questionId];

  const container = document.querySelector('.answers-container');
  const filledBlanks = document.querySelectorAll('.filled-blank');

  const allowDrop = (evt) => {
    evt.preventDefault();
  };

  const drag = (evt) => {
    evt.dataTransfer.setData('id', evt.target.id);
  };

  const drop = (evt) => {
    const itemId = evt.dataTransfer.getData('id');
    const elem = document.getElementById(itemId);
    evt.target.replaceWith(elem);
    elem.setAttribute('draggable', false);
    elem.classList.add('filled-blank');
    setBlanksCount((prev) => ++prev);
  };

  const handleClick = (e) => {
    const node = document.createElement('span');
    node.innerHTML = 'drop here';
    node.className = 'question-blank';
    node.ondragover = allowDrop;
    node.ondrop = drop;

    e.target.replaceWith(node);
    e.target.setAttribute('draggable', true);
    e.target.classList.add('grab');
    e.target.classList.remove('filled-blank');
    container.append(e.target);
    setBlanksCount((prev) => --prev);
  };

  useEffect(() => {
    if (filledBlanks.length) {
      filledBlanks.forEach((blank) => blank.addEventListener('click', handleClick));
      return () => filledBlanks.forEach((blank) => blank.removeEventListener('click', handleClick));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filledBlanks]);

  useEffect(() => {
    const blanks = document.querySelectorAll('.blank');
    const learnerBlanks = document.querySelectorAll('.learner-blank');

    if (!answeredTest) {
      blanks.forEach((el) => {
        el.ondragover = allowDrop;
        el.ondrop = drop;
        el.textContent = 'drop here';
        el.className = 'question-blank';
      });
      learnerBlanks.forEach((el) => {
        el.setAttribute('draggable', true);
        el.classList.add('grab');
        el.ondragstart = drag;
      });
    } else {
      answersIds?.forEach((id, i) => {
        const elem = document.getElementById(id);
        blanks[i]?.replaceWith(elem);
      });
    }
  }, [answeredTest, answersIds, container]);

  useEffect(() => {
    if (blanksCount === initialBlanksCount) {
      const idsArray = [];
      filledBlanks.forEach((el) => idsArray.push(el.id));
      setValue(`answers.${index}`, { type, questionId, answer: idsArray });
    }
  }, [index, questionId, setValue, type, blanksCount, initialBlanksCount, filledBlanks]);

  return (
    <AssessmentCard
      title={
        <>
          <Title className='card-title' variant={5}>
            {
              // eslint-disable-next-line
              <div dangerouslySetInnerHTML={{ __html: question }} />
            }
          </Title>
          {answeredTest && isCorrect ? (
            <div className='d-flex align-items-center'>
              <Check className='check-icon' />
              <Title variant={5} color={theme().colors.primary[600]}>
                Correct
              </Title>
            </div>
          ) : null}

          {answeredTest && !isCorrect ? (
            <div className='d-flex align-items-center'>
              <Close className='close-icon' />
              <Title variant={5} color={theme().colors.error[600]}>
                Incorrect
              </Title>
            </div>
          ) : null}
        </>
      }
    >
      <div className='card-box'>
        <div className='d-flex flex-column'>
          {text ? (
            <Text $variant={2} $dark={100}>
              {text}
            </Text>
          ) : null}
          {image ? (
            <div className='image-box'>
              <Image src={image} defaultImage={imagePlaceholder} className='image' alt='assessment' />
            </div>
          ) : null}

          <div className='answers-container'>
            {answer.map((el) => (
              <Text key={el.id} $variant={1} className='learner-blank' id={el.id}>
                {el.answer}
              </Text>
            ))}
          </div>
        </div>
      </div>
    </AssessmentCard>
  );
};

export default MultipleBlanksCard;
