const Left = (props) => (
  <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.49455 6L6.74874 1.74581L5.92378 0.920855L0.844637 6L5.92378 11.0791L6.74874 10.2542L2.49455 6Z'
      fill='#333333'
    />
  </svg>
);

export default Left;
