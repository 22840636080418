import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from 'style-guide/Button';
import DropdownV2 from 'style-guide/DropdownV2';
import { Filter } from 'style-guide/Icons';
import Radio from 'style-guide/Radio';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import DropdownFilterWrapper from './style/DropdownFilterWrapper';

const DropDownFilter = ({ name, firstParam, secondParam }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedValue, setCheckedValue] = useState(null);
  const searchValue = searchParams.get(name);

  const reset = () => {
    if (searchParams.get(name)) {
      searchParams.delete(name);
      setSearchParams(searchParams);
    }
    setCheckedValue(null);
  };

  useEffect(() => {
    if (checkedValue) {
      searchParams.set(name, checkedValue);
      setSearchParams(searchParams);
    }

    if (!checkedValue && searchValue) {
      searchParams.delete(name);
      setSearchParams(searchParams);
    }
  }, [checkedValue, name, searchParams, searchValue, setSearchParams]);

  return (
    <DropdownFilterWrapper>
      <div className='d-flex justify-content-center'>
        <Title variant={5} className='title'>
          Access type
        </Title>
        <DropdownV2
          overlay={
            <div className='filter-dropdown'>
              <Radio
                checked={checkedValue === '1'}
                value='1'
                onChange={(e) => setCheckedValue(e.target.value)}
                className='radio-button'
              >
                <Text $variant={2}>{firstParam}</Text>
              </Radio>
              <Radio
                checked={checkedValue === '2'}
                value='2'
                onChange={(e) => setCheckedValue(e.target.value)}
                className='radio-button'
              >
                <Text $variant={2}>{secondParam}</Text>
              </Radio>
              <Button variant='secondary' onClick={reset}>
                Reset
              </Button>
            </div>
          }
          placement='bottom'
        >
          <Filter width={16} className='pointer' />
        </DropdownV2>
      </div>
    </DropdownFilterWrapper>
  );
};

export default DropDownFilter;
