import moment from 'moment';
import Button from 'style-guide/Button';
import Text from 'style-guide/Typography/Text';

const NewAdminFooter = ({ url, lastChange, price, accessCodeResalePrice }) => (
  <>
    <div className='d-flex justify-content-between'>
      <Text $variant={2} paragraph className='last-updated-content'>
        Last edited: {moment(lastChange).fromNow()}
      </Text>
      <Text $variant={2} paragraph>
        {accessCodeResalePrice ? `$${accessCodeResalePrice}` : price}
      </Text>
    </div>

    <Button to={url} variant='secondary' className='footer-button mt--16' $block>
      Edit
    </Button>
  </>
);

export default NewAdminFooter;
