/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLearningCommunitiesMutation } from 'store/services/learningCommunities';
import { useDepartmentsMutation } from 'store/services/departments';
import { useOrganizationsMutation } from 'store/services/organizations';
import Image from 'style-guide/Image';
import noData from 'assets/images/noData.png';
import LearningCommunities from './LearningCommunities';
import Departments from './Departments';
import Organization from './Organization';
import ExploreAllCardsSkeleton from '../ExploreCardsSkeleton';

const LIMIT = 4;

const getParams = ({ userId, page, search, maxPrice, minPrice, ratingValue }) => {
  const params = { userId, isFavorite: true, limit: page.limit, offset: page.offset };
  if (minPrice !== null) {
    params.minPrice = minPrice;
  }
  if (maxPrice !== null) {
    params.maxPrice = maxPrice;
  }
  if (search) {
    params.searchText = search;
  }
  if (ratingValue && ratingValue.length) {
    params.ratings = JSON.stringify(ratingValue.map((el) => Number(el)));
  }

  return params;
};

const Favorites = ({ search, maxPrice, minPrice, ratingValue, category, colSpan }) => {
  const { user } = useSelector((state) => state?.authorizationsSlice);

  const [LCPage, setLCPage] = useState({ limit: LIMIT, offset: 0, search, maxPrice, minPrice, ratingValue });
  const [DEPPage, setDEPPage] = useState({ limit: LIMIT, offset: 0, search, maxPrice, minPrice, ratingValue });
  const [ORGPage, setORGPage] = useState({ limit: LIMIT, offset: 0, search, maxPrice, minPrice, ratingValue });

  const [learningCommunities, setLearningCommunities] = useState({});
  const [departments, setDepartments] = useState({});
  const [organizations, setOrganizations] = useState({});

  const [getFavoriteLC, { data: LCData, isLoading: LCIsLoading }] = useLearningCommunitiesMutation();
  const [getFavoritesDEP, { data: DEPData, isLoading: DEPIsLoading }] = useDepartmentsMutation();
  const [getFavoritesORG, { data: ORGData, isLoading: ORGIsLoading }] = useOrganizationsMutation();

  useEffect(() => {
    setLCPage({ ...LCPage, offset: 0, search, maxPrice, minPrice, ratingValue });
    setDEPPage({ ...DEPPage, offset: 0, search, maxPrice, minPrice, ratingValue });
    setORGPage({ ...ORGPage, offset: 0, search, maxPrice, minPrice, ratingValue });
  }, [search, maxPrice, minPrice, ratingValue]);

  // LC
  useEffect(() => {
    const params = getParams({ userId: user.id, page: LCPage, search, maxPrice, minPrice, ratingValue });
    getFavoriteLC(params);
  }, [LCPage]);

  useEffect(() => {
    if (LCData) {
      const rows = LCPage.offset === 0 ? LCData.rows : [...learningCommunities.rows, ...LCData.rows];

      setLearningCommunities({
        rows,
        total: LCData.total || 0,
      });
    }
  }, [LCData]);

  // DEP
  useEffect(() => {
    const params = getParams({ userId: user.id, page: DEPPage, search, maxPrice, minPrice, ratingValue });
    getFavoritesDEP(params);
  }, [DEPPage]);

  useEffect(() => {
    if (DEPData) {
      const rows = DEPPage.offset === 0 ? DEPData.rows : [...departments.rows, ...DEPData.rows];

      setDepartments({
        rows,
        total: DEPData.total || 0,
      });
    }
  }, [DEPData]);

  // ORG
  useEffect(() => {
    const params = getParams({ userId: user.id, page: ORGPage, search, maxPrice, minPrice, ratingValue });
    getFavoritesORG(params);
  }, [ORGPage]);

  useEffect(() => {
    if (ORGData) {
      const rows = ORGPage.offset === 0 ? ORGData.rows : [...organizations.rows, ...ORGData.rows];

      setOrganizations({
        rows,
        total: ORGData.total || 0,
      });
    }
  }, [ORGData]);

  const hasData = LCData || DEPData || ORGData;
  const isLoading = LCIsLoading || DEPIsLoading || ORGIsLoading;

  return (
    <div className='body-root'>
      {!hasData ? (
        <div className='favorites-skeletons'>
          {isLoading ? (
            <ExploreAllCardsSkeleton cardSize={colSpan} category={category} />
          ) : (
            <div className='card-box d-flex align-items-center justify-content-center'>
              <Image src={noData} />
            </div>
          )}
        </div>
      ) : (
        <div className='favorites-container'>
          {(!learningCommunities?.total || learningCommunities?.total <= 0) &&
          (!departments?.total || departments?.total <= 0) &&
          (!organizations?.total || organizations?.total <= 0) ? (
            <div className='card-box d-flex align-items-center justify-content-center'>
              <Image src={noData} />
            </div>
          ) : (
            <>
              <LearningCommunities data={learningCommunities} setLCPage={setLCPage} LCPage={LCPage} colSpan={colSpan} />
              <Departments data={departments} setDEPPage={setDEPPage} DEPPage={DEPPage} colSpan={colSpan} />
              <Organization data={organizations} setORGPage={setORGPage} ORGPage={ORGPage} colSpan={colSpan} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Favorites;
