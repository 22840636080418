const ShortEssayScored = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect x='4' y='4' width='16' height='16' rx='2' fill='#00B3CC' />
    <rect x='6' y='6' width='12' height='2' rx='1' fill='white' />
    <rect x='6' y='9' width='8' height='2' rx='1' fill='white' />
    <path
      d='M13.0043 17.4688C12.7971 17.4688 12.5899 17.3972 12.4318 17.2532L10.2372 15.2557C9.92093 14.9678 9.92093 14.5014 10.2372 14.2144C10.5535 13.9265 11.0649 13.9256 11.3812 14.2135L13.0043 15.6909L16.8219 12.2159C17.1382 11.928 17.6496 11.928 17.9659 12.2159C18.2822 12.5038 18.2822 12.9702 17.9659 13.2581L13.5767 17.2532C13.4186 17.3972 13.2114 17.4688 13.0043 17.4688Z'
      fill='white'
    />
  </svg>
);

export default ShortEssayScored;
