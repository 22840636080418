import { USER_ACCESS_TYPES } from 'common';
import { loadState } from 'configs/localStorage';

const useIsLimitedUser = () => {
  const { user } = loadState();
  const isLimitedUser = user?.accessTypeId === USER_ACCESS_TYPES.LIMITED.id;
  return isLimitedUser;
};

export default useIsLimitedUser;
