import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getScrollToTopState } from 'store/selectors/scrollToTop';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const isScrollable = useSelector(getScrollToTopState);

  useEffect(() => {
    if (isScrollable) {
      window.scrollTo(0, 0);
    }
  }, [isScrollable, pathname]);

  return null;
};
export default ScrollToTop;
