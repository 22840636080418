import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const JoinEntitiesWrapper = styled.div`
  width: 510px;
  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    width: 300px;
  }
  .purchase-code,
  .emtity-type-name {
    margin-bottom: 12px;
  }
  .entity-name-text,
  .skeleton-field {
    margin-bottom: 24px;
  }
  .field-box {
    margin-bottom: 20px;
  }
  .navigate-link {
    color: ${({ theme }) => theme.colors.tertiary[400]};
  }
`;

export default JoinEntitiesWrapper;
