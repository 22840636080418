import { useLayoutEffect, useRef, useState } from 'react';

function useInfiniteScroll({ hasMore, reset = false, distance = 300 }) {
  const scrollContainerRef = useRef();
  const loaderRef = useRef();
  const [page, setPage] = useState(0);

  if (reset && page !== 0) {
    setPage(0);
  }

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef.current;

    if (!scrollContainerNode || !loaderNode || !hasMore) return;
    const options = {
      root: scrollContainerNode,
      rootMargin: `0px 0px ${distance}px 0px`,
      threshold: 0.5,
    };

    let previousY;
    let previousRatio = 0;

    const listener = (entries) => {
      entries.forEach(({ isIntersecting, intersectionRatio, boundingClientRect = {} }) => {
        const { y } = boundingClientRect;
        if (isIntersecting && intersectionRatio >= previousRatio && (!previousY || y < previousY)) {
          setPage((item) => item + 1);
        }
        previousY = y;
        previousRatio = intersectionRatio;
      });
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer.disconnect();
  }, [hasMore, distance]);

  return [page, setPage, loaderRef, scrollContainerRef];
}

export default useInfiniteScroll;
