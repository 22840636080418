import { string, object } from 'yup';

const schema = object({
  userName: string()
    .trim()
    .required('Please, input the User Name')
    .matches('^[a-zA-Z0-9_]+$', 'Only letters, numbers and "_" are allowed'),
}).required();

export default schema;
