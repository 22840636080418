import emptySplitApi from '..';

export const reviewListApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getLearningCommunityReviews: build.query({
      query: (params) => ({
        url: `/courses/${params.urlName}/review-list`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
      providesTags: ['learning-community-reviews'],
    }),
    createLearningCommunityReview: build.mutation({
      query: (params) => ({
        url: `courses/${params.urlName}/review-list`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['learning-community-reviews'],
    }),
    updateLearningCommunityReview: build.mutation({
      query: (params) => ({
        url: `courses/${params.urlName}/review-list`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['learning-community-reviews'],
    }),
    deleteLearningCommunityReview: build.mutation({
      query: (params) => ({
        url: `courses/${params.urlName}/review-list/${params.id}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['learning-community-reviews'],
    }),
  }),
  overrideExisting: false,
});

export default reviewListApi;
export const {
  useGetLearningCommunityReviewsQuery,
  useCreateLearningCommunityReviewMutation,
  useDeleteLearningCommunityReviewMutation,
  useUpdateLearningCommunityReviewMutation,
} = reviewListApi;
