import { css } from 'styled-components';

export const getHeight = ({ size = 'medium' }) => {
  const heights = {
    small: css`
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      weight: 400;
    `,
    medium: css`
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      weight: 400;
    `,
    large: css`
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 16px;
      weight: 400;
    `,
  };

  return heights[size];
};

export const getIconSize = ({ size = 'medium' }) => {
  const heights = {
    small: css`
      height: 24px;
      padding: 0;
      width: 24px;
      justify-content: center;
    `,
    medium: css`
      height: 32px;
      width: 32px;
      padding: 0;
      justify-content: center;
    `,
    large: css`
      height: 40px;
      width: 40px;
      padding: 0;
      justify-content: center;
    `,
  };

  return heights[size];
};
