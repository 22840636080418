import { DOMAIN_KEYS } from 'common';
import { getDomainTracking } from 'configs/localStorage';
import { Link } from 'react-router-dom';
import { Eye, Pencil } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';

const CompaniesActions = ({ item }) => {
  const domainData = getDomainTracking();

  const isShowEdit = domainData.key === DOMAIN_KEYS.peertree || item.key === domainData.key;

  return (
    <div className='d-flex align-items-center '>
      <Tooltip placement='topRight' overlay='View Company'>
        <Link to={item.domainName} target='_blank' rel='noopener noreferrer'>
          <Eye className='icon action-icon pointer' />
        </Link>
      </Tooltip>
      {isShowEdit ? (
        <Tooltip placement='topRight' overlay='Edit Company'>
          <Link to={`/admin/companies/${item.id}`}>
            <Pencil className='company-pencil icon pointer' />
          </Link>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default CompaniesActions;
