import { forwardRef, useEffect, useState } from 'react';
import { components } from 'react-select';
import { Close } from 'style-guide/Icons';
import { message } from 'style-guide/ToastMessages';

import KeywordsSelectComponent from './style/KeywordsSelect';

const tokenSeparators = ['Enter', 'Tab', ';', ',', ' '];

const setSelectOptions = ({ value, setInputSelectValue, onChange }) => {
  const keywordsValues = value?.split(/[;\s]/);

  const uniqueKeywords = [...new Set(keywordsValues)].map((element) => ({
    label: element,
    value: element,
  }));

  const hasDuplicateLabel = uniqueKeywords.length !== keywordsValues.length;
  if (hasDuplicateLabel) {
    message.error('You are entering a duplicate word.');
  }
  onChange?.(uniqueKeywords?.map((e) => e.label).join(';'));
  setInputSelectValue('');
};

const KeywordsSelect = forwardRef(
  ({ onChange, value = '', inputValue = '', onInputChange, ...keywordsSelect }, ref) => {
    const [inputSelectValue, setInputSelectValue] = useState(inputValue);

    const handleChange = (e, inputText) => {
      const inputValueTrimed = inputText.trim();
      const separators = tokenSeparators.includes(e.key) || e.type === 'paste' || e.type === 'blur';
      if (!inputValueTrimed) return;
      if (separators) {
        const fieldValues = !value?.length ? inputValueTrimed : `${value};${inputValueTrimed}`;
        setSelectOptions({ value: fieldValues, setInputSelectValue, onChange });
        e?.preventDefault();
      }
    };

    useEffect(() => {
      if (value) {
        setSelectOptions({ value, setInputSelectValue, onChange });
      }
    }, [onChange, value]);

    return (
      <div
        onPaste={(e) => {
          const inputText = e.clipboardData.getData('text');
          handleChange(e, inputText);
        }}
      >
        <KeywordsSelectComponent
          placeholder='keyword 1, keyword 2, keyword 3...'
          isMulti
          isClearable={false}
          menuIsOpen={false}
          inputValue={inputSelectValue}
          onInputChange={(e) => {
            setInputSelectValue(e);
            onInputChange?.(e);
          }}
          onKeyDown={(e) => handleChange(e, e.target.value)}
          onBlur={(e) => {
            handleChange(e, e.target.value);
          }}
          onChange={(words) => {
            const fieldValues = words.map((e) => e.label).join(';');
            onChange?.(fieldValues);
          }}
          value={
            !value?.length
              ? []
              : value?.split(';').map((element) => ({
                  label: element,
                  value: element,
                }))
          }
          inputRef={ref}
          {...keywordsSelect}
        />
      </div>
    );
  }
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Close height={14} width={14} />
  </components.MultiValueRemove>
);

KeywordsSelect.defaultProps = {
  classNamePrefix: 'select',
  placeholder: 'Select the type of your question',
  components: { DropdownIndicator: null, MultiValueRemove },
};

export default KeywordsSelect;
