const CloseCircleOutlined = (props) => (
  <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.53221 4.15742C8.53221 4.10586 8.49002 4.06367 8.43846 4.06367L7.66503 4.06719L6.50019 5.45586L5.33652 4.06836L4.56191 4.06485C4.51035 4.06485 4.46816 4.10586 4.46816 4.1586C4.46816 4.18086 4.47637 4.20196 4.49043 4.21953L6.01503 6.03594L4.49043 7.85118C4.47627 7.86835 4.46841 7.88985 4.46816 7.91211C4.46816 7.96368 4.51035 8.00586 4.56191 8.00586L5.33652 8.00235L6.50019 6.61368L7.66385 8.00118L8.43729 8.00469C8.48885 8.00469 8.53104 7.96368 8.53104 7.91094C8.53104 7.88868 8.52284 7.86758 8.50877 7.85L6.98651 6.03477L8.51112 4.21836C8.52518 4.20196 8.53221 4.17969 8.53221 4.15742Z'
      fill='#212121'
    />
    <path
      d='M6.5 0.761719C3.60078 0.761719 1.25 3.1125 1.25 6.01172C1.25 8.91094 3.60078 11.2617 6.5 11.2617C9.39922 11.2617 11.75 8.91094 11.75 6.01172C11.75 3.1125 9.39922 0.761719 6.5 0.761719ZM6.5 10.3711C4.09297 10.3711 2.14062 8.41875 2.14062 6.01172C2.14062 3.60469 4.09297 1.65234 6.5 1.65234C8.90703 1.65234 10.8594 3.60469 10.8594 6.01172C10.8594 8.41875 8.90703 10.3711 6.5 10.3711Z'
      fill='#212121'
    />
  </svg>
);

export default CloseCircleOutlined;
