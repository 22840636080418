const ResponseMatching = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <path
      d='M7.33594 6.33398C7.33594 6.33398 7.49201 7.49016 8.14813 7.99022C8.80426 8.49027 10.3333 8.83333 11.5 10C12.5 11 12 12.2973 13.5 14C14.2529 14.8547 16.5 15.5 16.5 15.5'
      stroke='#B38600'
    />
    <rect x='14' y='4' width='6' height='6' rx='3' fill='#00B3CC' />
    <rect x='14' y='14' width='6' height='6' rx='3' fill='#00B3CC' />
    <rect x='4' y='14' width='6' height='6' rx='3' fill='#E8AF00' />
    <rect x='4' y='4' width='6' height='6' rx='3' fill='#E8AF00' />
  </svg>
);

export default ResponseMatching;
