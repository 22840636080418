const Pinterest = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 0.5C6.71601 0.5 0 7.2157 0 15.5C0 23.7843 6.71601 30.5 15 30.5C23.284 30.5 30 23.7843 30 15.5C30 7.2157 23.284 0.5 15 0.5ZM16.726 20.4823C15.3385 20.4823 14.0336 19.7322 13.5867 18.8806C13.5867 18.8806 12.8403 21.8407 12.6835 22.4118C12.1271 24.4313 10.4903 26.4513 10.363 26.6167C10.2743 26.7318 10.0782 26.6959 10.0579 26.5431C10.0232 26.2859 9.60522 23.7383 10.0969 21.6605C10.3427 20.617 11.749 14.6588 11.749 14.6588C11.749 14.6588 11.3395 13.8384 11.3395 12.6255C11.3395 10.7212 12.4432 9.29959 13.8175 9.29959C14.9859 9.29959 15.55 10.177 15.55 11.2288C15.55 12.4036 14.8024 14.1604 14.4166 15.7882C14.094 17.1511 15.0994 18.2631 16.4437 18.2631C18.8784 18.2631 20.5169 15.1363 20.5169 11.431C20.5169 8.6155 18.6215 6.50706 15.1713 6.50706C11.2745 6.50706 8.8444 9.41376 8.8444 12.6611C8.8444 13.7801 9.17524 14.5704 9.69269 15.1808C9.92992 15.4616 9.96368 15.5746 9.87744 15.8968C9.81514 16.1335 9.67488 16.7025 9.61535 16.9274C9.52972 17.2533 9.26639 17.3694 8.97146 17.2494C7.17729 16.5168 6.34066 14.5507 6.34066 12.3407C6.34066 8.69069 9.41862 4.31391 15.5239 4.31391C20.4298 4.31391 23.6587 7.86358 23.6587 11.6747C23.659 16.7166 20.8561 20.4823 16.726 20.4823Z'
      fill='#607D8B'
    />
  </svg>
);

export default Pinterest;
