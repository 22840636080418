const Heart = (props) => (
  <svg width='23' height='22' viewBox='0 0 23 22' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.243 0.0833526C14.735 0.0833359 13.763 0.262986 12.6487 0.808412C12.2361 1.01035 11.8491 1.25354 11.4896 1.53725C11.144 1.26944 10.7725 1.03742 10.377 0.841809C9.24104 0.279977 8.21912 0.0833359 6.76523 0.0833359C2.87137 0.0833359 0.041748 3.30966 0.041748 7.49987C0.041748 10.6643 1.80616 13.7211 5.09262 16.677C6.81768 18.2285 9.02021 19.7639 10.5976 20.5807L11.5001 21.048L12.4026 20.5807C13.98 19.7639 16.1825 18.2285 17.9075 16.677C21.194 13.7211 22.9584 10.6643 22.9584 7.49987C22.9584 3.35401 20.1021 0.0993341 16.243 0.0833526ZM20.8751 7.49987C20.8751 9.97906 19.4023 12.5305 16.5144 15.128C14.9399 16.5441 12.9157 17.9593 11.5001 18.7018C10.0845 17.9593 8.06031 16.5441 6.48578 15.128C3.59781 12.5305 2.12508 9.97906 2.12508 7.49987C2.12508 4.40388 4.08721 2.16667 6.76523 2.16667C7.92121 2.16667 8.63355 2.30374 9.45335 2.70921C9.93759 2.94872 10.3656 3.26497 10.7361 3.66018L11.5025 4.47782L12.262 3.6537C12.6408 3.24266 13.0751 2.91921 13.5646 2.67961C14.3604 2.2901 15.0282 2.16667 16.2387 2.16668C18.8836 2.17764 20.8751 4.44684 20.8751 7.49987Z'
    />
  </svg>
);

export const HeartFilled = () => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.23511 1.29738C8.94827 0.948312 9.57038 0.833335 10.5355 0.833347C13.0053 0.843575 14.8333 2.92657 14.8333 5.57992C14.8333 7.60517 13.7041 9.56152 11.6008 11.4533C10.4967 12.4462 9.08712 13.4289 8.0776 13.9517L7.5 14.2508L6.92241 13.9517C5.91289 13.4289 4.50327 12.4462 3.39923 11.4533C1.29589 9.56152 0.166672 7.60517 0.166672 5.57992C0.166672 2.89818 1.97763 0.833336 4.4697 0.833336C5.40019 0.833336 6.05422 0.959186 6.78121 1.31876C7.03432 1.44395 7.27213 1.59244 7.49332 1.76384C7.72336 1.58226 7.97107 1.42662 8.23511 1.29738Z'
      fill='#7CB342'
    />
  </svg>
);

export default Heart;
