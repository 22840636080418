import styled from 'styled-components';

const MembersWrapper = styled.div`
  .root {
    height: 190px;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 8px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white[100]};

    &:hover {
      box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]};
    }

    .avatar-box {
      height: 100px;
      padding-top: 20px;
      padding-bottom: 16px;
      img,
      .avatar {
        width: 100px;
        height: 100px;
      }
      .name {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .name-box {
      display: flex;
      justify-content: center;
      padding: 18px;
      padding-top: 17px;
      box-sizing: border-box;
      border-top: 1px solid #edeff1;
      .main {
        word-break: break-all;
        line-height: 19px;
        margin: 0;
      }
    }
  }
`;

export default MembersWrapper;
