import AvatarWithoutSource from '../AvatarWithoutSource';
import { MEMBERS_NUMBER } from '../avatarHelper';
import GroupAvatarWrapper from './GroupAvatarWrapper';

const GroupAvatar = ({ member, count, num, color }) => (
  <GroupAvatarWrapper className='avatar-item' $imgSrc={member?.imgSrc} $color={color}>
    {member?.imgSrc && num !== MEMBERS_NUMBER.first ? (
      <div className='user-avatar avatar-picture' />
    ) : (
      <AvatarWithoutSource type={member?.type} label={member?.label} number={num} count={count} />
    )}
  </GroupAvatarWrapper>
);

export default GroupAvatar;
