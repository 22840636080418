import { forwardRef, useEffect, useState } from 'react';

import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

// react hook form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import FormItems from 'style-guide/FormItems';

import HelmetComponent from 'Components/Helmet';
import SocialMediaLoginButtons from 'Components/SocialMediaLoginButtons';
import PATHS from 'constants/paths';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDomainTrackingQuery } from 'store/services/domain-tracking';
import { useAuthenticationUserMutation, useVerifyEmailApiMutation } from 'store/services/users';
import DividerWithText from 'style-guide/DividerWithText';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import { getErrorFromLocalStorage, removeErrorFromLocalStorage } from 'utils/helperFunctions';
import AuthorizationsWrapper from '../style/AuthorizationsWrapper';
import VerificationWrapper from '../style/VerificationWrapper';
import fields from './fields';
import loggingUserRedirectPaths from './helper';
import schema from './schema';

const SignIn = forwardRef(({ isDropdown }, ref) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { token } = useParams();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // redux toolkit
  const {
    data: { brandName },
  } = useDomainTrackingQuery();
  const [authenticationUser, { isLoading }] = useAuthenticationUserMutation();
  const [verifyEmail] = useVerifyEmailApiMutation();

  useEffect(() => {
    if (token) {
      verifyEmail({ token });
    }
  }, [token, verifyEmail]);

  useEffect(() => {
    const signInError = getErrorFromLocalStorage('signInError');
    if (signInError) {
      message.error(signInError);
      removeErrorFromLocalStorage('signInError');
    }
  }, []);

  return (
    <AuthorizationsWrapper $isDropdown={isDropdown}>
      <HelmetComponent title={`Sign in - ${brandName}`} />
      <div className='row container-box' ref={ref}>
        <div className='authorization-box'>
          {!user?.verification ? (
            <Title className='authorization-title'>Sign in to {brandName}</Title>
          ) : (
            <Title className='authorization-title' variant={5}>
              Verification code sent to {user?.verification?.to} number
            </Title>
          )}
          <Form
            onSubmit={handleSubmit((formData) => {
              authenticationUser(formData).then(({ data }) => {
                if (data?.user?.verification) {
                  setUser(data?.user);
                  return;
                }
                loggingUserRedirectPaths(data?.user, navigate);
              });
            })}
          >
            {user?.verification ? (
              <VerificationWrapper>
                <Controller
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder='Verification Code'
                      className='verification-code-input'
                      autoComplete='none'
                    />
                  )}
                  name='verificationCode'
                  control={control}
                />
                <Button variant='primary' type='submit' $block loading={isLoading}>
                  Verify
                </Button>
              </VerificationWrapper>
            ) : (
              <>
                <FormItems fields={fields(register)} errors={errors} />
                <div className='row'>
                  <div className='col-12 d-flex justify-content-end'>
                    <Link to={PATHS.FORGOT_PASSWORD}>
                      <Text $dark='60' $variant={3} className='link-button'>
                        Forgot Password?
                      </Text>
                    </Link>
                  </div>
                  <div className='col-12 d-flex justify-content-center'>
                    <Button
                      variant='primary'
                      type='submit'
                      className='authorization-button sign-in-button'
                      loading={isLoading}
                      $block
                    >
                      Sign in
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
          {!user?.verification ? (
            <>
              <DividerWithText>
                <Text $variant={3} $dark={80}>
                  Or
                </Text>
              </DividerWithText>
              <SocialMediaLoginButtons text='Sign in' />
              <div className='row'>
                <div className='col-12 d-flex justify-content-center'>
                  <Link to={PATHS.SIGN_UP}>
                    <Text $dark='60' $variant={3} className='link-button'>
                      Create an Account
                    </Text>
                  </Link>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </AuthorizationsWrapper>
  );
});

export default SignIn;
