import { Input } from 'style-guide/Input';
import RadioButtons from './RadioButtons';

const fields = ({ register, control }) => [
  {
    name: 'email',
    label: 'E-mail',
    required: true,
    component: <Input name='email' required {...register('email')} />,
  },
  {
    name: 'password',
    label: 'Password',
    required: true,
    component: <Input name='password' required {...register('password')} />,
  },
  {
    name: 'confirmPassword',
    required: true,
    label: 'Confirm Password',
    component: <Input name='confirmPassword' required {...register('confirmPassword')} />,
  },
  {
    name: 'accessType',
    label: 'User access type',
    component: <RadioButtons control={control} />,
  },
];

export default fields;
