/* eslint-disable no-template-curly-in-string */
import ENTITY_TYPES from './entity-types';
import USER_COURSE_TYPES from './user-course-types';
import USER_LIBRARY_TYPES from './user-library-types';
import USER_ORGANIZATION_TYPES from './user-organization-types';

const types = {
  learnerJoined: {
    id: 1,
    typeName: 'learnerJoined',
    title: 'A new learner joined the course/library/organization',
    text: '[${courseName}] User ${userName} joined the ${itemType}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.organization]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  learnerLeft: {
    id: 2,
    typeName: 'learnerLeft',
    title: 'A learner has un-enrolled from the course/library/organization',
    text: '[${courseName}] User ${userName} un-enrolled from the ${itemType}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.organization]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  newReview: {
    id: 3,
    typeName: 'newReview',
    title: 'A new review was posted for a course/library/organization',
    text: '[${courseName}] User ${userName} reviewed the ${itemType}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.organization]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
      ],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  learnerTookTest: {
    id: 4,
    typeName: 'learnerTookTest',
    title: 'A learner has taken a assessment',
    text: '[${courseName}] User ${userName} took the assessment for ${chapterName}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  learnerAnsweredShortEssay: {
    id: 5,
    typeName: 'learnerAnsweredShortEssay',
    title: 'A learner has answered a short essay',
    text: '[${courseName}] User ${userName} answered a short essay question that requires grading',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  addedNewCourse: {
    id: 6,
    typeName: 'addedNewCourse',
    title: 'A co-admin has added a new course to your library',
    text: '[${courseName}] User ${userName} added this course to ${libraryName}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  modifiedCourse: {
    id: 7,
    typeName: 'modifiedCourse',
    title: 'Course content has been modified',
    text: '[${courseName}] User ${userName} modified ${entityName}: ${versionType}',
    method: 'courseModify',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  modificationReviewed: {
    id: 8,
    typeName: 'modificationReviewed',
    title: 'A modification has been accepted/rejected',
    text: '[${courseName}] User ${userName} has ${action}ed your modification',
    method: 'courseModify',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  newChapterAdded: {
    // Not used
    id: 9,
    typeName: 'newChapterAdded',
    title: 'A new chapter has been added',
    text: '[${courseName}] User ${userName} added a new ${itemType}',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  chapterModified: {
    id: 10,
    typeName: 'chapterModified',
    title: 'A chapter has been modified',
    text: '[${courseName}] ${chapterName} has been modified by ${modifierRole} ${modifierUserPublicName}',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  itemDeleted: {
    id: 11,
    typeName: 'itemDeleted',
    title: 'A course/chapter has been deleted',
    text: '[${courseName}] The ${chapterName} has been deleted',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  permissionPromotion: {
    id: 12,
    typeName: 'permissionPromotion',
    title: 'A permission promotion/demotion',
    text: '[${courseName}] User ${userName} has ${promoteDemote}d you to be an ${userTypeName}',
    method: 'promote',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  newSection: {
    id: 13,
    typeName: 'newSection',
    title: 'A new section is available for a chapter (initial thoughts, resources..)',
    text: '[${courseName}] ${versionType} has been added to ${chapterName}',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  newComment: {
    id: 14,
    typeName: 'newComment',
    title: 'A new comment on a chapter',
    text: '[${courseName}] User ${userName} commented on ${chapterName}',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  testResult: {
    id: 15,
    typeName: 'testResult',
    title: 'A "Short Essay" question score is available',
    text: '[${courseName}] Your short essay score for ${chapterName} is now available',
    method: 'studentChapterModified',
    division: 'learners',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.advanced.level,
        USER_COURSE_TYPES.learnersStudents.level,
      ],
    },
  },
  addBankInformation: {
    // Not used
    id: 16,
    typeName: 'addBankInformation',
    title: 'Bank information is needed',
    text: 'Please, add your bank information to get paid for ${itemType} "${courseName}"',
    method: 'addBankInfo',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.organization]: [USER_ORGANIZATION_TYPES.administrator.level],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level],
      [ENTITY_TYPES.course]: [USER_COURSE_TYPES.administrator.level],
    },
  },
  addedNewLibrary: {
    id: 17,
    typeName: 'addedNewLibrary',
    title: 'A co-admin has added a new library to your organization',
    text: '[${courseName}] User ${userName} added this library to ${libraryName}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
    },
  },
  others: {
    // Not used
    id: 18,
    typeName: 'others',
    title: 'others',
    text: '',
    method: 'common',
    division: 'learners',
  },
  newVersioningComment: {
    id: 19,
    typeName: 'newVersioningComment',
    title: 'A new comment on a version',
    text: '[${courseName}] User ${userName} commented on ${versionTypeName}',
    method: 'adminCommon',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
      ],
    },
  },
  madePaidContributor: {
    id: 20,
    typeName: 'madePaidContributor',
    title: 'Member became paid contributor',
    text: 'You have been added to a paid contributors list of the "${entityName}" ${entityType}',
    method: 'promote',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.reviewer.level,
      ],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [ENTITY_TYPES.organization]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
        USER_ORGANIZATION_TYPES.member.level,
      ],
    },
  },
  madeUnpaidContributor: {
    id: 21,
    typeName: 'madeUnpaidContributor',
    title: 'Member is no longer a paid contributor',
    text: 'You have been removed from a paid contributors list of the "${entityName}" ${entityType}',
    method: 'promote',
    division: 'admins',
    userTypes: {
      [ENTITY_TYPES.course]: [
        USER_COURSE_TYPES.administrator.level,
        USER_COURSE_TYPES.coAdministrator.level,
        USER_COURSE_TYPES.authors.level,
        USER_COURSE_TYPES.coAuthorsCollaborators.level,
        USER_COURSE_TYPES.reviewer.level,
      ],
      [ENTITY_TYPES.library]: [USER_LIBRARY_TYPES.administrator.level, USER_LIBRARY_TYPES.coAdministrator.level],
      [ENTITY_TYPES.organization]: [
        USER_ORGANIZATION_TYPES.administrator.level,
        USER_ORGANIZATION_TYPES.coAdministrator.level,
        USER_ORGANIZATION_TYPES.member.level,
      ],
    },
  },
};

const replaceKeys = (text, params) => {
  let result = '';
  text.match(/\$\{([a-zA-Z]{1,})\}/g).forEach((i) => {
    result = text.replace(i, params[i.replace(/\$\{/g, '').replace(/\}/g, '')]);
  });
  return result;
};

const getNotificationTypeId = (key) => types[key].id;

const getNotificationData = (key, { replacement, url, userId }) => {
  const data = types[key];

  return {
    userId,
    typeId: data.id || null,
    text: replaceKeys(data.text, replacement),
    url,
    active: true,
    read: false,
  };
};

const defaultEnabled = (typeId) => {
  const data = {
    onTop: true,
    byEmail: false,
  };
  const byEmailIds = ['newComment', 'newSection'].map((key) => types[key].id);
  if (byEmailIds.includes(typeId)) {
    data.byEmail = true;
  }
  return data;
};

const NOTIFICATIONS = {
  types,
  getNotificationData,
  getNotificationTypeId,
  defaultEnabled,
};

export default NOTIFICATIONS;
