import { USER_ACCESS_TYPES } from 'common';
import { useUpdateUserAccessMutation } from 'store/services/superAdmin/AdminUsers';
import Button from 'style-guide/Button';
import { Check, ArrowBottom, ArrowTop } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Title from 'style-guide/Typography/Title';
import SearchDropdown from '../SearchDropdown';
import DropDownFilter from '../SearchDropdown/DropdownFilter';
import Actions from './Actions';

const { getTypeFromId } = USER_ACCESS_TYPES;

const getColumns = ({ verifyUser, page, isSuperAdmin }) => {
  const [updateUserAccess] = useUpdateUserAccessMutation();

  return [
    {
      title: <SearchDropdown title='First Name' name='firstName' />,
      dataIndex: 'firstName',
      key: 'firstName',
      width: 210,
    },
    {
      title: <SearchDropdown title='Last Name' name='lastName' />,
      dataIndex: 'lastName',
      key: 'lastName',
      width: 210,
    },
    {
      title: <SearchDropdown title='User Name' name='userName' />,
      dataIndex: 'userName',
      key: 'userName',
      width: 210,
    },
    {
      title: <SearchDropdown title='Email' name='email' />,
      dataIndex: 'email',
      key: 'email',
    },
    ...(isSuperAdmin
      ? [
          {
            title: <Title variant={5}>Domain</Title>,
            dataIndex: 'brandName',
            key: 'brandName',
          },
          {
            title: <Title variant={5}>Role</Title>,
            dataIndex: 'role',
            key: 'role',
          },
        ]
      : []),
    {
      title: <DropDownFilter name='accessTypeId' firstParam='Full' secondParam='Limited' />,
      dataIndex: 'accessType',
      key: 'accessType',
      render: (_value, row) => {
        const { accessTypeId } = row;
        const { name } = getTypeFromId(accessTypeId);
        const isFullAccess = accessTypeId === USER_ACCESS_TYPES.FULL.id;

        return (
          <div className='d-flex justify-content-center align-items-center'>
            <span className='mr--8'>{name}</span>
            <Tooltip overlay={isFullAccess ? 'Demote to Limited Access' : 'Promote to Full Access'} placement='top'>
              <Button
                variant='link'
                className='p--0'
                onClick={() => {
                  const newAccessTypeId = isFullAccess ? USER_ACCESS_TYPES.LIMITED.id : USER_ACCESS_TYPES.FULL.id;
                  updateUserAccess({
                    body: {
                      id: row.id,
                      accessTypeId: newAccessTypeId,
                    },
                    params: { page },
                  });
                }}
              >
                {isFullAccess ? (
                  <ArrowBottom className='user-remove-icon pointer' width={16} />
                ) : (
                  <ArrowTop className='user-add-icon pointer' width={16} />
                )}
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: (
        <Title variant={5} className='d-flex justify-content-center'>
          Status
        </Title>
      ),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (_index, item) => (
        <div className='d-flex justify-content-center'>
          {item.isVerified ? (
            <Tooltip overlay='User is verified' placement='top'>
              <Check width={16} height={16} className='verified-check' />
            </Tooltip>
          ) : (
            <Button variant='secondary' onClick={() => verifyUser({ body: { id: item.id }, params: { page } })}>
              Verify
            </Button>
          )}
        </div>
      ),
    },
    {
      title: (
        <Title variant={5} className='d-flex justify-content-center'>
          Actions
        </Title>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (_index, item) => <Actions item={item} />,
    },
  ];
};

export default getColumns;
