import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';

const FillBlank = ({ answer }) => (
  <>
    <Input placeholder='Type your answer' disabled className='mt--8' />
    {answer?.map((item) => (
      <div>
        <Text $variant={1}>{item?.answer}</Text>
      </div>
    ))}
  </>
);

export default FillBlank;
