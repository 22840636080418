import { forwardRef } from 'react';

const DragAndDrop = forwardRef((props, ref) => (
  <svg
    data-testid='DragAndDrop'
    width='10'
    height='16'
    viewBox='0 0 10 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <path
      d='M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z'
      fill='#333333'
      fillOpacity='0.38'
    />
  </svg>
));

export default DragAndDrop;
