import { useState } from 'react';
import Button from 'style-guide/Button';
import { Input } from 'style-guide/Input';
import Text from 'style-guide/Typography/Text';
import CloneEntityModalWrapper from './style/CloneEntityModalWrapper';

const CloneEntityModal = ({ hideModal, params: { onOk, onCancel, data, entityShortName } }) => {
  const [value, setValue] = useState(`${data?.urlName}_clone_${Date.now()}`);
  return (
    <CloneEntityModalWrapper className='row'>
      <div className='col-12'>
        <Input type='text' label='Course url' value={value} onChange={(e) => setValue(e.target.value)} required />
        <Text $variant={2} dark={100} className='question-text'>
          Are you sure you want to clone this {entityShortName}?
        </Text>
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <Button
          className='mr--24'
          variant='secondary'
          onClick={() => {
            hideModal();
            onCancel?.();
          }}
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            hideModal();
            onOk?.(value);
          }}
        >
          Yes
        </Button>
      </div>
    </CloneEntityModalWrapper>
  );
};

export default CloneEntityModal;
