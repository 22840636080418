const Right = (props) => (
  <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.51131 6L0.257118 1.74581L1.08208 0.920853L6.16122 6L1.08208 11.0791L0.257118 10.2542L4.51131 6Z'
      fill='#333333'
    />
  </svg>
);

export default Right;
