import Button from 'style-guide/Button';
import CheckBox from 'style-guide/CheckBox';
import { Plus, Trash } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';

import { Controller, useFieldArray, useWatch } from 'react-hook-form';

import { SYSTEM_LIMITS } from 'common';

import theme from 'style-guide/Theme';
import MultipleAnswerWrapper from './style/MultipleAnswerWrapper';

const {
  COURSE_SECTIONS: { TESTS },
} = SYSTEM_LIMITS;

const MultipleAnswer = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answer',
  });
  const answer = useWatch({ name: 'answer', control });

  return (
    <MultipleAnswerWrapper>
      <div className='row'>
        <div className='col-12'>
          <Text $variant={3} $dark={60} className='label'>
            *Check the answer that is correct
          </Text>
        </div>
        <div className='col-12'>
          <Button
            variant='link'
            prefix={<Plus />}
            onClick={() => append({ answer: '', isTrue: false })}
            disabled={fields.length >= TESTS.ANSWER.LIST.max}
          >
            Add New Option
          </Button>
        </div>
        <div className='col-12 options-box'>
          {fields.map((field, index) => (
            <div className='d-flex align-items-end multiple-answer-item' key={field.id}>
              <div className='d-flex flex-column flex-1'>
                <Text $variant={1} color={theme().colors.dark[80]} className='d-flex justify-content-end'>
                  {answer[index]?.answer?.length || 0}/{TESTS.VALUE.max}
                </Text>
                <div className='d-flex align-items-center checkbox-wrapper'>
                  <Controller
                    control={control}
                    name={`answer.${index}.isTrue`}
                    render={({ field: { ref, onChange, value } }) => (
                      <CheckBox
                        className='checkbox'
                        name={`answer.${index}.isTrue`}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        ref={ref}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`answer.${index}.answer`}
                    render={({ field: answerField }) => (
                      <input
                        className='multiple-answer-input'
                        type='text'
                        placeholder='Option'
                        maxLength={TESTS.VALUE.max}
                        {...answerField}
                      />
                    )}
                  />
                </div>
              </div>
              {fields.length > TESTS.ANSWER.LIST.min ? (
                <div className='trash-icon-wrapper'>
                  <Button variant='text' $danger onClick={() => remove(index)}>
                    <Trash />
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </MultipleAnswerWrapper>
  );
};
export default MultipleAnswer;
