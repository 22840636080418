import { ENTITY_TYPES } from 'common';
import PATHS from 'constants/paths';

const { LEARNING_COMMUNITY, DEPARTMENT, ORGANIZATION, MEMBER } = ENTITY_TYPES;

export const navigateEToExploreEntity = (value) => {
  switch (value) {
    case LEARNING_COMMUNITY.key:
      return PATHS.EXPLORE_LEARNING_COMMUNITIES;
    case DEPARTMENT.key:
      return PATHS.EXPLORE_COLLECTIONS;
    case ORGANIZATION.key:
      return PATHS.EXPLORE_ORGANIZATIONS;
    case MEMBER.key:
      return PATHS.EXPLORE_MEMBERS;
    default:
      return PATHS.EXPLORE_FAVORITES;
  }
};
