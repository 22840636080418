import { SYSTEM_LIMITS, VALIDATORS } from 'common';
import { object, ref, string } from 'yup';

const { password } = SYSTEM_LIMITS.INPUTS;
const { validatePassword } = VALIDATORS;
const getErrorMessage = (field) => validatePassword(field.value).errorMessage;

const schema = object({
  email: string().email('The input is not valid Email').required('Please, input your Email'),
  password: string()
    .nullable()
    .trim()
    .required('Please, input your Password')
    .min(password.min, `Password must be at least ${password.min} characters`)
    .test(
      '',
      (field) => getErrorMessage(field),
      (value) => validatePassword(value).isValid
    ),
  confirmPassword: string()
    .nullable()
    .trim()
    .required('Please, confirm your Password')
    .oneOf([ref('password')], 'Two passwords that you enter are inconsistent'),
}).required();

export default schema;
