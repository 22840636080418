import { yupResolver } from '@hookform/resolvers/yup';
import { loadState, saveLocalStorage } from 'configs/localStorage';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMembersMutation } from 'store/services/profile';
import { changeUserData } from 'store/slices/profile';

import usePlatform from 'hooks/usePlatform';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import { Close } from 'style-guide/Icons';
import {
  Facebook,
  Instagram,
  Linkedin,
  Pinterest,
  Reddit,
  Snapchat,
  Twitter,
  Vimeo,
  WeChat,
  Youtube,
} from 'style-guide/Icons/SocialMedia';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import ErrorMessageView from '../ErrorMessageView';
import schema from './schema';
import AddSocialMediaWrapper from './style/AddSocialMediaWrapper';

const SOCIAL_MEDIA_LIST = [
  { icon: <Linkedin />, title: 'Linkedin', name: 'linkedin' },
  { icon: <Facebook />, title: 'Facebook', name: 'facebook' },
  { icon: <Twitter />, title: 'Twitter', name: 'twitter' },
  { icon: <Instagram />, title: 'Instagram', name: 'instagram' },
  { icon: <Pinterest />, title: 'Pinterest', name: 'pinterest' },
  { icon: <Snapchat />, title: 'Snapchat', name: 'snapchat' },
  { icon: <Vimeo />, title: 'Vimeo', name: 'vimeo' },
  { icon: <Youtube />, title: 'Youtube', name: 'youtube' },
  { icon: <Reddit />, title: 'Reddit', name: 'reddit' },
  { icon: <WeChat />, title: 'Wechat', name: 'wechat' },
];

const hasSameValues = ({ currentValues, defaultValues }) => {
  let isSameValues = true;
  const keys = Object.keys(currentValues);

  keys.forEach((key) => {
    const currentValue = currentValues[key];

    const defaultValue = defaultValues[key] ? defaultValues[key] : '';
    if (currentValue !== defaultValue) {
      isSameValues = false;
    }
  });

  return isSameValues;
};

const ListItem = ({ item, errors, register, watch, setValue, clearErrors }) => {
  const { isMobile } = usePlatform();
  const { icon, title, name } = item;
  const value = watch(name);
  const reset = () => {
    clearErrors(name);
    setValue(name, '', { shouldDirty: true });
  };

  return (
    <div className='list-item-root'>
      <div className='left-box'>
        <div className='icon-box'>{icon}</div>
        {!isMobile ? <p className='title'>{title}</p> : null}
      </div>
      <div className='right-box'>
        <Input placeholder='Insert Link' name={name} {...register(name)} error={errors?.[name]} />
        {value?.trim() ? (
          <Button className='close-button' type='button' onClick={reset}>
            <Close className='icon' />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const AddSocialMedia = ({ hideModal, params }) => {
  const dispatch = useDispatch();
  const [updateSocialMedia, { data, isLoading, isError, error }] = useMembersMutation();
  const { user } = params;
  const { user: storageUser } = loadState();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: user?.userSocialProfiles || {},
  });
  const isDisabled =
    !isDirty ||
    Object.keys(errors).length ||
    hasSameValues({
      currentValues: getValues(),
      defaultValues: user?.userSocialProfiles || {},
    });

  const onFormSubmit = (formData) => {
    updateSocialMedia({
      type: 'socialProfile',
      userId: user.id,
      ...formData,
    });
  };

  useEffect(() => {
    if (isError) {
      const errorMessage = error?.data?.message;
      message.error(<ErrorMessageView errorMessage={errorMessage} />);
    }

    if (!isLoading && data) {
      const hesData = Object.values(data).find((el) => el);

      dispatch(
        changeUserData({
          userData: {
            ...user,
            userSocialProfiles: hesData ? data : null,
          },
        })
      );
      saveLocalStorage({
        user: {
          ...storageUser,
          userSocialProfiles: hesData ? data : null,
        },
      });
      hideModal();
      message.success('You have successfully updated the profile!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isError, error]);

  return (
    <AddSocialMediaWrapper>
      <div className='add-social-media-modal-root'>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <div className='list-root'>
            {SOCIAL_MEDIA_LIST.map((item) => (
              <ListItem
                key={item.name}
                item={item}
                errors={errors}
                setValue={setValue}
                register={register}
                watch={watch}
                clearErrors={clearErrors}
              />
            ))}
          </div>

          <div className='d-flex justify-content-center pt--24'>
            <Button type='button' className='ph--40 mr--16' variant='secondary' onClick={hideModal}>
              Cancel
            </Button>
            <Button loading={isLoading} className='ph--48' variant='primary' type='submit' disabled={isDisabled}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    </AddSocialMediaWrapper>
  );
};

export default AddSocialMedia;
