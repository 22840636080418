import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'style-guide/Tooltip';
import UserAvatar from 'style-guide/UserAvatar';

import { setRedirectUrlToLocalStorage } from 'utils/helperFunctions';

import PATHS from 'constants/paths';
import Text from 'style-guide/Typography/Text';
import MembersWrapper from './styles/MembersWrapper';

const Members = ({ data }) => {
  const authorizationsSlice = useSelector((state) => state?.authorizationsSlice);
  const { isAuth: isLoggedIn } = authorizationsSlice;
  const { profileImage, originalName, userName } = data || {};
  const name = originalName || userName;

  const navigate = useNavigate();

  const navigateToProfile = () => {
    const url = `/user/${userName}`;
    if (!isLoggedIn) {
      setRedirectUrlToLocalStorage(url);
      navigate(PATHS.SIGN_IN);
      return;
    }

    navigate(url);
  };

  const [isOverflowed, setIsOverflow] = useState(false);

  return (
    <MembersWrapper>
      <div className='root' onClick={navigateToProfile}>
        <div className='avatar-box'>
          <UserAvatar className='avatar' picture={profileImage} userName={name} />
        </div>
        <div className='name-box'>
          <Tooltip overlay={name} placement='top' $disabled={!isOverflowed}>
            <Text
              $variant={3}
              $ellipsis
              ref={(element) => setIsOverflow(element?.scrollHeight > element?.clientHeight)}
            >
              {name}
            </Text>
          </Tooltip>
        </div>
      </div>
    </MembersWrapper>
  );
};

export default Members;
