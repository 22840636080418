import styled from 'styled-components';

const CategoriesWrapper = styled.span`
  &.volume-categories {
    .filter-categories {
      min-height: 38px;
      .button-content {
        width: 100%;
      }
    }
    svg {
      &.video-icon {
        padding-top: 2px;
        padding-right: 2px;
      }
      &.file-icon {
        padding-left: 4px;
        padding-right: 2px;
      }
      path {
        fill: ${({ theme }) => theme.colors.dark[80]};
      }
    }
  }

  .filter-categories {
    display: grid;
    list-style-type: none;

    .filter-category {
      padding-left: 25px;
      min-height: 28px;
      border-radius: 2px;
      border-left: 2px solid ${({ theme }) => theme.colors.secondary[50]};
      font-size: 16px;
      font-weight: 400;

      .active {
        text-align: start;
        color: ${({ theme }) => theme.colors.dark[80]};
      }

      &:hover {
        text-decoration: none;
      }
    }
    .active-border {
      border-left: 2px solid ${({ theme }) => theme.colors.primary[600]};
      .active {
        color: ${({ theme }) => theme.colors.primary[600]};
      }
    }
  }
`;

export default CategoriesWrapper;
