/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useOutsideClick = ({ ref, excludedRef, condition = true, cb }) => {
  useEffect(() => {
    if (!condition) return;

    const handleClickOutside = (event) => {
      if (excludedRef?.current?.contains(event.target)) {
        return;
      }

      if (ref?.current && !ref.current.contains(event.target)) {
        cb?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [condition]);
};

export default useOutsideClick;
