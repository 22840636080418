import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import SliderWrapper from '.';

const activeDotColor = { backgroundColor: theme().colors.white[100] };

const renderTooltip = (renderProps) => (
  <div {...renderProps.props}>
    <div className='slider-tooltip'>
      <Text className='tooltip-value d-flex justify-content-center p--2'>{renderProps.props['aria-valuenow']}</Text>
    </div>
  </div>
);

const SliderComponent = ({ min = 0, max = 100, type, disabled, tooltip, ...props }) => (
  <SliderWrapper $type={type} $disabled={disabled}>
    <Slider
      min={min}
      max={max}
      activeDotStyle={activeDotColor}
      {...props}
      disabled={disabled}
      handleRender={tooltip ? (renderProps) => renderTooltip(renderProps) : null}
    />
  </SliderWrapper>
);

export default SliderComponent;
