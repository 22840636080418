import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import MODAL_TYPES from 'constants/modals';
import PATHS from 'constants/paths';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { showModal } from 'store/slices/modal';
import { message } from 'style-guide/ToastMessages';
import Title from 'style-guide/Typography/Title';
import { useCreateDepartmentMutation } from '../departments';
import { useCreateLearningCommunitiesMutation } from '../learningCommunities';
import { useCreateOrganizationMutation } from '../organizations';

const { organizer } = USER_COURSE_TYPES.userCourseRole;

const redirectParams = ({ data }) => ({ id: data.id, urlName: data.urlName, role: organizer });

const useEntityClone = ({ data, id, entityType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cloneLC] = useCreateLearningCommunitiesMutation();
  const [cloneDEP] = useCreateDepartmentMutation();
  const [cloneORG] = useCreateOrganizationMutation();

  const request = useMemo(
    () => ({
      [ENTITY_TYPES.LEARNING_COMMUNITY.key]: (value) => {
        cloneLC({ baseCourseId: id, urlName: value }).then((result) => {
          message.success(`${ENTITY_TYPES.LEARNING_COMMUNITY.singular} successfully has been cloned`);
          navigate(generatePath(PATHS.LEARNING_COMMUNITY_HOMEPAGE, { ...redirectParams(result), tab: 'homepage' }));
        });
      },
      [ENTITY_TYPES.DEPARTMENT.key]: (value) => {
        cloneDEP({ baseLibraryId: id, urlName: value }).then((result) => {
          message.success(`${ENTITY_TYPES.DEPARTMENT.singular} successfully has been cloned`);
          navigate(generatePath(PATHS.DEPARTMENT_HOMEPAGE, { ...redirectParams(result), tab: 'homepage' }));
        });
      },
      [ENTITY_TYPES.ORGANIZATION.key]: (value) => {
        cloneORG({ baseOrganizationId: id, urlName: value }).then((result) => {
          message.success(`${ENTITY_TYPES.ORGANIZATION.singular} successfully has been cloned`);
          navigate(generatePath(PATHS.ORGANIZATION_HOMEPAGE, { ...redirectParams(result), tab: 'homepage' }));
        });
      },
    }),
    [cloneDEP, cloneLC, cloneORG, id, navigate]
  );

  const handleClone = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CLONE_ENTITY,
        params: {
          modal: {
            title: <Title>Clone {entityType.singular}</Title>,
          },
          body: {
            data,
            entityShortName: entityType.singular,
            onOk: request[entityType.key],
          },
        },
      })
    );
  };

  return handleClone;
};

export default useEntityClone;
