import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from './usePrevious';

const useLocationChange = (action) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && prevLocation.pathname !== location.pathname) {
      action(location, prevLocation);
    }
  }, [location, prevLocation, action]);
};

export default useLocationChange;
