export const MEMBERS_NUMBER = {
  first: 3,
  last: 4,
};

export const handleInitials = (label) => {
  const splitUserName = label?.split(' ');
  const firstNameInitial = splitUserName?.at(-2)?.[0] || '';
  const lastNameInitial = splitUserName?.at(-1)?.[0] || '';
  return firstNameInitial + lastNameInitial;
};

export const handleIconSizes = {
  small: { width: 10, height: 10 },
  default: { width: 14, height: 14 },
  large: { width: 18, height: 18 },
};

export const COLORS = {
  GREEN: '#52A180',
  SALMON_RED: '#FF7875',
  ROYAL_PURPLE: '#9747FF',
  SILVER_CLOUD: '#A7A6A6',
  SAGA_GREEN: '88ae96',
};
