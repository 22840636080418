import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GOOGLE_CLIENT_API_KEY } from 'configs/env-vars';

const getYouTubeVideoDurationApi = createApi({
  reducerPath: 'youtube',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.googleapis.com/youtube/v3/',
  }),
  endpoints: (builder) => ({
    youTubeVideoDuration: builder.mutation({
      query: (params) => ({
        url: '/videos',
        params: {
          id: params.videoIds,
          part: params.part || 'contentDetails',
          key: params.key || GOOGLE_CLIENT_API_KEY,
        },
        method: 'GET',
      }),
    }),
  }),
});

export const { useYouTubeVideoDurationMutation } = getYouTubeVideoDurationApi;
export default getYouTubeVideoDurationApi;
