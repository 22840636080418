import { useSelector } from 'react-redux';
import endpoints from 'store/endpoints';
import { getUserData } from 'store/selectors/user';

const useGetLearningCommunityData = (urlName, includeLibrary) => {
  const { id: userId } = useSelector(getUserData) || {};
  const params = includeLibrary ? { includeLibrary, urlName, userId } : { urlName, userId };
  const learningCommunitySelector = endpoints.getLearningCommunity.select(params);
  const { data: learningCommunityData } = useSelector(learningCommunitySelector) || {};

  return learningCommunityData?.data || {};
};

export default useGetLearningCommunityData;
