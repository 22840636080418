import Text from 'style-guide/Typography/Text';

const MultipleBlanks = ({ answer }) => (
  <div className='multiple-blanks-wrapper'>
    {answer?.correctAnswers?.map((el) => (
      <div className='correct-blank' key={el.id}>
        <Text $variant={1}>{el.answer}</Text>
      </div>
    ))}
    {answer.wrongAnswers?.map((el) => (
      <div className='wrong-blank' key={el.id}>
        <Text $variant={1}>{el.answer}</Text>
      </div>
    ))}
  </div>
);

export default MultipleBlanks;
