import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';

import { ENTITY_TYPES, LEARNING_COMMUNITY_SECTIONS, TEST_TYPES, USER_COURSE_TYPES } from 'common';
import MODAL_TYPES from 'constants/modals';

import Button from 'style-guide/Button';
import CheckBox from 'style-guide/CheckBox';
import DropdownV2 from 'style-guide/DropdownV2';
import Form from 'style-guide/Form';
import { Help } from 'style-guide/Icons';
import { message } from 'style-guide/ToastMessages';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

import {
  useCreateQuestionMutation,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
} from 'store/services/learningCommunities/Assessment';

import { setEditedCardIdSlice } from 'store/slices/assessment';
import { showModal } from 'store/slices/modal';

import theme from 'style-guide/Theme';
import usePrompt from 'hooks/usePrompt';
import DropdownBody from '../DropdownBody';
import Question from './Question';

import Answers from '../Answers';
import SelectDropdown from '../SelectDropdown';
import Feedback from './Feedback';
import { initialFormValue } from './answerValidationSchema';
import schema from './schema';
import AddQuestionWrapper from './style/AddQuestionWrapper';

const SuccessMessageView = () => (
  <div className='d-flex flex-column'>
    <Text $variant={2}>The question was saved.</Text>
    <Text $variant={2}>Create another one!</Text>
  </div>
);

const { LEARNING_COMMUNITY } = ENTITY_TYPES;
const { TestsTypeFreeText, TestsTypeScoredEssay } = TEST_TYPES.types;
const { ASSESSMENT } = LEARNING_COMMUNITY_SECTIONS;
const { organizer } = USER_COURSE_TYPES.userCourseRole;

const AddQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chapterId, id, questionId, urlName } = useParams();
  const [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [hasOpenModal, setHasOpenModal] = useState(false);

  const currentAssessmentType = searchParams.get('assessmentType');
  const submitButtonText = questionId ? 'Update' : 'Save';
  const isScoredEssay = +currentAssessmentType === TEST_TYPES.types.TestsTypeScoredEssay;

  const freeTextType = +currentAssessmentType === TestsTypeFreeText;
  const hasAnswer = [TestsTypeScoredEssay, TestsTypeFreeText].includes(+currentAssessmentType);

  const { desc } = TEST_TYPES.getTypeData(currentAssessmentType) || {};

  const [updateQuestion, { reset: resetQuestionData, isSuccess, isLoading: inUpdatingProcess }] =
    useUpdateQuestionMutation();
  const [
    createQuestion,
    { isSuccess: isCreateSuccess, reset: resetCreatedQuestionData, isLoading: inCreatingProcess },
  ] = useCreateQuestionMutation();
  const { data: questionData } = useGetQuestionQuery(
    { questionId, chapterId, courseId: id },
    { refetchOnMountOrArgChange: true, skip: !questionId }
  );

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema(currentAssessmentType)),
    mode: 'onChange',
    defaultValues: initialFormValue(currentAssessmentType),
  });

  const question = watch('question');
  const disabled = !question?.trim() || !isValid;
  const showBrowserModal = isDirty && !hasOpenModal && !inCreatingProcess && !inUpdatingProcess;

  const navigateToAssessmentValue = () =>
    navigate(`/${LEARNING_COMMUNITY.key}/${id}/${urlName}/${organizer}/${chapterId}/${ASSESSMENT.value}`);

  useEffect(() => {
    if (questionData) {
      reset({
        ...questionData,
        answer: isScoredEssay ? questionData.answer.map((e) => e.answer).join(';') : questionData.answer,
        feedback: questionData.feedback || { correct: { text: '' }, wrong: { text: '' } },
      });
    }
  }, [isScoredEssay, questionData, reset]);

  useEffect(() => {
    if (isSuccess || isCreateSuccess) {
      resetQuestionData();
      resetCreatedQuestionData();
      reset(initialFormValue(currentAssessmentType));
    }
  }, [currentAssessmentType, isCreateSuccess, isSuccess, reset, resetCreatedQuestionData, resetQuestionData]);

  usePrompt({
    when: showBrowserModal,
    message: 'Changes you made may not be saved.',
  });

  const successFormData = async (data) => {
    if (inCreatingProcess || inUpdatingProcess) {
      return;
    }
    if (!data?.feedback?.correct?.text) {
      // eslint-disable-next-line no-param-reassign
      delete data.feedback;
    } else if (data.feedback.correct?.hyperlinks?.length || data.feedback.wrong?.hyperlinks?.length) {
      // eslint-disable-next-line no-param-reassign
      data.feedback.correct.hyperlinks = data.feedback.correct.hyperlinks.map((hyperLink) =>
        hyperLink.entityId === '0' ? { ...hyperLink, type: 'other' } : hyperLink
      );
      // eslint-disable-next-line no-param-reassign
      data.feedback.wrong.hyperlinks = data.feedback.wrong.hyperlinks.map((hyperLink) =>
        hyperLink.entityId === '0' ? { ...hyperLink, type: 'other' } : hyperLink
      );
    }

    const handleScoredEssayData = (prevAnswers, formData) =>
      formData.split(';').map((e) => {
        const existingKey = prevAnswers?.find((item) => item.answer === e);
        return existingKey || { answer: e };
      });

    const submitAnswer = isScoredEssay ? handleScoredEssayData(questionData?.answer, data.answer) : data.answer;
    if (questionId) {
      const { error, data: updatedData = {} } = await updateQuestion({
        courseId: id,
        chapterId,
        questionId,
        body: { ...data, answer: submitAnswer },
      });
      navigateToAssessmentValue();

      if (updatedData.message) {
        return message.success(updatedData.message);
      }

      if (updatedData.id) {
        message.success('The question was successfully updated.');
      }

      if (!error) {
        dispatch(setEditedCardIdSlice(updatedData.id));
      }

      if (error) {
        message.error(error?.data?.message || 'Request failed');
      }
      return;
    }

    const { error, data: createdData } = await createQuestion({
      id,
      chapterId,
      result: { ...data, type: currentAssessmentType, answer: submitAnswer },
    });

    if (!error && !checked) {
      dispatch(setEditedCardIdSlice(createdData.id));
      navigateToAssessmentValue();
    }
    if (createdData.message) {
      message.success(createdData.message);
      return;
    }

    if (!error && checked) {
      message.success(<SuccessMessageView />);
    }

    if (error) {
      message.error(error?.data?.message || 'Request failed');
    }
  };

  const onClose = () => {
    if (!isDirty) return navigateToAssessmentValue();

    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRMATION,
        params: {
          modal: {
            title: 'Confirmation',
          },
          body: {
            title: <Title variant={5}>Are you sure you want to cancel?</Title>,
            onOk: () => {
              reset?.();
              navigateToAssessmentValue();
            },
          },
        },
      })
    );
  };

  return (
    <AddQuestionWrapper className='row'>
      <div className='col-10 offset-1'>
        {questionId ? null : (
          <div className='choose-question-box'>
            <Text $variant={1} className='label'>
              Choose question type
            </Text>
            <div className='d-flex align-items-center'>
              <DropdownV2 overlay={<DropdownBody isDirty={isDirty} reset={reset} setHasOpenModal={setHasOpenModal} />}>
                <SelectDropdown />
              </DropdownV2>
              <Tooltip $helpTooltip placement='right' overlay={desc}>
                <Help />
              </Tooltip>
            </div>
          </div>
        )}

        <Form onSubmit={handleSubmit(successFormData)}>
          <Question
            errors={errors}
            setValue={setValue}
            control={control}
            freeTextType={freeTextType}
            watch={watch}
            reset={reset}
          />
          {!hasAnswer ? (
            <Answers
              searchParams={searchParams}
              register={register}
              watch={watch}
              setValue={setValue}
              control={control}
            />
          ) : null}
          <Feedback
            control={control}
            register={register}
            errors={errors}
            hasFeedback={!!questionData?.feedback?.correct?.text}
          />
          <div className='row button-section'>
            <div className='col-10 offset-1 d-flex flex-row align-items-center justify-content-end'>
              <CheckBox
                checked={checked}
                className={classNames({ 'd-none': questionId })}
                onChange={() => setChecked((item) => !item)}
                label={
                  <Text $variant={1} color={theme().colors.dark[80]} className='checkbox-label'>
                    Add another question
                  </Text>
                }
              />
              <Button variant='secondary' type='button' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={disabled}
                className='save-button d-flex align-items-center justify-content-center'
                type='submit'
              >
                {submitButtonText}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </AddQuestionWrapper>
  );
};

export default AddQuestion;
