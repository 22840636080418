import { Link } from 'react-router-dom';

import PATHS from 'constants/paths';
import SCREEN_SIZES from 'constants/screen-sizes';

import Button from 'style-guide/Button';
import Title from 'style-guide/Typography/Title';
import styled from 'styled-components';

const ModalBodyWrapper = styled.div`
  width: 368px;
  .main {
    text-align: center;
    padding: 48px 0;
  }
  .primary {
    margin-left: 10px;
  }
  .secondary {
    margin-right: 10px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.SM}px) {
    width: 244px;
  }
`;

const PasswordResetSuccessModal = ({ hideModal }) => (
  <ModalBodyWrapper>
    <Title variant={5}>Password has been changed successfully</Title>
    <div className='row'>
      <div className='col d-flex align-items-center justify-content-center'>
        <Link to={PATHS.HOME}>
          <Button
            variant='secondary'
            onClick={() => {
              hideModal();
            }}
          >
            Cancel
          </Button>
        </Link>
        <Link to={PATHS.SIGN_IN}>
          <Button
            variant='primary'
            onClick={() => {
              hideModal();
            }}
          >
            Sign in
          </Button>
        </Link>
      </div>
    </div>
  </ModalBodyWrapper>
);

export default PasswordResetSuccessModal;
