const WeChat = (props) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='15' cy='15' r='15' fill='#607D8B' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.5 23.8167C15.9101 23.8167 13 21.2946 13 18.1834C13 15.0722 15.9101 12.55 19.5 12.55C23.0899 12.55 26 15.0722 26 18.1834C26 19.4987 25.4799 20.7087 24.6081 21.6674L23.8333 24.2501L21.7518 23.4695C21.0502 23.6941 20.2916 23.8167 19.5 23.8167ZM18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17ZM22 18C22.5523 18 23 17.5523 23 17C23 16.4477 22.5523 16 22 16C21.4477 16 21 16.4477 21 17C21 17.5523 21.4477 18 22 18Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3283 18.9211C11.2949 18.6601 11.2777 18.3948 11.2777 18.126C11.2777 14.1904 14.959 11 19.5 11C19.6006 11 19.7008 11.0016 19.8006 11.0047C19.0212 8.13562 16.0484 6 12.5 6C8.35786 6 5 8.91015 5 12.5C5 14.0177 5.60016 15.4139 6.60601 16.52L7.5 19.5L9.90172 18.5994C10.358 18.7454 10.8352 18.8541 11.3283 18.9211ZM11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10ZM15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z'
      fill='white'
    />
  </svg>
);

export default WeChat;
