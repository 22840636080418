import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getDomainTracking, saveLocalStorage } from 'configs/localStorage';
import { changeUserData } from 'store/slices/profile';

import { useUpdateArchiveMutation } from 'store/services/archive';
import {
  useDeleteOrganizationMutation,
  useGetAdminOrganizationsQuery,
} from 'store/services/superAdmin/AdminOrganizations';
import { useLazyGetAdminDataQuery } from 'store/services/superAdmin/AdminUsers';

import Pagination from 'style-guide/Pagination';
import Table from 'style-guide/Table';

import params from '../helper/helperFunction';
import SuperAdminOrganizationsWrapper from './SuperAdminOrganizationsWrapper';
import { COURSES_CONST, getOrganizationsColumns } from './columns';

const SuperAdminOrganizations = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const domainId = getDomainTracking().id;

  const [trigger] = useLazyGetAdminDataQuery();
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [updateArchive] = useUpdateArchiveMutation();

  const { data: organizationsList } = useGetAdminOrganizationsQuery(params({ page, domainId, searchParams }));

  const setCourseUserData = async (item, setPath) => {
    await trigger({ id: item?.id, table: COURSES_CONST.ORGANIZATIONS }).then((data) => {
      saveLocalStorage({
        user: data?.data,
      });
      dispatch(changeUserData({ isAuth: true, userData: data?.data }));
      navigate(setPath(item));
    });
  };

  return (
    <SuperAdminOrganizationsWrapper>
      <Table
        columns={getOrganizationsColumns({
          setCourseUserData,
          dispatch,
          deleteOrganization,
          updateArchive,
        })}
        scroll={{ x: 1200 }}
        data={organizationsList?.rows}
        rowKey={(row) => row.id}
      />
      <Pagination total={organizationsList?.count || 0} current={page} onChange={setPage} className='pagination' />
    </SuperAdminOrganizationsWrapper>
  );
};

export default SuperAdminOrganizations;
