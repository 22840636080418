import { string, number, object } from 'yup';
import { cardDetails } from './helper';

// Regex to validate MM/YY format (month between 01 and 12, and year is any two digits)
const expDateRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;

export const cardDetailsSchema = {
  number: number()
    .typeError('Please enter a valid credit card number')
    .test(
      'len',
      `This field has to be exactly ${cardDetails.cardDigitCount} digits`,
      (val) => val && val.toString().length === cardDetails.cardDigitCount
    )
    .required(),
  cvc: number()
    .typeError('Please enter a valid CVV number')
    .test(
      'len',
      `CVC must be either ${cardDetails.cvcDigitCount} or ${cardDetails.americanExpressDigitCount} digits`,
      (val) => val && [cardDetails.cvcDigitCount, cardDetails.americanExpressDigitCount].includes(val.toString().length)
    )
    .required('CVC is required'),
  date: string()
    .matches(expDateRegex, 'Please enter a valid date in MM/YY format') // Custom regex validation
    .required('Expiration date is required'),
};

const schema = object().shape(cardDetailsSchema);

export default schema;
