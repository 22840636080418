import styled from 'styled-components';

const CompaniesWrapper = styled.div`
  width: 100%;

  .rc-table {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 36px;
    }
  }

  .icon {
    path {
      fill: ${({ theme }) => theme.colors.tertiary[800]};
    }
  }

  .add-button {
    margin: 28px;
  }

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .company-pencil {
    margin-right: 36px;
  }
`;

export default CompaniesWrapper;
