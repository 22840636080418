import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const ContactInformationWrapper = styled.div`
  width: 350px;
  @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
    width: 288px;
  }
`;

export default ContactInformationWrapper;
