import { date, mixed, object, string } from 'yup';

const bankRoutingNumberRegex = /^[0-9*]+$/;
const pattern = /^\d{5}([-]?\d{4})?$/;
const schema = object()
  .shape(
    {
      bankFirstName: string().trim().required('Please, input the First Name').max(70, 'Must be maximum 70 symbols'),
      bankStateName: mixed().required(),
      bankLastName: string().trim().required('Please, input the Last Name').max(70, 'Must be maximum 70 symbols'),
      bankCity: string().trim().required('Please, input the City'),
      accountType: string().required('Please, input the Account Type'),
      bankAddress: string().trim().required('Please, input the Address').max(70, 'Must be maximum 70 symbols'),
      bankAccountNumber: string()
        .trim()
        .required('This field can not be blank')
        .min(8, 'Must be minimum 8 digits')
        .max(12, 'Must be maximum 12 digits'),
      bankRoutingNumber: string()
        .trim()
        .matches(new RegExp(bankRoutingNumberRegex), (val) =>
          val.value ? 'Please enter a number' : 'This field is required'
        )
        .min(9, 'Must be exactly 9 digits')
        .max(9, 'Must be exactly 9 digits'),
      bankEmail: string().email('The input is not valid E-mail').required('Please, input the E-mail'),
      bankZipCode: string()
        .trim()
        .matches(new RegExp(pattern), 'Please, input a valid Postal Code')
        .required('Please, input the Postal Code'),
      bankDob: date().required(),
      companyName: string().when('accountType', {
        is: (value) => value === 'company',
        then: string().required('Please, input the Company Name'),
        otherwise: string().nullable(),
      }),
    },
    ['accountType', 'companyName']
  )
  .required();

export default schema;
