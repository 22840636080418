import { useDropzone } from 'react-dropzone';
import Button from 'style-guide/Button';
import { Input } from 'style-guide/Input';
import { message } from 'style-guide/ToastMessages';
import Title from 'style-guide/Typography/Title';

const DragAndDropFileUpload = ({ upload, defaultSelectedFile }) => {
  const onDrop = (acceptedFiles, fileRejections) => {
    if (!acceptedFiles[0]) {
      const rejectedFile = fileRejections[0];
      return message.error(rejectedFile.errors[0].message);
    }

    upload(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    noClick: true,
  });

  return (
    <div className='drag-file-box d-flex flex-column align-items-center' {...getRootProps()}>
      <Title variant={5}>Drag your files here to start uploading</Title>
      <Title variant={5}>or</Title>
      <Input
        id='file'
        name='file'
        type='file'
        value=''
        {...getInputProps()}
        accept={defaultSelectedFile?.[0]?.accept || ''}
        multiple={false}
      />
      <Button variant='primary' onClick={(e) => e.stopPropagation()}>
        <label htmlFor='file' className='d-flex align-items-center pointer'>
          Browse files
        </label>
      </Button>
    </div>
  );
};

export default DragAndDropFileUpload;
