import styled from 'styled-components';

const PaymentModalWrapper = styled.div`
  width: 428px;
  .arrow-button {
    padding: 0;
    .button-text {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .card-detail {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export default PaymentModalWrapper;
