import {
  MultipleAnswer,
  MultipleBlanks,
  MultipleChoice,
  ResponseMatching,
  ScaledResponse,
  ShortEssay,
  ShortEssayScored,
  TrueOrFalse,
} from 'style-guide/Icons';

const TEST_TYPES = {
  TestsTypeMultipleResponse: 1,
  TestsTypeCloseExercise: 2,
  TestsTypeDragAndDrop: 3,
  TestsTypeResponseMatching: 4,
  TestsTypeTrueFalse: 5,
  TestsTypeFreeText: 6,
  TestsTypeMultipleChoice: 7,
  TestsTypeScoredEssay: 8,
  TestsScaledResponseScored: 9,
};

const getTypeData = (type) => {
  const testData = {
    [TEST_TYPES.TestsTypeTrueFalse]: {
      name: 'True or False',
      type: TEST_TYPES.TestsTypeTrueFalse,
      desc: 'True or False questions allow learners to answer either true or false in response to a question.',
    },
    [TEST_TYPES.TestsTypeMultipleChoice]: {
      name: 'Multiple Choice',
      type: TEST_TYPES.TestsTypeMultipleChoice,
      desc: 'Multiple choice questions allow learners to choose only one correct answer out of multiple answers choices.',
    },
    [TEST_TYPES.TestsTypeMultipleResponse]: {
      name: 'Multiple Answer',
      type: TEST_TYPES.TestsTypeMultipleResponse,
      desc: 'Multiple answer questions allow learners to choose more than one answer from the pool of choices.',
    },
    [TEST_TYPES.TestsTypeCloseExercise]: {
      name: 'Fill in the Blank',
      type: TEST_TYPES.TestsTypeCloseExercise,
      desc: 'Fill in the Blank questions consist of a sentence with one blank space indicating where the student should type the missing word.',
    },
    [TEST_TYPES.TestsTypeResponseMatching]: {
      name: 'Response Matching',
      type: TEST_TYPES.TestsTypeResponseMatching,
      desc: 'Response Matching questions allow learners to match items in the right column to items of the left column.',
    },
    [TEST_TYPES.TestsTypeDragAndDrop]: {
      name: 'Multiple Blanks with Answer Pool',
      type: TEST_TYPES.TestsTypeDragAndDrop,
      desc: 'In Fill in Multiple Blanks questions, learners are presented with text containing multiple blanks and a pool of right and wrong answers to drag-and-drop into the blanks.',
    },
    [TEST_TYPES.TestsTypeFreeText]: {
      name: 'Short Essay',
      type: TEST_TYPES.TestsTypeFreeText,
      desc: 'Essay questions allow learners to type their answer in a large text box.',
    },
    [TEST_TYPES.TestsTypeScoredEssay]: {
      name: 'Short Essay - Scored',
      type: TEST_TYPES.TestsTypeScoredEssay,
      desc: 'Short Essay - Scored questions allow learners to type their responses in a large text box, and when submitted are scored by key-word analysis.',
    },
    [TEST_TYPES.TestsScaledResponseScored]: {
      name: 'Scaled Response – Scored',
      type: TEST_TYPES.TestsScaledResponseScored,
      desc: 'Scaled Response – Scored questions allow learners to select multiple responses from a numeric range, and when submitted displays the top and bottom responses with feedback text.',
    },
  };

  return type ? testData[type] : null;
};

const getTypes = () => [
  {
    name: 'True or False',
    type: TEST_TYPES.TestsTypeTrueFalse,
    desc: 'True or False questions allow learners to answer either true or false in response to a question.',
    Icon: TrueOrFalse,
  },
  {
    name: 'Multiple Choice',
    type: TEST_TYPES.TestsTypeMultipleChoice,
    desc: 'Multiple choice questions allow learners to choose only one correct answer out of multiple answers choices.',
    Icon: MultipleChoice,
  },
  {
    name: 'Multiple Answer',
    type: TEST_TYPES.TestsTypeMultipleResponse,
    desc: 'Multiple answer questions allow learners to choose more than one answer from the pool of choices.',
    Icon: MultipleAnswer,
  },

  // ToDo - Remove this code after the release of the new version

  // {
  //   name: 'Fill in the Blank',
  //   type: TEST_TYPES.TestsTypeCloseExercise,
  //   desc: 'Fill in the Blank questions consist of a sentence with one blank space indicating where the student should type the missing word.',
  //   Icon: FileBlank,
  // },
  {
    name: 'Multiple Blanks with Answer Pool',
    type: TEST_TYPES.TestsTypeDragAndDrop,
    desc: 'In Fill in Multiple Blanks questions, learners are presented with text containing multiple blanks and a pool of right and wrong answers to drag-and-drop into the blanks.',
    Icon: MultipleBlanks,
  },
  {
    name: 'Response Matching',
    type: TEST_TYPES.TestsTypeResponseMatching,
    desc: 'Response Matching questions allow learners to match items in the right column to items of the left column.',
    Icon: ResponseMatching,
  },
  {
    name: 'Short Essay',
    type: TEST_TYPES.TestsTypeFreeText,
    desc: 'Essay questions allow learners to type their answer in a large text box.',
    Icon: ShortEssay,
  },
  {
    name: 'Short Essay - Scored',
    type: TEST_TYPES.TestsTypeScoredEssay,
    desc: 'Short Essay - Scored questions allow learners to type their responses in a large text box, and when submitted are scored by key-word analysis.',
    Icon: ShortEssayScored,
  },
  {
    name: 'Scaled Response – Scored',
    type: TEST_TYPES.TestsScaledResponseScored,
    desc: 'Scaled Response – Scored questions allow learners to select multiple responses from a numeric range, and when submitted displays the top and bottom responses with feedback text.',
    Icon: ScaledResponse,
  },
];

const test_types = {
  types: TEST_TYPES,
  getTypeData,
  getTypes,
};

export default test_types;
