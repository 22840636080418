import 'rc-tooltip/assets/bootstrap.css';
import styled, { css } from 'styled-components';

const UserAvatarWrapper = styled.div`
  .avatar-item:not(:first-child) {
    margin-left: -6px;
  }
  .initials {
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 14px;
  }
  .avatar-photo {
    ${({ $imgSrc }) =>
      css`
        background-image: url(${$imgSrc});
      `}
  }
  .user-avatar {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    ${({ $type }) =>
      $type === 'large' &&
      css`
        width: 40px;
        height: 40px;
      `}
    ${({ $type }) =>
      $type === 'default' &&
      css`
        width: 32px;
        height: 32px;
      `}
    ${({ $type }) =>
      $type === 'small' &&
      css`
        width: 24px;
        height: 24px;
      `}
  }
`;

export default UserAvatarWrapper;
