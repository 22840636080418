import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import NodeView from './NodeView';

// eslint-disable-next-line import/extensions

export const shortCode = Node.create({
  name: 'shortCode',
  group: 'block',
  selectable: true,
  draggable: true,
  atom: true,
  inline: false,

  addOptions() {
    return {
      id: null,
      type: null,
      src: null,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        renderHTML: (e) => e?.id,
        parseHTML: (e) => e?.id,
      },
      type: {
        default: null,
        renderHTML: (e) => e?.type,
        parseHTML: (e) => e?.type,
      },
      src: {
        default: null,
        parseHTML: (e) => e?.src,
        renderHTML: (e) => e?.src,
      },
    };
  },

  addCommands() {
    return {
      setShortCode:
        (options) =>
        ({ commands }) =>
          commands.insertContent([
            {
              type: this.name,
              attrs: options,
            },
          ]),
      setShortCodeAt:
        (from, options) =>
        ({ commands }) =>
          commands.insertContentAt(from, {
            type: this.name,
            attrs: options,
          }),
    };
  },

  parseHTML() {
    return [{ tag: 'short-code' }];
  },

  renderHTML({ node }) {
    const { type, id } = node.attrs;
    return ['short-code', { type, id }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeView);
  },
});
