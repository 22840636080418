import emptySplitApi from '..';

export const departmentReviewListApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getDepartmentReviews: build.query({
      query: (params) => ({
        url: `/libraries/${params.urlName}/review-list`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response,
      providesTags: ['department-reviews'],
    }),
    createDepartmentReview: build.mutation({
      query: (params) => ({
        url: `libraries/${params.urlName}/review-list`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['department-reviews'],
    }),
    updateDepartmentReview: build.mutation({
      query: (params) => ({
        url: `libraries/${params.urlName}/review-list`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['department-reviews'],
    }),
    deleteDepartmentReview: build.mutation({
      query: (params) => ({
        url: `libraries/${params.urlName}/review-list/${params.id}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['department-reviews'],
    }),
  }),
  overrideExisting: false,
});

export default departmentReviewListApi;
export const {
  useGetDepartmentReviewsQuery,
  useCreateDepartmentReviewMutation,
  useDeleteDepartmentReviewMutation,
  useUpdateDepartmentReviewMutation,
} = departmentReviewListApi;
