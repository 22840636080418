import PATHS from 'constants/paths';
import { Navigate, useRoutes } from 'react-router-dom';

import { loadState } from 'configs/localStorage';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { getUserIsAuth } from 'store/selectors/user';
import useIsLimitedUser from 'store/services/helperHooks/useIsLimitedUser';
import PageSpin from 'style-guide/Spin/PageSpin';
import NotFound from 'views/NotFound';
import limitedUserRoute from './Helpers/limitedUserRoute';
import mainRoute from './Helpers/mainRoute';
import superAdminPaths from './Helpers/superAdminPaths';

const AppRouters = () => {
  const isAuth = useSelector(getUserIsAuth);

  const { user, adminUser } = loadState();
  const isLimitedUser = useIsLimitedUser();

  const element = useRoutes(
    isLimitedUser
      ? limitedUserRoute(user, adminUser)
      : [
          ...mainRoute(isAuth),
          ...superAdminPaths(adminUser),
          {
            path: '/*',
            element: <Navigate to={PATHS.NOT_FOUND} replace />,
          },
          {
            path: PATHS.NOT_FOUND,
            element: (
              <Suspense fallback={<PageSpin />}>
                <NotFound />
              </Suspense>
            ),
          },
        ]
  );

  return element;
};

export default AppRouters;
