const Video = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.5 19C6.1 19 5.75 18.85 5.45 18.55C5.15 18.25 5 17.9 5 17.5V3.5C5 3.1 5.15 2.75 5.45 2.45C5.75 2.15 6.1 2 6.5 2H20.5C20.9 2 21.25 2.15 21.55 2.45C21.85 2.75 22 3.1 22 3.5V17.5C22 17.9 21.85 18.25 21.55 18.55C21.25 18.85 20.9 19 20.5 19H6.5ZM6.5 17.5H20.5V3.5H6.5V17.5ZM3.5 22C3.1 22 2.75 21.85 2.45 21.55C2.15 21.25 2 20.9 2 20.5V5H3.5V20.5H19V22H3.5Z'
      fill='#333333'
      fillOpacity='0.8'
    />
    <path
      d='M15.1111 15.7222V14.8611C16.0093 14.6019 16.7431 14.1042 17.3125 13.3681C17.8819 12.6319 18.1667 11.7963 18.1667 10.8611C18.1667 9.92593 17.8843 9.08796 17.3194 8.34722C16.7546 7.60648 16.0185 7.11111 15.1111 6.86111V6C16.2593 6.25926 17.1944 6.84028 17.9167 7.74306C18.6389 8.64583 19 9.68518 19 10.8611C19 12.037 18.6389 13.0764 17.9167 13.9792C17.1944 14.8819 16.2593 15.463 15.1111 15.7222ZM9 12.5417V9.20833H11.2222L14 6.43056V15.3194L11.2222 12.5417H9ZM14.8333 13.2083V8.52778C15.3426 8.68519 15.7477 8.98148 16.0486 9.41667C16.3495 9.85185 16.5 10.338 16.5 10.875C16.5 11.4028 16.3472 11.8843 16.0417 12.3194C15.7361 12.7546 15.3333 13.0509 14.8333 13.2083ZM13.1667 8.54167L11.5972 10.0417H9.83333V11.7083H11.5972L13.1667 13.2222V8.54167Z'
      fill='#333333'
      fillOpacity='0.8'
    />
  </svg>
);

export default Video;
