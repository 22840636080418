/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  customModal: { isOpen: false },
  activeChapterDuration: {},
};

const activeChapterDurationDefault = {
  total: 0,
  videoDurationCount: 0,
  vimeoVideoDuration: 0,
  youTubeVideoDuration: 0,
  readingDuration: 0,
};

const learningCommunitySlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    customModal(state, action) {
      const { payload } = action;
      state.customModal = payload;
    },

    activeChapter(state, action) {
      const { payload } = action;
      state.activeChapter = payload;
    },

    activeVolume(state, action) {
      const { payload } = action;
      state.activeVolume = payload;
    },

    activeChapterDuration(state, action) {
      const { payload } = action;
      if (!payload.chapterId) {
        return;
      }
      const item = { ...(state.activeChapterDuration[payload.chapterId] || activeChapterDurationDefault), ...payload };
      const duration = item.videoDurationCount + item.youTubeVideoDuration + item.vimeoVideoDuration;
      const videoAndAudioDurationTotal = moment.utc(duration * 1000).format('HH:mm:ss');

      if (
        state.activeChapterDuration[payload.chapterId] &&
        state.activeChapterDuration[payload.chapterId] === videoAndAudioDurationTotal
      ) {
        return;
      }

      state.activeChapterDuration[payload.chapterId] = {
        ...item,
        readingDuration: moment.utc(item.readingDuration * 1000).format('HH:mm:ss'),
        videoAndAudioDurationTotal,
      };
    },
    resetActiveChapterDuration(state) {
      state.activeChapterDuration = {};
    },
    discussionPanel(state, action) {
      const { payload } = action;
      state.discussionPanel = payload;
    },
  },
});

export const {
  customModal,
  activeChapter,
  activeVolume,
  discussionPanel,
  activeChapterDuration,
  resetActiveChapterDuration,
} = learningCommunitySlice.actions;
export default learningCommunitySlice.reducer;
