import styled from 'styled-components';

const DropdownWrapper = styled.ul`
  width: max-content;
  li {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border: 1px solid ${({ theme }) => theme.colors.white[100]};
    list-style: none;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[15]};
    .dropdown-item-text {
      color: ${({ theme }) => theme.colors.secondary[400]};
    }
    cursor: pointer;
    svg {
      path {
        fill: ${({ theme }) => theme.colors.secondary[400]};
      }
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.primary[600]};
    }
    &:active {
      .dropdown-item-text {
        color: ${({ theme }) => theme.colors.primary[800]};
      }
      background-color: ${({ theme }) => theme.colors.primary[200]};
      border: 1px solid ${({ theme }) => theme.colors.primary[200]};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.primary[800]};
        }
      }
    }
  }
`;

export default DropdownWrapper;
