import styled from 'styled-components';
import FormItemsComponent from './FormItem';

const FormItem = styled(FormItemsComponent)`
  .input-content {
    input {
      margin-bottom: 0px !important;
    }
  }
  .required-symbol {
    color: ${({ theme }) => theme.colors.danger[600]};
  }

  .form-item-label {
    margin-bottom: 4px;
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.dark[100]};
  }
  &.form-component {
    width: 100%;
    margin-bottom: 20px;
    &.error {
      margin-bottom: 0px;
      input,
      textarea {
        border: 1px solid ${({ theme }) => theme.colors.error[400]};
      }
      input[type='radio'] {
        border-color: ${({ theme }) => theme.colors.primary[800]};
      }
      .required-error {
        margin-bottom: 0px;
        line-height: 20px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.error[400]};
      }
    }
  }
`;

export default FormItem;
