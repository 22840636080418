import styled, { css } from 'styled-components';

const SliderWrapper = styled.div`
  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 8px ${({ theme }) => theme.colors.greenBackground[35]} !important;
  }
  .rc-slider-handle {
    border: 2px solid ${({ theme }) => theme.colors.greenBackground[25]} !important;
    background-color: ${({ theme }) => theme.colors.greenBackground[25]} !important;
    opacity: 1;
  }
  .rc-slider-mark-text:nth-of-type(even) {
    transform: translate(-50%, -33px) !important;
  }
  .rc-slider-dot {
    border: 0 !important;
    width: 2px;
    height: 2px;
    bottom: 1px;
    background-color: ${({ theme }) => theme.colors.greenBackground[25]};
  }
  .rc-slider-step {
    background-color: ${({ theme }) => theme.colors.greenBackground[35]};
  }
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.greenBackground[25]};
  }
  ${({ $type }) =>
    $type === 'large' &&
    css`
      .rc-slider-rail {
        background-color: transparent !important ;
      }
      .rc-slider-step {
        height: 6px !important;
        border-radius: 12px;
      }
      .rc-slider-track {
        height: 6px !important;
        top: 5px;
      }
      .rc-slider-dot {
        &:is(:first-child) {
          margin-left: 2px;
        }
        &:is(:last-child) {
          margin-left: -2px;
        }
        bottom: 2px !important;
      }
      .rc-slider-handle {
        height: 20px !important;
        width: 20px !important;
        bottom: -4px;
      }
    `}
  ${({ $disabled, $type }) =>
    $disabled &&
    $type !== 'large' &&
    css`
      .rc-slider-disabled {
        background-color: transparent !important;
      }
      .rc-slider-step {
        background-color: ${({ theme }) => theme.colors.disabled[35]} !important;
      }
      .rc-slider-handle {
        border: 2px solid ${({ theme }) => theme.colors.disabled[60]} !important;
        background-color: ${({ theme }) => theme.colors.disabled[60]} !important;
      }
    `}
    ${({ $disabled, $type }) =>
    $disabled &&
    $type === 'large' &&
    css`
      .rc-slider-disabled {
        background-color: transparent !important;
      }
      .rc-slider-step {
        background-color: ${({ theme }) => theme.colors.disabled[45]} !important;
      }
      .rc-slider-handle {
        border: 2px solid ${({ theme }) => theme.colors.disabled[60]} !important;
        background-color: ${({ theme }) => theme.colors.disabled[60]} !important;
      }
    `}
  .rc-slider-handle:active .slider-tooltip {
    visibility: visible;
  }
  .rc-slider-handle:active .tooltip-value {
    visibility: visible;
  }
  .tooltip-value {
    visibility: hidden;
    background-color: ${({ theme }) => theme.colors.dark[80]};
    color: ${({ theme }) => theme.colors.white[100]};
    border-radius: 4px;
    position: absolute;
    top: -32px;
    right: -20px;
    min-width: 40px;
  }
  .slider-tooltip {
    visibility: hidden;
    position: relative;
    top: -10px;
    border-style: solid;
    border-width: 4px;
    border-color: ${({ theme }) => theme.colors.dark[80]} transparent transparent transparent;
  }
`;

export default SliderWrapper;
