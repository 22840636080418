import PATHS from 'constants/paths';

const paths = [
  PATHS.HOME,
  PATHS.ACTIVATE_ACCOUNT,
  PATHS.SUBMISSIONS,
  PATHS.FORGOT_PASSWORD,
  PATHS.RESET_PASSWORD,
  PATHS.VERIFY_EMAIL,
  PATHS.VERIFY_EMAIL,
  PATHS.ABOUT,
  PATHS.SERVICES,
  PATHS.REFOUND_POLICY,
];

const showFooterPaths = paths.map((e) => ({ path: e }));

export default showFooterPaths;
