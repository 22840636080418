/* eslint-disable no-param-reassign */
const addMonths = (date, count) => {
  if (!date || !count) return;

  const d = (date = new Date(+date)).getUTCDate();

  date.setUTCMonth(date.getUTCMonth() + count, 1);
  const m = date.getUTCMonth();
  date.setUTCDate(d);
  if (date.getUTCMonth() !== m) date.setUTCDate(0);

  return date;
};

const DATE_UTILS = {
  addMonths,
};

export default DATE_UTILS;
