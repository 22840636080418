import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const ChangePasswordWrapper = styled.div`
  .change-password-modal-root {
    width: 368px;

    @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
      width: 288px;
    }
  }
`;

export default ChangePasswordWrapper;
