import { transformErrorResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

export const accessCodeApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    enroll: build.mutation({
      query: (params) => ({
        url: '/enroll',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['enroll-user'],
      transformErrorResponse,
    }),
    buyAccessCode: build.mutation({
      query: (params) => ({
        url: 'access-codes/user',
        method: 'POST',
        body: params,
      }),
      transformErrorResponse,
      transformResponse: (response) => response.data,
    }),
    buyAccessCodeAdmin: build.mutation({
      query: (params) => ({
        url: 'access-codes/admin',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (_result, _err, arg) => {
        switch (arg.entityType) {
          case 'library':
            return ['department'];
          case 'organization':
            return ['organization'];

          default:
            null;
        }
      },
      transformErrorResponse,
      transformResponse: (response) => response.data,
    }),

    getOrganizationSubscriptions: build.query({
      query: () => ({
        url: '/organization-subscriptions',
        method: 'Get',
      }),
      transformErrorResponse,
    }),

    createOrganizationSubscription: build.mutation({
      query: (params) => ({
        url: '/organization-subscriptions',
        method: 'POST',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useEnrollMutation,
  useBuyAccessCodeMutation,
  useBuyAccessCodeAdminMutation,
  useGetOrganizationSubscriptionsQuery,
  useCreateOrganizationSubscriptionMutation,
} = accessCodeApi;
export default accessCodeApi;
