const Linkedin = (props) => (
  <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 0.5C6.717 0.5 0 7.217 0 15.5C0 23.783 6.717 30.5 15 30.5C23.283 30.5 30 23.783 30 15.5C30 7.217 23.283 0.5 15 0.5ZM10.6412 23.1758H6.98799V12.1851H10.6412V23.1758ZM8.8147 10.6843H8.79089C7.565 10.6843 6.77216 9.84044 6.77216 8.78575C6.77216 7.70726 7.58926 6.88672 8.83896 6.88672C10.0887 6.88672 10.8577 7.70726 10.8815 8.78575C10.8815 9.84044 10.0887 10.6843 8.8147 10.6843ZM23.8138 23.1758H20.1611V17.296C20.1611 15.8184 19.6321 14.8106 18.3103 14.8106C17.3012 14.8106 16.7001 15.4904 16.436 16.1466C16.3394 16.3814 16.3158 16.7096 16.3158 17.0381V23.1758H12.6629C12.6629 23.1758 12.7107 13.2162 12.6629 12.1851H16.3158V13.7413C16.8013 12.9924 17.6699 11.9272 19.6081 11.9272C22.0116 11.9272 23.8138 13.498 23.8138 16.8737V23.1758Z'
      fill='#607D8B'
    />
  </svg>
);

export default Linkedin;
