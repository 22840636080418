import { yupResolver } from '@hookform/resolvers/yup';
import PaymentForm from 'Components/PaymentForm';
import paymentImg from 'assets/images/paymentImg.jpg';
import { useForm, useWatch } from 'react-hook-form';
import Button from 'style-guide/Button';
import Form from 'style-guide/Form';
import { ArrowLeft } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Modal from 'style-guide/Modal';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';
import schema from '../../PaymentForm/schema';
import PaymentModalWrapper from './style/PaymentModalWrapper';

const PaymentModal = ({ showModal, onToggleModal, name, price, entityName, onOk, isSuccess, isEnrollLoading }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { number: '', date: null, cvc: '' },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    onOk?.({ ...data, 'card-number': data.number });
    if (isSuccess) {
      reset({});
    }
  };
  const { number, date, cvc } = useWatch({ control });
  const isDisabled = !number || !date || !cvc || isEnrollLoading || Object.keys(errors).length !== 0;
  return (
    <Modal
      visible={showModal}
      onClose={onToggleModal}
      title={<Title variant={5}>{`Join ${entityName}`}</Title>}
      afterClose={() => reset({})}
    >
      <PaymentModalWrapper className='row'>
        <div className='col'>
          <Button
            variant='link'
            className='arrow-button'
            prefix={<ArrowLeft width={12} height={12} />}
            onClick={onToggleModal}
          >
            Back
          </Button>
          <div className='d-flex justify-content-between'>
            <Text $variant={2} $dark={100}>
              {name}
            </Text>
            <Title variant={5}>${price}</Title>
          </div>
          <div className='d-flex justify-content-between card-detail'>
            <Text $variant={3} $dark={100}>
              Card Detail
            </Text>
            <Image src={paymentImg} />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <PaymentForm control={control} errors={errors} onToggleModal={onToggleModal} disabled={isDisabled} />
          </Form>
        </div>
      </PaymentModalWrapper>
    </Modal>
  );
};

export default PaymentModal;
