import { object, string } from 'yup';

const schema = object({
  companyPhoneNumber: string()
    .matches(/^\+?\d{1,3}\d{0,14}$/, 'Invalid phone number format') // Allow optional +
    .max(20, 'Phone number must be at most 20 characters')
    .transform((value) => (value === '' ? null : value))
    .optional()
    .nullable(),
  personalPhoneNumber: string()
    .matches(/^\+?\d{1,3}\d{0,14}$/, 'Invalid phone number format')
    .max(20, 'Phone number must be at most 20 characters')
    .transform((value) => (value === '' ? null : value))
    .optional()
    .nullable(),
  companyName: string().trim().optional().nullable(),
  industry: string().trim().optional().nullable(),
  referralCode: string().trim().optional().nullable(),
});

export default schema;
