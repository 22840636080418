import { USER_COURSE_TYPES, VERSION_TARGETS } from 'common';
import LEARNING_COMMUNITY_SECTIONS from './course-sections';

const { member } = USER_COURSE_TYPES.userCourseRole;

const { STORY, INITIAL_THOUGHTS, EXPERT_RESOURCES, NEW_INSIGHTS, ASSESSMENT } = VERSION_TARGETS;

export const allSection = [STORY, INITIAL_THOUGHTS, EXPERT_RESOURCES, NEW_INSIGHTS];

const firstPositionInitialThoughts = [INITIAL_THOUGHTS, STORY, EXPERT_RESOURCES, NEW_INSIGHTS];

export const getFirstSectionKey = (sectionsOrder) => {
  if (sectionsOrder?.[0] === 'INITIAL_THOUGHTS') {
    return LEARNING_COMMUNITY_SECTIONS.INITIAL_THOUGHTS.value;
  }

  return LEARNING_COMMUNITY_SECTIONS.STORY.value;
};

export const getChaptersList = ({ sections, hasEditAccess, disabled, initialThoughtsFirst, role }) => {
  if (!sections) {
    return [];
  }
  if (!sections.initialThoughts && sections.test && role === member) {
    return [STORY, ASSESSMENT];
  }

  if (!sections.initialThoughts && !sections.test && role === member) {
    return [STORY];
  }

  if (sections.initialThoughts && !sections.test && !initialThoughtsFirst && role === member) {
    return [...allSection];
  }

  if (sections.initialThoughts && !sections.test && initialThoughtsFirst && role === member) {
    return [...firstPositionInitialThoughts];
  }

  if (hasEditAccess && sections.initialThoughts && initialThoughtsFirst) {
    return [...firstPositionInitialThoughts, { ...ASSESSMENT, disabled: !hasEditAccess && disabled }];
  }

  if (hasEditAccess && sections.initialThoughts) {
    return [...allSection, { ...ASSESSMENT, disabled: !hasEditAccess && disabled }];
  }

  if (hasEditAccess && initialThoughtsFirst) {
    return [INITIAL_THOUGHTS, STORY, { ...ASSESSMENT, disabled: !hasEditAccess && disabled }];
  }

  if (hasEditAccess) {
    return [STORY, INITIAL_THOUGHTS, { ...ASSESSMENT, disabled: !hasEditAccess && disabled }];
  }

  let learnerSections = [STORY];
  if (sections.initialThoughts) {
    learnerSections = [STORY, INITIAL_THOUGHTS, EXPERT_RESOURCES, NEW_INSIGHTS];
  }

  if (sections.initialThoughts && initialThoughtsFirst) {
    learnerSections = [INITIAL_THOUGHTS, STORY, EXPERT_RESOURCES, NEW_INSIGHTS];
  }

  if (sections.test) {
    learnerSections.push({ ...ASSESSMENT, disabled: !hasEditAccess && disabled });
  }

  return learnerSections;
};
