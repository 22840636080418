import SCREEN_SIZES from 'constants/screen-sizes';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  z-index: 100;

  &.dark-mode {
    .footer-container {
      background-color: ${({ theme }) => theme.colors.dark[100]} !important;
      a {
        color: ${({ theme }) => theme.colors.white[100]} !important;
      }
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white[100]};
        }
      }
      .text {
        color: ${({ theme }) => theme.colors.white[100]};
      }
      .year-box {
        color: ${({ theme }) => theme.colors.white[100]};
      }
    }
  }

  .footer-root {
    .footer-container {
      border-top: 1px solid ${({ theme }) => theme.colors.secondary[200]};
      display: flex;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.colors.white[100]};
      padding: 36px 50px 15px 50px;

      @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
        flex-direction: column-reverse;
        padding: 25px 45px;
      }
      @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
        padding: 25px 45px;
      }

      .logo-box {
        @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
          margin-top: 20px;
        }

        .peer-tree-logo {
          height: 54px;
          margin-bottom: 36px;
        }

        .ecf-logo {
          height: 52px;
          margin-bottom: 36px;
        }
        .workvibes-logo {
          height: 48px;
          padding: 12px;
        }
        .hippo-logo {
          height: 54px;
          margin-bottom: 24px;
        }
        .vanderbilt-logo {
          height: 52px;
          margin-bottom: 24px;
        }
        .csm-logo {
          height: 44px;
          margin-bottom: 8px;
        }

        .fsc-logo,
        .mlk-logo,
        .tvg-logo,
        .me-logo,
        .pear-logo,
        .fba-logo {
          height: 60px;
          margin-bottom: 20px;
        }

        .nucleus-logo {
          width: 160px;
          padding-left: 12px;
        }

        .year-box {
          display: block;
        }
      }

      .column-box {
        display: flex;
        @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
          flex-direction: column;
        }
        .first-column {
          margin-right: 90px;
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            margin-right: 50px;
          }
          @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
            margin-right: 0;
          }
          .link {
            display: block;
            margin-bottom: 12px;
            &::last-child {
              margin-bottom: 0;
            }
            a {
              color: ${({ theme }) => theme.colors.dark[80]};
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
        .second-column {
          margin-right: 100px;
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            margin-right: 60px;
          }
          @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
            margin-right: 0;
            margin-top: 20px;
          }
          .link {
            display: block;
            margin-bottom: 12px;
            &::last-child {
              margin-bottom: 0;
            }
            a {
              color: ${({ theme }) => theme.colors.dark[80]};
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
        .third-column {
          @media only screen and (max-width: ${SCREEN_SIZES.MD}px) {
            margin-top: 20px;
          }
          .link {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            color: ${({ theme }) => theme.colors.dark[80]};
            font-weight: 400;
            font-size: 14px;
            &::last-child {
              margin-bottom: 0;
            }
            .map-icon {
              width: 16px;
              height: 16px;
            }
            a {
              color: ${({ theme }) => theme.colors.dark[80]};
              font-weight: 400;
              font-size: 14px;
            }

            .text {
              margin-left: 12px;
            }
            .icon {
              margin-right: 16px;
            }
          }
        }
      }
      .logo-box {
        @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
          margin-top: 20px;
        }
        .peer-tree-logo {
          height: 54px;
          margin-bottom: 36px;
        }
        .hippo-logo {
          height: 54px;
          margin-bottom: 24px;
        }
        .ssa-logo {
          height: 54px;
        }
        .vanderbilt-logo {
          height: 52px;
          margin-bottom: 24px;
        }
        .political-islam-logo {
          height: 52px;
          @media only screen and (max-width: ${SCREEN_SIZES.XL}px) {
            height: 40px;
          }
        }
        .year-box {
          display: block;
        }
        .fsc-logo {
          height: 60px;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export default FooterWrapper;
