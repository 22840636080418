import { Node, mergeAttributes } from '@tiptap/core';

const ImageExtension = Node.create({
  name: 'img', // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: false, // so we can drag the video
  atom: true, // is a single unit

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.src,
      },
      alt: {
        default: null,
        parseHTML: (element) => element.getAttribute('alt'),
      },
    };
  },

  addNodeView() {
    return ({ node }) => {
      const image = document.createElement('img');
      Object.assign(image, node.attrs);

      return {
        dom: image,
      };
    };
  },
  parseHTML() {
    return [{ tag: 'img' }];
  },
  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)];
  },
});

export default ImageExtension;
