import moment from 'moment';
import { useDownloadFileS3Mutation } from 'store/services/downloadFile/downloadFileS3';

import { Audio, DOC, DOCX, Download, Image, PDF, PPT, Trash, Upload, Video } from 'style-guide/Icons';
import Tooltip from 'style-guide/Tooltip';
import Text from 'style-guide/Typography/Text';

const getFile = {
  image: Image,
  video: Video,
  audio: Audio,
  pdf: PDF,
  ppt: PPT,
  doc: DOC,
  excel: DOCX,
};

const Actions = ({ row, upload, remove }) => {
  const [downloadFileS3] = useDownloadFileS3Mutation();
  const onDelete = () => {
    remove({ row });
  };
  const onUpload = () => {
    upload({ row });
  };

  const onDownload = () => {
    const { name, removePath } = row;
    downloadFileS3({ url: removePath, name });
  };

  return (
    <div className='action-box'>
      <Upload className='upload pointer' onClick={onUpload} />
      <Download className='upload pointer' onClick={onDownload} />
      <Trash className='delete pointer' onClick={onDelete} />
    </div>
  );
};

const getColumns = ({ remove, upload }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, row) => {
      const Icon = getFile[row.type];
      return (
        <Tooltip placement='top' overlay={name}>
          <div className='d-flex align-items-center'>
            <div>
              <Icon />
            </div>
            <Text $variant={2} $ellipsis className='table-name-content'>
              {name}
            </Text>
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) => moment(value).format('L'),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 150,
    render: (_value, row) => <Actions row={row} remove={remove} upload={upload} />,
  },
];

export default getColumns;
