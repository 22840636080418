import { matchRoutes, useLocation } from 'react-router-dom';

const useCurrentPath = (routes) => {
  const location = useLocation();
  const matchPath = matchRoutes(routes, location);

  const path = matchPath?.[0]?.route?.path;

  if (path) {
    return path;
  }

  return null;
};

export default useCurrentPath;
