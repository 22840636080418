import PATHS from 'constants/paths';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PageSpin from 'style-guide/Spin/PageSpin';

export const IsNotAuthenticated = ({ children, isAuth }) => {
  if (isAuth) {
    return <Navigate to={PATHS.NOT_FOUND} />;
  }

  return <Suspense fallback={<PageSpin />}>{children}</Suspense>;
};
