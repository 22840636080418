const EmptyStatus = (props) => (
  <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0H10.4142L14 3.58579V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0ZM8 2H2V14H12V6H10C8.89543 6 8 5.10457 8 4V2ZM10 2.41421V4H11.5858L10 2.41421Z'
      fill='#333333'
    />
  </svg>
);

export default EmptyStatus;
