import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UNSPLASH_API_KEY } from 'configs/env-vars';

const chunkIntoN = (arr, sectionCount) => {
  let count = 1;
  return arr.reduce((accumulator, currentValue) => {
    if (!accumulator[`section-${count}`]) {
      accumulator[`section-${count}`] = [];
    }
    accumulator[`section-${count}`].push(currentValue);

    if (count === sectionCount) {
      count = 1;
    } else {
      ++count;
    }

    return accumulator;
  }, {});
};

const getUnsplashApi = createApi({
  reducerPath: 'unsplash',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.unsplash.com/',
  }),
  endpoints: (builder) => ({
    getUnsplash: builder.query({
      query: ({ params }) => ({
        url: '/search/photos',
        params: {
          client_id: UNSPLASH_API_KEY,
          per_page: 10,
          ...params,
        },
        method: 'GET',
      }),

      transformResponse: (result, _meta, { args }) => {
        if (!result.total) {
          return result;
        }
        return {
          ...result,
          total: result.total,
          totalPages: result.total_pages,
          results: chunkIntoN(result.results, args.sectionCount),
        };
      },
    }),
  }),
});

export const { useGetUnsplashQuery } = getUnsplashApi;
export default getUnsplashApi;
