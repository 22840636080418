const NewHeart = (props) => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='40' height='40' rx='20' fill='white' />
    <path
      d='M29.6328 14.6459C29.3187 13.9185 28.8657 13.2593 28.2992 12.7053C27.7323 12.1496 27.064 11.708 26.3305 11.4045C25.5699 11.0886 24.7541 10.9268 23.9305 10.9287C22.775 10.9287 21.6477 11.2451 20.668 11.8428C20.4336 11.9858 20.2109 12.1428 20 12.3139C19.7891 12.1428 19.5664 11.9858 19.332 11.8428C18.3523 11.2451 17.225 10.9287 16.0695 10.9287C15.2375 10.9287 14.4312 11.0881 13.6695 11.4045C12.9336 11.7092 12.2703 12.1475 11.7008 12.7053C11.1336 13.2587 10.6805 13.918 10.3672 14.6459C10.0414 15.4029 9.875 16.2069 9.875 17.0342C9.875 17.8147 10.0344 18.6279 10.3508 19.4553C10.6156 20.1467 10.9953 20.8639 11.4805 21.5881C12.2492 22.7342 13.3062 23.9295 14.6187 25.1412C16.7937 27.1498 18.9477 28.5373 19.0391 28.5936L19.5945 28.9498C19.8406 29.1069 20.157 29.1069 20.4031 28.9498L20.9586 28.5936C21.05 28.535 23.2016 27.1498 25.3789 25.1412C26.6914 23.9295 27.7484 22.7342 28.5172 21.5881C29.0023 20.8639 29.3844 20.1467 29.6469 19.4553C29.9633 18.6279 30.1227 17.8147 30.1227 17.0342C30.125 16.2069 29.9586 15.4029 29.6328 14.6459ZM20 27.0959C20 27.0959 11.6562 21.7498 11.6562 17.0342C11.6562 14.6459 13.632 12.71 16.0695 12.71C17.7828 12.71 19.2688 13.6662 20 15.0631C20.7312 13.6662 22.2172 12.71 23.9305 12.71C26.368 12.71 28.3438 14.6459 28.3438 17.0342C28.3438 21.7498 20 27.0959 20 27.0959Z'
      fill='#818181'
    />
  </svg>
);

export default NewHeart;
