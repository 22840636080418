import { infiniteScrollMiddleware, transformErrorResponse } from 'store/redux-toolkit-common';
import emptySplitApi from '..';

const organizationsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    organizations: build.mutation({
      query: (params) => ({
        url: '/organizations',
        method: 'GET',
        params,
      }),
      transformErrorResponse,
    }),
    createOrganization: build.mutation({
      query: (params) => ({
        url: `/organizations`,
        method: 'POST',
        body: params,
      }),
      transformErrorResponse,
    }),
    updateOrganization: build.mutation({
      query: (params) => ({
        url: `/organizations/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      transformErrorResponse,
    }),
    removeOrganization: build.mutation({
      query: (params) => ({
        url: `/organizations/${params.id}`,
        method: 'DELETE',
        body: params,
        params: {
          cascade: false,
          id: params.id,
        },
      }),
      transformErrorResponse,
    }),
    getOrganizations: build.query({
      query: (params) => ({
        url: '/organizations',
        method: 'GET',
        params,
      }),
      providesTags: ['organizations'],
      ...infiniteScrollMiddleware,
      transformErrorResponse,
    }),

    getOrganization: build.query({
      query: (params) => ({
        url: `/organizations/${params.urlName}`,
        method: 'GET',
        params,
      }),
      providesTags: ['organization'],
      transformErrorResponse,
    }),
    getOrganizationAuthor: build.query({
      query: (params) => ({
        url: `organizations/${params.urlName}/members`,
        method: 'GET',
        params,
      }),
      transformResponse: (result) => result.rows[0],
    }),
    getOrganizationDepartments: build.query({
      query: (params) => ({
        url: `/organizations/${params.urlName}/libraries`,
        method: 'GET',
        params,
      }),
      providesTags: ['joined-departments'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useOrganizationsMutation,
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useRemoveOrganizationMutation,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useGetOrganizationAuthorQuery,
  useGetOrganizationDepartmentsQuery,
} = organizationsApi;
export default organizationsApi;
