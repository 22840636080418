const LinkIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.2117 12.9968C11.1823 12.9677 11.1426 12.9514 11.1013 12.9514C11.06 12.9514 11.0203 12.9677 10.991 12.9968L8.72144 15.2664C7.67065 16.3171 5.89722 16.4285 4.73706 15.2664C3.57495 14.1042 3.68628 12.3328 4.73706 11.282L7.00659 9.01245C7.06714 8.95191 7.06714 8.8523 7.00659 8.79175L6.22925 8.0144C6.19988 7.98533 6.16022 7.96901 6.1189 7.96901C6.07757 7.96901 6.03791 7.98533 6.00855 8.0144L3.73901 10.2839C2.08667 11.9363 2.08667 14.6101 3.73901 16.2605C5.39136 17.9109 8.06519 17.9128 9.71558 16.2605L11.9851 13.991C12.0457 13.9304 12.0457 13.8308 11.9851 13.7703L11.2117 12.9968ZM16.2625 3.73901C14.6101 2.08667 11.9363 2.08667 10.2859 3.73901L8.0144 6.00855C7.98533 6.03791 7.96901 6.07757 7.96901 6.1189C7.96901 6.16022 7.98533 6.19988 8.0144 6.22925L8.7898 7.00464C8.85034 7.06519 8.94995 7.06519 9.0105 7.00464L11.28 4.73511C12.3308 3.68433 14.1042 3.573 15.2644 4.73511C16.4265 5.89722 16.3152 7.6687 15.2644 8.71948L12.9949 10.989C12.9658 11.0184 12.9495 11.058 12.9495 11.0994C12.9495 11.1407 12.9658 11.1804 12.9949 11.2097L13.7722 11.9871C13.8328 12.0476 13.9324 12.0476 13.9929 11.9871L16.2625 9.71753C17.9128 8.06519 17.9128 5.39136 16.2625 3.73901ZM11.9167 7.27222C11.8874 7.24314 11.8477 7.22683 11.8064 7.22683C11.7651 7.22683 11.7254 7.24314 11.696 7.27222L7.27222 11.6941C7.24314 11.7235 7.22683 11.7631 7.22683 11.8044C7.22683 11.8458 7.24314 11.8854 7.27222 11.9148L8.04565 12.6882C8.1062 12.7488 8.20581 12.7488 8.26636 12.6882L12.6882 8.26636C12.7488 8.20581 12.7488 8.1062 12.6882 8.04566L11.9167 7.27222Z'
      fill='#818181'
    />
  </svg>
);

export default LinkIcon;
