import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserIsAuth } from 'store/selectors/user';
import { showModal } from 'store/slices/modal';

import { PAYMENT_TYPES, STRING_UTILS } from 'common';
import { getEntityName, getOrgPrice } from 'common/entity-types';
import MODAL_TYPES from 'constants/modals';
import PATHS from 'constants/paths';
import { setRedirectUrlToLocalStorage } from 'utils/helperFunctions';

import Button from 'style-guide/Button';
import { Rate } from 'style-guide/Icons';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

const { isPrivatePaid } = PAYMENT_TYPES;
const { cleanUpPrice } = STRING_UTILS;

const EntityNotJoinedUser = ({
  accessTypeId,
  paymentTypeId,
  areParentEntitiesFree,
  accessCodeResalePrice,
  type,
  price,
  subscriptionPlan,
  rating,
  name,
  urlName,
  id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasAccessCodePrice = isPrivatePaid({ accessTypeId, paymentTypeId });
  const isOrganization = type === getEntityName(type)?.plural.toLowerCase();
  const isAuth = useSelector(getUserIsAuth);

  const entityPrice = isOrganization
    ? getOrgPrice({ paymentTypeId, price, subscriptionPlan })
    : cleanUpPrice({ string: price, symbol: '$' });

  const priceForEntity = hasAccessCodePrice ? `$${accessCodeResalePrice}` : entityPrice;
  const areParentEntitiesPaid = priceForEntity === 'Free' && areParentEntitiesFree === false;

  const showJoinModal = (params, request) => {
    setRedirectUrlToLocalStorage(`/${type}/${id}/${urlName}/member/homepage`);
    if (!isAuth) {
      navigate(PATHS.SIGN_IN);
    } else {
      dispatch(
        showModal({
          type: MODAL_TYPES.JOIN_ENTITIES,
          params: {
            modal: {
              title: <Title>Join {name}</Title>,
            },
            body: {
              type,
              urlName,
              name,
              onOk: () => {
                request(params);
              },
            },
          },
        })
      );
    }
  };

  return (
    <div>
      <div className='d-flex justify-content-between footer-container '>
        {areParentEntitiesPaid ? null : (
          <div>
            <Text $variant={2}>{priceForEntity}</Text>
          </div>
        )}
        {rating !== null && (
          <div className='d-flex'>
            <Rate fill={theme().colors.dark[100]} />
            <Text $variant={3}>{rating}</Text>
          </div>
        )}
      </div>
      <Button variant='secondary' $block className='mt--16' onClick={showJoinModal}>
        Learn more
      </Button>
    </div>
  );
};
export default EntityNotJoinedUser;
