import { ENTITY_STATUS } from 'common';

const getStatus = ({ percent, isExpired, isDraft }) => {
  if (isExpired) {
    return ENTITY_STATUS.EXPIRED.value;
  }
  if (isDraft) {
    return ENTITY_STATUS.DRAFT.value;
  }
  if (percent >= 50 && percent < 75) {
    return ENTITY_STATUS.COMPLETED.value;
  }
  if (percent >= 75) {
    return ENTITY_STATUS.PASSED.value;
  }

  return null;
};

export default getStatus;
