import styled from 'styled-components';

const CreateNewButtonWrapper = styled.div`
  position: fixed;
  bottom: 28px;
  right: 32px;
  z-index: 1150;
  button {
    height: 42px;
    border-radius: 50px;
    .create-button-text {
      margin-left: 8px;
    }
  }
`;

export default CreateNewButtonWrapper;
