import styled from 'styled-components';
import Spin from '.';

const Wrapper = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
`;

const PageSpin = (props) => (
  <Wrapper>
    <Spin {...props} />
  </Wrapper>
);

export default PageSpin;
