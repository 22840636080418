import styled from 'styled-components';

const SuperAdminEmailTrackingWrapper = styled.div`
  .rc-pagination-item {
    width: 30px !important;
  }
  .email-tracking-text {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default SuperAdminEmailTrackingWrapper;
