import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import useGetLearningCommunityData from 'store/services/helperHooks/useGetLearningCommunityData';
import Button from 'style-guide/Button';
import Collapse, { Panel } from 'style-guide/Collapse';
import { Down, Top } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';
import PanelContent from './PanelContent';

const CompletedAssessments = ({
  assessments,
  questions,
  control,
  chapterId,
  courseId,
  setValue,
  navigate,
  testNumber,
  categoryUrl,
  answeredTest,
}) => {
  const location = useLocation();
  const { entities, urlName, role } = useParams();
  const learningCommunityData = useGetLearningCommunityData(urlName);
  const { useFinalTest, hasPassedFinalTest } = learningCommunityData || {};
  const isCertificate = useFinalTest && hasPassedFinalTest;
  const handleClick = (number) => {
    if (location.pathname !== `${categoryUrl}/preview/${number}`) {
      navigate(`${categoryUrl}/preview/${number}`);
    } else {
      navigate(`${categoryUrl}?mode=preview`);
    }
  };

  return (
    <>
      <Collapse accordion activeKey={testNumber}>
        {assessments?.map((el) => (
          <Panel
            header={
              <div className='d-flex justify-content-between' onClick={() => handleClick(el.testNumber)}>
                <div className='header-text-container'>
                  <Text $variant={3} className='header-text'>
                    Assessment {el.testNumber}
                  </Text>
                  <Text $variant={3}>Completed: {moment(el.date).format('LLL')}</Text>
                </div>
                <Down className='down-icon' />
                <Top className='top-icon' />
              </div>
            }
            key={el.testNumber}
            forceRender
          >
            <PanelContent
              questions={questions}
              control={control}
              chapterId={chapterId}
              courseId={courseId}
              setValue={setValue}
              answeredTest={answeredTest}
              categoryUrl={categoryUrl}
            />
          </Panel>
        ))}
      </Collapse>
      {isCertificate ? (
        <Button variant='link' to={`/${entities}/${courseId}/${urlName}/${role}/certificate`}>
          View and Download Certificate
        </Button>
      ) : null}
    </>
  );
};
export default CompletedAssessments;
