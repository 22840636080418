const Lock = (props) => (
  <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 4V6C0.931649 6 0 6.77637 0 7.83333V14.1667C0 15.2236 0.931649 16 2 16H10C11.0684 16 12 15.2236 12 14.1667V7.83333C12 6.77637 11.0684 6 10 6V4C10 1.79086 8.20914 0 6 0C3.79086 0 2 1.79086 2 4ZM8 4V6H4V4C4 2.89543 4.89543 2 6 2C7.10457 2 8 2.89543 8 4ZM2 14V8H10V14H2ZM7 11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11C5 10.4477 5.44772 10 6 10C6.55228 10 7 10.4477 7 11Z'
      fill='#333333'
    />
  </svg>
);

export default Lock;
