import styled, { css } from 'styled-components';

import { handleTypeProps } from './helper';

const LabelWrapper = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;

  ${(props) => css`
    background-color: ${handleTypeProps({ type: props.$type, theme: props.theme })?.color};
  `}
`;

export default LabelWrapper;
