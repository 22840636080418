import { string, object } from 'yup';

const schema = object({
  email: string().email('The input is not valid Email').required('Please, input your Email'),
  password: string()
    .nullable()
    .trim()
    .required('Please, input your Password')
    .min(6, 'Password length must be at least 6 characters'),
}).required();

export default schema;
