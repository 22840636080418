import { matchRoutes } from 'react-router-dom';
import { message } from 'style-guide/ToastMessages';

import { transformErrorResponse, transformResponse, upsertQueryData } from 'store/redux-toolkit-common';

import PATHS from 'constants/paths';
import transformVolumeResponse from 'utils/transformVolumeResponse';
import emptySplitApi from '..';

const onQueryStarted = async ({ id }, { dispatch, queryFulfilled }) => {
  const { data: updatedData } = await queryFulfilled;
  dispatch(upsertQueryData('getLearningCommunityVolumes', { id }, transformVolumeResponse(updatedData)));
};

export const volumeChapterApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getLearningCommunityVolumes: build.query({
      query: (params) => ({
        url: `/courses/${params.id}/volumes`,
      }),

      transformResponse: (response) => {
        const matchPath = matchRoutes(
          [{ path: `${PATHS.LEARNING_COMMUNITY}/${PATHS.LEARNING_COMMUNITY_CATEGORY}` }],
          window.location
        );

        const { role } = matchPath?.[0]?.params || {};
        return transformVolumeResponse(response, role);
      },

      providesTags: ['volume', 'volumes'],
    }),

    createLearningCommunityChapter: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}/chapters`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['volume'],
    }),

    createLearningCommunityVolume: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes`,
        method: 'POST',
        body: params,
      }),
      transformResponse,
      invalidatesTags: ['volume'],
    }),

    removeLearningCommunityVolume: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['volume'],
      transformResponse: (response) => {
        message.success(response.message || 'The volume was successfully deleted.');
        return response;
      },
      transformErrorResponse,
    }),

    removeLearningCommunityChapter: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}/chapters/${params.chapterId}`,
        method: 'DELETE',
      }),
      transformResponse,
      invalidatesTags: ['volume'],
    }),

    editLearningCommunityVolumeName: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['volume'],
      transformResponse: (response) => {
        message.success(response.message || 'Success, the changes are saved.');
        return response;
      },
      transformErrorResponse,
    }),
    editLearningCommunityChapterOrdering: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}/chapters/${params.chapterId}`,
        method: 'PUT',
        body: params,
      }),
      onQueryStarted,
    }),

    editLearningCommunityChapterName: build.mutation({
      query: (params) => ({
        url: `/courses/${params.id}/volumes/${params.volumeId}/chapters/${params.chapterId}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: (response) => (response.message ? [] : ['volume']),
      transformResponse: (response) => {
        message.success(response.message || 'Success, the changes are saved.');
        return response;
      },
      transformErrorResponse,
    }),
  }),
});

export const {
  useCreateLearningCommunityVolumeMutation,
  useRemoveLearningCommunityVolumeMutation,
  useEditLearningCommunityVolumeNameMutation,
  useEditLearningCommunityChapterNameMutation,
  useCreateLearningCommunityChapterMutation,
  useRemoveLearningCommunityChapterMutation,
  useEditLearningCommunityChapterOrderingMutation,
  useGetLearningCommunityVolumesQuery,
  useLazyGetLearningCommunityVolumesQuery,
  useUpdateLearningCommunitiesMutation,
} = volumeChapterApi;

export default volumeChapterApi;
