import { SYSTEM_LIMITS } from 'common';
import { Controller, useFieldArray } from 'react-hook-form';
import Button from 'style-guide/Button';
import { Plus, Trash } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import Switch from 'style-guide/Switch';
import Text from 'style-guide/Typography/Text';
import ResponseMatchingWrapper from './style/ResponseMatchingWrapper';

const ResponseMatching = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    name: 'answer',
    control,
  });

  const {
    COURSE_SECTIONS: { TESTS },
  } = SYSTEM_LIMITS;

  return (
    <ResponseMatchingWrapper>
      <div className='row'>
        <div className='col-12 d-flex flex-row justify-content-between'>
          <Text $variant={3} $dark={60} className='label'>
            *Fill the answer that is correct
          </Text>
          <div className='d-flex flex-row'>
            <Controller
              control={control}
              name='switchForm'
              defaultValue={false}
              render={({ field: { onChange, value, ref } }) => (
                <Switch
                  ref={ref}
                  $size='small'
                  checked={value}
                  className='d-flex align-items-center'
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
            <Text $dark={60} className='d-flex align-items-center shuffleText'>
              Shuffle the answers
            </Text>
          </div>
        </div>
        <div className='col-12'>
          <Button
            variant='link'
            prefix={<Plus />}
            onClick={() => append({ option1: '', option2: '' })}
            disabled={fields.length >= TESTS.ANSWER.LIST.max}
          >
            Add New Option
          </Button>
        </div>
        {fields.map((item, index) => (
          <div className='inputs-wrapper d-flex flex-row' key={item.id}>
            <Controller
              control={control}
              name={`answer.${index}.option1`}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder='Option'
                  className='response-matching-input'
                  maxLength={TESTS.VALUE.max}
                />
              )}
            />
            <Controller
              control={control}
              name={`answer.${index}.option2`}
              render={({ field }) => (
                <Input
                  {...field}
                  maxLength={TESTS.VALUE.max}
                  placeholder='Matching choice'
                  className='response-matching-input second-option'
                />
              )}
            />
            {fields.length > 2 ? (
              <div className='trash-icon-wrapper'>
                <Button variant='text' $danger onClick={() => remove(index)}>
                  <Trash />
                </Button>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </ResponseMatchingWrapper>
  );
};
export default ResponseMatching;
