import { ENTITY_TYPES } from 'common';

export const AVATAR_OPTIONS = [
  {
    name: 'My Profile',
    value: 'profile',
  },
  {
    name: 'My Invitations',
    value: 'My Invitations',
  },
  {
    name: ENTITY_TYPES.MEMBER.plural,
    value: ENTITY_TYPES.MEMBER.key,
  },
  {
    name: 'Log Out',
    value: 'logOut',
  },
];

const DROPDOWN_OPTIONS = (isAuthSuperAdmin) =>
  isAuthSuperAdmin ? [{ name: 'Admin Dashboard', value: 'adminDashboard' }, ...AVATAR_OPTIONS] : AVATAR_OPTIONS;

export default DROPDOWN_OPTIONS;
