import styled from 'styled-components';

const ReviewModalWrapper = styled.div`
  width: 368px;
  .action-buttons {
    gap: 20px;
    margin-top: 40px;
  }
  .rc-rate-star-second {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export default ReviewModalWrapper;
