import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import FontProvider from 'style-guide/GlobalStyle';
import theme from 'style-guide/Theme';
import ToastMessages from 'style-guide/ToastMessages';
import { ThemeProvider } from 'styled-components';

// redux toolkit
import ModalRenderer from 'Components/Modals/ModalRenderer';
import { useDomainTrackingQuery } from 'store/services/domain-tracking';

// hooks
import useIdleTimeout from 'hooks/useIdleTimeout';
import ScrollToTop from 'hooks/useScrollToTop';

import 'style-guide/GridSystem/index.css';
import 'style-guide/Spacing/index.css';

import PageSpin from 'style-guide/Spin/PageSpin';
import AppRouters from './AppRouters';

const App = () => {
  const { data } = useDomainTrackingQuery();

  useIdleTimeout();

  return data?.key ? (
    <HelmetProvider>
      <ThemeProvider theme={theme(data.key)}>
        <FontProvider />
        <ScrollToTop />
        <Suspense fallback={<PageSpin />}>
          <AppRouters />
        </Suspense>
        <ToastMessages />
        <ModalRenderer />
      </ThemeProvider>
    </HelmetProvider>
  ) : null;
};

export default App;
