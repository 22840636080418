import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Form from 'style-guide/Form';
import { Input } from 'style-guide/Input';
import Button from 'style-guide/Button';
import { message } from 'style-guide/ToastMessages';

import { useMembersMutation } from 'store/services/profile';
import { changeUserData } from 'store/slices/profile';

import { loadState, saveLocalStorage } from 'configs/localStorage';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './shema';

import ContactInformationWrapper from './style/ContactInformationWrapper';

const ContactInformation = ({ hideModal, params }) => {
  const [updateContactInfo, { data, isLoading, isSuccess }] = useMembersMutation();
  const { user: storageUser } = loadState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, id, companyName, personalPhoneNumber, companyPhoneNumber, industry, referralCode, userName } = params;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email,
      id,
      companyName,
      personalPhoneNumber,
      companyPhoneNumber,
      industry,
      referralCode,
      userName,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onFormSubmit = (formData) => {
    updateContactInfo({
      type: 'contactInfo',
      id,
      ...formData,
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(
        changeUserData({
          userData: {
            email,
            id,
            userName,
            companyPhoneNumber: data.companyPhoneNumber,
            companyName: data.companyName,
            industry: data.industry,
            referralCode: data.referralCode,
            personalPhoneNumber: data.personalPhoneNumber,
          },
        })
      );
      saveLocalStorage({
        user: {
          ...storageUser,
          companyPhoneNumber: data.companyPhoneNumber,
          companyName: data.companyName,
          industry: data.industry,
          referralCode: data.referralCode,
          personalPhoneNumber: data.personalPhoneNumber,
        },
      });
      isSuccess && message.success('You have successfully updated the profile!');
      hideModal();
    }
  }, [data, isLoading, storageUser, navigate, dispatch, hideModal, id, email, isSuccess, userName]);

  return (
    <ContactInformationWrapper>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Input
          type='tel'
          name='Company phone'
          label='Company Phone Number'
          error={errors?.companyPhoneNumber}
          placeholder='Write your company phone number'
          {...register('companyPhoneNumber')}
        />
        <Input
          type='tel'
          name='Personal phone number'
          label='Personal Phone Number'
          error={errors?.personalPhoneNumber}
          placeholder='Write your personal phone number'
          {...register('personalPhoneNumber')}
        />
        <Input
          type='text'
          name='Company Name'
          label='Company Name'
          error={errors?.companyName}
          placeholder='Write your company Name'
          {...register('companyName')}
        />
        <Input
          type='text'
          name='Industry'
          label='Industry'
          error={errors?.industry}
          placeholder='Write your industry'
          {...register('industry')}
        />
        <Input
          type='text'
          name='Referral Code'
          label='Referral Code'
          error={errors?.referralCode}
          placeholder='Write your referral code'
          {...register('referralCode')}
        />
        <div className='d-flex justify-content-center pt--16'>
          <Button variant='secondary' className='mr--16 ph--40' onClick={hideModal}>
            Cancel
          </Button>
          <Button variant='primary' className='ph--48' type='submit' disabled={!isDirty}>
            Save
          </Button>
        </div>
      </Form>
    </ContactInformationWrapper>
  );
};

export default ContactInformation;
