import noBackground from 'assets/images/noBackground.png';
import noImage from 'assets/images/noImage.png';
import { AWS3 } from 'configs/env-vars';

export function toAwsDefaultImage(img) {
  return `${AWS3}profilePictures/default/${img}`;
}

export function toAwsCourseImage(img) {
  return img ? `${AWS3}profilePictures/courses/${img}` : noImage;
}

export function toAwsOrganizationImage(img) {
  return img ? `${AWS3}profilePictures/organizations/${img}` : noImage;
}

export function toAwsLibraryImage(img) {
  return img ? `${AWS3}profilePictures/libraries/${img}` : noImage;
}

export function toAwsUserImage(img) {
  return img ? `${AWS3}profilePictures/users/${img}` : `${AWS3}profilePictures/default/member.png`;
}

export function toAwsCoverPicturesImage(img) {
  return img ? `${AWS3}coverPictures/${img}` : noBackground;
}

export function toAwsBackgroundImage(img) {
  return img ? `${AWS3}backgroundImages/${img}` : noBackground;
}

export function toAwsStampImage(img) {
  return img ? `${AWS3}stampImages/${img}` : noBackground;
}

export function toAwsSignatureImage(img) {
  return img ? `${AWS3}signatureImages/${img}` : noBackground;
}

const images = {
  toAwsDefaultImage,
  toAwsCourseImage,
  toAwsOrganizationImage,
  toAwsLibraryImage,
  toAwsUserImage,
  toAwsCoverPicturesImage,
  toAwsBackgroundImage,
  toAwsStampImage,
};

export default images;
