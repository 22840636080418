import { Node, mergeAttributes } from '@tiptap/core';

const PdfExtension = Node.create({
  name: 'pdf', // unique name for the Node
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: false, // so we can drag the video
  atom: false, // is a single unit

  addAttributes() {
    return {
      ...this?.parent?.(),
      src: {
        default: null,
        parseHTML: (element) => element.src,
      },
      height: {
        default: null,
        parseHTML: (element) => element.getAttribute('height') || 500,
      },
      alt: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'iframe' }];
  },

  addNodeView() {
    return ({ node }) => {
      const iframe = document.createElement('iframe');
      Object.assign(iframe, { ...node.attrs, height: node.attrs.height || 500 });

      return {
        dom: iframe,
      };
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['iframe', mergeAttributes({ ...HTMLAttributes, height: HTMLAttributes.height || 500 })];
  },
});

export default PdfExtension;
