import { ENTITY_STATUS } from 'common';
import LABEL_STATUS from 'common/label-status';

const { COMPLETED, DRAFT, EXPIRED, PASSED } = ENTITY_STATUS;

export const handleCourseLabel = (archive, courseStatus, progressValue, progressStatus, isAdmin) => {
  if (archive) return { text: DRAFT.name, type: LABEL_STATUS.DEFAULT };
  if (courseStatus === EXPIRED.value && !isAdmin) return { text: EXPIRED, type: LABEL_STATUS.ERROR };
  if (progressValue === '0.00' && !isAdmin) null;
  if (progressValue !== '0.00' && !progressStatus && !isAdmin)
    return { text: COMPLETED.name, type: LABEL_STATUS.WARNING };
  if (progressStatus && !isAdmin) return { text: PASSED.name, type: LABEL_STATUS.SUCCESS };
};
