import useOutsideClick from 'hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import DropdownV2 from 'style-guide/DropdownV2';
import { Search } from 'style-guide/Icons';
import Title from 'style-guide/Typography/Title';
import SearchComponent from './SearchComponent';
import DropdownComponentWrapper from './style/DropdownComponentWrapper';

const SearchDropdown = ({ name, title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);
  const excludedRef = useRef(null);
  const searchValue = searchParams.get(name);

  const { handleSubmit, control, setFocus } = useForm({
    defaultValues: { [name]: '' },
    mode: 'onChange',
  });

  const { field } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (!field.value && searchValue) {
      searchParams.delete(name);
      setSearchParams(searchParams);
    }
  }, [field.value, name, searchParams, searchValue, setSearchParams]);

  useOutsideClick({
    ref,
    excludedRef,
    cb: () => setVisible(false),
  });

  return (
    <DropdownComponentWrapper>
      <div className='d-flex align-items-center'>
        <DropdownV2
          visible={visible}
          overlay={
            <SearchComponent
              field={field}
              setVisible={setVisible}
              ref={excludedRef}
              name={name}
              handleSubmit={handleSubmit}
              setFocus={setFocus}
              control={control}
            />
          }
          placement='bottom'
        >
          <div
            ref={ref}
            onClick={() => setVisible((item) => !item)}
            className='d-flex align-items-center justify-content-space-between'
          >
            <Title variant={5} className='title'>
              {title}
            </Title>
            <Search width={16} className='search-icon pointer' />
          </div>
        </DropdownV2>
      </div>
    </DropdownComponentWrapper>
  );
};

export default SearchDropdown;
