const CheckCircleOutlined = (props) => (
  <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.69096 4.13671H8.14135C8.02182 4.13671 7.90814 4.19413 7.83783 4.29257L5.99564 6.84726L5.16126 5.68945C5.09095 5.59218 4.97845 5.53359 4.85774 5.53359H4.30813C4.23196 5.53359 4.18743 5.6203 4.23196 5.68241L5.69212 7.70741C5.72662 7.75556 5.77209 7.79479 5.82477 7.82185C5.87745 7.84891 5.93583 7.86303 5.99505 7.86303C6.05428 7.86303 6.11265 7.84891 6.16533 7.82185C6.21802 7.79479 6.26349 7.75556 6.29798 7.70741L8.76596 4.28554C8.81166 4.22343 8.76713 4.13671 8.69096 4.13671Z'
      fill='#212121'
    />
    <path
      d='M6.5 0.749992C3.60078 0.749992 1.25 3.10077 1.25 5.99999C1.25 8.89921 3.60078 11.25 6.5 11.25C9.39922 11.25 11.75 8.89921 11.75 5.99999C11.75 3.10077 9.39922 0.749992 6.5 0.749992ZM6.5 10.3594C4.09297 10.3594 2.14062 8.40702 2.14062 5.99999C2.14062 3.59296 4.09297 1.64062 6.5 1.64062C8.90703 1.64062 10.8594 3.59296 10.8594 5.99999C10.8594 8.40702 8.90703 10.3594 6.5 10.3594Z'
      fill='#212121'
    />
  </svg>
);

export default CheckCircleOutlined;
