import styled from 'styled-components';

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background[15]};
  border-radius: 8px;
  .close-button-container {
    svg {
      cursor: pointer;
      width: 18px;
      height: 18px;
      fill: ${({ theme }) => theme.colors.dark[60]};
    }
  }
  &.filter-root {
    .left-box-content-header {
      .filter {
        font-weight: 500;
        font-size: 20px;
        color: ${({ theme }) => theme.colors.dark[100]};
      }
    }
  }
`;

export default FilterWrapper;
