import { ATTRIBUTE_REGEX, PARSE_SHORT_CODE_REGEX } from './regex';

function parseShortCode(shortCode) {
  let data = {};

  shortCode.replace(PARSE_SHORT_CODE_REGEX, (match, tag, attributes) => {
    const htmlTag = tag.toLowerCase();
    const attributeMap = {};

    let attributeMatch;
    // eslint-disable-next-line no-cond-assign
    while ((attributeMatch = ATTRIBUTE_REGEX.exec(attributes)) !== null) {
      const [, key, value] = attributeMatch;
      attributeMap[key.toLowerCase()] = value;
    }

    data = {
      htmlTag,
      attributes: attributeMap,
      shortCode: match,
    };
  });

  return data;
}

export default parseShortCode;
