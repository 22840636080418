import classNames from 'classnames';
import { toast } from 'react-toastify';
import { MessageError, MessageInfo, MessageSuccess } from 'style-guide/Icons';
import Text from 'style-guide/Typography/Text';

const defaultToastParams = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Icon = ({ type }) => {
  switch (type) {
    case 'success':
      return <MessageSuccess />;
    case 'error':
      return <MessageError />;
    case 'info':
      return <MessageInfo />;

    default:
      return <MessageError />;
  }
};

const MessageText = ({ type, message }) => {
  const leftBarClassName = classNames('left-bar', type);

  return (
    <div className='message-container'>
      <div className='text-container'>
        <div className='icon'>
          <div className={leftBarClassName} />
          <Icon type={type} />
        </div>
        <Text $variant={3} className='text'>
          {message}
        </Text>
      </div>
    </div>
  );
};

const message = {
  success: (messageText, params = {}) => {
    toast(<MessageText type='success' message={messageText} />, {
      ...defaultToastParams,
      ...params,
    });
  },
  error: (messageText, params = {}) => {
    toast(<MessageText type='error' message={messageText} />, {
      ...defaultToastParams,
      ...params,
    });
  },
  info: (messageText, params = {}) => {
    toast(<MessageText type='info' message={messageText} />, {
      ...defaultToastParams,
      ...params,
    });
  },
};

export default message;
