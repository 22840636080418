const ShortEssay = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='24' height='24' rx='2' fill='#EDEFF1' />
    <rect x='4' y='4' width='16' height='16' rx='2' fill='#E8AF00' />
    <rect x='6' y='6' width='12' height='2' rx='1' fill='white' />
    <rect x='6' y='9' width='8' height='2' rx='1' fill='white' />
  </svg>
);

export default ShortEssay;
