const SYSTEM_DEFAULTS = {
  currency: 'usd',
  serviceFee: 10,
  maxPaidContributorsCount: 10,
  volumeName: 'Sample Volume',
  chapterName: 'Sample Chapter',
  finalVolumeName: 'Final Assessment',
  finalChapterName: 'Final Assessment',
};

export default SYSTEM_DEFAULTS;
