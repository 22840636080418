import styled from 'styled-components';

const AssessmentListWrapper = styled.div`
  .assessment-root {
    padding-bottom: 20px;
    box-sizing: border-box;
    .dropdown-children {
      display: inline-block;
      margin-top: 20px;
      .link {
        padding: 0;
        height: auto;
      }
      .icon-button {
        height: 16px;
      }
    }

    .hover-icons {
      .trash-icon {
        margin-right: 0;
      }
      .pencil-icon {
        margin-right: 14px;
        path {
          fill: ${({ theme }) => theme.colors.primary[800]};
        }
      }
    }

    .card-icons {
      justify-content: end !important;
    }

    .card-box {
      margin-bottom: 6px;
      .image-box {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 20px;
        max-height: 200px;
        .image {
          max-width: 100%;
          max-height: 200px;
        }
      }
    }

    .card-title {
      word-break: break-word;
    }
  }

  .essay-scored-wrapper {
    .essay-scored-keyword {
      margin: 0 8px 8px 0;
    }
  }
  .true-false-wrapper {
    .radio-button-box {
      cursor: pointer;
      width: 168px;
      height: 33px;
      margin-top: 8px;
      border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
      background-color: ${({ theme }) => theme.colors.secondary[50]};
      box-sizing: border-box;
      border-radius: 4px;
      label {
        margin-left: 10px;
      }
      label,
      .radio-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .multiple-choice-wrapper {
    .radio-button-box {
      cursor: pointer;
      width: 100%;
      min-height: 40px;
      margin-top: 8px;
      border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
      background-color: ${({ theme }) => theme.colors.secondary[50]};
      box-sizing: border-box;
      border-radius: 4px;

      .radio-wrapper {
        width: 100%;

        .radio-children {
          width: 100%;
          display: flex;
        }
      }

      label {
        margin-left: 10px;
      }
      label,
      .radio-button {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .multiple-answer-wrapper {
    .checkbox-wrapper {
      cursor: pointer;
      width: 100%;
      height: 40px;
      margin-top: 8px;
      border: 1px solid ${({ theme }) => theme.colors.secondary[200]};
      background-color: ${({ theme }) => theme.colors.secondary[50]};
      box-sizing: border-box;
      border-radius: 4px;

      .checkbox {
        margin-left: 10px;
      }
      .multiple-answer-input {
        margin-left: 8px;
      }
    }
  }

  .true-false-wrapper,
  .multiple-choice-wrapper,
  .multiple-answer-wrapper {
    .has-error {
      border: 1px solid ${({ theme }) => theme.colors.error[600]};
      background-color: ${({ theme }) => theme.colors.error[200]};
    }
    .success {
      border: 1px solid ${({ theme }) => theme.colors.primary[600]};
      background-color: ${({ theme }) => theme.colors.primary[50]};
    }
    .answeredField {
      cursor: default;
      label,
      input {
        cursor: default;
      }
    }
  }

  .short-essay-textarea,
  .short-essay-scored-textarea {
    textarea {
      resize: none;
    }
  }

  .response-matching-wrapper {
    padding: 0 12px;
    .response-matching-input,
    .response-matching-container {
      border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
      border-radius: 4px;
      height: 32px;
      flex: 1;
    }
    .response-matching-container {
      display: flex;
      align-items: center;
      .text {
        padding-left: 12px;
      }
    }

    .second-option {
      margin-left: 20px;
    }

    .inputs-wrapper {
      margin-top: 16px;
    }
  }

  .scaled-response-wrapper {
    .item-box {
      width: 100%;
      margin-top: 8px;
      .item-select {
        margin-right: 16px;
        width: 76px;
      }

      .item-input {
        flex: 1;
      }
    }

    .feedback-answer {
      margin-top: 8px;
    }
  }

  .multiple-blanks-wrapper {
    .correct-blank,
    .wrong-blank {
      height: 20px;
      border-radius: 4px;
      padding: 4px 24px;
      margin-right: 4px;
      margin-top: 12px;
    }
    .correct-blank {
      background-color: ${({ theme }) => theme.colors.primary[200]};
      border: 1px solid ${({ theme }) => theme.colors.primary[800]};
    }
    .wrong-blank {
      background-color: ${({ theme }) => theme.colors.error[200]};
      border: 1px solid ${({ theme }) => theme.colors.error[600]};
    }
  }

  .answers-container {
    display: block;
    margin-top: 12px;
  }
  .learner-blank {
    border-radius: 2px;
    padding: 4px 24px;
    margin-right: 4px;
    margin-top: 12px;
    background-color: ${({ theme }) => theme.colors.primary[200]};
    border: 1px solid ${({ theme }) => theme.colors.primary[800]};
    cursor: default;
  }
  .question-blank {
    padding: 4px 18px !important;
    font-size: 12px;
    background-color: ${({ theme }) => theme.colors.secondary[50]} !important;
    color: ${({ theme }) => theme.colors.dark[100]} !important;
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]} !important;
  }
  .filled-blank {
    cursor: pointer !important;
  }
  .grab {
    cursor: grab;
  }
  .blanks-card-question {
    line-height: 30px;
  }
  .preview-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 16px;
    .total-grade {
      font-weight: 700;
      font-size: 20px;
    }
    @media print {
      display: none;
    }
  }

  .check-icon {
    width: 16px;
    height: 16px;
    stroke: ${({ theme }) => theme.colors.primary[600]};
    padding-right: 12px;
  }
  .close-icon {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.error[600]} !important;
    cursor: unset !important;
    padding-right: 12px;
  }

  .correct-answer {
    color: ${({ theme }) => theme.colors.primary[600]};
  }
  .incorrect-answer {
    color: ${({ theme }) => theme.colors.error[600]};
  }
  .pending-icon,
  .scored-icon {
    width: 24px;
    height: 24px;
    padding-right: 16px;
  }
  .pending-answer {
    color: #b38600;
  }
  .scored-answer {
    color: #008699;
  }

  .skeletons-container {
    margin-top: 20px;
  }

  .retake-assessment-section {
    .title {
      display: flex;
      width: 100%;
    }
    margin-bottom: 8px;
  }
  .rc-collapse-expand-icon {
    display: none !important;
  }
  .rc-collapse {
    background-color: #f7f7f7 !important;
    .rc-collapse-item {
      border-radius: 8px;
      margin-bottom: 8px;
      border: none !important;
      .rc-collapse-content {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      div.rc-collapse-header:hover {
        background-color: white !important;
      }
    }
    .rc-collapse-item-active {
      .rc-collapse-header {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .top-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
      .down-icon {
        display: none;
      }
    }
  }
  .rc-collapse-header {
    background-color: white;
    padding: 16px 20px !important;
    border-radius: 8px;
    &:hover {
      background-color: white !important;
    }
    .rc-collapse-header-text {
      width: 100%;
      .header-text {
        padding-right: 60px;
      }
    }
  }

  .rc-collapse-content-hidden {
    display: none !important;
  }
  .rc-collapse-content-box {
    > div:not(:last-child) {
      border: 1px solid #edeff1;
    }
  }
  .rc-collapse-content-active.rc-collapse-content {
    padding: 0px 20px !important;
  }
  .top-icon {
    display: none;
  }
  .down-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .description {
    width: 100%;
    iframe {
      max-width: 100%;
    }
  }
  .description-section {
    max-width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
  }
`;

export default AssessmentListWrapper;
