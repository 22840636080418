import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import FormItem from 'style-guide/FormItem';
import { TextArea } from 'style-guide/Input';

const ShortEssay = ({ index, questionId, type, setValue, control, answeredTest }) => {
  const {
    field: { onChange, value = answeredTest?.userAnswers?.[questionId] || '' },
  } = useController({ name: `answers.${index}.answer`, control });

  useEffect(() => {
    setValue(`answers.${index}`, { type, questionId, answer: value });
  }, [index, questionId, setValue, type, value]);
  return (
    <FormItem>
      <TextArea onChange={onChange} value={value} className='short-essay-textarea' disabled={answeredTest} />
    </FormItem>
  );
};

export default ShortEssay;
