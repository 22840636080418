import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ROOT_V2 } from 'configs/env-vars';
import { getAccessToken, getDomainTracking, loadState, saveLocalStorage } from 'configs/localStorage';

import PATHS from 'constants/paths';
import { message } from 'style-guide/ToastMessages';
import {
  removeRedirectUrlToLocalStorage,
  setErrorToLocalStorage,
  setRedirectUrlToLocalStorage,
} from 'utils/helperFunctions';

const customFetchBase = async (args, api, extraOptions) => {
  const { id } = getDomainTracking();
  const getApiRoot = () => `${API_ROOT_V2}api/v2`;
  const result = await fetchBaseQuery({
    baseUrl: getApiRoot(),
    keepUnusedDataFor: process.env.NODE_ENV === 'test' ? 0 : 60,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('Domain_id', id || 1);
      return headers;
    },
  })(args, api, extraOptions);

  const { pathname, search } = window.location;

  if (result.error?.status === 401) {
    setErrorToLocalStorage('signInError', 'Looks like your password or email do not match. Please try again.');
    if (pathname === PATHS.SIGN_IN) {
      message.error('Incorrect email and/or password');
    }
    if (pathname !== PATHS.SIGN_IN) {
      const url = `${pathname}${search}`;
      saveLocalStorage({ user: null, adminUser: null });
      setRedirectUrlToLocalStorage(url);
      window.location.pathname = PATHS.SIGN_IN;
      return;
    }
  }
  if (result.error?.status === 404) {
    window.location.pathname = PATHS.NOT_FOUND;
    return;
  }

  if (pathname === PATHS.SIGN_IN && result?.error?.status === 201) {
    const { redirectUrl = null } = loadState('redirectUrl');
    if (redirectUrl) {
      removeRedirectUrlToLocalStorage();
      window.location.pathname = redirectUrl;
      return;
    }
  }

  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
const nestAPi = createApi({
  reducerPath: 'modernNestApi',
  baseQuery: customFetchBase,
  tagTypes: [],
  endpoints: () => ({}),
});

export default nestAPi;
