import Text from 'style-guide/Typography/Text';
import AvatarWithSource from './AvatarWithSource';
import AvatarWithoutSource from './AvatarWithoutSource';
import GroupAvatar from './GroupAvatar';
import UserAvatarWrapper from './UserAvatarWrapper';
import { COLORS, MEMBERS_NUMBER } from './avatarHelper';

const { GREEN, SALMON_RED, ROYAL_PURPLE, SILVER_CLOUD, SAGA_GREEN } = COLORS;

const UserAvatar = ({ type, imgSrc, label, data }) => {
  const colors = [GREEN, SALMON_RED, ROYAL_PURPLE, SILVER_CLOUD, SAGA_GREEN];

  const getDefaultColor = () => {
    const index = Math.floor(Math.random() * colors.length);
    const color = colors.splice(index, 1)[0];
    return color;
  };

  return (
    <UserAvatarWrapper
      $type={type}
      $imgSrc={imgSrc}
      className='d-inline-flex justify-content-center align-items-center'
    >
      {data ? (
        data.map((member, num) =>
          num <= MEMBERS_NUMBER.first ? (
            <GroupAvatar member={member} count={data?.length} num={num} color={getDefaultColor()} key={member?.id} />
          ) : null
        )
      ) : (
        <>
          {imgSrc ? <AvatarWithSource /> : <AvatarWithoutSource type={type} label={label} />}
          <Text className='pl--8' $variant={3}>
            {label}
          </Text>
        </>
      )}
    </UserAvatarWrapper>
  );
};
export default UserAvatar;
