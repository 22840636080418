import PATHS from 'constants/paths';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PageSpin from 'style-guide/Spin/PageSpin';
import { setRedirectUrlToLocalStorage } from 'utils/helperFunctions';

export const IsAuthenticatedRoute = ({ children, isAuth }) => {
  if (!isAuth) {
    setRedirectUrlToLocalStorage(window.location.pathname);
    return <Navigate to={PATHS.SIGN_IN} />;
  }
  return <Suspense fallback={<PageSpin />}>{children}</Suspense>;
};
