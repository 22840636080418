import styled from 'styled-components';

const InitialThoughtCardWrapper = styled.div`
  margin-top: 20px;
  padding: 16px 20px 10px 20px;
  background-color: ${(props) => props.$background || props.theme.colors.white[100]};
  border-radius: 8px;
  box-sizing: border-box;

  &.has-footer {
    padding: 16px 20px;
  }
  &:hover {
    box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.boxShadow[25]};
    &.no-hover-shadow {
      box-shadow: none;
    }
    .hover-icons {
      svg {
        visibility: visible;
      }
    }
  }
  .hover-icons {
    .trash-icon {
      margin-right: 15px;
      path {
        fill: ${({ theme }) => theme.colors.error[600]};
      }
    }
    .drag-and-drop-icon {
      cursor: move;
    }
    svg {
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
      svg {
        visibility: visible;
      }
    }
    &.only-one-question {
      .drag-and-drop-icon {
        visibility: hidden;
      }
    }
  }
  &:hover {
    &.only-one-question {
      .drag-and-drop-icon {
        visibility: hidden;
      }
    }
  }

  .content-section {
    margin-top: 8px;
    .keywords-title {
      margin-bottom: 8px;
    }
    .question-field-textarea {
      textarea {
        min-height: 35px;
        max-height: 250px !important;
        width: 100% !important;
        overflow-y: auto;
        overflow-x: hidden;
        ::-webkit-scrollbar {
          width: 4px;
          border-radius: 5px;
          -webkit-appearance: none;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #d9d9d9;
        }
      }
    }
  }
  .card-primary-button {
    margin-left: 20px;
  }
`;

export default InitialThoughtCardWrapper;
