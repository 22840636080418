/* eslint-disable no-return-assign */
import Placeholder from '@tiptap/extension-placeholder';

const placeholder = 'Tell your story...';

const PlaceholderExtension = Placeholder.extend({
  addCommands() {
    return {
      showPlaceholder: () => () => (this.options.placeholder = placeholder),
      hidePlaceholder: () => () => (this.options.placeholder = ''),
    };
  },
}).configure({
  placeholder,
  emptyEditorClass: 'placeholder',
  considerAnyAsEmpty: true,
  showOnlyWhenEditable: true,
});

export default PlaceholderExtension;
