import noData from 'assets/images/noData.png';
import TableComponent from 'rc-table';
import Image from 'style-guide/Image';
import styled, { css } from 'styled-components';

import 'rc-table/assets/index.css';

const EmptyState = () => (
  <div className='d-flex justify-content-center '>
    <Image src={noData} />
  </div>
);

const Table = styled(TableComponent)`
  .rc-table-content,
  .rc-table-cell,
  .rc-table-tbody-virtual,
  .rc-table th,
  .rc-table td,
  .rc-table-rtl.rc-table th,
  .rc-table-rtl.rc-table td,
  .rc-table-header,
  .rc-table-body {
    border: none !important;
  }
  table {
    color: ${({ theme }) => theme.colors.dark[100]};
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    cursor: default;
    th,
    td {
      font-size: 14px;
      padding: 12px 16px;
      line-height: 16px;
    }
    td {
      font-weight: 400;
      background-color: ${({ theme }) => theme.colors.white[100]};
      box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.boxShadow[15]};
    }
    .rc-table-thead {
      th {
        font-weight: 500;
      }
      background-color: ${({ theme }) => theme.colors.secondary[50]};
    }
    .rc-table-placeholder {
      text-align: center;
      .rc-table-cell {
        padding: 0 !important;
        box-shadow: none;
      }
    }
    .rc-table-expanded-row-fixed {
      .empty-data {
        height: 150px;
        width: 150px;
        margin: 40px 0;
      }
    }
    tr {
      td,
      th {
        border: none;
        box-shadow: inset ${({ theme }) => theme.colors.boxShadow[25]};
      }
    }
    .rc-table-content {
      border: none;
    }
    .rc-table-cell {
      text-align: start;
      &.rc-table-cell-row-hover {
        background-color: ${({ theme }) => theme.colors.secondary[50]} !important;
      }
    }
    .rc-table-expanded-row .rc-table-cell {
      box-shadow: ${({ theme }) => theme.colors.boxShadow[25]} 0px -1px 0px inset;
    }
    thead {
      tr {
        td,
        th {
          background-color: ${({ theme }) => theme.colors.secondary[50]} !important;
          box-sizing: none;
        }
      }
      .rc-table-row-expand-icon-cell {
        box-shadow: none;
      }
    }
  }
  ${(props) =>
    props.$member &&
    css`
      table {
        th,
        td {
          &:not(:last-child) {
            border: none;
          }
          padding: 9px 20px;
        }
      }
      .rc-table-cell-row-hover {
        background-color: ${({ theme }) => theme.colors.secondary[50]};
        cursor: default;
      }
    `}
`;

Table.defaultProps = {
  emptyText: EmptyState,
  data: [],
};

export default Table;
