import MODAL_TYPES from 'constants/modals';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIsAuth } from 'store/selectors/user';
import useLogout from 'store/services/helperHooks/useLogout';
import { hideModal, showModal } from 'store/slices/modal';

const timeout = 1000 * 60 * 20;
const promptBeforeIdle = 1000 * 60 * 2;

const useIdleTimeout = () => {
  const [logOut] = useLogout();
  const isAuth = useSelector(getUserIsAuth);

  const dispatch = useDispatch();

  const onIdle = () => {
    if (isAuth) {
      logOut();
      dispatch(hideModal({ type: MODAL_TYPES.IDLE_TIMER }));
    }
  };

  const { reset } = useIdleTimer({
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'timeupdate',
      'visibilitychange',
      'MSPointerDown',
      'MSPointerMove',
      'focus',
    ],
    onIdle,
    onPrompt: () =>
      isAuth
        ? dispatch(
            showModal({
              type: MODAL_TYPES.IDLE_TIMER,
              params: {
                body: { reset, promptBeforeIdle },
                modal: { closable: false },
              },
            })
          )
        : null,
    timeout,
    promptBeforeIdle,
  });

  return null;
};

export default useIdleTimeout;
