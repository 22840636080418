import Youtube from '@tiptap/extension-youtube';

const YoutubeExtension = Youtube.configure({
  disableKBcontrols: true,
  modestBranding: false,
  controls: false,
}).extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      'data-resource-id': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-resource-id'),
      },
    };
  },
});

export default YoutubeExtension;
