const ArrowLeft = (props) => (
  <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.69945 5.25H11.1388V6.75H4.69945L7.16912 9.21966L6.10846 10.2803L1.82812 6L6.10846 1.71967L7.16912 2.78033L4.69945 5.25Z'
    />
  </svg>
);

export default ArrowLeft;
