import { useState } from 'react';

import Content from 'Components/Layouts/layout/Content';
import SideBar from 'Components/Layouts/layout/SideBar/SideBar';
import SCREEN_SIZES from 'constants/screen-sizes';
import useWindowSize from 'hooks/useWindowSize';

import Container from 'Components/Container';
import HelmetComponent from 'Components/Helmet';
import classNames from 'classnames';
import { ENTITY_TYPES } from 'common';
import { useParams } from 'react-router-dom';
import Body from './Body';
import Filter from './Filter';
import ToggleFilter from './Filter/ToggleFilter';
import ExploreWrapper from './style/ExploreWrapper';

const Explore = () => {
  const { innerWidth } = useWindowSize();
  const [isOpenFilterSection, setIsOpenFilterSection] = useState(false);
  const { category } = useParams();
  const { MEMBER, DEPARTMENT, LEARNING_COMMUNITY, ORGANIZATION } = ENTITY_TYPES;
  const isSideBar = innerWidth >= SCREEN_SIZES.XL || isOpenFilterSection;

  return (
    <ExploreWrapper className='row' $exploreMembersPage={category === MEMBER.key}>
      <HelmetComponent
        title={`Explore | ${category}`}
        description={`Explore ${LEARNING_COMMUNITY.lowercase}, ${DEPARTMENT.lowercase} and ${ORGANIZATION.lowercase}`}
      />
      <div className='col d-flex flex-row'>
        <SideBar showSidebar={isSideBar} className={classNames({ 'side-bar-container': isSideBar })}>
          <Filter setIsOpenFilterSection={setIsOpenFilterSection} />
        </SideBar>
        <Content className={classNames('card-box overflow', { 'card-position': isSideBar })}>
          <Container className='content-root'>
            {innerWidth < SCREEN_SIZES.XL ? <ToggleFilter setIsOpenFilterSection={setIsOpenFilterSection} /> : null}
            <Body />
          </Container>
        </Content>
      </div>
    </ExploreWrapper>
  );
};

export default Explore;
