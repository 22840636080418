import classnames from 'classnames';
import { useState } from 'react';
import VerticalDividerIcon from 'style-guide/Icons/RichEditor/VerticalDivider';

const MenuItem = ({ icon, name, onSelect, selected, borderAfter, borderBefore, buttonClassName }) => {
  const [active, setActive] = useState(false);
  const onMouseDown = (e) => {
    e.preventDefault();
    setActive((value) => !value);
    onSelect?.(name);
  };

  return (
    <>
      {borderBefore && <VerticalDividerIcon className='p--2' />}
      <button
        type='button'
        className={classnames(
          'menu-button',
          {
            'menu-button-selected': selected?.() || active,
          },
          buttonClassName
        )}
        onMouseDown={onMouseDown}
      >
        {icon}
      </button>
      {borderAfter && <VerticalDividerIcon />}
    </>
  );
};

export default MenuItem;
