import { useEffect } from 'react';
import { Controller, useController, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { SYSTEM_LIMITS, TEST_TYPES } from 'common';
import MODAL_TYPES from 'constants/modals';

import { showModal } from 'store/slices/modal';
import { setSelectedFile } from 'store/slices/selectedFile';

import { getSelectedFileManager } from 'utils/helperFunctions';

import SimplifiedEditor from 'Components/RichEditor/SimplifiedEditor';
import { getSelectedFile } from 'store/selectors/fileManager';
import FormItem from 'style-guide/FormItem';
import { Input, TextArea } from 'style-guide/Input';
import KeywordsSelect from 'style-guide/KeywordsSelect';
import TinymceBlank from 'style-guide/TinymceBlank';
import Title from 'style-guide/Typography/Title';
import ImageUpload from './ImageUpload';

const { TESTS } = SYSTEM_LIMITS.COURSE_SECTIONS;
const { TestsTypeCloseExercise, TestsTypeScoredEssay, TestsTypeDragAndDrop } = TEST_TYPES.types;

const Question = ({ errors, setValue, control, freeTextType, watch, reset }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const imageField = useWatch({ control, name: 'image' });
  const selectedFile = useSelector(getSelectedFile);
  const assessmentType = searchParams.get('assessmentType');

  const isScoredEssayType = +assessmentType === TestsTypeScoredEssay;
  const isTestsTypeCloseExercise = +assessmentType === TestsTypeCloseExercise;

  const { field: questionField } = useController({
    name: 'question',
    control,
  });

  const tinymceBlankTypes = [TestsTypeCloseExercise, TestsTypeDragAndDrop];
  const hasBlankType = tinymceBlankTypes.includes(+assessmentType);

  useEffect(() => {
    if (selectedFile?.fullPath) {
      setValue('image', selectedFile.fullPath, { shouldValidate: true, shouldDirty: true });
    }

    return () => {
      dispatch(setSelectedFile({ file: null }));
    };
  }, [dispatch, selectedFile, setValue]);

  const showFileManagerModal = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.FILE_MANAGER,
        params: {
          modal: { title: 'File Manager', $top: 90 },
          body: {
            defaultSelectedFile: [getSelectedFileManager('image')],
            disabled: true,
          },
        },
      })
    );
  };

  return (
    <div className='row'>
      <div className='col'>
        <div className='d-flex flex-column'>
          <ImageUpload image={imageField} setValue={setValue} upload={showFileManagerModal} />
          <div className='image-caption-box'>
            <Controller
              control={control}
              name='description'
              render={({ field }) => <Input {...field} label='Image Caption' maxLength={TESTS.VALUE.max} />}
            />
          </div>
        </div>
        <Title variant={5} className='question-title'>
          Question
        </Title>
        <Controller
          control={control}
          name='text'
          render={({ field }) => (
            <FormItem label='Question description' className='description-box'>
              <SimplifiedEditor
                onChange={field.onChange}
                content={field.value}
                className='description-editor ph--12 pb--12'
              />
            </FormItem>
          )}
        />

        {hasBlankType ? (
          <FormItem error={errors.question}>
            <TinymceBlank
              label='Question text*'
              onEditorChange={questionField.onChange}
              value={questionField.value}
              onChange={questionField.onChange}
              multiple={TestsTypeDragAndDrop === +assessmentType}
              name={questionField.name}
              setValue={setValue}
              reset={reset}
              watch={watch}
              maxLength={TESTS.content.max}
              // 8 and 10 are constant blanks lengths
              blankCharactersCount={isTestsTypeCloseExercise ? 8 : 10}
            />
          </FormItem>
        ) : (
          <FormItem error={errors.question}>
            <TextArea
              {...questionField}
              label='Question text*'
              className='short-essay-textarea'
              maxLength={TESTS.content.max}
              autoSize={{ minRows: freeTextType ? 6 : 1 }}
            />
          </FormItem>
        )}
        {isScoredEssayType ? (
          <Controller
            control={control}
            name='answer'
            render={({ field }) => (
              <FormItem label='Keyword' error={errors.answer}>
                <KeywordsSelect {...field} />
              </FormItem>
            )}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Question;
