import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGetFilesQuery, useRemoveFileMutation, useUploadFileMutation } from 'store/services/file-manager';
import { setSelectedFile } from 'store/slices/selectedFile';

import Pagination from 'style-guide/Pagination';
import Skeleton from 'style-guide/Skeleton';
import Spin from 'style-guide/Spin';
import Table from 'style-guide/Table';
import { message } from 'style-guide/ToastMessages';

import getColumns from './columns';
import DragAndDropFileUpload from './DragAndDropFileUpload';
import { getParams, handleUploadFile } from './helper';
import Search from './Search';
import SelectFile from './Select';
import FileManagerWrapper from './style/FileManagerWrapper';

const LIMIT = 9;

const FileManager = ({ hideModal, params = {} }) => {
  const { defaultSelectedFile, disabled = false, onUpload } = params;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(defaultSelectedFile || []);

  const offset = useMemo(() => (page === 1 ? 0 : page * LIMIT - LIMIT), [page]);
  const reqParams = useMemo(
    () => getParams({ limit: LIMIT, offset, searchText, selectedFiles }),
    [offset, searchText, selectedFiles]
  );

  const { data, currentData, isLoading, refetch } = useGetFilesQuery(reqParams, {
    refetchOnMountOrArgChange: true,
  });

  const [removeFile, { isLoading: isRemoveLoading }] = useRemoveFileMutation();
  const [uploadFile, { isLoading: isUploadLoading }] = useUploadFileMutation();

  useEffect(() => {
    setPage(1);
  }, [selectedFiles]);

  const onSearch = (value) => {
    if (searchText !== value) {
      setSearchText(value);
      setPage(1);
    }
  };

  const changePage = (event) => {
    setPage(event);
  };

  const remove = ({ row }) => {
    removeFile({ id: row.id })
      .then(() => {
        if ((data.total - 1) % LIMIT === 0) {
          const newPage = page > 1 ? page - 1 : page;
          setPage(newPage);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const upload = (files) => {
    const reqParamsData = getParams({ limit: LIMIT, offset: 0, searchText, selectedFiles });
    handleUploadFile({ files, uploadFile, getFiles: refetch, reqParams: reqParamsData, defaultSelectedFile, setPage });
  };

  const setFileToEditor = ({ row }) => {
    dispatch(setSelectedFile({ file: row }));
    onUpload?.(row);
    hideModal();
  };

  const files = isRemoveLoading ? currentData : data;
  const showSkeleton = isLoading && !currentData;
  const isTableLoading = isLoading || isUploadLoading || isRemoveLoading;

  return (
    <FileManagerWrapper className='row'>
      <div className='col-12'>
        <DragAndDropFileUpload upload={upload} defaultSelectedFile={defaultSelectedFile} />
        <div className='filter d-flex'>
          <Search searchValue={searchValue} setSearchValue={setSearchValue} cb={onSearch} />
          <SelectFile selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} isDisabled={disabled} />
        </div>
        <div className='table-box'>
          {showSkeleton ? (
            <>
              <Skeleton skeleton={{ height: 40 }} />
              <Skeleton skeleton={{ count: 9, height: 43 }} />
            </>
          ) : (
            <>
              {isTableLoading ? (
                <div className='table-loading-box d-flex align-items-center justify-content-center'>
                  <Spin height='30px' width='30px' />
                </div>
              ) : null}
              <Table
                sticky
                rowKey={(row) => row.id}
                columns={getColumns({
                  remove,
                  upload: setFileToEditor,
                })}
                data={files?.rows || []}
              />
            </>
          )}
        </div>
        <div className='pagination-box d-flex justify-content-end'>
          {showSkeleton ? (
            <Skeleton skeleton={{ height: 18, width: 200 }} />
          ) : (
            <Pagination pageSize={LIMIT} total={currentData?.total || 0} current={page} onChange={changePage} />
          )}
        </div>
      </div>
    </FileManagerWrapper>
  );
};

export default FileManager;
