import ACCESS_TYPES from './access-types';
import PAYMENT_TYPES from './payment-types';

const { AccessTypePublic, AccessTypePrivate } = ACCESS_TYPES;
const { PaymentTypePublicFree, PaymentTypePublicPaid, PaymentTypePrivatePrePaid, PaymentTypePrivatePaid } =
  PAYMENT_TYPES;

const eTypes = {
  PublicFree: 1,
  PublicPaid: 2,
  PrivatePrePaid: 3,
  PrivatePaid: 4,
};

/* All possible combinations are as follows
  a1-p3, a1-p4, a2-p1, a2-p2
*/
const enrollmentTypesMap = {
  [AccessTypePublic]: {
    [PaymentTypePublicFree]: eTypes.PublicFree,
    [PaymentTypePublicPaid]: eTypes.PublicPaid,
  },
  [AccessTypePrivate]: {
    [PaymentTypePrivatePrePaid]: eTypes.PrivatePrePaid,
    [PaymentTypePrivatePaid]: eTypes.PrivatePaid,
  },
};

const getEnrollmentType = (a, p) => enrollmentTypesMap[a][p];

const ENROLLMENT_TYPES = {
  ...eTypes,
  getEnrollmentType,
};

export default ENROLLMENT_TYPES;
