import { useEffect, useMemo, useState } from 'react';

import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import DASHBOARD_OPTIONS from 'constants/DropdownOptions/dashboard';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import usePrevious from 'hooks/usePrevious';
import { paginate } from 'utils/helperFunctions';

import Categories from 'Components/Categories';
import EntityCard from 'Components/NewCards/ColDepCard';
import LearningCommunityCard from 'Components/NewCards/LearningCommunityCard';
import noData from 'assets/images/noData.png';
import { useDepartmentsMutation } from 'store/services/departments';
import { useLearningCommunitiesMutation } from 'store/services/learningCommunities';
import { useOrganizationsMutation } from 'store/services/organizations';
import Image from 'style-guide/Image';
import ViewMembershipWrapper from './style/ViewMembershipWrapper';

const CARD_COMPONENTS = {
  [ENTITY_TYPES.LEARNING_COMMUNITY.key]: LearningCommunityCard,
  [ENTITY_TYPES.DEPARTMENT.key]: EntityCard,
  [ENTITY_TYPES.ORGANIZATION.key]: EntityCard,
};
const { member } = USER_COURSE_TYPES.userCourseRole;

const ViewMembership = ({ params }) => {
  const [category, setCategory] = useState(ENTITY_TYPES.LEARNING_COMMUNITY.key);
  const userName = params?.userName;

  const [getLearningCommunities, { data: learningCommunitiesData, isLoading: learningCommunitiesLoading }] =
    useLearningCommunitiesMutation();
  const [getDepartments, { data: departmentsData, isLoading: departmentsLoading }] = useDepartmentsMutation();
  const [getOrganizations, { data: organizationsData, organizationsLoading }] = useOrganizationsMutation();
  const [data, setData] = useState({});
  const hasNextPage = data.total > data.rows?.length;
  const [page, setPage, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore: hasNextPage,
  });
  const prevPage = usePrevious(page);
  const isLoading = learningCommunitiesLoading || departmentsLoading || organizationsLoading;

  const setCurrentData = (currentData) => {
    if (currentData) {
      if (page === 0) {
        setData({
          rows: currentData.rows,
          total: currentData.total,
        });
      } else {
        setData({
          rows: [...data.rows, ...currentData.rows],
          total: currentData.total,
        });
      }
    }
  };

  const getCurrentData = (urlParams) => {
    switch (category) {
      case ENTITY_TYPES.LEARNING_COMMUNITY.key:
        !learningCommunitiesLoading && getLearningCommunities({ ...urlParams, userName, role: member });
        break;
      case ENTITY_TYPES.DEPARTMENT.key:
        !departmentsLoading && getDepartments({ ...urlParams, userName, role: member });
        break;
      case ENTITY_TYPES.ORGANIZATION.key:
        !organizationsLoading && getOrganizations({ ...urlParams, userName, role: member });
        break;
      default:
    }
  };

  useEffect(() => {
    switch (category) {
      case ENTITY_TYPES.LEARNING_COMMUNITY.key:
        setCurrentData(learningCommunitiesData);
        break;
      case ENTITY_TYPES.DEPARTMENT.key:
        setCurrentData(departmentsData);
        break;
      case ENTITY_TYPES.ORGANIZATION.key:
        setCurrentData(organizationsData);
        break;
      default:
    }
  }, [organizationsData, departmentsData, learningCommunitiesData]);

  useEffect(() => {
    const paginationParams = paginate({ page });
    if (prevPage !== page && page !== 0) {
      !isLoading && getCurrentData(paginationParams);
    }
  }, [page]);

  useEffect(() => {
    setPage(0);
    setData({});
    const paginationParams = paginate({ page: 0 });
    getCurrentData(paginationParams);
  }, [category]);

  const CardComponent = useMemo(() => CARD_COMPONENTS[category], [category]);

  return (
    <ViewMembershipWrapper>
      <div className='view-membership-root'>
        <div className='menu-box'>
          <Categories changeCategory={setCategory} activeCategory={category} options={DASHBOARD_OPTIONS} />
        </div>
        <div className='membership-card-box' ref={scrollerRef}>
          {data?.rows && !data.rows?.length && !isLoading ? (
            <div className='d-flex align-items-center justify-content-center empty-state-box'>
              <Image src={noData} />
            </div>
          ) : (
            <>
              <div className='row cards'>
                {data?.rows?.map((el) => (
                  <div key={el.id} className='col-12 col-md-6 card'>
                    <CardComponent data={el} entityType={category} />
                  </div>
                ))}
              </div>
              {hasNextPage ? <div ref={loaderRef} children='loaderRef' className='invisible' /> : null}
            </>
          )}
        </div>
      </div>
    </ViewMembershipWrapper>
  );
};

export default ViewMembership;
