import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import endpoints from 'store/endpoints';
import Button from 'style-guide/Button';
import FormItem from 'style-guide/FormItem';
import { Plus, Trash } from 'style-guide/Icons';
import { Input } from 'style-guide/Input';
import Select from 'style-guide/Select';

const AddHyperlink = ({ control, name, register, errors }) => {
  const { id } = useParams();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const getVolumesData = endpoints.getLearningCommunityVolumes.select({ id });
  const { data: volumesData } = useSelector(getVolumesData);
  const learningCommunityChapters =
    volumesData?.reduce(
      (result, current) => [...result, ...current.chapters.map((e) => ({ label: e.name, value: e.id }))],
      []
    ) || [];

  const options = [...learningCommunityChapters, { value: '0', label: 'Other' }];

  return (
    <div className='row'>
      <div className='col-12'>
        <Button
          variant='link'
          className='add-hyperlink-button'
          prefix={<Plus />}
          onClick={() => append({ text: '', entityId: '', type: 'chapter' }, { shouldFocus: false })}
        >
          Add Hyperlink
        </Button>
      </div>
      {fields?.map((e, index) => (
        <div className='row' key={e.id}>
          <div className='col-12 d-flex flex-row'>
            <Controller
              control={control}
              name={`${name}.${index}.entityId`}
              render={({ field: { onChange, ref, value } }) => (
                <FormItem label='Navigate to' className='navigate' error={errors?.[index]?.entityId}>
                  <Select
                    placeholder='Chapter to navigate'
                    options={options}
                    name={`${name}.${index}.entityId`}
                    onChange={(item) => onChange(item.value)}
                    value={options.find((n) => n.value === value)}
                    ref={ref}
                  />
                </FormItem>
              )}
            />
            <Input
              {...register(`${name}.${index}.text`)}
              label='Hyperlink text'
              className='hyperlink-text'
              error={errors?.[index]?.text}
            />
            <div>
              <Button variant='text' $danger className='trash-icon-button' onClick={() => remove(index)}>
                <Trash />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddHyperlink;
